import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { pickBy, map } from 'lodash'

import { Button, CheckBox, Textarea } from '@ui/atoms'
import ClientService from '@services/ClientService'
import { stylesList } from '@constants/styles'
import { Lightbox } from '@ui/organisms';
import { ProjectService } from '@services/ProjectService';
import { StyleBox } from './style.style'

const cards = stylesList

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId
})

@connect(mapStateToProps)
class Style extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: cards.reduce((res, card) => ({ ...res, [card.id]: '' }), {}),
      activeCards: [],
      currentStyle: null,
      currentImage: 1,
      showFullImage: false,
      ...props.stepState
    }
  }

  checkCard = (id) => {
    const { activeCards } = this.state
    const index = activeCards.indexOf(id)

    if (index !== -1) {
      this.setState({
        activeCards: [...activeCards.slice(0, index), ...activeCards.slice(index + 1)],
        currentStyle: null
      })
    } else {
      this.setState({ activeCards: [...activeCards, id], currentStyle: id })
    }
  }

  goNext = async () => {
    const { activeProjectId, goNext } = this.props
    const { activeCards, comments } = this.state

    try {
      await ClientService.setStyles({
        id: activeProjectId,
        styles: map(pickBy(cards, card => activeCards.includes(card.id)), style => ({
          name: style.model,
          description: comments[style.id]
        }))
      })

      await ProjectService.finishFilling(activeProjectId)

      goNext()
    } catch (e) {
      console.log(e)
    }
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  hoverCard = (id) => {
    this.setState({
      currentImage: id
    })
  }

  inputChange = (e) => {
    const { value } = e.target

    this.setState(prevState => ({
      comments: { ...prevState.comments, [prevState.currentStyle]: value }
    }))
  }

  iconClick = (id) => {
    this.setState({
      currentStyle: id
    })
  }

  render() {
    const {
      activeCards, currentStyle, currentImage, comments, showFullImage
    } = this.state

    return (
      <StyleBox>
        <div className="page-title">
          Отлично! Теперь нам очень интересно узнать какой вы предпочитаете стиль?
          {' '}
          <span role="img" aria-label="hi">
            👨‍🎨
          </span>
        </div>
        <div className="page-description">
          Чтобы мы смогли более точно подобрать вам дизайнера, пожалуйста, в комментариях уточните, что именно из предложенных картинок вам
          больше всего понравилось.
        </div>
        <div className="tabs">
          <div className="tab">
            <div className="mini-title">
Выберите стиль по душе
            </div>
            <div className="cards-wrapper">
              {cards.map(card => (
                <CheckBox
                  onMouseOver={() => this.hoverCard(card.id)}
                  onFocus={() => undefined}
                  label={card.name}
                  onChange={() => this.checkCard(card.id)}
                  checked={activeCards.includes(card.id)}
                  key={card.name}
                  value={card.name}
                  placeholder={card.name}
                  id={card.id}
                  icon={activeCards.includes(card.id) ? 'edit' : false}
                  onIconClick={this.iconClick}
                  className={classNames({
                    editable: !!comments[card.id],
                    current: currentStyle === card.id
                  })}
                />
              ))}
            </div>
          </div>
          <div className="tab">
            <Lightbox
              isOpen={showFullImage}
              currentImageIndex={0}
              images={[cards[currentImage - 1].img]}
              onCancel={() => this.setState({ showFullImage: false })}
            />
            <div className="fullscreen-icon-wrapper" onClick={() => this.setState({ showFullImage: true })}>
              <i className="material-icons">
fullscreen
              </i>
            </div>
            <img
              alt={cards[currentImage - 1].name}
              className="card-img"
              src={cards[currentImage - 1].img}
            />
            <div className="input-holder">
              {currentStyle > 0 && (
                <Textarea
                  onChange={this.inputChange}
                  value={comments[currentStyle]}
                  placeholder="Опишите, что вам нравится на картинке..."
                  className="description-input"
                />
              )}
            </div>

            <div className="controls">
              {/* <Button text="Назад" onClick={this.goPrev} type="text" /> */}
              <div />
              <Button
                text="Сохранить и продолжить"
                onSave={this.goNext}
                disabled={!activeCards.length}
              />
            </div>
          </div>
        </div>
      </StyleBox>
    )
  }
}

export { Style }
