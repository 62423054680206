import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const DesignerBox = styled.div`
  width: 100%;

  .field-title {
    margin-top: 62px;
    margin-bottom: 16px;
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};

    span {
      margin-right: 30px;
    }
  }

  .age-input {
    width: 160px;
    position: relative;

    input {
      padding-left: 40px;
      padding-bottom: 8px;
      caret-color: ${COLORS.orange};
    }

    &:before {
      position: absolute;
      bottom: 27px;
      font-size: 24px;
      line-height: 24px;
      color: ${COLORS.getBlueColor(0.38)};
      z-index: 3;
    }

    &:first-child {
      margin-right: 30px;

      &:before {
        content: "от";
      }
    }

    &:last-child:before {
      content: "до";
    }
  }

  .next-button {
    margin-top: 48px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .foot-note {
    padding-left: 34px;
    font-size: 14px;
    line-height: 24px;
    color: ${COLORS.getBlueColor(0.38)};
    margin-top: 8px;
    position: relative;

    &:before {
      content: "*";
      position: absolute;
      top: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      left: 10px;
    }
  }

  .slider-title {
    font-size: 28px;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
    margin-top: 48px;
    margin-bottom: 32px;
    font-family: FuturaNewBook-Reg;
  }
  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    .next-btn,
    .prev-btn {
      margin-top: 32px;
      width: 100%;
    }
    .prev-btn {
      margin-top: 12px;
    }
    .field-title {
      position: relative;
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
      color: ${COLORS.getBlueColor()};
      & .title-box {
        max-width: 90%;
        display: block;
      }
      & .step {
        color: ${COLORS.orange};
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .age-input {
        width: 100%;
        margin-right: 0;
      }
  }
`

const CarouselBox = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  box-sizing: border-box;

  .slide {
    height: 368px;
    background: ${COLORS.backgroundLight};
  }
`

export { DesignerBox, CarouselBox }
