import styled from 'styled-components'
import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const StyleBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tabs {
    display: flex;
    justify-content: space-between;
  }

  .tab {
    display: flex;
    flex-direction: column;
    position: relative;

    .fullscreen-icon-wrapper {
      position: absolute;
      top: 468px;
      right: 12px;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      opacity: 0.7;
      background-color: ${COLORS.darkBlue};

      i {
        color: white;
        font-size: 33px;
        cursor: pointer;
      }
    }

    &:last-child {
      width: 455px;
      margin-left: 30px;
    }
  }

  .card-img {
    width: 450px;
    height: 450px;
    margin-top: 60px;
    margin-bottom: 15px;
    border-radius: 8px;
    object-fit: cover;
  }

  .input-holder {
    min-height: 131px;
  }

  .description-input {
    width: 100%;
    height: 140px;

    textarea {
      padding: 15px;
      border: 1px solid ${COLORS.getBlueColor(0.54)};
      border-radius: 6px;
      min-height: 100px;
      box-sizing: border-box;
    }

    .input-underline {
      &:before,
      &:after {
        border-bottom: none !important;
      }
    }
  }

  .controls {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: 100px;
  }

  .mini-title {
    width: 100%;
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
  }

  .cards-wrapper {
    margin-top: 28px;
    max-width: 1250px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .checkbox-container {
    width: 100%;

    &.checked {
      &.editable {
        .icon {
          color: ${COLORS.getBlueColor(0.38)};
          display: inline-block;
        }

        &.current {
          .icon {
            color: ${COLORS.getBlueColor(1)};
          }
        }
      }

      &.current {
        .icon {
          color: ${COLORS.getBlueColor(0.38)};
          display: inline-block;
        }

        &:hover {
          border: 1px solid ${COLORS.skyBlue};
        }
      }
    }

    .icon {
      display: none;
    }
  }
  @media (max-width: ${RESPONSIVE.TABLET}px) {
    padding-left: 200px;
  }
  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    padding-left: 0px;

    .page-title {
      padding-left: 50px;
      line-height: 1;
      font-size: 32px;
    }
    .page-description {
      line-height: 1.22;
      margin-bottom: 30px;
      font-size: 18px;
    }
    .mini-title {
      font-size: 18px;
      margin-bottom: 28px;
      line-height: 1.22;
    }
    .name-slider-container {
      height: 30px;
    }
    .slide-box {
      width: 100%;
      height: auto;
      overflow: hidden;
    }
    .slick-slider.slick-initialized {
      padding: 0;
      width: auto;
    }
    .slide-name-box {
      padding: 0 20px;
      position: absolute;
      font-family: Roboto-Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      position: relative;
      color: ${COLORS.getBlueColor(0.54)};
      & i {
        font-size: 18px;
        position: absolute;
        top: 1px;
        margin-left: 4px;
      }
    }
    .slide-name-box.active {
      color: ${COLORS.orange};
    }
    .slick-active {
      & .slide-name-box {
        color: ${COLORS.getBlueColor(1)};
      }
    }

    .photoContainer {
      img {
        width: 100%;
      }
    }
    .choice-btn {
      width: 100%;
      border-radius: 0;
      margin-top: -5px;
      &.set {
        color: ${COLORS.darkBlue};
        background-color: ${COLORS.getBlueColor(0.05)};
      }
    }
    .description-input {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 10px;
    }

    .controls {
      width: 100%;
      padding: 0 30px;

      button {
        width: 100%;
      }
    }
  }
`

export { StyleBox }
