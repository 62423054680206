import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const DesignerTestPageBox = styled.div`
  min-height: 800px;

  .page-footer {
    display: flex;
    justify-content: flex-end;

    &.second {
      justify-content: flex-start;
    }

    &.third {
      justify-content: space-between;
    }
  }

  .statuses {
    display: flex;
    justify-content: space-between;

    .status {
      &.active {
        .badge {
          width: 250px;
          height: 250px;
          background-color: ${COLORS.orange};
          color: ${COLORS.white};
          margin-top: -7.5px;

          svg {
            fill: ${COLORS.white};
          }
        }

        .price,
        .room {
          color: ${COLORS.getBlueColor()};
        }
      }
    }

    .first-status {
      svg {
        width: 128px;
        height: 128px;
        margin-top: 40px;
        margin-bottom: 15px;
      }
    }

    .second-status {
      .icons {
        margin-top: 68px;
        margin-bottom: 30px;

        svg {
          width: 72px;
          height: 72px;
        }
      }
    }

    .third-status {
      .icons {
        margin-top: 55px;
        margin-bottom: 28px;

        .middle-icon {
          width: 88px;
          height: 88px;
        }
      }
    }

    .badge {
      width: 235px;
      height: 235px;
      border-radius: 8px;
      background-color: ${COLORS.getBlueColor(0.08)};
      margin-bottom: 31px;
      color: ${COLORS.getBlueColor(0.38)};
      font-weight: 500;
      font-size: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        fill: ${COLORS.getBlueColor(0.54)};
      }
    }

    .price {
      color: ${COLORS.getBlueColor(0.54)};
      font-size: 34px;
      line-height: 24px;
      text-align: center;
      font-family: Roboto-Regular;
    }

    .room {
      color: ${COLORS.getBlueColor(0.54)};
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      font-family: Roboto-Regular;
      margin-top: 5px;
    }
  }

  .text {
    color: ${COLORS.getBlueColor()};
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto-Regular;
    margin-top: 24px;
  }

  .test-text {
    font-size: 16px;
    color: ${COLORS.getBlueColor()};
    line-height: 24px;
    font-family: Roboto-Regular;
    margin-bottom: 37px;
    margin-top: -32px;
  }

  .download-block {
    display: flex;
    align-items: center;
  }

  .download-desc {
    font-size: 12px;
    line-height: 20px;
    font-family: Roboto-Regular;
    color: ${COLORS.getBlueColor(0.54)};
    max-width: 300px;
    margin-left: 32px;
    margin-top: -22px;
  }
`
export { DesignerTestPageBox }
