import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const OrderedProductsBox = styled.div`
  background-color: ${COLORS.getBlueColor(0.02)};
  padding: 30px;
  border-radius: 8px;
  position: relative;
  margin: 0 -30px 16px -30px;
  border: solid 1px rgba(37, 60, 94, 0.12);
  
  .title {
    margin-bottom: 24px;
  
    .hide {
      position: absolute;
      cursor: pointer;
      top: 36px;
      right: 30px;
      opacity: 0.54;
      font-family: Roboto-Regular;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: normal;
      & svg {
        margin-right: 6px;
        max-width: 12px;
        max-height: 8px;
      }
      &.hidden svg {
        transform: rotate(180deg);
      }
    }
    
    span {
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }
  }
  
  .list-box {
    margin-top: 4px;
    
    .item-box {
      display: inline-flex;
      width: 100%;
      margin-bottom: 16px;
      
      .item-name {
        font-family: Roboto-Regular;
        font-size: 16px;
        line-height: 24px;
        color: ${COLORS.getBlueColor(0.7)};
      }
      
      .item-color {
        font-size: 16px;
        font-family: Roboto-Regular;
        color: ${COLORS.getBlueColor(0.38)};
        line-height: 24px;
      }
      
      .img-box {
        width: 48px;
        height: 48px;
        
        img {
          width: 100%;
          height: 100%;
        }
      }
      
      .additional-box {
        margin-right: 16px;
      }
      
      .item-price {
        font-size: 20px;
        font-family: FuturaNewBold-Reg;
        color: ${COLORS.getBlueColor(0.87)};
        font-weight: 600;
      }
      
      .item-count {
        font-size: 16px;
        line-height: 24px;
        color: ${COLORS.getBlueColor(0.38)};
        text-align: right;
      }
      
      .center-box {
        margin-left: 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .hide-box {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }
`

export { OrderedProductsBox }
