function breakPriceToDigits(value) {
  const valueStr = value.toString()

  return valueStr
    .split('')
    .reverse()
    .join('')
    .replace(/(.{3})/g, '$1 ')
    .split('')
    .reverse()
    .join('')
    .trim()
}

export default breakPriceToDigits
export { breakPriceToDigits }
