import React from 'react'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'

import { Icon } from '@ui/atoms'
import { StepsBox } from './steps.style'

const Steps = ({
  steps, currentStep, maxStep, onSelect, activeStage, prevStage, nextStage, ...rest
}) => (
  <StepsBox {...rest}>
    {!!prevStage && (
      <div
        className={classnames('stage-change prev', {
          active: prevStage.active
        })}
        onClick={prevStage.active && prevStage.onClick}
      >
        <Icon icon="chevronLeft" />
        {prevStage.name}
      </div>
    )}
    {steps.map((step, index) => (
      <React.Fragment key={index}>
        <span
          key={step.name}
          className={classnames('step', {
            active: currentStep > maxStep - 1 ? index === maxStep - 1 : index === currentStep - 1,
            completed: currentStep - 1 > index && index < maxStep - 1,
            clickable: typeof onSelect === 'function' && index <= activeStage && !step.disableReturn
          })}
          onClick={() => !step.disableReturn && typeof onSelect === 'function' && index <= activeStage && onSelect(index)}
        >
          {step.name}
        </span>
        {index < maxStep - 1 && (
          <div
            className={classnames('chevron-container', {
              completed: currentStep - 1 > index && index < maxStep - 1
            })}
          >
            <Icon icon="chevronRight" className="chevron" />
          </div>
        )}
      </React.Fragment>
    ))}
    {!!nextStage && (
      <div className="stage-change-container next">
        <div
          className={classnames('stage-change next', {
            active: nextStage.active
          })}
          onClick={() => nextStage.active && nextStage.onClick && nextStage.onClick()}
        >
          {nextStage.name}
          <Icon icon="chevronRight" />
        </div>
      </div>
    )}
  </StepsBox>
)

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStep: PropTypes.number.isRequired
}

export { Steps }
