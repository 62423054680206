const AnalyticsService = {
  pageVisited(page) {
    if (window.yaCounter46734402) {
      window.yaCounter46734402.reachGoal(`page_visited_${page}`)
    }

    if (window.ga) {
      window.ga('set', 'page', page)
      window.ga('send', 'pageview')
    }
  }
}

export { AnalyticsService }
