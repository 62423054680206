import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Radio, Input } from '@ui/atoms'

import ClientService from '@services/ClientService'
import { QuestionsCarouselMobile } from '@ui/organisms/questionsCarousel.mobile'
import { DesignerBox, CarouselBox } from './designer.style'

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId
})

@connect(mapStateToProps)
class DesignerMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      subStep: 1,
      sex: 'Unknown',
      age: [18, 90],
      personalMeeting: 'false',
      authorSupervision: 'false',
      questions: [],
      answers: [],
      ...props.stepState
    }
  }

  async componentDidMount() {
    try {
      const questionResponse = await ClientService.getQuestions()

      this.setState({
        questions: questionResponse.result,
        answers: questionResponse.result.reduce(
          (res, question) => ({
            ...res,
            [question.id]: null
          }),
          {}
        )
      })
    } catch (e) {
      console.log(e)
    }
  }

  goNext = () => {
    const { goNext } = this.props

    goNext(this.state)
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  onAgeIntervalChange = (values) => {
    this.setState({ age: values })
  }

  onNextBtn = () => {
    this.setState(prevState => ({
      step: prevState.step + 1
    }))
  }

  onPrevBtn = () => {
    this.setState(prevState => ({
      step: prevState.step - 1
    }))
  }

  onAnswerChange = (name, value) => {
    this.setState(state => ({
      answers: {
        ...state.answers,
        [name]: value
      }
    }))
  }

  onAllAnswered = async () => {
    const { activeProjectId } = this.props
    const {
      sex,
      age,
      personalMeeting,
      authorSupervision,
      questions,
      answers
    } = this.state
    try {
      await ClientService.setDesignerRequirements({
        id: activeProjectId,
        gender: sex,
        ageFrom: age[0],
        ageTo: age[1],
        needPersonalMeeting: personalMeeting === 'true',
        needPersonalControl: authorSupervision === 'true',
        testing: {
          questions: questions.map(quest => ({
            index: quest.id,
            answer: parseInt(answers[quest.id], 10)
          }))
        }
      })

      this.goNext()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const {
      step,
      sex,
      age,
      personalMeeting,
      authorSupervision,
      questions,
      answers
    } = this.state

    return (
      <React.Fragment>
        <DesignerBox>
          {step === 1 && (
            <div>
              <div className="field-title">
                <span className="title-box">
Выберите пол
                </span>
                <div className="step">
                  {step}
                  /4
                </div>
              </div>
              <Radio
                value={sex}
                onChange={e => this.setState({ sex: e.target.value })}
                items={[
                  {
                    value: 'Male',
                    label: 'Мужчина'
                  },
                  {
                    value: 'Female',
                    label: 'Женщина'
                  },
                  {
                    value: 'Unknown',
                    label: 'Не важно'
                  }
                ]}
              />
            </div>
          )}
          {step === 2 && (
            <React.Fragment>
              <div className="field-title">
                <span className="title-box">
Возрастной промежуток
                </span>
                {' '}
                <div className="step">
                  {step}
                  /4
                </div>
              </div>
              <div>
                <Input
                  className="age-input"
                  size="big"
                  value={String(age[0])}
                  onChange={(e) => {
                    const { value } = e.target

                    this.setState(state => ({ age: [value, state.age[1]] }))
                  }}
                  mask="999"
                />
                <Input
                  className="age-input"
                  size="big"
                  value={String(age[1])}
                  onChange={(e) => {
                    const { value } = e.target

                    this.setState(state => ({ age: [state.age[0], value] }))
                  }}
                  mask="999"
                />
              </div>
            </React.Fragment>
          )}
          {step === 3 && (
            <div>
              <div className="field-title">
                <span className="title-box">
                  Понадобится ли личная встреча с дизайнером?
                </span>
                <div className="step">
                  {step}
                  /4
                </div>
              </div>
              <Radio
                value={personalMeeting}
                onChange={e => this.setState({ personalMeeting: e.target.value })
                }
                items={[
                  {
                    value: 'true',
                    label: 'Да, конечно'
                  },
                  {
                    value: 'false',
                    label: 'Нет, не понадобится'
                  }
                ]}
              />
            </div>
          )}
          {step === 4 && (
            <div>
              <div className="field-title">
                <span className="title-box">
                  Нужет авторский надзор во время ремонта?
                </span>
                <div className="step">
                  {step}
                  /4
                </div>
              </div>
              <Radio
                value={authorSupervision}
                onChange={e => this.setState({ authorSupervision: e.target.value })
                }
                items={[
                  {
                    value: 'true',
                    label: 'Да, это важно'
                  },
                  {
                    value: 'false',
                    label: 'Нет, не нужен'
                  }
                ]}
              />
            </div>
          )}
          {step === 5 && (
            <div>
              <CarouselBox>
                <QuestionsCarouselMobile
                  onAllAnswered={this.onAllAnswered}
                  questions={questions}
                  answers={answers}
                  onAnswerChange={this.onAnswerChange}
                />
              </CarouselBox>
            </div>
          )}
          {step < 5 && (
            <div>
              <Button
                text="Далее"
                type="filled"
                size="large"
                className="next-btn"
                onClick={() => this.onNextBtn()}
              />
              {step > 1 && (
                <Button
                  text="Назад"
                  type="text"
                  size="large"
                  className="prev-btn"
                  onClick={() => this.onPrevBtn()}
                />
              )}
            </div>
          )}
        </DesignerBox>
      </React.Fragment>
    )
  }
}

export { DesignerMobile }
