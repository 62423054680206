import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'
import { ProjectsSelect } from '@pages/Marketplace/common/ProjectsSelect'
import { Modal } from '@ui/molecules'
import { Product } from '@pages/Marketplace/Product'
import { breakPriceToDigits } from '@helpers'
import { ProductCardBox, ModalBody } from './productCard.style'

@withRouter
class ProductCard extends Component {
  state = {
    isModalOpen: false
  }

  render() {
    const { product, history } = this.props

    const { isModalOpen } = this.state

    return (
      <ProductCardBox>
        <div onClick={() => history.push(`${ROUTES.product}${product.id}`)} className="wrapper">
          <div className="image-wrapper">
            <img className="image" src={product.photoUrl} alt="" />

            <div
              className="show"
              onClick={(e) => {
                e.stopPropagation()
                this.setState({ isModalOpen: true })
              }}
            >
              Быстрый просмотр
            </div>
          </div>

          <div className="price">
            {`${breakPriceToDigits(product.price)} ₽`}
            {' '}
          </div>
          <div className="discount">
            {product.discount && `${breakPriceToDigits(product.discount)} ₽`}
          </div>
          <div className="name">
            {product.name}
          </div>
          <ProjectsSelect className="project-select" productId={product.id} />
        </div>

        <Modal isOpen={isModalOpen} onCancel={() => this.setState({ isModalOpen: false })}>
          <ModalBody>
            <Product className="product" isModal productId={product.id} />
          </ModalBody>
        </Modal>
      </ProductCardBox>
    )
  }
}

export { ProductCard }
