import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const FormatBox = styled.div`
  min-height: 800px;

  .data-box {
    width: 540px;
  }

  .policy {
    color: ${COLORS.getBlueColor(0.38)};
    font-size: 12px;
    line-height: 20px;
    font-family: Roboto-Regular;
    width: 350px;

    .button-name {
      font-family: Roboto-Medium;
    }

    .link {
      cursor: pointer;
      border-bottom: 1px solid ${COLORS.getBlueColor(0.12)};
    }
  }

  .page-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export { FormatBox }
