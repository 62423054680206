import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { get } from 'lodash'
import MediaQuery from 'react-responsive'

import { Steps } from '@ui/organisms/steps'
import {
  ImageBox,
  HeaderBox
} from '@pages/client/FirstUserSteps/firstUserSteps.style'
import { DesignerHelloPage } from '@pages/designer/FirstDesignerSteps/Hello'
import { TellAboutYourself } from '@pages/designer/FirstDesignerSteps/TellAboutYourself'
import { DesignerTestPage } from '@pages/designer/FirstDesignerSteps/Test'
import { Portfolio } from '@pages/designer/FirstDesignerSteps/Portfolio'
import { Survey } from '@pages/designer/FirstDesignerSteps/Survey'
import { Format } from '@pages/designer/FirstDesignerSteps/Format'
import { MainFooter } from '@ui/organisms/mainFooter'
import { FirstDesignerStepsBox } from '@pages/designer/FirstDesignerSteps/firstDesignerSteps.style'
import { DESIGNER_STAGES } from '@constants'
import { AnalyticsService } from '@services/AnalyticsService'
import { pages } from '@pages/designer/FirstDesignerSteps/configs/analytics'
import logo from '@assets/images/logo.png'
import { Stub } from '@ui/organisms/stub'

import { RESPONSIVE } from '@constants/responsive'
import {
  img1, img2, img3, img4, img5, img6
} from './configs/images'

const steps = [
  {
    name: 'Авторизация',
    stepForStepsNumber: 1,
    renderComponent: ({ state, ...rest }) => (
      <DesignerHelloPage stepState={state} {...rest} />
    ),
    imgSrc: img1
  },
  {
    name: 'Знакомство',
    stepForStepsNumber: 2,
    renderComponent: ({ state, ...rest }) => (
      <TellAboutYourself stepState={state} {...rest} />
    ),
    imgSrc: img2
  },
  {
    name: 'Задание',
    stepForStepsNumber: 3,
    renderComponent: ({ state, ...rest }) => (
      <DesignerTestPage stepState={state} {...rest} />
    ),
    imgSrc: img3
  },
  {
    name: 'Портфолио',
    stepForStepsNumber: 4,
    renderComponent: ({ goNext, state, goPrev }) => (
      <Portfolio goNext={goNext} stepState={state} goPrev={goPrev} />
    ),
    imgSrc: img4
  },
  {
    name: 'Опрос',
    stepForStepsNumber: 5,
    renderComponent: ({ state, ...rest }) => (
      <Survey stepState={state} {...rest} />
    ),
    imgSrc: img5
  },
  {
    name: 'Формат работы',
    stepForStepsNumber: 6,
    renderComponent: ({ state, ...rest }) => (
      <Format stepState={state} {...rest} />
    ),
    imgSrc: img6
  }
]

const mapStateToProps = state => ({
  userData: state.user.userData,
  designer: state.designer
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: 'LOGOUT_REQUEST' })
})

const stepsForSteps = steps.filter(step => step.visible !== false)
const defaultStepsData = steps.reduce(
  (res, el, index) => ({
    ...res,
    [index]: {}
  }),
  {}
)

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class FirstDesignerSteps extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stepsData: defaultStepsData,
      globalState: {},
      currentStep: 1
    }
  }

  changeGlobalState = (data) => {
    this.setState(prevState => ({
      globalState: { ...prevState.globalState, ...data }
    }))
  }

  componentDidMount() {
    const { designer, userData } = this.props

    if (
      designer.basicInfo.stage === DESIGNER_STAGES.TestApproved
      || designer.basicInfo.stage === DESIGNER_STAGES.SendTestTask
      || get(designer, 'basicInfo.basic.name', false)
    ) {
      this.goToStep(3)

      return
    }

    if (userData.phone) {
      this.goToStep(2)

      return
    }

    AnalyticsService.pageVisited(pages[1])
  }

  goToStep = (step) => {
    this.setState({ currentStep: step })

    AnalyticsService.pageVisited(pages[step])

    window.scroll(window, 0)
  }

  goNext = () => {
    const { currentStep } = this.state

    this.setState(state => ({ currentStep: state.currentStep + 1 }))

    AnalyticsService.pageVisited(pages[currentStep + 1])

    window.scroll(window, 0)
  }

  goPrev = () => {
    const { currentStep } = this.state

    this.setState(state => ({ currentStep: state.currentStep - 1 }))

    AnalyticsService.pageVisited(pages[currentStep - 1])

    window.scroll(window, 0)
  }

  render() {
    const { stepsData, globalState, currentStep } = this.state

    return (
      <FirstDesignerStepsBox>
        <MediaQuery minWidth={RESPONSIVE.TABLET}>
          <ImageBox src={steps[currentStep - 1].imgSrc} />
          <div className="page">
            <HeaderBox>
              <div className="header-container">
                <a className="logo" href="/">
                  <img src={logo} alt="logo" />
                </a>
                <Steps
                  steps={stepsForSteps}
                  currentStep={steps[currentStep - 1].stepForStepsNumber}
                  maxStep={6}
                />
              </div>
            </HeaderBox>

            {steps[currentStep - 1].renderComponent({
              goNext: this.goNext,
              state: stepsData[currentStep],
              goPrev: this.goPrev,
              globalState,
              changeGlobalState: this.changeGlobalState
            })}
          </div>
          <MainFooter className="first-step-footer" />
        </MediaQuery>
        <MediaQuery maxWidth={RESPONSIVE.TABLET}>
          <Stub />
        </MediaQuery>
      </FirstDesignerStepsBox>
    )
  }
}

export { FirstDesignerSteps }
