import {
  SET_CATEGORIES,
  SET_ACTIVE_CATEGORY,
  SET_SEARCH_PHRASE,
  SET_ACTIVE_SUBCATEGORY,
  SET_ACTIVE_PRODUCT
} from '../constants/marketplace'

const initialState = {
  categories: [],
  activeCategory: null,
  activeSubcategory: null,
  activeProduct: null,
  searchPhrase: ''
}

export default function projectState(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIES:
      return { ...state, categories: action.payload }
    case SET_ACTIVE_CATEGORY:
      return { ...state, activeCategory: action.payload }
    case SET_ACTIVE_SUBCATEGORY:
      return { ...state, activeSubcategory: action.payload }
    case SET_ACTIVE_PRODUCT:
      return { ...state, activeProduct: action.payload }
    case SET_SEARCH_PHRASE:
      return { ...state, searchPhrase: action.payload }
    default:
      return state
  }
}
