import React, { Component } from 'react'
import queryString from 'query-string'
import { withRouter } from 'react-router'

import { breakPriceToDigits, setLocationSearch } from '@helpers'
import { ProductCard } from '@pages/Marketplace/common/ProductCard'
import { Pagination } from '@ui/molecules'
import { MarketplaceService } from '@services/MarketplaceService'
import { Preloader } from '@ui/atoms'
import { SearchBox } from './search.style'
import { PRODUCTS_COUNT_PER_PAGE } from '@constants'


@withRouter
class Search extends Component {
  state = {
    products: [],
    totalCount: 0,
    currentPage: 1,
    loading: true
  }

  debounceTime = 1000

  componentDidUpdate(prevProps) {
    const { searchPhrase } = this.props

    if (prevProps.searchPhrase !== searchPhrase) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.getProducts()
      }, this.debounceTime)
    }
  }

  componentDidMount() {
    const { location } = this.props

    const { page } = queryString.parse(location.search)

    if (page) {
      this.setState({ currentPage: page })
    }

    this.timeout = setTimeout(() => {
      this.getProducts(page)
    }, this.debounceTime)
  }

  getProducts = async (page = 1) => {
    try {
      const { searchPhrase } = this.props

      this.setState({ loading: true })

      const params = {
        SearchWords: searchPhrase,
        Count: PRODUCTS_COUNT_PER_PAGE,
        Offset: (page - 1) * PRODUCTS_COUNT_PER_PAGE
      }

      const response = await MarketplaceService.getProducts(params)

      this.setState({ totalCount: response.result.allCount, products: response.result.products, loading: false })
    } catch (error) {
      console.error(error)
    }
  }

  onPageChange = (page) => {
    setLocationSearch('page', page)

    this.setState({ currentPage: page })
    this.getProducts(page)
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { searchPhrase } = this.props

    const {
      products, totalCount, currentPage, loading
    } = this.state

    if (loading) {
      return (
        <SearchBox>
          <Preloader />
        </SearchBox>
      )
    }

    return (
      <SearchBox>
        <div className="page-title">
Мы нашли для вас
        </div>

        <div className="products-count">
          {`${breakPriceToDigits(totalCount)} товаров по запросу "${searchPhrase}"`}
        </div>

        <div className="products-wrapper">
          {products.map(product => <ProductCard product={product} key={product.id} />)}
        </div>

        {products.length
          ? (
            <div className="pagination-wrapper">
              <Pagination onChangePage={this.onPageChange} perPage={PRODUCTS_COUNT_PER_PAGE} currentPage={currentPage} total={totalCount} />

              <div className="go-top" onClick={this.scrollToTop}>
                <i className="material-icons">
expand_less
                </i>
                <span>
Наверх
                </span>
              </div>
            </div>
          ) : null}
      </SearchBox>
    )
  }
}

export { Search }
