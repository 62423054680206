import { put, takeLatest, call } from 'redux-saga/effects'

import ClientService from '@services/ClientService'
import { UPDATE_PROJECTS_LIST } from '../constants/project'
import { setProjectsList } from '../actions/project'

function* updateProjectsList(action) {
  try {
    const { result } = yield call(ClientService.getProjectsList)
    const { projects } = result

    yield put(setProjectsList(projects))

    if (typeof action.cb === 'function') {
      action.cb()
    }

    return false
  } catch (e) {
    console.error(e)
    return false
  }
}

function* updateProjectsListWatcher() {
  yield takeLatest(UPDATE_PROJECTS_LIST, updateProjectsList)
}

export { updateProjectsListWatcher }
