import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const ProjectsSelectBox = styled.div`
  width: 100%;
  position: relative;

  .toggle {
    height: 40px;
    position: relative;
    padding: 9px 16px;
    color: ${COLORS.darkBlue};
    display: flex;
    justify-content: space-between;
    font-family: Roboto-Regular;
    font-size: 14px;
    border-radius: 6px;
    background-color: white;
    border: solid 1px rgba(37, 60, 94, 0.54);
    cursor: pointer;
  }

  .dropdown {
    z-index: 1000;
    width: 300px;
    position: absolute;
    top: 42px;
    left: 0;
    padding: 20px 8px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(37, 60, 94, 0.24);
    background-color: #ffffff;
    border: solid 1px rgba(37, 60, 94, 0.12);
    overflow: auto;
    max-height: 350px;

    .project-title {
      color: ${COLORS.orange};
      font-family: Roboto-Regular;
      font-size: 14px;
      padding-left: 9px;
    }

    .checkboxes-wrapper {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .control {
      width: 35px;
      height: 35px;
    }

    .checbox-label {
      font-size: 14px;
    }

    .room-info {
      .control {
        width: 35px !important;
        height: 35px !important;
      }

      .checkbox-container {
        padding-left: 12px;
      }

      .checkbox-label {
        font-size: 14px !important;
      }

      .suffix {
        top: 8px;
      }
    }
  }
`

export { ProjectsSelectBox }
