import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const SearchBox = styled.div`
  margin-top: 160px;
  min-height: 60vh;

  .products-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .products-count {
    margin-top: -12px;
    margin-bottom: 32px;
    opacity: 0.38;
    font-family: FuturaNewMedium-Reg;
    font-size: 28px;
    font-weight: 500;
    color: ${COLORS.darkBlue};
  }

  .filters-wrapper {
    display: flex;

    .filter-select {
      width: 255px;
    }

    .filter-input {
      margin-left: 30px;
      width: 130px;
    }
  }

  .controls {
    margin-bottom: 20px;
  }

  .pagination-wrapper {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .go-top {
      cursor: pointer;
      display: flex;
      padding-top: 8px;
      margin-right: 8px;
      width: 96px;
      height: 40px;
      border-radius: 6px;
      border: solid 1px ${COLORS.lightGrey};
      color: ${COLORS.darkBlue};
      font-size: 14px;
      font-family: Roboto-Medium;
      padding-left: 5px;

      i {
        margin-top: -2px;
        margin-right: 3px;
      }
    }
  }
`

export { SearchBox }
