import React, { Component } from 'react'
import { pickBy } from 'lodash'
import { connect } from 'react-redux'

import { Button, CheckBox, CardSelect } from '@ui/atoms'
import ClientService from '@services/ClientService'
import roomAssignment from '@constants/rooms'
import { InfoBox } from './info.style'
import { whyCheckBox } from './config/whyCheckbox'
import { typeOfDwelling } from './config/typeOfDwelling'

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId
})

@connect(mapStateToProps)
class Info extends Component {
  constructor(props) {
    super(props)
    this.state = {
      other: '',
      currentRoom: [],
      currentDwelling: [],
      checked: whyCheckBox.reduce(
        (a, c) => ({
          ...a,
          [c.id]: false
        }),
        {}
      ),
      ...props.stepState
    }
  }

  goNext = () => {
    const { goNext } = this.props

    goNext(this.state)
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  setRoom = (card) => {
    const { currentRoom } = this.state
    const index = currentRoom.findIndex(r => r.model === card.model)
    if (index !== -1) {
      currentRoom.splice(index, 1)
    } else {
      currentRoom.push(card)
    }
    this.setState({ currentRoom })
  }

  changeCountRoom = ({ count, card }) => {
    const { currentRoom } = this.state
    const index = currentRoom.findIndex(r => r.model === card.model)
    currentRoom[index].count = count
    this.setState({ currentRoom })
  }

  setDwelling = (card) => {
    this.setState({ currentDwelling: [card] })
  }

  save = async () => {
    try {
      const {
        currentRoom, checked, currentDwelling, other
      } = this.state

      const { activeProjectId } = this.props

      await ClientService.setAdditionalInfo({
        id: activeProjectId,
        objectType: currentDwelling[0].model,
        rooms: currentRoom.map(room => ({ purpose: room.model, count: room.count || 1 })),
        reasons: whyCheckBox
          .filter(reason => pickBy(checked, val => val)[reason.id])
          .map(reason => ({
            name: reason.model,
            description: reason.model === 'Custom' ? other : '',
          }))
      })

      this.goNext()
    } catch (e) {
      console.log(e)
    }
  }

  checkboxChange = (id, value) => {
    this.setState(state => ({
      ...state,
      checked: {
        ...state.checked,
        [id]: value
      }
    }))
  }

  render() {
    const {
      currentRoom, checked, other, currentDwelling
    } = this.state

    return (
      <InfoBox>
        <div className="field-title">
Выбери тип объекта
        </div>
        <div className="cards-wrapper">
          {typeOfDwelling.map(card => (
            <CardSelect key={card.id} card={card} onChange={this.setDwelling} active={!!currentDwelling.find(c => c.id === card.id)} />
          ))}
        </div>
        <div className="field-title">
С какой комнатой мы будем работать? И сколько их?
        </div>
        <div className="cards-wrapper with-counter">
          {roomAssignment.map(card => (
            <CardSelect
              key={card.model}
              onCounterChange={this.changeCountRoom}
              card={card}
              withCounter
              onChange={this.setRoom}
              active={!!currentRoom.find(c => c.model === card.model)}
            />
          ))}
        </div>
        <div className="field-title">
Почему вы хотите изменить дизайн комнаты?
        </div>
        <div className="checkbox-form">
          {whyCheckBox.map(checkbox => (
            <CheckBox
              key={checkbox.id}
              onChange={value => this.checkboxChange(checkbox.id, value)}
              withInput={checkbox.withInput}
              label={checkbox.label}
              checked={checked[checkbox.id]}
              onInputChange={value => this.setState({ other: value })}
              inputValue={other}
              placeholder={checkbox.placeholder}
            />
          ))}
        </div>
        <div className="button-container">
          {/* <Button text="Назад" type="text" className="next-button" onClick={this.goPrev} /> */}
          <div />
          <Button
            text="Сохранить и продолжить"
            color="primary"
            onSave={this.save}
            disabled={!currentDwelling.length || !currentRoom.length || !Object.keys(pickBy(checked, value => value)).length}
          />
        </div>
      </InfoBox>
    )
  }
}

export { Info }
