import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Scrollbars } from 'react-custom-scrollbars'

import { Icon } from '@ui/atoms'
import { Search } from '@ui/organisms/search'
import ClientService from '@services/ClientService'
import { HeaderBox } from '@ui/organisms/mainHeader.style'
import { isClient } from '@helpers/helpers'
import logo from '@assets/images/logo.png'
import {
  setActiveProjectId,
  updateProjectsList as updateProjectsListAction
} from '../../redux/actions/project'
import { ROUTES } from '@constants'

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId,
  userData: state.user.userData,
  projectsList: state.project.list
})

const mapDispatchToProps = dispatch => ({
  setActiveProject: id => dispatch(setActiveProjectId(id)),
  logout: () => dispatch({ type: 'LOGOUT_REQUEST' }),
  updateProjectsList: cb => dispatch(updateProjectsListAction(cb))
})

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class MainHeader extends React.Component {
  state = {
    projectListOpened: false,
    profileOpened: false
  }

  async componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideProjectMenu)
    document.addEventListener('click', this.handleClickOutsideProfileMenu)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideProjectMenu)
    document.removeEventListener('click', this.handleClickOutsideProfileMenu)
  }

  handleClickOutsideProjectMenu = (event) => {
    if (this.projectMenu && !this.projectMenu.contains(event.target)) {
      this.setState({ projectListOpened: false })
    }
  }

  handleClickOutsideProfileMenu = (event) => {
    if (this.profileMenu && !this.profileMenu.contains(event.target)) {
      this.setState({ profileOpened: false })
    }
  }

  handleProjectMenuRef = (ref) => {
    this.projectMenu = ref
  }

  handleProfileMenuRef = (ref) => {
    this.profileMenu = ref
  }

  formatPhone = (phone) => {
    const splited = phone.split('')

    return (
      `+${splited[0]} (${splited[1]}${splited[2]}${splited[3]}) `
      + `${splited[4]}${splited[5]}${splited[6]} - ${splited[7]}${splited[8]} - ${
        splited[9]
      }${splited[10]}`
    )
  }

  render() {
    const {
      children,
      activeProjectId,
      userData,
      logout,
      setActiveProject,
      short,
      updateProjectsList,
      projectsList,
      activeTab,
      history,
      withSearch
    } = this.props

    const { projectListOpened, profileOpened } = this.state

    // const activeProject = projects.find(project => project.id === activeProjectId)
    // const activeProjectName = activeProject ? activeProject.name : ''

    return (
      <HeaderBox className={classNames({ short })}>
        <div className="top-row">
          <div className="top-row-content">
            {activeTab === 'projects' && (
              <div className="left-side">
                {short ? (
                  <div className="new-project">
Создать новый проект
                  </div>
                ) : (
                  <React.Fragment>
                    <a className="logo" href="/">
                      <img src={logo} alt="logo" />
                    </a>
                    {!!projectsList.length && (
                      <div className="active-project-block">
                        <div className="project">
ПРОЕКТ:
                        </div>
                        <div className="name">
                          {
                            projectsList.find(
                              project => project.id === activeProjectId
                            ).name
                          }
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
            {activeTab === 'marketplace' && (
              <div className="left-side">
                <a className="logo" href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            )}
            <div className="right-side">
              <div className="tabs">
                <div
                  className={classNames('tab', {
                    active: activeTab === 'marketplace'
                  })}
                  onClick={() => history.replace(ROUTES.marketplace)}
                >
                  МАГАЗИН
                </div>
                <div
                  className={classNames('tab projects', {
                    opened: projectListOpened,
                    active: activeTab === 'projects'
                  })}
                  ref={this.handleProjectMenuRef}
                >
                  <div
                    className="trigger"
                    onClick={(event) => {
                      if (!projectsList.length) {
                        history.push(ROUTES.main)
                        return
                      }

                      if (
                        event.target
                        !== this.projectMenu.getElementsByClassName('trigger')[0]
                      ) {
                        return
                      }

                      this.setState(prevState => ({
                        projectListOpened: !prevState.projectListOpened
                      }))
                    }}
                  />
                  <span>
МОИ ПРОЕКТЫ
                  </span>
                  {!!projectsList.length && (
                    <Icon icon="dropdownArrow" className="arrow" />
                  )}
                  {projectListOpened && (
                    <div className="pop-over">
                      <Scrollbars autoHeight autoHeightMax={300}>
                        <div className="projects-list body">
                          {projectsList.map(project => (
                            <div
                              className={classNames('project', {
                                inactive: project.status === 1,
                                active: project.id === activeProjectId
                              })}
                              key={`project-${project.id}`}
                              onClick={() => {
                                history.replace(ROUTES.main)

                                setActiveProject(project.id)
                              }}
                            >
                              {project.name}
                            </div>
                          ))}
                        </div>
                      </Scrollbars>
                      {isClient() && (
                        <div
                          className="add-project"
                          onClick={async () => {
                            const { setActiveProject } = this.props

                            const {
                              result
                            } = await ClientService.createProject()

                            await ClientService.setBasicInfo({
                              projectName: 'Новый проект',
                              city: 'Unknown',
                              id: result
                            })

                            updateProjectsList(() => {
                              setActiveProject(result)
                            })
                          }}
                        >
                          <Icon icon="circledCross" />
                          Создать новый проект
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="notifications menu-control"> */}
              {/* <Icon icon="notification" /> */}
              {/* </div> */}
              <div
                className={classNames('profile menu-control', {
                  opened: profileOpened
                })}
                ref={this.handleProfileMenuRef}
              >
                <div
                  className="trigger"
                  onClick={(event) => {
                    if (
                      event.target
                      !== this.profileMenu.getElementsByClassName('trigger')[0]
                    ) {
                      return
                    }

                    this.setState(prevState => ({
                      profileOpened: !prevState.profileOpened
                    }))
                  }}
                />
                <Icon icon="user" />
                {profileOpened && (
                  <div className="pop-over">
                    <div className="body">
                      <div className="name">
                        {userData.name}
                      </div>
                      <div className="phone">
                        {userData.phone.includes('+')
                          ? userData.phone
                          : this.formatPhone(userData.phone)}
                      </div>
                    </div>
                    <div className="logout" onClick={logout}>
                      Выйти из аккаунта
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-row">
          {children}
        </div>

        {withSearch && (
        <div className="search-box">
          {' '}
          <Search />
        </div>
        )}
      </HeaderBox>
    )
  }
}

export { MainHeader }
