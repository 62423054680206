import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const ProductBox = styled.div`
  display: flex;
  min-width: 904px;
  min-height: 714px;
  flex-wrap: wrap;
  margin-top: ${({ isModal }) => (isModal ? 0 : '150px')};

  .left-side {
    .img-wrapper {
      position: relative;
      width: 714px;
      height: 714px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        .arrow-wrapper {
          display: block;
        }
      }

      .img {
        width: 100%;
        height: auto;
      }

      .arrow-wrapper {
        display: none;
        position: absolute;
        top: 50%;
        cursor: pointer;
        width: 48px;
        height: 48px;
        background-color: rgba(37, 60, 94, 0.5);

        &.disabled {
          cursor: default;
          pointer-events: none;

          .arrow {
            color: ${COLORS.lineLight};
          }
        }

        .arrow {
          font-size: 47px;
          color: white;
        }

        &.left {
          left: 0;
        }

        &.right {
          right: 0;
        }
      }
    }

    .images-slider {
      margin-top: 12px;
      position: relative;
      width: 443px;

      .arrow {
        position: absolute;
        top: 27px;
        cursor: pointer;

        &.left {
          left: -25px;
        }

        &.right {
          right: -25px;
        }

        &.disabled {
          cursor: default;
          color: ${COLORS.lineLight};
          pointer-events: none;
        }
      }

      .slides-wrapper {
        overflow: hidden;

        .slides {
          width: max-content;
          display: flex;

          .img-preview-wrapper {
            position: relative;
            margin-right: 13px;
            width: 78px;
            height: 78px;
            cursor: pointer;

            &:hover {
              .img-preview-overlay {
                display: none;
              }
            }

            &.active {
              padding: 7px;
              border: 1px solid ${COLORS.darkBlue};
              border-radius: 6px;
              background-color: rgba(37, 60, 94, 0.05);

              .img-preview-overlay {
                display: none;
              }
            }

            .img-preview {
              width: 100%;
              height: 100%;
              border-radius: 6px;
            }

            .img-preview-overlay {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0.7;
              background-color: white;
              top: 0;
            }
          }
        }
      }
    }
  }

  .right-side {
    margin-left: 30px;
    width: 350px;

    .product-name {
      font-family: FuturaNewMedium-Reg;
      font-size: 28px;
      color: ${COLORS.darkBlue};
      line-height: 1;
    }

    .article {
      margin-top: 8px;
      opacity: 0.7;
      font-family: Roboto-Regular;
      font-size: 14px;
    }

    .price {
      margin-top: 8px;
      font-family: FuturaNewBold-Reg;
      font-size: 32px;
      color: ${COLORS.darkBlue};
    }

    .info-title {
      margin-top: 16px;
      margin-bottom: 2px;
      font-family: Roboto-Medium;
      font-size: 16px;
      color: ${COLORS.darkBlue};
    }

    .info-text {
      font-family: Roboto-Regular;
      font-size: 16px;
      color: ${COLORS.darkBlue};
      opacity: 0.7;
    }

    .project-select {
      margin-top: 20px;
    }

    .additional-info {
      width: 100%;
      display: flex;

      .color {
        width: 200px;
        font-family: Roboto-Regular;
        font-size: 16px;
        color: ${COLORS.darkBlue};
        opacity: 0.7;
      }

      .count {
        margin-left: 10px;
        width: 200px;
        color: ${COLORS.green};
        font-family: Roboto-Regular;
        font-size: 16px;
        opacity: 0.7;
      }
    }

    .show-more {
      cursor: pointer;
      margin-top: 20px;
      font-weight: bold;
      color: ${COLORS.orange};
      font-family: Roboto-Regular;
      font-size: 16px;
    }
  }
`

export { ProductBox }
