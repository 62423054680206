import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import ProjectService from '@services/ProjectService'
import { RoomTabs } from '@ui/organisms/roomTabs'
import { PicturesContainer } from '@ui/atoms/picture'
import { Button, WatchVideo } from '@ui/atoms'
import { COLORS, ROUTES } from '@constants'
import { RoomItems } from '@pages/designer/roomProducts'

const PageBox = styled.div`
  &.page_content {
    width: 790px;
  }
  
  .market-block {
    border-top: 1px solid ${COLORS.getBlueColor(0.12)};
    margin-top: 32px;
    padding-top: 32px;
  }
  
  .market-button {
    width: 100%;
    margin-top: 24px;
  }
  
  .buttons-row {
    margin-top: 80px;
    display: flex;
    justify-content: space-between
  }

  .watch-video {
    margin-top: 20px;
  }

  .check {
    margin-right: 20px;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@withRouter
@connect(mapStateToProps)
class DesignerPlans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rooms: [],
      allRooms: [],
      activeRoom: 0,
      step: (() => {
        if (props.stage.stage > props.step) {
          return 1
        }

        return props.stage.designerIsReady ? 3 : props.stage.userMadeDecision ? 1 : 2
      })(),
    }
  }

  componentDidMount() {
    this.getStepInfo()
  }

  getStepInfo = async () => {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      const orderedRoomResponse = await ProjectService.getOrderedPlanRooms(activeProject)
      const orderedRooms = result.filter(room => orderedRoomResponse.result.includes(room.roomId))

      const plansResponse = await Promise.all(orderedRooms.map(room => ProjectService.getRoomPlans(activeProject, room.roomId)))

      this.setState({
        rooms: orderedRooms.map((room, index) => ({
          model: room.purpose,
          roomPhotos: plansResponse[index].result.planFiles.map(photo => ({
            link: photo.fileLink,
            id: photo.fileId
          })),
          roomId: room.roomId
        })),
        allRooms: result.map(room => ({
          model: room.purpose,
          roomId: room.roomId
        }))
      })
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({
        step: (() => {
          if (nextProps.stage.stage > nextProps.step) {
            return 1
          }

          return nextProps.stage.designerIsReady ? 3 : nextProps.stage.isPaid ? 1 : 2
        })()
      }, () => this.getStepInfo())
    }
  }

  addRoomPhoto = async (fileList, roomId) => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      const promises = fileList.map(file => ProjectService.uploadPlansFile({
        ProjectId: activeProject,
        RoomId: roomId,
        File: file
      }))

      const responses = await Promise.all(promises)

      responses.forEach(({ result }, index) => rooms[activeRoom].roomPhotos.push({
        link: fileList[index].preview,
        id: result
      }))

      this.setState({ rooms });
    } catch (e) {
      console.log(e)
    }
  }

  removeRoomPhoto = async (photoIndex) => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      await ProjectService.removePlansFile({
        projectId: activeProject,
        fileId: rooms[activeRoom].roomPhotos[photoIndex].id,
        roomId: rooms[activeRoom].roomId
      })

      rooms[activeRoom].roomPhotos.splice(photoIndex, 1);

      this.setState({ rooms });
    } catch (e) {
      console.log(e)
    }
  }

  confirmStage = async () => {
    const { activeProject } = this.props
    const { rooms } = this.state

    await Promise.all(
      rooms.map(room => ProjectService.finishRoomPlans(activeProject, room.roomId))
    )

    await ProjectService.confirmDevelopmentStage(activeProject, 'Plan')

    this.setState({ step: 3 })
  }

  renderFirstStep = () => {
    const { rooms, activeRoom, step } = this.state
    const {
      stage, goPrev, goNext, updateStage, ...props
    } = this.props
    const passedPage = stage.stage > props.step

    return (
      <React.Fragment>
        {step === 1 ? (
          <React.Fragment>
            <div className="g_big-text mb32">
              Пора приступить к рабочим чертежам
            </div>
            <div className="g_text dark">
              В среднем создание чертежей для одной комнаты должно у вас занять не более 5 дней.
              {' '}
              Во время этого периода вы можете общаться с клиентом в чате.
              <br />
              <br />
              После того, как вы загрузите файлы у вас будет два дня на согласование и обсуждение Рабочих чертежей.
            </div>
          </React.Fragment>
        ) : (
          <div className="g_big-text mb32">
            Вы отправили Рабочие чертежи Клиенту!
            <br />
            <br />
            Вы можете получить комментарии в чате и сразу внести изменения - просто загрузите новый файл.
            <br />
            <br />
            Рекомендуем вам пройти этот этап в течении двух дней!
          </div>
        )}
        <WatchVideo link="https://youtu.be/n0QaB_RbZDI" className="watch-video" />

        {!!rooms.length && (
          <div>
            <div className="g_small-title mt48 mb16">
              Выберите комнату
            </div>
            <RoomTabs
              items={rooms}
              active={activeRoom}
              onChange={index => this.setState({ activeRoom: index })}
            />
            <div className="g_small-title mt48">
              Загрузите изображения
            </div>
            <div className="g_text">
              Вы можете загрузить не более 4 фотографий
            </div>
            <PicturesContainer
              items={rooms[activeRoom].roomPhotos}
              onRemove={this.removeRoomPhoto}
              onAdd={files => this.addRoomPhoto(files, rooms[activeRoom].roomId)}
              max={4}
              disabled={passedPage}
            />
          </div>
        )}
        <div className="buttons-row">
          <Button
            type="text"
            text="Назад"
            onClick={goPrev}
          />
          <div>
            {!passedPage ? (
              <React.Fragment>
                {step === 1 && (
                <Button
                  text="Отправить клиенту на согласование"
                  disabled={!!rooms.find(room => room.roomPhotos.length === 0)}
                  onClick={this.confirmStage}
                />
                )}
                {step === 3 && (
                  <React.Fragment>
                    <Button
                      text="Проверить"
                      type="bordered"
                      onClick={() => updateStage()}
                      className="check"
                    />
                    <Button
                      text="Отправить еще раз"
                      disabled={!!rooms.find(room => room.roomPhotos.length === 0)}
                      onClick={this.confirmStage}
                    />
                  </React.Fragment>
                )
                }
              </React.Fragment>
            ) : (
              <Button
                text="Вперед"
                onClick={() => goNext()}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { updateStage } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Это окно ожидания. Клиент принимает решение относительно Рабочих чертежей.
          Данный этап Клиент оплачивает отдельно.
          <br />
          <br />
          Если Клиент оплатит этот этап, вы будете отправлены в окно загрузки файлов.
          <br />
          <br />
          Если Клиент откажется от этого этапа, вы перейдете в следующий.
        </div>
        <div className="buttons-row">
          <div>
            <Button
              text="Назад"
              onClick={() => this.setState({ step: 1 })}
              type="text"
            />
          </div>
          <div>
            <Button
              text="Проверить"
              type="bordered"
              onClick={() => updateStage()}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { step, allRooms } = this.state
    const { history } = this.props

    return (
      <PageBox className="page_content">
        <div className="page-body">
          <div className="page-title">
            Рабочие чертежи
          </div>
          { (step === 1 || step === 3) && this.renderFirstStep() }
          { step === 2 && this.renderSecondStep() }
          <div className="market-block">
            <div className="g_big-text">
              Добавьте товары из нашего магазина к данному проекту. В дальнейшем, клиент сможет приобрести их у нас.
            </div>
            <div className="g_text">
              Если нужного товара нет, сообщите нам об этом
            </div>
            <Button
              text="Перейти в магазин FARADISE"
              color="additional"
              type="filled"
              className="market-button"
              onClick={() => history.push(ROUTES.marketplace)}
            />
          </div>
          {!!allRooms.length && (
            <RoomItems
              rooms={allRooms}
            />
          )}
        </div>
      </PageBox>
    )
  }
}

export { DesignerPlans }
