import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'
import { Header, OtherBox } from '@pages/other/style'

import { MainFooter } from '@ui/organisms/mainFooter'

@withRouter
class Agreement extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
            Политика конфиденциальности для мобильных приложений и сайтов
            Faradise
          </h1>
          <a href="/">
www.faradise.ru
          </a>
          <ol>
            <span>
              Данное приложение создано, обслуживается и предоставляется
              компанией Technology and Media Centre Group Ltd. (далее «CTM Group
              Ltd.») (наименование на русском языке: Общество с ограниченной
              ответственностью «Центр технологий и медиа групп»), головной офис
              (юридический адрес) которой расположен по адресу: 107564,
              Российская Федерация, г. Москва, Краснобогатырская улица, дом 6,
              строение 2, комната 18. Если вы установите, на свое устройство, и
              будете использовать данное приложение, мы будем собирать данные о
              вас. В настоящей Политике конфиденциальности приводятся сведения о
              том, как именно используются данные, собранные нашими приложениями
              и сайтами, а также о том, куда вы можете обратиться в случае
              каких-либо вопросов или сомнений. Если вы получили доступ к
              данному приложению через сайт третьего лица, таковое лицо также
              может собирать данные о вас. В этом случае вам следует
              ознакомиться с условиями использования и политикой
              конфиденциальности программного обеспечения и сайта данного лица
              перед установкой и использованием приложения, чтобы узнать о том,
              как будут использоваться собранные данные.
            </span>
            <li>
Какие виды данных мы собираем?
            </li>
            <span>
              Если вы захотите зарегистрироваться в нашем приложении или на
              сайте, мы можем попросить вас предоставить такие данные, как имя,
              имя пользователя, адрес электронной почты, почтовый адрес,
              возраст, пол, номер телефона и сведения о продукте. На наших
              сайтах и в наших приложениях могут проводиться дополнительные
              опросы, в ходе которых вам будет предложено выразить свое мнение о
              различных продуктах и услугах. Если вы загрузите какие-либо
              материалы с наших сайтов или посредством наших приложений, мы
              автоматически получим сведения о вашем оборудовании. Также мы
              можем сохранять сведения о ваших действиях и личных предпочтениях
              при посещении наших сайтов или использовании наших приложений с
              использованием «cookie». Так же мы используем изображения и видео,
              получаемые с камеры (камер) вашего устройства, исключительно в
              целях нормального функционирования приложения, нормальная работа
              которого связана с функциями камеры. Изображения и видео
              получаемое с камеры (камер) обрабатывается приложением в
              автоматическом режиме, без передачи изображения третьим лицам.
            </span>
            <li>
Что мы делаем с собранными данными?
            </li>
            <span>
              Мы используем собранные данные в следующих целях: a) для
              обеспечения функционирования наших сайтов и приложений, а также
              предоставления вам информации и услуг, включая, без ограничения,
              загружаемые материалы, чаты, форумы, рекламные акции и конкурсы;
              b) для оказания вам услуг технической поддержки; c) для
              внутреннего анализа и исследований в целях улучшения наших сайтов,
              а также других продуктов; d) для отправки вам новостей и
              маркетинговой информации о продуктах и услугах CTM Group Ltd., а
              также предложениях, продуктах и услугах избранных сторонних
              компаний, но только в пределах, разрешенных действующим
              законодательством или указанных вами; e) для отправки вам
              административных сообщений (к примеру, в случае, если вы забыли
              пароль); f) для обнаружения и предотвращения случаев мошенничества
              и злоупотребления нашими сайтами, приложениями или услугами.
            </span>
            <li>
Кто еще может получить доступ к вашим данным?
            </li>
            <span>
              Наши аффилированные компании, местные дистрибьюторы и другие
              организации, сотрудничающие с нами, могут получать доступ к вашим
              данным с целью их обработки для наших нужд, а также предоставления
              вам информации и услуг по вашему запросу. Кроме того, мы
              сотрудничаем со сторонними компаниями в области развития и
              улучшения нашего сайта и приложений. Эти компании собирают
              статистическую информацию о пользовании сайтом и приложениями и
              имеют право использовать ваши персональные данные только в том
              объеме, который необходим для оказания нам соответствующих услуг.
              В исключительных обстоятельствах мы можем раскрыть ваши
              персональные данные по требованию законодательства, а также в
              целях защиты себя или третьих лиц от ущерба и противоправных
              действий.
            </span>
            <li>
Файлы cookie
            </li>
            <span>
              Некоторые из наших сайтов и приложений могут использовать файлы
              cookie. Это небольшие текстовые файлы, которые сайт отправляет на
              ваш компьютер с целью записи ваших действий в сети. Такие файлы
              могут использоваться нашими сайтами и приложениями для сохранения
              ваших предпочтений при пользовании нашими сайтами и приложениями,
              для улучшения некоторых аспектов работы наших сайтов и приложений,
              для записи ваших действий в сети, а также для предоставления вам
              персонализированной рекламы и других материалов. Вы можете
              отключить использование файлов cookie в меню параметров браузера,
              однако в этом случае некоторые функции наших сайтов или приложений
              могут быть недоступны.
            </span>
            <li>
Безопасность
            </li>
            <span>
              Мы принимаем коммерчески оправданные меры по защите ваших
              персональных данных. В число этих мер входят процессы и процедуры,
              направленные на снижение рисков несанкционированного доступа к
              вашим данным или разглашения таковых. Однако мы не гарантируем
              полного исключения злоупотреблений вашими персональными данными со
              стороны нарушителей. Храните пароли для ваших учетных записей в
              безопасном месте и не разглашайте их третьим лицам. Если вам
              станет известно о несанкционированном использовании вашего пароля
              или ином нарушении безопасности, немедленно свяжитесь с нами.
            </span>
            <li>
Как отказаться от подписки
            </li>
            <span>
              Если вы больше не хотите получать от нас маркетинговые и рекламные
              сообщения, перейдите по ссылке, которая приводится в любом из
              таких сообщений, либо посетите регистрационный раздел нашего сайта
              или приложения и измените параметры получения сообщений. Если вы
              не регистрировались ни на одном из наших сайтов или приложений,
              отправьте сообщение с темой «subscription» (без кавычек) по адресу
              privet@faradise.ru
            </span>
            <li>
Контактная информация
            </li>
            <span>
              По общим вопросам обращайтесь в службу поддержки пользователей,
              реквизиты которой опубликованы на сайтеwww.faradise.ru или на
              электронную почту: privet@faradise.ru Вы имеете законное право
              знать, какими персональными данными о вас владеет организация и
              как эти данные обрабатываются. Если вы хотите узнать, какими
              данными о вас мы располагаем, отправьте письмо с упоминанием
              сайта, на котором вы регистрировались или использовали наше
              приложение, по адресу: 107564, Российская Федерация, г. Москва,
              Краснобогатырская улица, дом 6, строение 2, комната 18. Мы
              оставляем за собой право потребовать от вас подтверждения вашей
              личности.
            </span>

            <h3>
CTM Group Ltd.
            </h3>
          </ol>
        </OtherBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Agreement }
