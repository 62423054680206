import { SET_DESIGNER_BASIC_INFO, SET_DESIGNER_FULL_INFO } from '../constants/designer'

const initialState = {
  basicInfo: {},
  fullInfo: {}
}

export default function designer(state = initialState, action) {
  switch (action.type) {
    case SET_DESIGNER_BASIC_INFO:
      return { ...state, basicInfo: { ...state.basicInfo, ...action.payload } }
    case SET_DESIGNER_FULL_INFO:
      return { ...state, fullInfo: { ...state.fullInfo, ...action.payload } }
    default:
      return state
  }
}
