import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import { Modal } from '@ui/molecules/modal'
import { Input, Button } from '@ui/atoms'
import { COLORS } from '@constants/colors'
import { validateEmail } from '@helpers'
import { UserService } from '@services/UserService'
import { setUserData } from '@redux/actions/user';

const ModalBody = styled.div`
  width: 500px;

  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -1px;
    color: ${COLORS.orange};
  }

  .text {
    margin-top: 32px;
    margin-bottom: 32px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
  }

  .input {
    width: 100%;
    display: block;
  }

  .button {
    margin-top: 80px;
    width: 100%;
  }
`

const mapDispatchToProps = dispatch => ({
  setUserData: data => dispatch(setUserData(data))
});

@connect(null, mapDispatchToProps)
class EmailModal extends Component {
  state = {
    email: ''
  }

  setEmail = async () => {
    console.log(1)
  }

  confirm = async () => {
    try {
      const { confirm, setUserData } = this.props
      const { email } = this.state

      await UserService.setEmail(email)

      setUserData({ email })

      confirm()
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { isOpen } = this.props
    const { email } = this.state

    return (
      <Modal isOpen={isOpen}>
        <ModalBody>
          <div className="title">
Введите email
          </div>
          <div className="text">
            На него мы отправим вам электронный чек. Это обязательно, потому что это закон
            {' '}
            <span role="img" aria-label="email">
              👮‍
            </span>
          </div>

          <Input
            value={email}
            className="input"
            label="Введите email"
            onChange={e => this.setState({ email: e.target.value })}
            error={validateEmail(email)}
          />

          <Button text="Готово" className="button" disabled={!email || validateEmail(email)} onSave={this.confirm} />
        </ModalBody>
      </Modal>
    )
  }
}

export { EmailModal }
