import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const StepsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .step {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: ${COLORS.getBlueColor(0.4)};
    padding: 0 8px 29px 8px;
    white-space: nowrap;

    &.active {
      color: ${COLORS.orange};
      font-family: Roboto-Medium;
    }

    &.completed {
      color: ${COLORS.getBlueColor()};
    }

    &.active,
    &.completed {
      border-bottom: 2px solid ${COLORS.orange};
      margin-bottom: -2px;
    }

    &.clickable {
      cursor: pointer;
    }
  }

  .chevron {
    fill: ${COLORS.getBlueColor(0.4)};
    width: 10px;
    height: 10px;
  }

  .chevron-container {
    height: 37px;
    display: flex;
    align-items: center;
    padding: 0 5px 15px 5px;

    &.completed {
      border-bottom: 2px solid ${COLORS.orange};
      margin-bottom: -2px;
    }
  }

  .stage-change {
    height: 51px;
    background-color: ${COLORS.getBlueColor(0.05)};
    display: flex;
    margin-top: -17px;
    color: ${COLORS.getBlueColor(0.38)};
    margin-right: 45px;
    align-items: center;
    font-family: Roboto-Medium;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    svg {
      fill: ${COLORS.getBlueColor(0.38)};
      height: 20px;
      width: 20px;
    }

    &.prev {
      margin-right: 45px;
      padding-left: 10px;
      padding-right: 16px;

      svg {
        margin-right: 8px;
      }
    }

    &.next {
      padding-left: 16px;
      padding-right: 10px;

      svg {
        margin-left: 8px;
      }
    }

    &.active {
      background-color: ${COLORS.orange};
      color: white;

      svg {
        fill: white;
      }
    }
  }

  .stage-change-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`

export { StepsBox }
