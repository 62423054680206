import React from 'react'
import PropTypes from 'prop-types'

import { icons } from '@constants/icons'

const Icon = ({ icon, ...rest }) => (
  <svg {...rest} viewBox="0 0 1024 1024">
    <path d={icons[icon]} />
  </svg>
)

Icon.defaultProps = {
  width: 64,
  height: 64
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number
}

export { Icon }
