import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'

import { MainFooter } from '@ui/organisms/mainFooter'
import { Header, OtherBox } from '@pages/other/style'

@withRouter
class RatesDesign extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
Тарифы на дизайн интерьера
          </h1>
          <span className="sub-title">
            Мы знаем, что для вас самое главное результат, поэтому разработали
            уникальные тарифы на разработку дизайна интерьера.
          </span>
          <table>
            <tr>
              <th>
Услуга
              </th>
              <th>
Описание
              </th>
              <th>
Стоимость, ₽
              </th>
              <th>
Условия оказания
              </th>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
Мудборд
                </div>
              </td>
              <td>
                Подбор стилистических решений и примеров, чтобы определить
                направление в котором будет двигаться дизайнер интерьера
              </td>
              <td rowSpan="3" className="top">
                <div className="free">
Бесплатно
                </div>
              </td>
              <td rowSpan="3" className="top">
                Услуги оказываются на основании предоставленных Покупателем
                Faradise материалов
              </td>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
Коллаж
                </div>
              </td>
              <td>
                Пример реализации интерьера в формате 2D, демонстрирующий
                сочетания подобранного интерьера и мебели
              </td>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
Зонирование
                </div>
              </td>
              <td>
                Документация, демонстрирующая расположение всех объектов в
                комнате (квартире)
              </td>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
3D-визуализация
                </div>
              </td>
              <td>
                3D-тур в режиме 360, с возможностью &quot;погулять&quot; по
                своему будущему интерьеру
              </td>
              <td>
                <span className="bold">
7 900
                </span>
                {' '}
за одну комнату (не более 25
                кв.м)
              </td>
              <td rowSpan="4" className="top">
                Услуги оказываются на основании предоставленных Покупателем
                Faradise материалов и только в совокупности с оказанием услуг
                {' '}
                <span className="soft">
«Мудбоард»
                </span>
                ,
                {' '}
                <span className="soft">
«Зонирование»
                </span>
                {' '}
и
                {' '}
                <span className="soft">
«Коллаж»
                </span>
              </td>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
Разработка рабочих чертежей
                </div>
              </td>
              <td>
Пакет рабочей документации для ремонтной бригады
              </td>
              <td>
                <span className="bold">
8 900
                </span>
                {' '}
за одну комнату (не более 25
                кв.м)
              </td>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
Заказ бригады на ремонт
                </div>
              </td>
              <td>
Подбор ремонтной бригады для реализации дизайна интерьера
              </td>
              <td>
                <div className="free">
Бесплатно
                </div>
              </td>
            </tr>
            <tr>
              <td className="top">
                <div className="service">
Авторский надзор
                </div>
              </td>
              <td>
Контроль дизайнером интерьера ремонтной бригады
              </td>
              <td>
Индивидуально с дизайнером интерьера
              </td>
            </tr>
          </table>
        </OtherBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { RatesDesign }
