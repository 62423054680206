import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map, pickBy } from 'lodash'

import { stylesList } from '@constants/styles'
import Slider from 'react-slick'
import { Button, Textarea } from '@ui/atoms'
import ClientService from '@services/ClientService'
import classnames from 'classnames'
import { ProjectService } from '@services/ProjectService';
import { StyleBox } from './style.style'
import { SlickStyleBox } from '../../../../assets/slick-style/index.style'

const cards = stylesList

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId
})

@connect(mapStateToProps)
class StyleMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: cards.reduce(
        (res, card) => ({
          ...res,
          [card.id]: ''
        }),
        {}
      ),
      activeCards: [],
      currentStyle: null,
      currentImageId: 1,
      ...props.stepState
    }
  }

  goNext = async () => {
    const { activeProjectId, goNext } = this.props
    const { activeCards, comments } = this.state

    try {
      await ClientService.setStyles({
        id: activeProjectId,
        styles: map(pickBy(cards, card => activeCards.includes(card.id)), style => ({
          name: style.model,
          description: comments[style.id]
        }))
      })

      await ProjectService.finishFilling(activeProjectId)

      goNext()
    } catch (e) {
      console.log(e)
    }
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  onImgsSliderChange = (index) => {
    this.setState({ currentImageId: cards[index].id })
    this.slider.slickGoTo(index)
  }

  inputChange = (e) => {
    const { value } = e.target
    const { currentImageId, activeCards } = this.state
    const isSet = activeCards.includes(currentImageId)
    if (value !== '' && !isSet) {
      this.setState({
        activeCards: [...activeCards, currentImageId]
      })
    }
    this.setState(prevState => ({
      comments: {
        ...prevState.comments,
        [prevState.currentImageId]: value
      }
    }))
  }

  checkCard = () => {
    const { currentImageId, activeCards } = this.state
    const index = activeCards.indexOf(currentImageId)
    if (index !== -1) {
      this.setState({
        activeCards: [
          ...activeCards.slice(0, index),
          ...activeCards.slice(index + 1)
        ]
      })
    } else {
      this.setState({
        activeCards: [...activeCards, currentImageId]
      })
    }
  }

  render() {
    // const {
    //   activeCards, currentStyle, currentImage, comments
    // } = this.state
    const { activeCards, currentImageId, comments } = this.state

    const settingsImgs = {
      dots: false,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      afterChange: this.onImgsSliderChange
    }

    const settingsNames = {
      dots: false,
      speed: 250,
      slidesToShow: 1,
      centerMode: true,
      infinite: false
    }

    return (
      <StyleBox>
        <div className="page-title">
          Какой стиль вы предпочитаете?
          {' '}
          <span role="img" aria-label="hi">
            👨‍🎨
          </span>
        </div>
        <div className="responsive-box">
          <div className="page-description">
            Чтобы мы смогли более точно подобрать вам дизайнера, пожалуйста, в
            комментариях уточните, что именно из предложенных картинок вам
            больше всего понравилось.
          </div>
          <div className="mini-title">
            Выберите стили
          </div>
        </div>
        <div className="slide-box">
          <div>
            <SlickStyleBox>
              <div className="name-slider-container">
                <Slider
                  ref={slider => (this.slider = slider)}
                  {...settingsNames}
                >
                  {cards.map(slide => (
                    <div
                      className={classnames('slide-name-box', {
                        active: activeCards.includes(slide.id)
                      })}
                    >
                      {slide.name}
                      {activeCards.includes(slide.id) && (
                        <i className="material-icons">
check
                        </i>
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            </SlickStyleBox>
          </div>
        </div>
        <div className="slide-box">
          <div>
            <SlickStyleBox>
              <Slider {...settingsImgs}>
                {cards.map(slide => (
                  <div>
                    <img width="100%" src={slide.img} alt="123" />
                  </div>
                ))}
              </Slider>
            </SlickStyleBox>
          </div>
        </div>
        <Button
          text={
            activeCards.includes(currentImageId) ? 'Отменить выбор' : 'Выбрать'
          }
          type="filled"
          color="additional"
          size="large"
          className={classnames('choice-btn', {
            set: activeCards.includes(currentImageId)
          })}
          onClick={() => {
            this.checkCard()
          }}
        />
        <Textarea
          onChange={this.inputChange}
          value={comments[currentImageId]}
          placeholder="Опишите, что вам нравится на картинке..."
          className="description-input"
        />
        <div className="controls">
          <Button
            text="Сохранить и продолжить"
            onClick={this.goNext}
            disabled={!activeCards.length}
          />
        </div>
      </StyleBox>
    )
  }
}

export { StyleMobile }
