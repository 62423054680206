import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'

import img1 from '@assets/images/justWork/1.png'
import img2 from '@assets/images/justWork/2.png'
import img3 from '@assets/images/justWork/3.png'
import img4 from '@assets/images/justWork/4.png'
import img5 from '@assets/images/justWork/5.png'
import phone from '@assets/images/justWork/phone.png'

const JustWorkBox = styled(OtherBox)`
  margin-bottom: 80px;

  h1 {
    margin-bottom: 0;
  }
    
  .first-block {
    display: flex;
    margin-top: 30px;

    .left {
      min-width: 500px;
      max-width: 550px;
    }

    .right {
      margin-left: 79px;
      min-width: 500px;
      max-width: 550px;
      padding-top: 116px;
    }

    .row {
      display: flex;
      margin-top: 40px;

      .image {
        width: 30px;
        height: 30px;
      }

      .number {
        width: 30px;
        font-family: FuturaNewBold-Reg;
        font-size: 32px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.06;
        letter-spacing: -1.3px;
        color: ${COLORS.orange};
        min-width: 30px;
      }

      .text-column {
        margin-left: 48px;

        .sub-title {
          font-family: FuturaNewBold-Reg;
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: ${COLORS.darkBlue};

          &.big {
            font-size: 32px;
          }
        }

        .text {
          margin-top: 16px;
          opacity: 0.7;
          font-family: Roboto-Regular;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.75;
          letter-spacing: normal;
          color: #253c5e;
        }
      }
    }
  }

  .second-block {
    margin-right: -165px;
    margin-top: 80px;
    border-radius: 32px;
    background-color: rgba(37, 60, 94, 0.03);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 60px;
    padding-bottom: 35px;
    padding-left: 21px;
    display: flex;
    position: relative;

    .number {
      min-width: 70px;
      font-family: FuturaNewBold-Reg;
      font-size: 32px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.06;
      letter-spacing: -1.3px;
      color: ${COLORS.orange};
    }

    .phone {
      position: absolute;
      left: 650px;
      bottom: 0;
    }

    .info {
        width: 445px;

        .info-title {
          font-family: FuturaNewBold-Reg;
          font-size: 32px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.06;
          letter-spacing: -1.3px;
          color: ${COLORS.darkBlue};
        }

        .info-text {
          margin-top: 40px;
          opacity: 0.7;
          font-family: Roboto-Regular;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.75;
          letter-spacing: normal;
          color: #253c5e;
        }

        .buttons {
          margin-top: 40px;
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
      }

      .download {
        cursor: pointer;
        width: 203px;
        height: 64px;
        border-radius: 9.7px;
        background-color: #ffffff;
        border: solid 1.6px rgba(37, 60, 94, 0.12);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        z-index: 3;

        .inner {
          display: flex;
          align-items: center;
        }

        svg {
          width: 19px;
          height: 23px;
          margin-right: 12px;
          margin-left: 12px;

          path {
            fill: #253c5e;
          }
        }

        &:hover {
          background-color: #253c5e;

          .download-text {
            .top,
            .bottom {
              color: white;
              opacity: 0.87;
            }
          }

          svg {
            path {
              fill: white;
              opacity: 0.87;
            }
          }
        }

        .download-text {
          width: 145px;

          .top {
            opacity: 0.53;
            font-family: Roboto-Medium;
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.83;
            letter-spacing: 0.4px;
            color: #253c5e;
            text-transform: uppercase;
            margin-bottom: -7px;
            width: 100%;
          }

          .bottom {
            font-family: Roboto-Medium;
            font-size: 22px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #253c5e;
          }
        }
      }
  }
`

@withRouter
class JustWork extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <JustWorkBox isInnerPage={isInnerPage}>
          <h1>
Что вы можете с помощью Faradise?
          </h1>

          <div className="first-block">
            <div className="left">
              <div className="row">
                <div className="number">
1.
                </div>

                <div className="text-column">
                  <div className="sub-title big">
Получить бесплатный дизайн интерьера
                  </div>
                </div>
              </div>

              <div className="row">
                <img src={img1} className="image" alt="" />

                <div className="text-column">
                  <div className="sub-title">
Онлайн-анкета
                  </div>

                  <div className="text">
                    Вы выбираете комнаты, которые хотите изменить, заполняете онлайн-анкету с размерами, бюджетами и ваши предпочтениями.
                    Далее мы начинаем подбор дизайнера именно под вас. Если вам не понравится дизайнер на данном этапе и далее - вы можете
                    попросить о замене.
                  </div>
                </div>
              </div>

              <div className="row">
                <img src={img2} className="image" alt="" />

                <div className="text-column">
                  <div className="sub-title">
Утверждение информации
                  </div>

                  <div className="text">
                    После подбора дизайнера вы вместе с ним обсуждаете ваше техническое задание через внутренний онлайн чат. Дизайнер
                    уточнит все детали, чтобы создать настоящий шедевр.
                  </div>
                </div>
              </div>


              <div className="row">
                <img src={img3} className="image" alt="" />

                <div className="text-column">
                  <div className="sub-title">
Разработка дизайна
                  </div>

                  <div className="text">
                    Мы делаем для вас мудборд, коллаж, зонирование и подборку мебели из нашего магазина для вашего интерьера абсолютно
                    бесплатно. Если у вас появится проблема или вас не будет устраивать результат - в чат подключится админиатор Faradise,
                    который либо заменит дизайнера, либо решит вашу проблему.
                  </div>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="row">
                <img src={img4} className="image" alt="" />

                <div className="text-column">
                  <div className="sub-title">
                  3D-визуализация, рабочие чертежи и онлайн заказ
                  </div>

                  <div className="text">
                  Если вам нужны 3D-визуализация или рабочие чертежи под реализацию дизайн-проекта -
                  мы разработаем их по фиксированной цене.
                  Всю подобранную мебель вы можете заказать одной кнопкой
                  и оплатить удобным для вас образом. Доставку и отслеживание заказа мы возьмем на себя. Все будет согласовано с вами.
                  Заказывайте товары от десятков поставщиков, а общайтесь только с нами.
                  </div>
                </div>
              </div>


              <div className="row">
                <img src={img5} className="image" alt="" />

                <div className="text-column">
                  <div className="sub-title">
                  Ремонт и авторский надзор
                  </div>

                  <div className="text">
                  Когда все готово для того, чтобы начать ремонт - не стоит откладывать.
                  Мы предложим вам одну из отобранных нами рабочих бригад.
                  Также вы можете обсудить со своим дизайнером о возможности авторского надзора.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="second-block">
            <div className="number">
2.
            </div>

            <img className="phone" src={phone} alt="" />

            <div className="info">
              <div className="info-title">
              Купить мебель

                {' '}
                <br />
                и товары для дома
              </div>

              <div className="info-text">
              Вы можете подобрать то, что вам нравится самостоятельно.
              А с помощью нашего удивительного мобильного приложения примерить мебель
в дополненной реальности.
                <br />
                <br />

После оформления мы свяжемся с вами, уточним детали и сообщим о наличии товара.
Оформленный заказ мы отследим самостоятельно и доставим вам прямо к двери.
              </div>

              <div className="buttons">

                <a
                  className="download"
                  href="https://itunes.apple.com/ru/app/faradise/id1315339948?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="inner">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="39" viewBox="0 0 32 39">
                      <path
                        fillRule="nonzero"
                        // eslint-disable-next-line
                        d="M28.665 33.487c-1.576 2.313-3.248 4.57-5.793 4.607-2.546.056-3.363-1.473-6.25-1.473-2.906 0-3.799 1.436-6.211 1.53-2.489.093-4.37-2.463-5.965-4.72-3.248-4.607-5.736-13.095-2.393-18.803 1.653-2.835 4.616-4.626 7.826-4.682 2.431-.037 4.749 1.623 6.25 1.623 1.481 0 4.292-1.996 7.237-1.697 1.234.056 4.691.485 6.914 3.693-.171.112-4.122 2.388-4.084 7.107.057 5.634 5.034 7.518 5.09 7.536-.056.13-.797 2.686-2.62 5.28zM17.82 3.642C19.206 2.093 21.504.918 23.404.844c.246 2.182-.646 4.383-1.976 5.95-1.31 1.586-3.476 2.817-5.604 2.649-.284-2.145.78-4.384 1.995-5.801z"
                        opacity=".868"
                      />
                    </svg>
                    <div className="download-text">
                      <div className="top">
Загрузите в
                      </div>
                      <div className="bottom">
App Store
                      </div>
                    </div>
                  </div>
                </a>

                <a
                  className="download"
                  href="https://play.google.com/store/apps/details?id=com.awespace.faradise"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="inner">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 34 37">
                      <path
                        fillRule="nonzero"
                        // eslint-disable-next-line
                        d="M.57 33.62V2.66C.57 1.584 1.195.637 2.113.2l18.1 17.94-18.1 17.938A2.744 2.744 0 0 1 .57 33.62zm25.376-9.798L6.174 35.15l15.6-15.462 4.172 4.134zm6.156-7.85c.625.492 1.084 1.257 1.084 2.168 0 .91-.404 1.639-1.047 2.149l-4.208 2.404-4.594-4.553 4.594-4.553 4.17 2.385zM6.174 1.13l19.772 11.328-4.171 4.135-15.6-15.463z"
                        opacity=".869"
                      />
                    </svg>
                    <div className="download-text">
                      <div className="top">
Доступно в
                      </div>
                      <div className="bottom">
Google Play
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </JustWorkBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { JustWork }
