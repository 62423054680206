import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withRouter } from 'react-router'

import { Button, Icon, Textarea } from '@ui/atoms'
import { DownloadButton } from '@ui/atoms/download-button'
import Timer from '@ui/atoms/timer'
import DesignerService from '@services/DesignerService'
import { validateLink } from '@helpers/validators'
import { DesignerTestPageBox } from './test.style'


const categories = {
  Novice: 'Новичок',
  Specialist: 'Специалист',
  Professional: 'Профессионал'
}

const STAGES = {
  TestApproved: 2,
  SendTestTask: 1,
}

const mapStateToProps = state => ({
  userData: state.user.userData,
  basicInfo: state.designer.basicInfo,
  fullInfo: state.designer.fullInfo
})

@withRouter
@connect(mapStateToProps)
class DesignerTestPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: STAGES[props.basicInfo.stage] || 0,
      link: '',
    }
  }

  renderFirstStep = () => {
    const { link } = this.state;
    const { goPrev } = this.props;

    return (
      <React.Fragment>
        <div className="page-body">
          <div className="page-title">
            А теперь давайте определим
            <br />
            ваш уровень
            {' '}
            <span role="img" aria-label="hi">
              👑
            </span>
          </div>
          <div className="page-description">
            Мы подготовили для вас интересное тестовое задание,
            {' '}
            оно необходимо для того, чтобы мы смогли понять ваши умения и навыки.
          </div>
          <div className="test-text">
          После его выполнения мы определим вас на один из 3-х уровней дизайнеров интерьера на нашей платформе.
          Чем качественнее и лучше вы выполните задание, тем выше будет ваш уровень.
            <br />
            <br />
            Желаем успехов!
            {' '}
            <span role="img" aria-label="fingers">
              ✌️
            </span>
          </div>
          <div className="download-block">
            <DownloadButton
              onClick={() => {
                window.open('https://drive.google.com/file/d/1_fAWwmY_jIyovct1uJwDFKmv6J-2K5w0/view', '_blank')
              }}
              text="Скачать задание"
              name="Faradice_test.pdf 2,3 мб"
            />
            <div className="download-desc">
              В документе вы найдете описание всего цикла работы дизайнера интерьера на платформе Faradise
            </div>
          </div>
          <div className="page-small-title">
            <span>
              Укажите ссылку на файлы с выполненным тестовым заданием
            </span>
          </div>
          <Textarea
            value={link}
            placeholder="Пожалуйста, используйте файлообменники с неограниченным доступом, например Яндекс.Диск или Google Drive и тп."
            onChange={e => this.setState({ link: e.target.value })}
          />
        </div>
        <div className="page-footer">
          <Button
            type="text"
            text="Назад"
            onClick={goPrev}
          />
          <Button
            text="Отправить тестовое задание на проверку"
            onSave={async () => {
              try {
                await DesignerService.setTestLink({ testLink: link })

                this.setState({ step: 1 });
              } catch (e) {
                console.log(e);
              }
            }}
            disabled={validateLink(link)}
          />
        </div>
      </React.Fragment>
    )
  };

  renderSecondStep = () => {
    const { userData } = this.props;

    return (
      <React.Fragment>
        <div className="page-body">
          <div className="page-title">
            Вы хорошо постарались!
            <br />
            Дайте нам время проверить
            ваш результат
          </div>
          <div className="page-description">
            {`${userData.name}, спасибо за желание работать с Faradise!`}
            <br />
            Проверка вашей работы займет некоторое время, но совсем скоро мы свяжемся с вами.
            <br />
            <br />
            О результате мы оповестим вас по SMS и Email
          </div>
        </div>
        <div className="page-footer second">
          <Timer />
        </div>
      </React.Fragment>
    )
  };

  renderThirdStep = () => {
    const { goNext, userData, fullInfo } = this.props

    return (
      <React.Fragment>
        <div className="page-body">
          <div className="page-title">
            Поздравляем!
            <br />
            Вы показали хороший
            <br />
            результат
            {' '}
            <span role="img" aria-label="cool">
            👍
            </span>
          </div>
          <div className="page-description">
            {`${userData.name}, мы отнесли вас к ступени «${categories[fullInfo.level]}».`}
            <br />
            По мере работы на платформе мы будем вас повышать
            по уровням.
          </div>
          <div className="statuses">
            <div className={classnames('status first-status', { active: fullInfo.level === 'Novice' })}>
              <div className="badge">
                <Icon icon="star" />
                НОВИЧОК
              </div>
              <div className="description">
                <div className="price">
                  4500 ₽
                </div>
                <div className="room">
                  за 1 комнату
                  <br />
                  (сред. = 25м²)
                </div>
              </div>
            </div>
            <div className={classnames('status second-status', { active: fullInfo.level === 'Specialist' })}>
              <div className="badge">
                <div className="icons">
                  <Icon icon="star" />
                  <Icon icon="star" />
                </div>
                СПЕЦИАЛИСТ
              </div>
              <div className="description">
                <div className="price">
                  6000 ₽
                </div>
                <div className="room">
                  за 1 комнату
                  <br />
                  (сред. = 25м²)
                </div>
              </div>
            </div>
            <div className={classnames('status third-status', { active: fullInfo.level === 'Professional' })}>
              <div className="badge">
                <div className="icons">
                  <Icon icon="star" />
                  <Icon icon="star" className="middle-icon" />
                  <Icon icon="star" />
                </div>
                ПРОФИ
              </div>
              <div className="description">
                <div className="price">
                  9000 ₽
                </div>
                <div className="room">
                  за 1 комнату
                  <br />
                  (сред. = 25м²)
                </div>
              </div>
            </div>
          </div>
          <div className="text">
            Обращаем внимание, что необходимый набор чертежей оплачивается дополнительно
            {' '}
            и не зависит от уровня — 2500 ₽ за комнату.
          </div>
        </div>
        <div className="page-footer third">
          {/* <Button
            color="primary"
            type="text"
            text="Не согласны с оценкой?"
            onClick={() => {}}
          /> */}
          <div />
          <Button
            text="Подтвердить и продолжить"
            onClick={goNext}
          />
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { step } = this.state;

    return (
      <DesignerTestPageBox className="page-content">
        {step === 0 && this.renderFirstStep()}
        {step === 1 && this.renderSecondStep()}
        {step === 2 && this.renderThirdStep()}
      </DesignerTestPageBox>
    )
  }
}

export { DesignerTestPage }
