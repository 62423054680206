import React, { Component } from 'react'
import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const SearchBox = styled.div`
  height: 48px;
  position: relative;
  padding-left: 7px;
  padding-right: 16px;
  border-left: 1px solid ${COLORS.getBlueColor(0.12)};
  border-right: 1px solid ${COLORS.getBlueColor(0.12)};
  background-color: ${COLORS.backgroundSearch};
  display: flex;
  align-items: center;

  .search-input {
    background: transparent;
    border: none;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: ${COLORS.darkBlue};
    margin-left: 8px;
    outline: none;
    width: 95%;
    max-width: 350px;
  }

  .total-search-result {
    right: 16px;
    position: absolute;
    opacity: 0.38;
    font-family: Roboto-Regular;
    font-size: 12px;
    text-align: right;
    color: ${COLORS.getBlueColor(0.62)};
  }
`

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  onChangeValue = (e) => {
    this.setState({ value: e.target.value })
  }

  render() {
    const { value } = this.state
    const { count } = this.props
    return (
      <SearchBox>
        <i className="material-icons">
search
        </i>
        <input
          value={value}
          onChange={this.onChangeValue}
          className="search-input"
          placeholder="Поиск ..."
        />
        <div className="total-search-result">
          {count || 0}
          {' '}
товаров
        </div>
      </SearchBox>
    )
  }
}

export { Search }
