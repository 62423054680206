import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const PaginationBox = styled.div`
  display: flex;

  .navigation-button {
    cursor: pointer;
    display: flex;
    padding-top: 8px;
    margin-right: 8px;
    width: 90px;
    height: 40px;
    border-radius: 6px;
    border: solid 1px ${COLORS.lightGrey};
    color: ${COLORS.darkBlue};
    font-size: 14px;
    font-family: Roboto-Medium;

    &.disabled {
      color: ${COLORS.textGrey};
      cursor: default;
      pointer-events: none;
    }

    &.left {
      padding-left: 4px;

      i {
        margin-right: 6px;
      }
    }

    &.right {
      padding-left: 10px;

      i {
        margin-left: 6px;
      }
    }

    i {
      margin-top: -1px;
    }
  }

  .page-number {
    cursor: pointer;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    border: solid 1px ${COLORS.lightGrey};
    color: ${COLORS.darkBlue};
    font-size: 14px;
    font-family: Roboto-Medium;
    text-align: center;
    padding-top: 9px;

    &.active {
      color: white;
      background: ${COLORS.orange};
      border-color: ${COLORS.orange};
    }
  }
`

export { PaginationBox }
