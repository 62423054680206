import styled from 'styled-components'
import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants'

const OtherBox = styled.div`
  min-height: calc(100vh - 48px);
  padding: 32px 165px;
  max-width: 1440px;
  margin: 0 auto;

  h1 {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1px;
    color: ${COLORS.orange};
    margin-bottom: 32px;
  }
  .sub-title {
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    line-height: 1.29;
    color: ${COLORS.darkBlue};
    &.indent {
      margin: 20px 0;
    }
  }
  span,
  p {
    color: ${COLORS.getBlueColor(0.7)};
    font-family: Roboto-Regular;
    font-size: 16px;
    line-height: 1.75;
  }
  table {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
    th {
      width: 25%;
      height: 56px;
      font-family: FuturaNewBold-Reg;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.71;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${COLORS.getBlueColor(0.38)};
    }
    td {
      border: solid 1px ${COLORS.getBlueColor(0.12)};
      padding: 30px;
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 1.5;
      color: ${COLORS.getBlueColor(0.7)};
      &:hover {
        background-color: ${COLORS.getBlueColor(0.03)};
      }
      &.top {
        vertical-align: top;
      }
      .service {
        font-family: FuturaNewBold-Reg;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        color: ${COLORS.darkBlue};
      }
      .soft {
        color: ${COLORS.getBlueColor(0.85)};
      }
      .free {
        font-weight: 500;
        color: ${COLORS.orange};
      }
      .bold {
        color: ${COLORS.darkBlue};
      }
    }
  }
  a {
    margin: 32px 0;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    line-height: 1.29;
    color: ${COLORS.darkBlue};
    &:hover {
      color: ${COLORS.orange};
    }
  }
  ol {
    padding: 0;
    margin-top: 32px;
    color: ${COLORS.darkBlue};
    span,
    h3 {
      opacity: 0.7;
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 1.75;
    }
    li {
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    h3 {
      color: ${COLORS.darkBlue};
      margin-top: 40px;
      font-size: 20px;
    }
  }
  ol.with-sub {
    counter-reset: item;
    font-weight: normal;
    color: ${COLORS.getBlueColor(0.55)};
    font-family: Roboto-Regular;
    li {
      font-family: Roboto-Regular;
      font-size: 16px;
      &:hover {
        color: ${COLORS.getBlueColor(1)};
      }
      margin-top: 5px;
      display: block;
      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
      }
    }
    &.firstSub {
      font-size: 16px;
      color: ${COLORS.getBlueColor(0.9)};
      & > li {
        font-family: FuturaNewBold-Reg;
        font-size: 20px;
      }
    }
    &.no-list {
      li {
        color: ${COLORS.getBlueColor(0.55)};
        &:hover {
          color: ${COLORS.getBlueColor(1)};
        }
        &:before {
          content: " ";
        }
        list-style-type: none;
      }
    }
  }
  ol.medium > li {
    font-family: Roboto-Regular;
    font-size: 16px;
  }
  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    padding: 32px 65px;
  }
`

const Header = styled.div`
  padding-left: 165px;
  padding-top: 23px;
  height: 72px;
  box-shadow: 0 2px 4px 0 rgba(37, 60, 94, 0.08);

  .logo {
    cursor: pointer;
    width: 120px;
    height: 32px;
    font-family: FuturaNewExtraBold-Reg;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.6;
    letter-spacing: 2.5px;
    color: ${COLORS.darkBlue};
  }
`

export { Header, OtherBox }
