import React, { Component } from 'react'
import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { breakPriceToDigits } from '@helpers/breakPriceToDigits'

const FilterInputBox = styled.div`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    border: solid 1px rgba(37, 60, 94, 0.12);
    outline: none;
    padding-left: 16px;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: ${COLORS.darkBlue};

    &::placeholder {
      opacity: 0.4;
    }
  }

  .suffix {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.38;
    font-family: Roboto-Regular;
    font-size: 14px;
  }

  .active-filter {
    width: 100%;
    height: 40px;
    padding-top: 8px;
    border-radius: 8px;
    background-color: #ffffff;
    border: solid 1px ${COLORS.darkBlue};
    outline: none;
    padding-left: 16px;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: ${COLORS.darkBlue};
  }

  .control {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 8px;

    &.done {
      color: ${COLORS.green};
    }
  }
`

class FilterInput extends Component {
  constructor(props) {
    super(props)

    const { initialValue } = props

    this.state = {
      value: initialValue || '',
      active: !!initialValue
    }
  }

  onChange = (value) => {
    const { onChange } = this.props

    this.setState({ value })

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ active: true })

      onChange(value)
    }, 2000)
  }

  onCancel = () => {
    const { onCancel } = this.props

    this.setState({ active: false, value: '' })

    onCancel()
  }

  render() {
    const {
      className, suffix, placeholder, prefix
    } = this.props

    const { value, active } = this.state

    return (
      <FilterInputBox className={className}>
        {!active ? (
          <React.Fragment>
            <input value={value} placeholder={placeholder} onChange={e => this.onChange(e.target.value)} />
            <span className="suffix">
              {suffix}
            </span>
          </React.Fragment>
        ) : (
          <div className="active-filter">
            {`${prefix} ${breakPriceToDigits(value)} ${suffix}`}

            <div className="control" onClick={this.onCancel}>
              <i className="material-icons">
clear
              </i>
            </div>
          </div>
        )}
      </FilterInputBox>
    )
  }
}

export { FilterInput }
