import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import classNames from 'classnames'

import { roomAssignment } from '@pages/client/FirstUserSteps/Info/config/roomAsSignment';
import { MarketplaceService } from '@services/MarketplaceService';
import { COLORS } from '@constants';
import { Icon } from '@ui/atoms';

const RoomProductsBox = styled.div`
  background-color: ${COLORS.getBlueColor(0.02)};
  padding: 30px 30px 0 30px;
  margin-top: 18px;
  
  .products-block {
    display: flex;
    flex-flow: row wrap;
    margin-right: -30px;
  }
 
  .product {
    width: 160px;
    margin-right: 30px;
    position: relative;
    
    .info-block {
      box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
      height: 228px;
      background-color: white;
      border-radius: 8px;
      padding: 16px;
    }
    
    .image {
      width: 128px;
      height: 136px;
      padding: 8px 8px 16px 8px;
    }
    
    .price {
      font-family: FuturalNewBold-Reg;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: ${COLORS.getBlueColor(0.87)};
    }
    
    .name {
      font-family: Roboto-Regular;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: ${COLORS.getBlueColor(0.54)};
      height: 36px;
      overflow: hidden;
    }
    
    .remove-btn {
      position: absolute;
      top: -7px;
      right: -7px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      fill: ${COLORS.orange};
      transform: rotate(45deg)
    }
    
    .count-block {
      height: 40px;
      border-radius: 8px;
      background-color: ${COLORS.getBlueColor(0.05)};
      display: flex;
      justify-content: space-between;
      font-size: 17px;
      letter-spacing: -0,4px;
      font-family: SFPROText-Semibold;
      color: ${COLORS.getBlueColor()};
      font-weight: 600;
      align-items: center;
      margin: 12px 0 30px 0;
      
      .icon {
        width: 24px;
        height: 24px;
        margin: 8px;
        cursor: pointer;
      }
    }
  }
  
  .categories {
    display: flex;
    margin-top: 30px;
    margin-bottom: 16px;
    
    .category {
      padding: 7px 16px;
      border-radius: 4px;
      font-family: Roboto-Regular;
      font-size: 14px;
      line-height: 18px;
      color: ${COLORS.getBlueColor(0.7)};
      cursor: pointer;
      
      &:hover {
        color: ${COLORS.getBlueColor()};
        background-color: ${COLORS.getBlueColor(0.05)};
      }
      
      &.active {
        color: rgba(255, 255, 255, 0.87);
        font-family: Roboto-Medium;
        font-weight: 500;
        background-color: ${COLORS.getBlueColor()};
      }
    }
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class RoomItems extends React.Component {
  state = {
    products: [],
    activeRoom: null,
  };

  componentDidMount() {
    this.getProductsInfo()
  }

  getProductsInfo = async () => {
    try {
      const { activeProject, rooms } = this.props

      const response = await Promise.all(
        rooms.map(room => MarketplaceService.getOrderedProductsForRoom(activeProject, room.roomId))
      )

      const products = response.reduce((acc, value, index) => (
        [
          ...acc,
          ...value.result.map(product => ({
            ...product,
            model: rooms[index].model,
            roomId: rooms[index].roomId
          }))
        ]
      ), [])

      this.setState({ products })
    } catch (e) {
      console.log(e)
    }
  }

  removeProduct = async (product) => {
    const { activeProject } = this.props

    try {
      const { result } = await MarketplaceService.removeProductFromProject({
        projectId: activeProject,
        productId: product.shortProductModel.id,
        roomId: product.roomId,
        count: product.count
      })

      if (result) {
        this.getProductsInfo()
      }
    } catch (e) {
      console.log(e)
    }
  }

  increaseProduct = async (product) => {
    const { activeProject } = this.props

    try {
      const { result } = await MarketplaceService.addProductToProject({
        projectId: activeProject,
        count: 1,
        productId: product.shortProductModel.id,
        roomId: product.roomId
      })

      if (result) {
        this.getProductsInfo()
      }
    } catch (e) {
      console.log(e)
    }
  }

  decreaseProduct = async (product) => {
    const { activeProject } = this.props

    try {
      const { result } = await MarketplaceService.removeProductFromProject({
        projectId: activeProject,
        count: 1,
        productId: product.shortProductModel.id,
        roomId: product.roomId
      })

      if (result) {
        this.getProductsInfo()
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { products, activeRoom } = this.state
    const { rooms } = this.props
    const currentProducts = activeRoom !== null ? products.filter(product => product.roomId === activeRoom) : products

    if (!products.length) {
      return null;
    }

    return (
      <RoomProductsBox>
        <div className="g_small-title">
          Товары из магазина FARADISE
        </div>
        <div className="categories">
          <div
            className={classNames('category', {
              active: activeRoom === null
            })}
            onClick={() => this.setState({ activeRoom: null })}
          >
            Все товары
          </div>
          {rooms.map(room => (
            <div
              className={classNames('category', {
                active: activeRoom === room.roomId
              })}
              onClick={() => this.setState({ activeRoom: room.roomId })}
            >
              {roomAssignment.find(r => r.model === room.model).name}
            </div>
          ))}
        </div>
        <div className="products-block">
          {currentProducts.map(product => (
            <div className="product">
              <div className="info-block">
                <div className="image">
                  <img
                    src={product.shortProductModel.photoUrl}
                    alt={product.shortProductModel.name}
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="price">
                  {product.shortProductModel.price}
                  {' '}
                  ₽
                </div>
                <div className="name">
                  {product.shortProductModel.name}
                </div>
              </div>
              <div className="count-block">
                {product.paidFor ? (
                  <Icon
                    className="icon"
                  />
                ) : (
                  <Icon
                    icon="minus"
                    className="icon"
                    onClick={() => this.decreaseProduct(product)}
                  />
                )}
                <span>
                  {product.count}
                </span>
                <Icon
                  icon="plus"
                  className="icon"
                  onClick={() => this.increaseProduct(product)}
                />
              </div>
              {!product.paidFor && (
                <Icon
                  icon="add"
                  className="remove-btn"
                  onClick={() => this.removeProduct(product)}
                />
              )}
            </div>
          ))}
        </div>
      </RoomProductsBox>
    )
  }
}


export { RoomItems }
