import React, { Component } from 'react'
import {
  Button, CheckBox, Icon, Input, Textarea
} from '@ui/atoms'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { pickBy } from 'lodash'

import breakPriceToDigits from '@helpers/breakPriceToDigits'
import { MarketplaceService } from '@services/MarketplaceService';
import { roomAssignment } from '@pages/client/FirstUserSteps/Info/config/roomAsSignment';
import { Modal } from '@ui/molecules';
import { Column, Row } from '@ui/atoms/layout';
import { FurnitureChoiceBox, OrderModalBox } from './furnitureChoice.style'

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId,
  userData: state.user.userData,
})

@connect(mapStateToProps)
class FurnitureChoice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hide: props.defaultHide,
      totalAmount: 0,
      totalCount: 0,
      activeRoom: null,
      orderModalOpened: false,
      userName: props.userData.name,
      userSurname: '',
      userPhone: props.userData.phone || '',
      userEmail: props.userData.email || '',
      userCity: '',
      userAddress: '',
      userComment: '',
      checked: {},
      thanksModalOpened: false,
      orderAll: true,
    }
  }

  switchPanel = () => this.setState(prevState => ({ hide: !prevState.hide }))

  resetStateAfterOrder = () => this.setState({
    totalAmount: 0,
    totalCount: 0,
    checked: {}
  })

  checkboxChange = (id, value) => {
    const {
      totalCount, totalAmount
    } = this.state
    const { products } = this.props

    const item = products.find(f => (f.customId === id))
    const total = item.shortProductModel.price * item.count


    this.setState(state => ({
      ...state,
      checked: {
        ...state.checked,
        [id]: value
      },
      totalCount: value ? totalCount + 1 : totalCount - 1,
      totalAmount: value ? totalAmount + total : totalAmount - total
    }))
  }

  removeProduct = async (product) => {
    const { activeProject, updateProducts } = this.props

    try {
      await MarketplaceService.removeProductFromProject({
        projectId: activeProject,
        productId: product.shortProductModel.id,
        roomId: product.roomId,
        count: product.count
      })

      updateProducts()
    } catch (e) {
      console.log(e)
    }
  }

  increaseProduct = async (product) => {
    const { activeProject, updateProducts } = this.props

    try {
      await MarketplaceService.addProductToProject({
        projectId: activeProject,
        count: 1,
        productId: product.shortProductModel.id,
        roomId: product.roomId
      })

      updateProducts()
    } catch (e) {
      console.log(e)
    }
  }

  order = async () => {
    const { activeProject, products, updateProducts } = this.props
    const {
      checked, userCity, userName, userSurname, userPhone, userEmail, userAddress, userComment, orderAll
    } = this.state
    const productsToOrder = orderAll
      ? products.map(p => ({
        roomId: p.roomId,
        productId: p.shortProductModel.id,
        count: p.count
      }))
      : Object.keys(pickBy(checked, item => !!item)).map((key) => {
        const product = products.find(p => p.customId === key)

        return {
          roomId: product.roomId,
          productId: product.shortProductModel.id,
          count: product.count
        }
      })

    try {
      await MarketplaceService.issueOrderForProject({
        projectId: activeProject,
        products: productsToOrder,
        city: userCity,
        name: userName,
        surname: userSurname,
        phone: userPhone,
        email: userEmail,
        adress: userAddress,
        description: userComment
      })

      this.setState({
        orderModalOpened: false,
        thanksModalOpened: true,
      })

      this.resetStateAfterOrder()
      updateProducts()
    } catch (e) {
      console.log(e)
    }
  }

  decreaseProduct = async (product) => {
    const { activeProject, updateProducts } = this.props

    try {
      await MarketplaceService.removeProductFromProject({
        projectId: activeProject,
        count: 1,
        productId: product.shortProductModel.id,
        roomId: product.roomId
      })

      updateProducts()
    } catch (e) {
      console.log(e)
    }
  }

  ending = count => (count === 0 || count >= 5 ? 'ов' : count === 1 ? '' : 'а')

  render() {
    const {
      checked,
      hide,
      totalAmount,
      totalCount,
      activeRoom,
      orderModalOpened,
      userName,
      userSurname,
      userPhone,
      userEmail,
      userCity,
      userAddress,
      userComment,
      thanksModalOpened
    } = this.state
    const { title, rooms, products } = this.props
    const currentProducts = activeRoom !== null ? products.filter(product => product.roomId === activeRoom) : products

    return (
      <FurnitureChoiceBox>
        <div className="title">
          <span>
            {title}
          </span>
          <div
            className={classnames('hide', { hidden: hide })}
            onClick={this.switchPanel}
          >
            <Icon icon="expand" />
            {hide ? 'Показать' : 'Скрыть'}
          </div>
        </div>
        <div className={classnames({ 'hide-box': hide })}>
          <div className="list-box">
            <div className="type-box">
              <div
                className={classnames('type', {
                  active: activeRoom === null
                })}
                onClick={() => this.setState({ activeRoom: null })}
              >
                Все товары
              </div>
              {rooms.map(room => (
                <div
                  className={classnames('type', {
                    active: activeRoom === room.roomId
                  })}
                  onClick={() => this.setState({ activeRoom: room.roomId })}
                  key={`room-category-${room.roomId}`}
                >
                  {roomAssignment.find(r => r.model === room.model).name}
                </div>
              ))}
            </div>
            {currentProducts.map(f => (
              <CheckBox
                onChange={value => this.checkboxChange(f.customId, value)}
                checked={checked[f.customId]}
                key={f.customId}
                renderCustomContent={() => (
                  <div className="item-box">
                    <div className="img-box">
                      <img src={f.shortProductModel.photoUrl} alt=" " />
                    </div>
                    <div className="center-box">
                      <div>
                        <div className="item-name">
                          {f.shortProductModel.name}
                        </div>
                        {!!f.color && (
                          <div className="item-color">
                            Цвет:
                            {' '}
                            {f.color}
                          </div>
                        )}
                      </div>
                      <div className="count-controls">
                        <div className="count-block">
                          <Icon
                            icon="minus"
                            className="count-icon"
                            onClick={() => this.decreaseProduct(f)}
                          />
                          <span>
                            {f.count}
                          </span>
                          <Icon
                            icon="plus"
                            className="count-icon"
                            onClick={() => this.increaseProduct(f)}
                          />
                        </div>
                        <div className="remove-btn" onClick={() => this.removeProduct(f)}>
                          Удалить
                        </div>
                      </div>
                    </div>
                    <div className="additional-box">
                      <div className="item-price">
                        {breakPriceToDigits(f.shortProductModel.price * f.count)}
                        {' '}
                        ₽
                      </div>
                      <div className="delivery">
                        {f.delivery}
                      </div>
                    </div>
                  </div>)
                }
              />
            ))}
          </div>
          <div className="subtotal-box">
            <div className="text">
              Выбрано
              {' '}
              {totalCount}
              {' '}
товар
              {this.ending(totalCount)}
              {' '}
на сумму:
            </div>
            <div className="amount">
              {breakPriceToDigits(totalAmount)}
              {' '}
₽
            </div>
          </div>
          <div className="total-box">
            <div className="left-panel">
              <Button
                text="Ввести промокод"
                type="text"
                color="primary"
              />
            </div>
            <div className="right-panel">
              <div className="total-text">
Общая сумма:
              </div>
              <div className="total-amount">
                {breakPriceToDigits(totalAmount)}
                {' '}
₽
              </div>
            </div>
          </div>
          <div className="button-box">
            <Button
              icon="shopping_cart"
              type="primary"
              text="Оформить заказ на все товары"
              onClick={() => this.setState({ orderModalOpened: true, orderAll: true })}
              disabled={!products.length}
            />
            <Button
              icon="done_all"
              type="filled"
              color="additional"
              text="Оформить заказ на выбранные товары"
              onClick={() => this.setState({ orderModalOpened: true, orderAll: false })}
              disabled={Object.keys(checked).length === 0 || Object.keys(pickBy(checked, val => !!val)).length === 0}
            />
          </div>
        </div>
        <Modal
          isOpen={orderModalOpened}
          onCancel={() => this.setState({ orderModalOpened: false })}
        >
          <OrderModalBox>
            <div className="page-title no-before">
              Оформление заказа
            </div>
            <div className="g_small-title mt48 mb16">
              Контактная информация
            </div>
            <Row>
              <Column lg={6}>
                <Input
                  onChange={e => this.setState({ userName: e.target.value })}
                  value={userName}
                  label="Имя"
                  full
                />
              </Column>
              <Column lg={6}>
                <Input
                  onChange={e => this.setState({ userSurname: e.target.value })}
                  value={userSurname}
                  label="Фамилия"
                  full
                />
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <Input
                  onChange={e => this.setState({ userPhone: e.target.value })}
                  value={userPhone}
                  label="Телефон"
                  disabled
                  full
                />
              </Column>
              <Column lg={6}>
                <Input
                  onChange={e => this.setState({ userEmail: e.target.value })}
                  value={userEmail}
                  label="Email"
                  full
                />
              </Column>
            </Row>
            <div className="g_small-title mt48 mb16">
              Адрес доставки
            </div>
            <Row>
              <Column lg={6}>
                <Input
                  onChange={e => this.setState({ userCity: e.target.value })}
                  value={userCity}
                  label="Город"
                  full
                />
              </Column>
              <Column lg={6}>
                <Input
                  onChange={e => this.setState({ userAddress: e.target.value })}
                  value={userAddress}
                  label="Улица, дом, строение, квартира"
                  full
                />
              </Column>
            </Row>
            <div className="g_small-title mt48 mb16">
              Комментарий к заказу
            </div>
            <Textarea
              value={userComment}
              onChange={e => this.setState({ userComment: e.target.value })}
            />
            <Button
              text="Заказать"
              className="order-btn"
              onClick={this.order}
            />
          </OrderModalBox>
        </Modal>
        <Modal
          isOpen={thanksModalOpened}
          onCancel={() => this.setState({ thanksModalOpened: false })}
        >
          <OrderModalBox>
            <div className="page-title no-before">
              Спасибо!
              <br />
              Мы уже обрабатываем
              ваш заказ
            </div>
            <div className="g_big-text">
              Скоро с вами свяжется наш менеджер,
              чтобы обсудить все детали
            </div>
            <Button
              text="Хорошо, буду ждать"
              className="order-btn"
              onClick={() => this.setState({ thanksModalOpened: false })}
            />
          </OrderModalBox>
        </Modal>
      </FurnitureChoiceBox>
    )
  }
}

FurnitureChoice.defaultProps = {
  defaultHide: false
}

FurnitureChoice.propTypes = {
  defaultHide: PropTypes.bool,
  title: PropTypes.string.isRequired
}

export { FurnitureChoice }
