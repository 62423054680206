import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@ui/atoms'
import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const CodeInputBox = styled.div`
  display: flex;
  position: relative;

  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    && {
      flex-wrap: wrap;
      width: 275px;
    }
  }

  .input-box {
    width: 20px;
    margin-right: 18px;
    display: inline-block;
    position: relative;
    & input {
      width: 20px;
      text-align: center;
      font-family: Roboto-Regular;
      font-size: 32px;
      caret-color: ${COLORS.orange};
    }
    .input-underline {
      &:hover {
        border-bottom-color: ${COLORS.orange};
      }
      &:before {
        border-bottom-color: ${COLORS.orange};
      }

      &:after {
        border-bottom-color: ${COLORS.orange};
      }
    }
  }

  input {
    /* letter-spacing: 16px; */
    width: 255px;
    /* padding-left: 7px; */
  }

  .send-code-again {
    margin-top: 15px;
    cursor: pointer;
    margin-left: 20px;
    font-size: 16px;
    font-family: Roboto-Medium;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${COLORS.orange};

    i {
      vertical-align: bottom;
      margin-right: 3px;
    }
  }

  .waiting-send-code-again {
    margin-top: 15px;
    cursor: default;
    margin-left: 20px;
    font-size: 16px;
    opacity: 0.38;
    font-family: Roboto-Regular;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};

    i {
      vertical-align: bottom;
      margin-right: 3px;
    }
  }

  .code-error {
    position: absolute;
    top: 68px;
    left: 0px;
    margin-top: -15px;
    height: 20px;
    font-family: Roboto-Regular;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: ${COLORS.orange};
  }

  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    flex-flow: row;

    .send-code-again {
      margin-left: 0;
    }

    .waiting-send-code-again {
      margin-left: 0;
    }
  }
`

class CodeInput extends Component {
  constructor(props) {
    super(props)
    const { countCode } = this.props
    const inputs = []
    this.nodes = new Map()

    let i = 0
    while (i < countCode) {
      inputs.push({
        id: i,
        value: '',
        ref: React.createRef()
      })
      i += 1
    }

    this.state = {
      timer: 0,
      code: ''
    }
  }

  onChange = (value) => {
    const { onFilled } = this.props

    this.setState({ code: value })

    onFilled(value)
  }

  resendCode = () => {
    const { resendCode } = this.props

    const interval = setInterval(() => {
      const { timer } = this.state

      this.setState(state => ({ timer: state.timer - 1 }))

      if (timer === 1) {
        clearInterval(interval)
      }
    }, 1000)

    this.setState({ timer: 60 })

    resendCode()
  }

  render() {
    const { timer, code } = this.state
    const { error } = this.props

    return (
      <CodeInputBox>
        <Input
          label="Введите код подтверждения"
          mask="9999"
          maskChar=""
          value={code}
          onChange={e => this.onChange(e.target.value)}
          error={error && 'Неверный код'}
        />
        <div>
          {timer === 0 ? (
            <div className="send-code-again" onClick={this.resendCode}>
              <i className="material-icons">
refresh
              </i>
              {' '}
Отправить код повторно
            </div>
          ) : (
            <div className="waiting-send-code-again">
              <i className="material-icons">
history
              </i>
              {' '}
Отправить код еще раз, через
              {` ${timer}`}
              {' '}
сек.
            </div>
          )}
        </div>
      </CodeInputBox>
    )
  }
}

CodeInput.defaultProps = {
  countCode: 4,
  error: false
}

CodeInput.propTypes = {
  countCode: PropTypes.number,
  onFilled: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  error: PropTypes.bool
}

export { CodeInput }
