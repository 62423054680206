import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Icon } from 'antd'
import classnames from 'classnames'

import { Radio } from '@ui/atoms'
import { CarouselBox } from './questionsCarousel.style'

class QuestionsCarousel extends Component {
  state = {
    currentSlide: 0,
    slideWidth: 500,
    transition: 0,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transition: 1 });
    }, 1000);
  }

  goNext = () => {
    this.setState(state => ({ currentSlide: state.currentSlide + 1 }))
  }

  goPrev = () => {
    this.setState(state => ({ currentSlide: state.currentSlide - 1 }))
  }

  handleContainerRef = (ref) => {
    if (ref) {
      this.setState({ slideWidth: parseInt(getComputedStyle(ref).width, 10) - 30 })
    }
  }

  render() {
    const { questions, onAnswerChange, answers } = this.props
    const { currentSlide, slideWidth, transition } = this.state

    return (
      <CarouselBox>
        <div
          className={classnames({
            'arrow left': true,
            active: currentSlide !== 0,
          })}
        >
          {currentSlide !== 0 && <Icon type="left" onClick={this.goPrev} />}
        </div>
        <div className="slides-container" ref={this.handleContainerRef}>
          <div
            className="slick-track"
            style={{
              width: slideWidth * questions.length,
              display: 'flex',
              transform: `translateX(-${slideWidth * currentSlide}px)`,
              transition: `${transition}s`,
            }}
          >
            {questions.map(slide => (
              <div
                className={classnames('carousel-slide')}
                key={slide.id}
                style={{ width: slideWidth }}
              >
                <div className="slide-title">
                  <div className="carousel-counter">
                    {`${currentSlide + 1}/${questions.length}`}
                  </div>
                  {slide.text}
                </div>
                <Radio
                  value={`${answers[slide.id]}`}
                  onChange={(e) => {
                    onAnswerChange(slide.id, e.target.value);

                    if (currentSlide !== questions.length - 1) {
                      this.goNext();
                    }
                  }}
                  items={slide.answers.map(answer => ({ value: `${answer.id}`, label: answer.text }))}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={classnames({
            'arrow right': true,
            active: currentSlide !== questions.length - 1,
          })}
        >
          {currentSlide !== questions.length - 1 && <Icon type="right" onClick={this.goNext} />}
        </div>
      </CarouselBox>
    )
  }
}

QuestionsCarousel.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  onAnswerChange: PropTypes.func.isRequired
}

export { QuestionsCarousel }
