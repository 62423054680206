import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import { Button, Textarea } from '@ui/atoms/index'
import { COLORS } from '@constants/colors'
import { ProjectService } from '@services/ProjectService'
import { ProjectProducts } from '@ui/organisms/projectProducts'
import { FreelanceService } from '@services/FreelanceService'

const MudbordBox = styled.div`
  .page_content {
    margin-bottom: 14px;
  }
  .text,
  .page-info {
    font-size: 16px;
    color: ${COLORS.getBlueColor(0.54)};
    line-height: 24px;
    font-family: Roboto-Regular;

    .bold {
      font-family: Roboto-Medium;
      font-weight: 500;
    }
  }
  .page-description {
    margin-bottom: 16px;
  }
  .text {
    max-width: 350px;
  }
  .page-info {
    margin-bottom: 16px;
    color: ${COLORS.darkBlue};
  }
  .wait-box {
    margin-top: 24px;
    .page-info {
      display: inline-block;
    }
    svg {
      fill: ${COLORS.orange};
      vertical-align: top;
    }
    .page-info {
      margin-left: 18px;
      max-width: 379px;
    }
  }
  .page-footer.step2 {
    display: flex;
    justify-content: space-between;
  }

  .btn_content {
    display: flex;
    justify-content: flex-end;
    width: 730px;
    margin: 20px 165px 48px 165px;

    .all-download-btn {
      margin-right: 20px;
    }
  }
  
  .skip-btn {
    margin-right: 20px;
  }
  
  .price {
    font-family: Roboto-Regular;
    font-size: 48px;
    color: ${COLORS.getBlueColor()};
  }
  
  .pay-btn {
    margin-top: 48px;
    width: 191px;
  }
  
  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 32px;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class ClientBrigade extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: (() => {
        if (props.stage.stage > props.step) {
          return 3
        }

        return !props.stage.userMadeDecision ? 1 : props.stage.designerIsReady ? 3 : 2
      })(),
      rooms: [],
    }
  }

  componentDidMount() {
    this.getStepInfo()
  }

  getStepInfo = async () => {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      const brigadesResponse = await FreelanceService.brigadeGetTechSpec(activeProject)

      this.setState({
        rooms: result.map(room => ({
          model: room.purpose,
          roomId: room.roomId,
          roomTZ: brigadesResponse.result.description || '',
        })),
      })
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({
        step: (() => {
          if (nextProps.stage.stage > nextProps.step) {
            return 3
          }

          return !nextProps.stage.userMadeDecision ? 1 : nextProps.stage.designerIsReady ? 3 : 2
        })()
      }, () => this.getStepInfo())
    }
  }

  confirmStage = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.confirmDevelopmentStage(activeProject, 'Brigade')

    goNext(true)
  }

  skipStep = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.skipDevelopmentStage(activeProject, 'Brigade')

    goNext(true)
  }

  orderBrigade = async () => {
    try {
      const { activeProject } = this.props

      await ProjectService.orderBrigade(activeProject)

      this.setState({ step: 2 })
    } catch (e) {
      console.log(e)
    }
  }

  renderFirstStep = () => {
    const { goPrev } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Настал момент воплотить дизайн интерьера в жизнь. Если у вас нет рабочей бригады, мы готовы для вас
          подобрать ее среди проверенных подрядчиков.
          <br />
          <br />
          Дизайнер вместе с вами составит подробное описание для рабочих, а после они с вами свяжутся.
        </div>
        <div className="buttons-row">
          <div>
            <Button
              type="text"
              text="Назад"
              onClick={goPrev}
            />
          </div>
          <div>
            <Button
              type="bordered"
              className="skip-btn"
              text="Пропустить шаг"
              onClick={this.skipStep}
            />
            <Button
              text="Заказать бригаду на ремонт"
              onClick={this.orderBrigade}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { goPrev, updateStage } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Ожидайте! Дизайнер разрабатывает ТЗ.
        </div>
        <div className="buttons-row">
          <div>
            <Button
              type="text"
              text="Назад"
              onClick={goPrev}
            />
          </div>
          <div>
            <Button
              type="bordered"
              className="skip-btn"
              text="Проверить"
              onClick={() => updateStage()}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderThirdStep = () => {
    const { rooms } = this.state
    const {
      goPrev, stage, step, goNext
    } = this.props
    const passedStep = stage.stage > step

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Дизайнер прислал вам ТЗ для ремонтной бригады, утвердите или не утверждайте…
        </div>
        <div className="g_small-title mb16">
          ТЗ для рабочей бригады
        </div>
        {!!rooms.length && (
          <Textarea
            value={rooms[0].roomTZ}
            onChange={() => {}}
            disabled
          />
        )}
        <div className="buttons-row">
          <div>
            <Button
              type="text"
              text="Назад"
              onClick={goPrev}
            />
          </div>
          <div>
            {passedStep ? (
              <Button
                text="Вперед"
                onClick={() => goNext()}
              />
            ) : (
              <Button
                text="Согласовать и продолжить"
                onClick={this.confirmStage}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { step, rooms } = this.state

    if (!rooms.length) {
      return null
    }

    console.log(step)

    return (
      <MudbordBox>
        <div className="page_content">
          <div className="page-body">
            <div className="page-title">
              Бригада на ремонт
            </div>
            {step === 1 && this.renderFirstStep()}
            {step === 2 && this.renderSecondStep()}
            {step === 3 && this.renderThirdStep()}
            <ProjectProducts
              rooms={rooms}
            />
          </div>
        </div>
      </MudbordBox>
    )
  }
}

export { ClientBrigade }
