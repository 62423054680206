import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import { COLORS } from '@constants/colors'
import { Preloader } from '@ui/atoms/preloader'

const StyledButton = styled.button`
  padding: 0 24px;
  text-align: center;
  border-radius: 8px;
  font-family: Roboto-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  border: none;
  cursor: pointer;
  background-color: ${COLORS.orange};
  border: solid 1px ${COLORS.orange};
  color: white;
  height: 48px;
  display: inline-block;
  position: relative;

  &.default-btn.disabled {
    background-color: rgba(37, 60, 94, 0.05);
    color: ${COLORS.getBlueColor(0.37)};
    opacity: 1;
    border-color: rgba(37, 60, 94, 0.05);
  }

  &.with-icon {
    padding: 0 20px 0 14px;
    text-align: start;
    font-family: Roboto-Regular;
    font-size: 14px;

    i {
      margin-right: 10px;
      vertical-align: sub;
    }
  }
  &.default.bordered {
    border-color: ${COLORS.getBlueColor(0.38)};
    background-color: ${COLORS.white};
    color: ${COLORS.getBlueColor()};
  }

  &.text-btn {
    border: none;
    background-color: ${COLORS.transparent};

    &.default {
      color: ${COLORS.getBlueColor()};
      font-family: Roboto-Regular;
    }

    &.primary {
      font-family: Roboto-Medium;
      font-weight: 500;
      color: ${COLORS.orange};
      padding: 0;
    }
  }

  &.filled-btn {
    &.primary {
      color: ${COLORS.white};
      background-color: ${COLORS.orange};
      box-shadow: 0 4px 4px 0 rgba(239, 78, 34, 0.08),
        0 2px 2px 0 rgba(239, 78, 34, 0.2);
      border: none;
    }
    &.additional {
      color: ${COLORS.white};
      background-color: ${COLORS.darkBlue};
      border: none;
    }
  }
  &.bordered-btn {
    background-color: white;
    border-style: solid;

    &.default {
      border-color: ${COLORS.getBlueColor(0.54)};
      color: ${COLORS.getBlueColor()};
    }

    &.primary {
      color: ${COLORS.orange};
    }
  }
  &.small {
    height: 36px;
    line-height: 36px;
  }
  &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  // &:disabled.additional.filled-btn {
  //   color: ${COLORS.getBlueColor(0.38)};
  //   background-color: rgba(37, 60, 94, 0.05);
  //   opacity: 1;
  // }

  .preloader {
    display: inline-block;
    margin-left: 14px;
    position: relative;
    left: 0;
    top: 0;

    div {
      width: 26px!important;
      height: 26px!important;
      position: relative;
      top: 7px;
    }
  }

  &.orange {
    border-color: ${COLORS.orange};
    color: ${COLORS.orange};
  }
`

class Button extends Component {
  state = {
    saving: false
  }

  save = async () => {
    const { onSave } = this.props

    this.setState({ saving: true })

    await onSave()

    this.setState({ saving: false })
  }

  render() {
    const {
      className, type, text, size, color, variant, disabled, icon, onSave, onClick, ...rest
    } = this.props

    const { saving } = this.state

    return (
      <StyledButton
        {...rest}
        type="button"
        className={classnames(className, {
          'with-icon': icon,
          [size]: true,
          [`${type}-btn`]: true,
          [color]: true,
          disabled: disabled || saving
        })}
        onClick={onSave ? this.save : onClick}
      >
        <span>
          {icon && (
          <i className="material-icons">
            {icon}
          </i>
          )}
          {text}
          {saving && <Preloader className="preloader" />}
        </span>
      </StyledButton>
    )
  }
}

Button.defaultProps = {
  type: 'default',
  className: '',
  size: 'medium',
  color: 'default',
  icon: ''
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['default', 'primary', 'additional'])
}

export { Button }
