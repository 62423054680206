import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const CarouselBox = styled.div`
  background: ${COLORS.getBlueColor(0.06)};
  border-radius: 8px;
  position: relative;
  display: flex;

  .carousel-counter {
    font-size: 20px;
    font-family: FuturaNewBold-Reg;
    font-weight: 600;
    color: ${COLORS.orange};
    margin-right: 12px;
  }

  .slides-container {
    padding: 30px;
    width: 100%;
    overflow: hidden;
  }

  .arrow {
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: ${COLORS.getBlueColor(0.3)};

    &.active {
      color: ${COLORS.getBlueColor()};

      &:hover {
        color: ${COLORS.orange};
      }
    }

    &.right {
      right: -20px;
    }

    &.left {
      left: -20px;
    }
  }

  .slide-title {
    font-family: FuturaNewBold-Reg;
    margin-bottom: 28px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
    display: flex;
    align-items: center;
  }

  .carousel-slide {
    margin-right: 35px;
  }

  .radio-option {
    align-items: flex-start;
    padding: 12px 16px 12px 0;

    &:hover {
      border-radius: 8px;
      background-color: rgba(103, 147, 199, 0.08);
    }

    .control {
      height: auto;
    }

    .label {
      padding: 0;
    }
  }
  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    background-color: ${COLORS.transparent};
    .btn-next,
    .prev-btn {
      width: 100%;
    }
    .btn-next {
      margin-top: 32px;
    }
    .slide-title{
      position: relative;
      .title-box{
        max-width: 80%;
      }
      .carousel-counter{
      position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
`

export { CarouselBox }
