const CLIENT_STAGES = {
  Description: 'Description',
  Preparetions: 'Preparetions',
  Development: 'Development'
}

const DESIGNER_STAGES = {
  Registered: 'Registered',
  SendTestTask: 'SendTestTask',
  TestApproved: 'TestApproved',
  ReadyToWork: 'ReadyToWork'
}

const PROJECT_STAGES = {
  Description: 'Description',
  Preparetions: 'Preparetions',
  Development: 'Development'
}

export { CLIENT_STAGES, DESIGNER_STAGES, PROJECT_STAGES }
