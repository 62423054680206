import {
  CREATE_USER_SUCCESS, LOGOUT_SUCCESS, LOGIN_SUCCESS, SET_USER_DATA
} from '../constants/user'

const initialState = {
  userData: JSON.parse(localStorage.getItem('userData')) || {
    name: '',
    phone: '',
    city: ''
  }
}

export default function userState(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
      return { ...state, userData: { ...action.payload } }
    case SET_USER_DATA:
      return { ...state, userData: { ...state.userData, ...action.payload } }
    case LOGOUT_SUCCESS:
      return { ...state, userData: {} }
    case LOGIN_SUCCESS:
      return { ...state, userData: { ...state.userData, ...action.payload } }
    default:
      return state
  }
}
