import React, { Component } from 'react'
import { get } from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import Loadable from 'react-loadable'

import { Steps } from '@ui/organisms/steps'
import { MainHeader } from '@ui/organisms/mainHeader'
import { MainFooter } from '@ui/organisms/mainFooter'
import { ProjectService } from '@services/ProjectService'
import { DesignerMudboard } from '@pages/designer/Development/Mudboard'
import { Designer3DVisual } from '@pages/designer/Development/3DVisual'
import { DesignerZoning } from '@pages/designer/Development/Zoning'
import { DesignerCollage } from '@pages/designer/Development/Collage'
import { DesignerPlans } from '@pages/designer/Development/Plans'
import { DesignerBrigade } from '@pages/designer/Development/Brigade'
import { DesignerControlAndOrder } from '@pages/designer/Development/ControlAndOrder'
import { setActiveStage } from '@redux/actions/project'
import { RESPONSIVE } from '@constants/responsive'
import { Stub } from '@ui/organisms/stub'

const Chat = Loadable({
  loader: () => import('@ui/organisms/chat'),
  loading: () => null
})

const steps = [
  {
    name: 'Мудборд',
    stepForStepsNumber: 1,
    renderComponent: ({ state, ...rest }) => (
      <DesignerMudboard stepState={state} {...rest} />
    )
  },
  {
    name: 'Зонирование',
    stepForStepsNumber: 2,
    renderComponent: ({ state, ...rest }) => (
      <DesignerZoning stepState={state} {...rest} />
    )
  },
  {
    name: 'Коллаж',
    stepForStepsNumber: 3,
    renderComponent: ({ state, ...rest }) => (
      <DesignerCollage stepState={state} {...rest} />
    )
  },
  {
    name: '3D-визуализация',
    stepForStepsNumber: 4,
    renderComponent: ({ state, ...rest }) => (
      <Designer3DVisual stepState={state} {...rest} />
    )
  },
  {
    name: 'Рабочие чертежи',
    stepForStepsNumber: 5,
    renderComponent: ({ state, ...rest }) => (
      <DesignerPlans stepState={state} {...rest} />
    )
  },
  {
    name: 'Бригада на ремонт',
    stepForStepsNumber: 6,
    renderComponent: ({ state, ...rest }) => (
      <DesignerBrigade stepState={state} {...rest} />
    )
  },
  {
    name: 'Надзор и заказ товаров',
    stepForStepsNumber: 7,
    renderComponent: ({ state, ...rest }) => (
      <DesignerControlAndOrder stepState={state} {...rest} />
    )
  }
]

const stepsForSteps = steps.filter(step => step.visible !== false)
const defaultStepsData = steps.reduce(
  (res, el, index) => ({
    ...res,
    [index]: {}
  }),
  {}
)

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

const mapDispatchToProps = dispatch => ({
  setActiveStage: stage => dispatch(setActiveStage(stage))
})

const STAGES = {
  Moodboard: 0,
  Zoning: 1,
  Collage: 2,
  Visualization: 3,
  Plan: 4,
  Brigade: 5,
  Curation: 6
}

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class DesignerDevelopment extends Component {
  state = {
    stepsData: defaultStepsData,
    currentStage: {
      stage: 0,
      clientIsReady: false
    },
    currentStep: 0,
    activeStage: null
  }

  interval = null

  componentDidMount() {
    this.updateStage()

    // this.interval = setInterval(this.updateStage, 1000 * 60)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateStage = async () => {
    const { activeProject } = this.props
    const { activeStage } = this.state

    try {
      const response = await ProjectService.getDevelopmentStage(activeProject)
      const stage = STAGES[get(response, 'result.stage', 0)]

      this.setState(prevState => ({
        currentStage: {
          ...response.result,
          stage
        },
        currentStep:
          stage > activeStage || activeStage === null
            ? stage
            : prevState.currentStep
      }))

      if (stage > activeStage || activeStage === null) {
        this.setState({ activeStage: stage })
      }
    } catch (e) {
      console.log(e)
    }
  }

  goNext = async (update = false) => {
    if (update) {
      this.updateStage()
    } else {
      this.setState(state => ({ currentStep: state.currentStep + 1 }))
    }

    window.scroll(window, 0)
  }

  goPrev = () => {
    this.setState(state => ({ currentStep: state.currentStep - 1 }))

    window.scroll(window, 0)
  }

  goTo = (step) => {
    this.setState({ currentStep: step })

    window.scroll(window, 0)
  }

  render() {
    const {
      stepsData, currentStage, currentStep, activeStage
    } = this.state
    const { activeProject, setActiveStage } = this.props

    return (
      <div className="page_container">
        <MediaQuery minWidth={RESPONSIVE.TABLET}>
          {activeProject && <Chat activeProjectId={activeProject} />}
          <MainHeader activeTab="projects">
            {!!activeProject && (
              <Steps
                steps={stepsForSteps}
                currentStep={steps[currentStep].stepForStepsNumber}
                maxStep={7}
                className="main-steps"
                onSelect={this.goTo}
                activeStage={activeStage}
                prevStage={{
                  name: 'Стадия 1',
                  onClick: () => setActiveStage('Preparetions'),
                  active: true
                }}
              />
            )}
          </MainHeader>

          {activeStage !== null && (
          <React.Fragment key={activeProject}>
            {steps[currentStep].renderComponent({
              goNext: this.goNext,
              state: stepsData[currentStep + 1],
              goPrev: this.goPrev,
              stage: currentStage,
              step: currentStep,
              updateStage: this.updateStage
            })}
          </React.Fragment>
          )}

          <MainFooter showBeta />
        </MediaQuery>
        <MediaQuery maxWidth={RESPONSIVE.TABLET}>
          <Stub />
        </MediaQuery>
      </div>
    )
  }
}

export { DesignerDevelopment }
