import { all } from 'redux-saga/effects'

import { loginUserWatcher, logoutUserWatcher } from './user'
import { createUserWatcher } from './client'
import { createDesignerWatcher, updateDesignerInfoWatcher } from './designer'
import { updateProjectsListWatcher } from './project'

export function* rootSaga() {
  yield all([
    createUserWatcher(),
    logoutUserWatcher(),
    loginUserWatcher(),
    createDesignerWatcher(),
    updateDesignerInfoWatcher(),
    updateProjectsListWatcher()
  ])
}
