import { API } from '@constants/api'
import { HttpService } from '@services/HttpsService'

class FreelanceService extends HttpService {
  getTechSpec = (projectId, roomId) => this.get(API.FREELANCE.VISUALIZATION().getTechspec, { projectId, roomId })

  setTechSpec = data => this.post(API.FREELANCE.VISUALIZATION().setTechspec, data)

  visualizationAddFile = data => this.post(API.FREELANCE.VISUALIZATION().addFile, data, 'file')

  publishTechSpec = data => this.post(API.FREELANCE.VISUALIZATION().publishTechSpec, data)

  visualizationRemoveFile = data => this.post(API.FREELANCE.VISUALIZATION().removeFile, data)

  brigadeGetTechSpec = projectId => this.get(API.FREELANCE.BRIGADE().getTechspec, { projectId })

  brigadeSetTechSpec = data => this.post(API.FREELANCE.BRIGADE().setTechspec, data)

  brigadeAddFile = data => this.post(API.FREELANCE.BRIGADE().addFile, data, 'file')

  brigadePublishTechSpec = data => this.post(API.FREELANCE.BRIGADE().publishTechSpec, data)

  brigadeRemoveFile = data => this.post(API.FREELANCE.BRIGADE().removeFile, data)
}

const freelanceService = new FreelanceService()

export { freelanceService as FreelanceService }
