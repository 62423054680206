import React from 'react'
import { connect } from 'react-redux'

import { FurnitureChoice } from '@ui/organisms/furnitureChoice'
import { OrderedProducts } from '@ui/organisms/orderedProducts'
import { MarketplaceService } from '@services/MarketplaceService'

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class ProjectProducts extends React.Component {
  state = {
    products: []
  }

  componentDidMount() {
    this.updateProducts()
  }

  updateProducts = async () => {
    try {
      const { activeProject, rooms } = this.props

      const response = await Promise.all(
        rooms.map(room => MarketplaceService.getOrderedProductsForRoom(activeProject, room.roomId))
      )

      const products = response.reduce((acc, value, index) => (
        [
          ...acc,
          ...value.result.map(product => ({
            ...product,
            model: rooms[index].model,
            roomId: rooms[index].roomId
          }))
        ]
      ), [])

      this.setState({
        products,
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { rooms } = this.props
    const { products } = this.state

    return (
      <React.Fragment>
        <FurnitureChoice
          rooms={rooms}
          products={products
            .filter(product => product.paidFor === false)
            .map(product => ({ ...product, customId: `${product.shortProductModel.id}-${product.roomId}` }))}
          title="Мебель подобранная дизайнером для вашего проекта"
          updateProducts={this.updateProducts}
        />
        <OrderedProducts
          defaultHide
          products={products.filter(product => product.paidFor)}
          rooms={rooms}
          title="Заказанные товары"
        />
      </React.Fragment>
    )
  }
}

export { ProjectProducts }
