import styled from 'styled-components'
import { COLORS } from '@constants/colors'

import TextField from '@material-ui/core/TextField'

const InputBox = styled.div`
  display: ${({ full }) => (full ? 'block' : 'inline-block')};
  position: relative;

  .input-example {
    font-family: Roboto-Regular;
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;
    color: ${COLORS.getBlueColor(0.38)};
  }

  .suffix {
    position: absolute;
    top: 24px;
    right: 5px;
    color: ${COLORS.text};
    font-family: Roboto-Regular;
    font-size: 16px;
    z-index: 10;
  }

  .input-error {
    height: 17px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: ${COLORS.orange};
  }
`

const StyledInput = styled(TextField)`
  padding-left: 4px;
  background-color: #ffffff;
  border: none;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  outline: none;
  box-shadow: none;

  &.error {
    .input-underline {
      &:before,
      &:after {
        border-bottom-color: ${COLORS.orange}!important;
      }
    }
  }

  .input-root {
    font-family: Roboto-Regular;
  }

  .big {
    font-size: 32px;
    line-height: 24px;

    input {
      padding-bottom: 20px;
    }
  }

  input {
    color: ${COLORS.getBlueColor()};
    caret-color: ${COLORS.getBlueColor()};
    line-height: 28px;
  }

  .focused ~ .label {
    color: ${COLORS.orange};
  }

  .label {
    color: ${COLORS.getBlueColor()};

    &[data-shrink='true'] {
      color: ${COLORS.getBlueColor(0.87)};
    }
  }

  .input-underline {
    &:before,
    &:after {
      border-bottom: 1px solid ${COLORS.getBlueColor(0.54)};
    }

    &.focused {
      &:before,
      &:after {
        border-bottom-color: ${COLORS.orange};
      }
    }

    &:hover {
      &:before,
      &:after {
        border-bottom-color: ${COLORS.orange}!important;
      }
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export { InputBox, StyledInput }
