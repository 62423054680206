import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { Button, Input } from '@ui/atoms';
import DesignerService from '@services/DesignerService';
import UserService from '@services/UserService';
import { TOKEN_KEY, DESIGNER_TOKEN, TOKEN_TYPE } from '@constants/common';
import { ROUTES } from '@constants/routes';
import { CodeInput } from '@ui/molecules/codeInput';
import { Modal } from '@ui/molecules/modal';
import { COLORS } from '@constants/colors';
import { createDesigner as createDesignerAction } from '@redux/actions/designer';
import { setUserData } from '@redux/actions/user';


const DesignerHelloPageBox = styled.div`
  min-height: 800px;

  .resend-code {
    font-size: 14px;
    line-height: 24px
    color: ${COLORS.getBlueColor()};
    font-family: Roboto-Regular;
  }
  
  .page-footer.step2 {
    display: flex;
    justify-content: space-between;
  }
`;

const ModalBody = styled.div`
  max-width: 570px;

  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: bold;
    color: ${COLORS.orange};
  }

  .text {
    margin-bottom: 48px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    font-weight: normal;
    color: ${COLORS.darkBlue};
    line-height: 1.14;
  }

  .buttons-wrapper {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;

    button {
      width: 255px;
    }
  }
`

const mapDispatchToProps = dispatch => ({
  createDesigner: phone => dispatch(createDesignerAction(phone)),
  setUserData: data => dispatch(setUserData(data))
});

@withRouter
@connect(null, mapDispatchToProps)
class DesignerHelloPage extends React.Component {
  state = {
    phoneNumber: '',
    isCodeWrong: false,
    step: 1,
    code: '',
    isModalOpen: false
  };

  onSubmit = async () => {
    try {
      const { phoneNumber } = this.state

      const response = await UserService.checkPhone(phoneNumber)

      if (response.result) {
        this.setState({ isModalOpen: true })

        return
      }

      const createTokenResponse = await DesignerService.createDesigner();

      localStorage.setItem(TOKEN_KEY, createTokenResponse.result);
      localStorage.setItem(TOKEN_TYPE, DESIGNER_TOKEN);

      const sendCodeResponse = await UserService.requestCode(phoneNumber);

      if (sendCodeResponse.result) {
        this.setState({ step: 2 });
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {
      phoneNumber, step, code, isCodeWrong, isModalOpen
    } = this.state

    const { goNext, setUserData, history } = this.props

    return (
      <DesignerHelloPageBox className="page-content">
        <div className="page-body">
          <div className="page-title">
            Привет!
            {' '}
            <span role="img" aria-label="hi">
              🖐
            </span>
            <br />
            Мы рады вас видеть
          </div>
          <div className="page-description">
            Чтобы стать партнером Faradise необходимо авторизоваться.
            {' '}
            Для этого укажите свой номер телефона, на который мы пришлём код подтверждения.
          </div>
          {step === 1
            && (
              <React.Fragment>
                <div className="page-small-title">
                    Введите ваш номер телефона
                </div>
                <Input
                  className="phone-input"
                  size="big"
                  mask="+7 (\999) 999 - 99 - 99"
                  maskChar="_"
                  value={phoneNumber}
                  onChange={e => this.setState({ phoneNumber: e.target.value.replace(/\D+/g, '') })}
                />
              </React.Fragment>
            )
          }
          {step === 2
            && (
              <React.Fragment>
                <div className="page-small-title">
                  Введите проверочный код из SMS
                </div>
                <div className="code-box">
                  <CodeInput
                    onFilled={value => this.setState({ code: value })}
                    resendCode={() => UserService.requestCode(phoneNumber)}
                    error={isCodeWrong}
                  />
                </div>
              </React.Fragment>
            )
          }
        </div>
        {step === 1
          && (
          <div className="page-footer">
            <Button
              text="Выслать проверочный код"
              disabled={phoneNumber.replace(/\D+/g, '').length < 11}
              onClick={this.onSubmit}
            />
          </div>
          )
        }
        {step === 2
        && (
          <div className="page-footer step2">
            <Button
              type="text"
              color="primary"
              text="У вас другой номер?"
              onClick={() => this.setState({ step: 1, code: '' })}
            />
            <Button
              text="Подтвердить и продолжить"
              disabled={code.length < 4}
              onSave={async () => {
                try {
                  const bindResponse = await UserService.bindPhone(phoneNumber, code);

                  if (bindResponse.result) {
                    localStorage.setItem('userData', JSON.stringify({ phone: phoneNumber }))
                    setUserData({ phone: phoneNumber })

                    goNext()
                  }
                } catch (error) {
                  if (error === 'invalid_code') {
                    this.setState({ isCodeWrong: true })
                  }

                  console.error(error)
                }
              }}
            />
          </div>
        )
        }

        <Modal isOpen={isModalOpen} onCancel={() => this.setState({ isModalOpen: false })}>
          <ModalBody>
            <div className="title">
              Кажется мы знакомы?
            </div>
            <div className="text">
            Данный номер телефона уже зарегистрирован. Пройдите авторизацию или используйте другой номер телефона.
            </div>
            <div className="buttons-wrapper">
              <Button text="Авторизоваться" onClick={() => history.replace(ROUTES.main)} />
              <Button
                text="Ввести другой номер"
                type="bordered"
                onClick={() => {
                  this.setState({ phoneNumber: '', step: 1, isModalOpen: false })
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </DesignerHelloPageBox>
    )
  }
}

export { DesignerHelloPage }
