import React from 'react'
import styled from 'styled-components'

const LogoBox = styled.div`
  width: 134px;
  height: 29px;
  font-family: FuturaNewExtraBold-Reg;
  font-size: 20px;
  letter-spacing: 2px;
  color: white;
  position: relative;
  z-index: 10;
`

const Logo = ({ className }) => (
  <LogoBox className={className}>
FARADISE
  </LogoBox>
)

export { Logo }
