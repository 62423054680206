import { API } from '@constants/api'
import { HttpService } from '@services/HttpsService'

class UserService extends HttpService {
  validateToken = token => this.post(API.AUTHENTICATION.validateToken, { token })

  authorize = () => {
    const url = API.USER.authorize

    return this.post(url)
  }

  checkPhone = phone => this.get(API.AUTHENTICATION.checkPhone, { phone })

  login = data => this.post(API.AUTHENTICATION.login, data, null, false)

  requestCode = phone => this.post(API.AUTHENTICATION.requestCode, { phone }, null, false)

  bindPhone = (phone, code) => this.post(API.AUTHENTICATION.bind, { phone, code })

  setEmail = email => this.post(API.USER.setEmail, { email })
}

const userService = new UserService()

export default userService
export { userService as UserService }
