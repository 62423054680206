import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Textarea } from '@ui/atoms'
import { Modal } from '@ui/molecules'
import { ClientService } from '@services/ClientService'
import { ProjectService } from '@services/ProjectService'
import { DesignerCard } from './designerCard'
import { DesignerChoiceBox, FindOtherDesignersBox } from './designerChoice.style'

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class DesignerChoice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      findOtherDesignersModalOpen: false,
      designers: [],
      ...props.stepState,
      comments: {}
    }
  }

  async componentDidMount() {
    try {
      const { activeProject } = this.props
      const designersListResponse = await ClientService.getDesigners(activeProject)

      this.setState({
        designers: designersListResponse.result,
        comments: designersListResponse.result.reduce((res, elem) => ({ ...res, [elem.id]: '' }), {})
      })
    } catch (error) {
      console.error(error)
    }
  }

  removeDesigners = async () => {
    try {
      const { comments } = this.state
      const { activeProject, goToStep } = this.props

      const data = {
        projectId: activeProject,
        designersToRemove: Object.keys(comments).map(key => ({ designerId: key, description: comments[key] }))
      }

      await ProjectService.removeDesigners(data)

      this.setState({ findOtherDesignersModalOpen: false })

      goToStep(8)
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { findOtherDesignersModalOpen, designers, comments } = this.state
    const { activeProject } = this.props

    return (
      <DesignerChoiceBox>
        <div className="choice-title">
        Мы учли ваши предпочтения и подобрали достойных кандидатов.
        Внимательно изучите профили дизайнеров. Мы хотим, чтобы вам было комфортно и интересно работать вместе.
        </div>
        {designers.map(d => (
          <DesignerCard
            key={d.id}
            imgUrl={d.avatarUrl}
            name={d.name}
            gender={d.gender}
            age={d.age}
            city={d.city}
            completedProjects={d.completedProjects}
            about={d.description}
            id={d.id}
            projectId={activeProject}
            selectDesigner={this.selectDesigner}
          />
        ))}

        <div className="show-more-box">
          <div className="text">
Если по какой-то причине вам не понравились дизайнеры, то мы можем подобрать для вас других.
          </div>
          <Button
            type="bordered"
            text="Подобрать других дизайнеров"
            className="button"
            onClick={() => this.setState({ findOtherDesignersModalOpen: true })}
          />

          <Modal
            isOpen={findOtherDesignersModalOpen}
            onCancel={() => this.setState({ findOtherDesignersModalOpen: false })}
          >
            <FindOtherDesignersBox>
              <div className="title">
Не понравились дизайнеры?
              </div>
              <div className="text">
Напишите нам причину и мы подберем для вас других
              </div>

              {designers.map(designer => (
                <div className="designer-card" key={designer.id}>
                  <img className="photo" src={designer.avatarUrl} alt="" />
                  <div className="info">
                    <div className="name">
                      {designer.name}
                    </div>
                    <Textarea
                      className="textarea"
                      value={comments[designer.id]}
                      onChange={(e) => {
                        const { value } = e.target

                        this.setState(
                          state => ({ comments: { ...state.comments, [designer.id]: value } })
                        )
                      }}
                    />
                  </div>
                </div>
              ))}

              <Button text="Отправить" className="button" onClick={this.removeDesigners} />
            </FindOtherDesignersBox>
          </Modal>
        </div>
      </DesignerChoiceBox>
    )
  }
}

export { DesignerChoice }
