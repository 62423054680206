import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const ProductsBox = styled.div`
  display: flex;
  min-height: 40vh;

  .controls-wrapper {
    width: 255px;
    margin-top: 70px;

    .control-title {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 10px;
      font-family: FuturaNewBold-Reg;
      color: ${COLORS.darkBlue};
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .filter-input {
      margin-bottom: 10px;
    }
  }

  .products-wrapper {
    margin-top: 50px;
    margin-left: 35px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 50vh;
    width: 845px;

    .sorters {
      margin-top: 16px;
      margin-bottom: 20px;
      display: flex;
      width: 100%;

      .sorter {
        padding: 0 16px;
        box-sizing: border-box;
        height: 32px;
        margin-right: 5px;
        opacity: 0.7;
        font-family: Roboto-Regular;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.86;
        letter-spacing: normal;
        color: ${COLORS.darkBlue};
        cursor: pointer;
        border-radius: 6px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: rgba(37, 60, 94, 0.06);
          opacity: 1;
        }

        &.active {
          background-color: ${COLORS.darkBlue};
          opacity: 1;
          color: white;
        }
      }
    }

    .category-title {
      margin-top: 80px;
      width: 100%;
      font-family: FuturaNewBold-Reg;
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      color: ${COLORS.orange};
    }

    .category-count {
      margin-bottom: 40px;
      margin-top: 8px;
      width: 100%;
      opacity: 0.7;
      font-family: FuturaNewBook-Reg;
      font-size: 28px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: ${COLORS.blue};
    }
  }

  .products-count {
    margin-top: -12px;
    margin-bottom: 32px;
    opacity: 0.38;
    font-family: FuturaNewMedium-Reg;
    font-size: 28px;
    font-weight: 500;
    color: ${COLORS.darkBlue};
  }

  .filters-wrapper {
    display: flex;

    .filter-select {
      width: 255px;
    }

    .filter-input {
      margin-left: 30px;
      width: 130px;
    }
  }

  .pagination-wrapper {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .go-top {
      cursor: pointer;
      display: flex;
      padding-top: 8px;
      margin-right: 8px;
      width: 96px;
      height: 40px;
      border-radius: 6px;
      border: solid 1px ${COLORS.lightGrey};
      color: ${COLORS.darkBlue};
      font-size: 14px;
      font-family: Roboto-Medium;
      padding-left: 5px;

      i {
        margin-top: -2px;
        margin-right: 3px;
      }
    }
  }
`

export { ProductsBox }
