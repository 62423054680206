import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'
import deliveryImg from '@assets/images/delivery.png'

const DeliveryBox = styled(OtherBox)`
  position: relative;

  .top-text {
    width: 635px;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    font-family: FuturaNewBook-Reg;
  }

  .right-image {
    position: absolute;
    right: 0;
    width: 515px;
    height: 480px;
    object-fit: contain;
    top: 200px;
  }

  .text {
    margin-top: 32px;
    width: 635px;
    opacity: 0.7;
    font-family: Roboto-Regular;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    }
  }
`

@withRouter
class Delivery extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <DeliveryBox isInnerPage={isInnerPage}>
          <img src={deliveryImg} alt="" className="right-image" />

          <h1>
Доставка
          </h1>

          <div className="top-text">
            Условия доставки по каждому товару индивидуальны, поскольку доставку осуществляет поставщик мебели самостоятельно под нашим
            чутким контролем.
          </div>

          <div className="text">
            Большая просьба уточнять условия доставки при заказе у нашего менеджера.
            <br />
            <br />
            {' '}
Все наши поставщики доставляют товары по всей России. В случае, если поставщик не осуществляет доставку в ваш регион - мы
            воспользуемся услугами проверенной транспортной компании.
          </div>
        </DeliveryBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Delivery }
