import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const FirstUserStepsBox = styled.div`
  display: flex;

  .logo {
    height: 22px;
    margin-right: 32px;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  .page {
    margin-left: 270px;
    padding-left: 95px;
    width: calc(100vw - 270px);
    min-height: 100vh;
    min-width: 805px;
    @media (max-width: ${RESPONSIVE.TABLET}px) {
      margin: 0;
      width: 100%;
      padding-left: 0;
      .responsive-box {
        min-width: 805px;
        margin: 0 auto;
      }
    }
    @media (max-width: ${RESPONSIVE.MOBILE}px) {
      min-width: auto;
      width: 100%;
      .responsive-box {
        padding: 0 50px;
        min-width: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    @media (max-width: 360px) {
      .responsive-box {
        padding: 0 10px;
        min-width: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }

  .first-step-footer {
    margin-left: -95px;
    margin-top: 48px;
    @media (max-width: ${RESPONSIVE.TABLET}px) {
      margin-left: 0;
    }
    .top-row,
    .bottom-row,
    .social-row {
      padding-left: 63px;
      padding-right: 60px;
      @media (max-width: ${RESPONSIVE.MOBILE}px) {
        & {
          padding: 24px 15px 32px;
        }
      }
      & > div {
        max-width: 1000px;
      }
    }
  }

  .step-title {
    width: 100%;
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1px;
    color: ${COLORS.orange};
    position: relative;
    [role='img'] {
      font-size: 40px;
    }

    :before {
      content: '—';
      position: absolute;
      transform: translateX(calc(-100% - 10px));
    }

    &.small {
      width: auto;
      max-width: 330px;
    }

    .step-title-description-wrapper {
      display: flex;
      align-items: center;
      margin-top: 32px;
    }

    .step-title-subdescription {
      flex: 1;
      font-size: 14px;
      line-height: 24px;
      color: ${COLORS.getBlueColor(0.7)};
      font-weight: 400;
      font-family: Roboto-Regular;
      letter-spacing: normal;
    }

    .step-title-description {
      color: ${COLORS.getBlueColor(0.87)};
      font-size: 28px;
      font-family: FuturaNewBook-Reg;
      font-weight: 400;
      line-height: 1.14;
      letter-spacing: normal;
      padding-right: 65px;
      flex: 1.5;
    }
    @media (max-width: ${RESPONSIVE.MOBILE}px) {
      .step-title-subdescription {
        display: none;
      }
      & {
        font-size: 32px;
        line-height: 1;
      }
      .step-title-description-wrapper,
      .step-title-description {
        font-size: 18px;
      }
      .step-title-description-wrapper {
        margin-top: 16px;
      }
    }
  }

  .step-page {
    max-width: 780px;
    padding-top: 168px;
    height: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: ${RESPONSIVE.MOBILE}px) {
      min-height: initial;

      &.step5 {
        width: 275px;
      }

      &.step6 {
        width: 300px;
      }

      & {
        padding-top: 110px;
        &.step1 {
          flex-direction: column;
        }
      }
    }
  }
`

const ImageBox = styled.div`
  position: fixed;
  width: 270px;
  height: 100vh;
  background: url(${({ src }) => src}) 100% 100% no-repeat;
  background-size: cover;
  @media (max-width: ${RESPONSIVE.TABLET}px) {
    display: none;
  }
  &:after {
    background: transparent;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .logo {
    margin-top: 31px;
    margin-left: 110px;
  }
`

const HeaderBox = styled.div`
  display: flex;
  border-bottom: 2px solid ${COLORS.borderLight};
  position: fixed;
  top: 0;
  width: 100%;
  margin-left: -95px;
  padding-left: 30px;
  z-index: 500;
  background-color: ${COLORS.white};
  @media (max-width: ${RESPONSIVE.TABLET}px) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  .header-container {
    width: 877px;
    @media (max-width: ${RESPONSIVE.TABLET}px) {
      margin: 0 auto;
    }
    display: flex;
    align-items: center;
    .mobile-header {
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      margin: 12px 15px;
      font-weight: 800;
      line-height: 1.6;
      letter-spacing: 2.5px;
      color: ${COLORS.darkBlue};
    }
    @media (max-width: ${RESPONSIVE.MOBILE}px) {
      .login-btn {
        margin-right: 15px;
      }
    }
  }

  .step {
    padding-top: 28px;
  }

  .chevron-container {
    padding-bottom: 3px;
    height: 77px;

    &.completed {
      margin-bottom: -4px;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex: 1;
    @media (max-width: ${RESPONSIVE.MOBILE}px) {
      & {
        margin-right: 15px;
      }
    }
  }

  .auth-user {
    font-family: Roboto-Regular;

    .login-btn {
      border-radius: 4px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
    }
  }

  .greeting {
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.getBlueColor()};
    text-align: right;
  }

  .logout {
    color: ${COLORS.getBlueColor(0.5)};
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    text-align: right;
  }
`

export { FirstUserStepsBox, ImageBox, HeaderBox }
