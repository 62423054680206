import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { isEqual, get } from 'lodash'
import classnames from 'classnames'

import { MarketplaceService } from '@services/MarketplaceService'
import { ProductCard } from '@pages/Marketplace/common/ProductCard'
import { Pagination } from '@ui/molecules'
import { setLocationSearch } from '@helpers'
import { Preloader } from '@ui/atoms'
import { setActiveCategory, setActiveSubcategory } from '@redux/actions/marketplace'
import { breakPriceToDigits } from '@helpers/breakPriceToDigits';
import { ProductsBox } from './products.style'
import { PRODUCTS_COUNT_PER_PAGE } from '@constants'
import { Categories } from '../common/Categories'
import { FilterSelect } from '../common/FilterSelect'
import { FilterInput } from '../common/FilterInput'
import { Subcategories } from '../common/Subcategories'

const mapStateToProps = state => ({
  categories: state.marketplace.categories,
  activeCategory: state.marketplace.activeCategory,
  activeSubcategory: state.marketplace.activeSubcategory
})

const mapDispatchToProps = dispatch => ({
  setActiveSubcategory: category => dispatch(setActiveSubcategory(category)),
  setActiveCategory: id => dispatch(setActiveCategory(id))
})

@connect(mapStateToProps, mapDispatchToProps)
class Products extends Component {
  constructor(props) {
    super(props)

    this.countPerPage = 25

    const { location } = props

    const str = queryString.parse(location.search)

    // let Brands = []

    // if (str.Brands && Array.isArray(str.Brands)) {
    //   Brands = str.Brands.map(id => Number(id))
    // }

    // if (str.Brands && !Array.isArray(str.Brands)) {
    //   Brands = [str.Brands]
    // }

    // console.log()

    this.state = {
      products: [],
      totalCount: 0,
      loading: true,
      brands: [],
      minPricePlaceholder: '500',
      maxPricePlaceholder: '100000000',
      colors: [],
      rooms: [],
      filters: {
        page: 1,
        Ordering: 'None',
        PriceFrom: str.PriceFrom || '',
        PriceTo: str.PriceTo || '',
        Brands: [],
        Colors: [],
        Rooms: []
      }
    }

    this.inputFilterTimeout = null
  }

  async componentDidMount() {
    const { activeCategory } = this.props

    this.setState({ loading: true })

    const response = await MarketplaceService.getFilters(activeCategory.id)

    this.setState({
      brands: response.result.brands.map(brand => ({ name: brand.name, value: brand.id })),
      minPricePlaceholder: response.result.minPrice,
      maxPricePlaceholder: response.result.maxPrice,
      colors: response.result.colors.map(color => ({ name: color.name, value: color.id, hex: color.hex })),
      rooms: response.result.rooms.map(room => ({ name: room.name, value: room.id, image: room.image }))
    })

    this.getProducts(this.parseFilters())
  }

  async componentDidUpdate(prevProps) {
    const { setActiveCategory, setActiveSubcategory, activeCategory } = this.props
    const { location } = this.props

    if (!isEqual(prevProps.location.search, location.search)) {
      this.setState({ loading: true })

      if (!queryString.parse(location.search).category) {
        setActiveCategory(null)
      }

      if (!queryString.parse(location.search).subcategory) {
        setActiveSubcategory(null)
      }

      const response = await MarketplaceService.getFilters(activeCategory.id)

      this.setState({
        brands: response.result.brands.map(brand => ({ name: brand.name, value: brand.id })),
        minPricePlaceholder: response.result.minPrice,
        maxPricePlaceholder: response.result.maxPrice,
        colors: response.result.colors.map(color => ({ name: color.name, value: color.id, hex: color.hex })),
        rooms: response.result.rooms.map(room => ({ name: room.name, value: room.id, image: room.image }))
      })

      this.getProducts(this.parseFilters())
    }
  }

  getSugestions = async (from = 1) => {
    try {
      this.setState({ loading: true })

      const response = await MarketplaceService.getSugestions({ from, count: PRODUCTS_COUNT_PER_PAGE })

      this.setState({
        products: get(response, 'result.products', []),
        totalCount: get(response, 'result.allCount', 0),
        loading: false
      })
    } catch (error) {
      console.error(error)
    }
  }

  parseFilters = () => {
    const { location } = this.props

    const str = queryString.parse(location.search)

    const filters = {}

    if (str.Ordering) {
      filters.Ordering = str.Ordering
    } else {
      filters.Ordering = 'None'
    }

    if (str.Count) {
      filters.Count = str.Count
    }

    if (str.page) {
      filters.Offset = (Number(str.page) - 1) * PRODUCTS_COUNT_PER_PAGE
    }

    if (str.category) {
      filters.Category = str.category
    } else {
      filters.Category = '0'
    }

    if (str.subcategory) {
      filters.Category = str.subcategory
    }

    if (str.PriceFrom) {
      filters.PriceFrom = str.PriceFrom
    }

    if (str.PriceTo) {
      filters.PriceTo = str.PriceTo
    }

    if (str.Brands && Array.isArray(str.Brands)) {
      filters.Brands = str.Brands.map(id => Number(id))
    }

    if (str.Brands && !Array.isArray(str.Brands)) {
      filters.Brands = [Number(str.Brands)]
    }

    if (str.Colors && Array.isArray(str.Colors)) {
      filters.Colors = str.Colors.map(id => Number(id))
    }

    if (str.Colors && !Array.isArray(str.Colors)) {
      filters.Colors = [Number(str.Colors)]
    }

    if (str.Rooms && Array.isArray(str.Rooms)) {
      filters.Rooms = str.Rooms.map(id => Number(id))
    }

    if (str.Rooms && !Array.isArray(str.Rooms)) {
      filters.Rooms = [Number(str.Rooms)]
    }

    this.setState(state => ({ filters: { ...state.filters, ...filters, page: str.page || 1 } }))

    return filters
  }

  getProducts = async (filters) => {
    const params = {
      Count: PRODUCTS_COUNT_PER_PAGE,
      Offset: (filters.page ? filters.page - 1 : 0) * PRODUCTS_COUNT_PER_PAGE,
      ...filters
    }

    try {
      const response = await MarketplaceService.getProducts(params)

      this.setState({ products: [...response.result.products], totalCount: response.result.allCount, loading: false })
    } catch (error) {
      console.error(error)
    }
  }

  setFilter = (name, value) => {
    this.setState(state => ({ filters: { ...state.filters, [name]: value } }))

    setLocationSearch(name, value)
  }

  setInputFilter = (name, value) => {
    this.setState(state => ({ filters: { ...state.filters, [name]: value } }))

    clearTimeout(this.inputFilterTimeout)

    this.inputFilterTimeout = setTimeout(() => setLocationSearch(name, value), 1000)
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      products, totalCount, filters, loading, brands, minPricePlaceholder, maxPricePlaceholder, colors, rooms
    } = this.state

    const { activeCategory, activeSubcategory } = this.props

    let showSubcategories = false

    if (!activeSubcategory && activeCategory.childs > 0) {
      showSubcategories = true
    }

    if (activeSubcategory && activeSubcategory.childs > 0) {
      showSubcategories = true
    }

    return (
      <React.Fragment>
        <ProductsBox>
          <div>
            <div className="controls-wrapper">
              <div className="control-title">
            Категории
              </div>
              <Categories />

              <FilterSelect
                items={rooms}
                title="Товары для"
                selectedValues={filters.Rooms}
                onSelect={values => this.setFilter('Rooms', values)}
              />

              <div className="control-title">
            Цена
              </div>

              <FilterInput
                suffix="₽"
                placeholder={`От ${breakPriceToDigits(minPricePlaceholder)}`}
                prefix="От"
                onChange={value => this.setInputFilter('PriceFrom', value)}
                onCancel={() => this.setInputFilter('PriceFrom', null)}
                initialValue={filters.PriceFrom}
                className="filter-input"
              />

              <FilterInput
                suffix="₽"
                placeholder={`До ${breakPriceToDigits(maxPricePlaceholder)}`}
                prefix="До"
                onChange={value => this.setInputFilter('PriceTo', value)}
                onCancel={() => this.setInputFilter('PriceTo', null)}
                initialValue={filters.PriceTo}
                className="filter-input"
              />

              <FilterSelect
                items={colors}
                selectedValues={filters.Colors}
                onSelect={values => this.setFilter('Colors', values)}
                title="Цвета"
              />

              <FilterSelect
                items={brands}
                selectedValues={filters.Brands}
                onSelect={values => this.setFilter('Brands', values)}
                title="Бренды"
                withSearch
              />
            </div>
          </div>

          <div className="products-wrapper">
            {loading ? <Preloader />
              : (
                <React.Fragment>
                  <div className="category-title">
                    {activeSubcategory ? activeSubcategory.name : activeCategory.name}
                  </div>
                  <div className="category-count">
                  Товаров:
                    {' '}
                    {breakPriceToDigits(totalCount)}
                  </div>

                  {
                    showSubcategories && <Subcategories className="subcategories" />
                  }

                  <div className="sorters">
                    <div
                      className={classnames('sorter', { active: filters.Ordering === 'Ascending' })}
                      onClick={() => this.setFilter('Ordering', 'Ascending')}
                    >
Сначала дешёвые
                    </div>
                    <div
                      className={classnames('sorter', { active: filters.Ordering === 'Descending' })}
                      onClick={() => this.setFilter('Ordering', 'Descending')}
                    >
Сначала дорогие
                    </div>
                  </div>

                  {products.map(product => <ProductCard product={product} key={product.id} />)}
                </React.Fragment>
              )}
            {!loading ? (
              <div className="pagination-wrapper">
                <Pagination
                  onChangePage={page => this.setFilter('page', page)}
                  perPage={PRODUCTS_COUNT_PER_PAGE}
                  currentPage={filters.page}
                  total={totalCount}
                />

                <div className="go-top" onClick={this.scrollToTop}>
                  <i className="material-icons">
expand_less
                  </i>
                  <span>
Наверх
                  </span>
                </div>
              </div>
            ) : null}
          </div>


        </ProductsBox>
      </React.Fragment>
    )
  }
}

export { Products }
