import React, { Component } from 'react'
import Slider from 'react-slick'

import { Preloader, Icon } from '@ui/atoms'
// import slideImg from '@assets/images/marketplace/slideImg.png'
import { MarketplaceService } from '@services/MarketplaceService'
import { ProductCard } from '@pages/Marketplace/common/ProductCard'
// import bonus from '@assets/images/banners/bonus.png'
// import tinkoff from '@assets/images/banners/tinkoff.png'
import apps from '@assets/images/apps-links.png'
import classNames from 'classnames'
import { Categories } from '../common/Categories'
import { SlickStyleBox } from '../../../assets/slick-style/index.style'
import { MainBox } from './main.style'
import { BrandsBox } from './brands.style'

const PrevButton = ({ onClick }) => (
  <div onClick={onClick} className="slick-prev">
    <Icon icon="chevronLeft" />
  </div>
)

const NextButton = ({ onClick }) => (
  <div onClick={onClick} className="slick-next">
    <Icon icon="chevronRight" />
  </div>
)

// const settings = {
//   dots: true,
//   speed: 300,
//   slidesToShow: 1,
//   infinite: true,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   prevArrow: <PrevButton />,
//   nextArrow: <NextButton />
// }

const settingsCompilation = {
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />
}

class Main extends Component {
  constructor(props, loading = true) {
    super(props)
    this.state = {
      loading,
      products: [],
      rooms: [],
      compilationProducts: [],
      activeRoom: 1
    }
  }

  componentDidMount() {
    this.getProducts()
    this.getRooms()
    this.getCompilationProducts(1)
  }

  getRooms = async () => {
    const response = await MarketplaceService.getRooms()
    this.setState({
      rooms: response.result
    })
  }

  setRoom = async (roomId) => {
    await this.getCompilationProducts(roomId)
    this.setState({
      activeRoom: roomId
    })
  }

  getCompilationProducts = async (roomId) => {
    const response = await MarketplaceService.getRoomProduct({ roomId })
    this.setState({
      compilationProducts: [...response.result.products]
    })
  }

  getProducts = async () => {
    const params = {
      Count: 9,
      Offset: 0
    }

    try {
      this.setState({ loading: true })

      const response = await MarketplaceService.getSugestions(params)

      this.setState({
        products: [...response.result.products],
        loading: false
      })
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {
      loading,
      products,
      rooms,
      activeRoom,
      compilationProducts
    } = this.state
    return (
      <MainBox>
        <div className="controls-wrapper">
          <div className="control-title">
            Категории
          </div>
          <Categories />
        </div>
        <div className="products-container">
          {/* <div className="slider"> */}
          {/* <SlickStyleBox> */}
          {/* <Slider {...settings}> */}
          {/* <div className="slide"> */}
          {/* <div className="slide-1"> */}
          {/* <div className="title"> */}
          {/* Авторский дизайн вашего интерьера бесплатно! */}
          {/* </div> */}
          {/* <div className="sub-title"> */}
          {/* Вся мебель и отделочные материалы подобраны из нашего */}
          {/* магазина */}
          {/* </div> */}
          {/* <div className="action"> */}
          {/* <Button text="Начать проект" /> */}
          {/* </div> */}
          {/* <img */}
          {/* src={slideImg} */}
          {/* className="slide-img" */}
          {/* alt="картинка слайда" */}
          {/* /> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="slide"> */}
          {/* <div className="slide-1"> */}
          {/* <div className="title"> */}
          {/* Авторский дизайн вашего интерьера бесплатно! */}
          {/* </div> */}
          {/* <div className="sub-title"> */}
          {/* Вся мебель и отделочные материалы подобраны из нашего */}
          {/* магазина */}
          {/* </div> */}
          {/* <div className="action"> */}
          {/* <Button text="Начать проект" /> */}
          {/* </div> */}
          {/* <img */}
          {/* src={slideImg} */}
          {/* className="slide-img" */}
          {/* alt="картинка слайда" */}
          {/* /> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="slide"> */}
          {/* <div className="slide-1"> */}
          {/* <div className="title"> */}
          {/* Авторский дизайн вашего интерьера бесплатно! */}
          {/* </div> */}
          {/* <div className="sub-title"> */}
          {/* Вся мебель и отделочные материалы подобраны из нашего */}
          {/* магазина */}
          {/* </div> */}
          {/* <div className="action"> */}
          {/* <Button text="Начать проект" /> */}
          {/* </div> */}
          {/* <img */}
          {/* src={slideImg} */}
          {/* className="slide-img" */}
          {/* alt="картинка слайда" */}
          {/* /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </Slider> */}
          {/* </SlickStyleBox> */}
          {/* </div> */}
          <div className="popular-container">
            <div className="title">
              Взгляните на популярные товары
            </div>
            <div className="products-wrapper">
              {loading ? (
                <Preloader />
              ) : (
                products.map(product => (
                  <ProductCard product={product} key={product.id} />
                ))
              )}
            </div>
            <div className="go-to-more">
              Посмотреть все
              <i className="material-icons">
                keyboard_arrow_right
              </i>
            </div>
          </div>
          {/* <div className="banners"> */}
          {/* <img src={bonus} alt="500₽" /> */}
          {/* <img src={tinkoff} alt="tinkoff" /> */}
          {/* </div> */}
          <div className="compilation">
            <div className="title">
              Дизайнерская подборка для вашей комнаты
            </div>
            <div className="compilation-container">
              <ul className="menu">
                {rooms.map(r => (
                  <li
                    className={classNames({
                      active: activeRoom === r.roomNameId
                    })}
                    onClick={() => {
                      this.setRoom(r.roomNameId)
                    }}
                  >
                    {r.name}
                  </li>
                ))}
              </ul>
              {loading ? (
                <Preloader />
              ) : (
                <SlickStyleBox>
                  <div className="compilation-slider">
                    <Slider {...settingsCompilation}>
                      {compilationProducts.map(product => (
                        <div className="slide" key={product.id}>
                          <ProductCard product={product} key={product.id} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </SlickStyleBox>
              )}
              <div className="go-to-more">
                Показать больше
                <i className="material-icons">
                  keyboard_arrow_right
                </i>
              </div>
            </div>
          </div>
          <div className="apps-links">
            <img alt="img" src={apps} />
            <div className="title">
              Примерьте мебель дома ещё до покупки
            </div>
            <div className="sub-title">
              С нашим удивительным мобильным приложением Faradise!
            </div>
            <div className="links">
              <a
                className="download"
                href="https://itunes.apple.com/ru/app/faradise/id1315339948?mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="39"
                  viewBox="0 0 32 39"
                >
                  <path
                    fill="#253C5E"
                    fillRule="nonzero"
                    // eslint-disable-next-line
                    d="M28.665 33.487c-1.576 2.313-3.248 4.57-5.793 4.607-2.546.056-3.363-1.473-6.25-1.473-2.906 0-3.799 1.436-6.211 1.53-2.489.093-4.37-2.463-5.965-4.72-3.248-4.607-5.736-13.095-2.393-18.803 1.653-2.835 4.616-4.626 7.826-4.682 2.431-.037 4.749 1.623 6.25 1.623 1.481 0 4.292-1.996 7.237-1.697 1.234.056 4.691.485 6.914 3.693-.171.112-4.122 2.388-4.084 7.107.057 5.634 5.034 7.518 5.09 7.536-.056.13-.797 2.686-2.62 5.28zM17.82 3.642C19.206 2.093 21.504.918 23.404.844c.246 2.182-.646 4.383-1.976 5.95-1.31 1.586-3.476 2.817-5.604 2.649-.284-2.145.78-4.384 1.995-5.801z"
                    opacity=".868"
                  />
                </svg>
                <div className="download-text">
                  <div className="top">
                    Загрузите в
                  </div>
                  <div className="bottom">
                    App Store
                  </div>
                </div>
              </a>

              <a
                className="download"
                href="https://play.google.com/store/apps/details?id=com.awespace.faradise"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="37"
                  viewBox="0 0 34 37"
                >
                  <path
                    fill="#FFF"
                    fillRule="nonzero"
                    // eslint-disable-next-line
                    d="M.57 33.62V2.66C.57 1.584 1.195.637 2.113.2l18.1 17.94-18.1 17.938A2.744 2.744 0 0 1 .57 33.62zm25.376-9.798L6.174 35.15l15.6-15.462 4.172 4.134zm6.156-7.85c.625.492 1.084 1.257 1.084 2.168 0 .91-.404 1.639-1.047 2.149l-4.208 2.404-4.594-4.553 4.594-4.553 4.17 2.385zM6.174 1.13l19.772 11.328-4.171 4.135-15.6-15.463z"
                    opacity=".869"
                  />
                </svg>
                <div className="download-text">
                  <div className="top">
                    Доступно в
                  </div>
                  <div className="bottom">
                    Google Play
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="brands">
            <div className="title">
              Ведущие бренды собраны в одном месте
            </div>
            <BrandsBox>
              <div className="img hoff" />
              <div className="img divcvet" />
              <div className="img zov" />
              <div className="img maria" />
              <div className="img angstrem" />
              <div className="img askona" />
              <div className="img andersen" />
              <div className="img mri" />
              <div className="img pinsk" />
              <div className="img boconcept" />
              <div className="img mrdoors" />
              <div className="img opmatek" />
            </BrandsBox>
          </div>
        </div>
      </MainBox>
    )
  }
}

export { Main }
