import React from 'react'
import styled from 'styled-components'

import { COLORS } from '@constants/colors'

import sorterDownImg from '@assets/images/icons/sorting-down.png'

const SorterBox = styled.div`
  cursor: pointer;
  align-items: center;
  padding-left: 16px;
  padding-right: 6px;
  display: flex;
  justify-content: space-between;
  width: 255px;
  height: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  border: solid 1px rgba(37, 60, 94, 0.12);
  font-family: Roboto-Regular;
  font-size: 14px;
  color: ${COLORS.darkBlue};

  img {
    width: 20px;
    height: 20px;
  }
`

const Sorter = ({ className, onChange, value }) => {
  let nextValue = 'None'

  if (value === 'Ascending') {
    nextValue = 'Descending'
  }

  if (value === 'None') {
    nextValue = 'Ascending'
  }

  return (
    <SorterBox className={className} onClick={() => onChange(nextValue)}>
      {value === 'None' && 'Порядок не важен'}
      {value === 'Ascending' && 'Сначала дешевые'}
      {value === 'Descending' && 'Сначала дорогие'}
      <img src={sorterDownImg} alt="" />
    </SorterBox>
  )
}

export { Sorter }
