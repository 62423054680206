import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const StyledTextarea = styled.textarea`
  width: 100%;
  outline: none;
  border: solid 1px rgba(37, 60, 94, 0.54);
  border-radius: 6px;
  resize: none;
  padding: 16px;
  color: ${COLORS.darkBlue};
  font-family: Roboto-Regular;
  font-size: 16px;
  caret-color: ${COLORS.darkBlue};
`

class Textarea extends Component {
  onChange = (event) => {
    const { onChange } = this.props

    onChange(event, event)
  }

  render() {
    return <StyledTextarea {...this.props} onChange={this.onChange} />
  }
}

export { Textarea }
