import React, { Component } from 'react'
import {
  Icon
} from '@ui/atoms'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import breakPriceToDigits from '@helpers/breakPriceToDigits'
import { OrderedProductsBox } from '@ui/organisms/orderedProducts.style'

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId,
  userData: state.user.userData,
})

@connect(mapStateToProps)
class OrderedProducts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hide: props.defaultHide,
      activeRoom: null,
    }
  }

  switchPanel = () => this.setState(prevState => ({ hide: !prevState.hide }))

  render() {
    const {
      hide,
      activeRoom,
    } = this.state
    const { title, products } = this.props
    const currentProducts = activeRoom !== null ? products.filter(product => product.model === activeRoom) : products

    if (!products.length) {
      return null;
    }

    return (
      <OrderedProductsBox>
        <div className="title">
          <span>
            {title}
          </span>
          <div
            className={classnames('hide', { hidden: hide })}
            onClick={this.switchPanel}
          >
            <Icon icon="expand" />
            {hide ? 'Показать' : 'Скрыть'}
          </div>
        </div>
        <div className={classnames({ 'hide-box': hide })}>
          <div className="list-box">
            {currentProducts.map(f => (
              <div className="item-box" key={f.shortProductModel.id}>
                <div className="img-box">
                  <img src={f.shortProductModel.photoUrl} alt=" " />
                </div>
                <div className="center-box">
                  <div>
                    <div className="item-name">
                      {f.shortProductModel.name}
                    </div>
                    {!!f.color && (
                    <div className="item-color">
                      {f.color}
                    </div>
                    )}
                  </div>
                </div>
                <div className="additional-box">
                  <div className="item-price">
                    {breakPriceToDigits(f.shortProductModel.price * f.count)}
                    {' '}
                        ₽
                  </div>
                  <div className="item-count">
                    {f.count}
                    {' '}
                    шт.
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </OrderedProductsBox>
    )
  }
}

OrderedProducts.defaultProps = {
  defaultHide: false
}

OrderedProducts.propTypes = {
  defaultHide: PropTypes.bool,
  title: PropTypes.string.isRequired
}

export { OrderedProducts }
