import { put, takeLatest, call } from 'redux-saga/effects'
import ClientService from '@services/ClientService'
import { TOKEN_KEY } from '@constants/common'

import { CREATE_USER_SUCCESS, CREATE_USER_REQUEST, CREATE_USER_ERROR } from '../constants/user'
import { setActiveProjectId } from '../actions/project'

function* createUser(action) {
  try {
    const data = yield call(ClientService.createClient)
    const token = data.result

    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem('userData', JSON.stringify(action.payload))

    const newProjectData = yield call(ClientService.createProject)

    const {
      name, phone, projectName, city
    } = action.payload

    yield call(ClientService.setBasicInfo, {
      userName: name,
      userPhone: phone,
      projectName,
      city,
      id: newProjectData.result
    })

    localStorage.setItem('activeProjectId', newProjectData.result)

    yield put(setActiveProjectId(newProjectData.result))

    return yield put({
      type: CREATE_USER_SUCCESS,
      payload: { ...action.payload, role: 'Client' }
    })
  } catch (error) {
    return yield call({ type: CREATE_USER_ERROR })
  }
}

function* createUserWatcher() {
  yield takeLatest(CREATE_USER_REQUEST, createUser)
}

export { createUserWatcher }
