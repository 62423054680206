import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'

import { MainFooter } from '@ui/organisms/mainFooter'
import { Header, OtherBox } from '@pages/other/style'

@withRouter
class OfferMarketplace extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
            Публичная оферта по предложению заключить договор на приобретение
            товаров и/или услуг представленных в маркетплейсе мебели в
            дополненной реальности «Faradise»
          </h1>
          <span>
            Общество с ограниченной ответственностью «Центр технологий и медиа
            групп», именуемое в дальнейшем «Faradise», предлагает Товары и
            Услуги своих Партнеров (далее «Продавец») для которых является
            агентом, размещенные на Платформе, любому дееспособному
            физическому/юридическому лицу/индивидуальному предпринимателю,
            именуемому в дальнейшем «Покупатель», в случае принятия последним
            условий настоящий Оферты (безусловный акцепт). Настоящая Оферта
            заключается в соответствии с Пользовательским соглашением (оферта)
            на использование платформы-сервиса маркетплейса мебели в дополненной
            реальности «Faradise» (далее – «Пользовательское оглашение»)
            размещенным в сети интернет и доступным по адресу:
            www.faradise.ru/design/offer_client_order, при этом настоящая Оферта
            является самостоятельным юридическим правоустанавливающим документом
            и дополняет Пользовательское соглашение и использует и применяет
            терминологию и определения изложенные и зафиксированные в
            {' '}
            <strong>
п. 1.1.
            </strong>
            {' '}
Пользовательского соглашения. Оферта не
            требует двустороннего физического подписания, считается заключенной
            с момента её акцепта Покупателем. Под Акцептом стороны понимают
            полное и безоговорочное принятие Покупателем условий публичной
            оферты путем выражения согласия нажатием кнопки «Согласен» при
            регистрации на Платформе либо при полной или частичной оплате Товара
            в случае оформления Заказа по телефону.
          </span>
          <div className="sub-title indent">
Основные условия Оферты
          </div>
          <ol className="medium">
            <li>
              В связи с тем, что по настоящей Оферте Faradise обязуется
              совершать от своего имени, но за счет Продавца (под «Продавцом»
              Стороны понимают любое юридическое лицо или индивидуального
              предпринимателя, чьи товары и/или услуги представлены на Платформе
              и доступны для заказа и приобретения со сторон Покупателя) сделки
              по купле продаже товара Партнера к отношениям между Покупателем и
              Faradise не применяются положения Гражданского кодекса Российской
              Федерации о розничной купле-продаже и Закон Российской Федерации
              от 07.02.1992 № 2300-1 «О защите прав потребителей», так как
              положения вышеприведенных нормативно-правовых актов
              распространяются на отношения между Покупателем и Продавцом.
            </li>
            <li>
              Faradise обязуется совершить сделку по купле-продаже товара
              Продавца, а Покупатель обязуется оплатить и принять Товары,
              заказанные на Платформе и подтвержденные Faradise.
            </li>
            <li>
              Совершая Заказ, Покупатель гарантирует, что полностью ознакомился
              с текстом настоящей Оферты, полностью понимает и принимает ее
              условия.
            </li>
            <li>
              Оформление Заказа осуществляется через Платформу путем заполнения
              и отправки Faradise формы с указанием параметров заказа и/или по
              телефону, указанному на Платформе. Обязательным условием
              формирования заказа является предоставление Покупателем следующей
              информации: Ф.И.О. Покупателя, контактный телефон и адрес
              электронной почты.
            </li>
            <li>
              Информация (Инструкция) по оформлению заказа и его дальнейшей
              обработки Faradise доступна на Платформе по адресу:
            </li>
            <li>
              Информация о Товаре, включая цвета, размеры и формы, о наличии
              товара и сроках поставки, представленная на Платформе, носит
              справочный характер и подлежит уточнению Faradise при
              подтверждении Заказа.
            </li>
            <li>
              В течение 24 часов с момента оформления Заказа Faradise
              подтверждает Покупателю получение Заказа посредством звонка или
              направления сообщения на указанный при оформлении заказа адрес
              электронной почты Покупателя, согласовывает с Покупателем наличие
              товара по Заказу, сроки и способы оплаты заказа, сроки исполнения
              заказа, условия передачи товара. После согласования указанных
              условий заказ считается подтвержденным.
              {' '}
            </li>
            <li>
              В случае аннулирования подтвержденного и предоплаченного заказа по
              инициативе Faradise стоимость аннулированного Товара возвращается
              Покупателю на расчетный счет, с которого произведена оплата, без
              каких-либо штрафных санкций и выплат компенсационного характера.
            </li>
            <li>
              Сроки получения Покупателем заказанного товара определяются,
              исходя из наличия товара, адреса и региона доставки, работы
              конкретной службы доставки, и напрямую не зависят от Faradise.
            </li>
            <li>
              Покупатель имеет возможность вносить изменения в подтвержденный
              заказ путем сообщения необходимой информации по телефону,
              указанному на Платформе либо путем направления соответствующего
              письма через форму обратной связи на Платформе до момента передачи
              товара в службу доставки. После внесения изменений в
              Подтвержденный Заказ срок поставки Товара начинает исчисляться с
              момента внесения таких изменений.
            </li>
            <li>
              В случае, если после размещения заказа у Покупателя изменились
              сведения, имеющие значение для надлежащего исполнения Faradise
              своих обязательств, или Покупатель заметил ошибку в указанном
              адресе доставки или иных сведениях, он обязан незамедлительно,
              уведомить об этом Faradise по телефону, указанному на Платформе
              либо путем направления соответствующего письма через форму
              обратной связи на Платформе.
            </li>
            <li>
              Покупатель вправе выбрать один из способов оплаты Товара, описание
              и условия совершения которых расположены на Платформе и доступны
              по адресу: www.faradise.ru/design/how_to_pay
            </li>
            <li>
              Окончательная стоимость конкретного заказа подтверждается
              Faradise, при этом стоимость указывается в российских рубля.
              Стоимость товара, представленного на Платформе, может быть
              изменена Faradise в одностороннем порядке, при этом цена на
              подтвержденный Заказ изменению не подлежит.
            </li>
            <li>
              Сроки, стоимость и прочие условия доставки товара Покупателю
              определяются в конкретном заказе, при этом обязанность по их
              исполнению и неукоснительному соблюдению целиком и полностью
              возлагается на Продавца.
            </li>
            <li>
              Заказ считается выполненным и обязательства Faradise и Продавца
              перед Покупателем считаются исполненными в момент фактической
              передачи товаров, входящих в состав подтвержденного Заказа,
              Покупателю или иным
              {' '}
            </li>
            <li>
              При приемке Товара Покупатель обязан проверить Товар по
              количеству, качеству (общая целостность Товара, наличие трещин,
              сколов, потертостей, пятен, разводов, а также иных недостатков,
              для обнаружения которых не требуется применение специального
              оборудования) и комплектности, на его соответствие
              образцу/описанию Товара, а также данным, указанным в
              подтвержденном заказе. В случае обнаружения недостатков Товара
              и/или его несоответствия данным, указанным на Платформе или в
              подтвержденном Заказе, Покупатель обязан указать об этом в
              соответствующем документе при приеме Товара, в противном случае,
              Товар считается принятым без претензий по количеству, качеству и
              комплектности
            </li>
            <li>
              Продавец обязуется приложить все усилия для соблюдения сроков
              поставки, указанных в подтвержденном заказе, тем не менее,
              задержки в поставке возможны ввиду непредвиденных обстоятельств,
              произошедших не по вине Продавца и/или Faradise.
            </li>
            <li>
              Право собственности на Товар и риск случайной гибели или
              случайного повреждения Товара переходит от Продавца к Покупателю с
              момента передачи ему товара и подписания Покупателем документов,
              подтверждающих получение товара.
            </li>
            <li>
              Покупатель вправе предъявить претензии в отношении качества Товара
              в порядке и сроки, установленные действующим законодательством РФ,
              оформив свои требования в письменной форме.
            </li>
            <li>
              Информация о порядке, способах и сроках возврата и обмена товара,
              а также порядке и способах получения денежных средств за
              возвращенный товар, размещена на Платформе по адресу:
              www.faradise.ru/design/return. Для оформления возврата или обмена Товара
              Покупателю необходимо обратиться к Faradise по телефону,
              указанному на Платформе либо путем направления соответствующего
              письма через форму обратной связи на Платформе
            </li>
            <li>
              Не подлежат удовлетворению требования Покупателей о
              возврате/обмене товара с недостатками в случаях если внешние
              механические повреждения возникли после передачи товара или если
              недостатки являются следствием неправильной эксплуатации товара.
            </li>
            <li>
              В случае, если Товар был оплачен безналичным способом, возврат
              денежных средств осуществляется на основании оформленной заявки на
              возврат, при этом возврат денежных средств производится на тот же
              расчетный счет, с которого производилась оплата.
            </li>
            <li>
              Возврат денежных средств определяется Faradise индивидуально, но
              не может превышать тридцати пяти календарных дней с моменты
              признания соответствующего требования.
            </li>
            <li>
              Акцептуя оферту, Покупатель подтверждает, что он ознакомлен и
              согласен с условиями Политикой конфиденциальности Платформы,
              размещенной по адресу: www.faradise.ru/design/agreement
            </li>
            <li>
              Акцептуя оферту, Покупатель дает разрешение Faradise, его
              уполномоченным представителям и его партнерам, в целях
              информирования о товарах и услугах Faradise, заключения и
              исполнения договора купли-продажи обрабатывать – собирать,
              записывать, систематизировать, накапливать, хранить, уточнять
              (обновлять, изменять), извлекать, использовать, передавать (в том
              числе, поручать обработку другим лицам), обезличивать,
              блокировать, удалять, уничтожать персональные данные Покупателя к
              которым относятся: фамилию, имя, пол, дату рождения, номера
              телефонов и адрес электронной почты.
            </li>
            <li>
              Акцептуя оферту, Покупатель дает разрешение Faradise в целях
              информирования о товарах, работах, услугах направлять на указанный
              Покупателем номер мобильного телефона и/или адрес электронной
              почты рекламу и информацию о товарах, работах, услугах Faradise и
              его партнеров, в том числе, в виде смс-сообщений, входящих вызовов
              и e-mail рассылок.
            </li>
            <li>
              Faradise гарантирует конфиденциальность и защиту полученной
              информации в соответствии с действующим законодательством РФ.
            </li>
            <li>
              В случаях, предусмотренных действующим законодательством РФ,
              Покупатель вправе отозвать свое согласие на обработку персональных
              данных, при этом согласие считается отозванным по истечении 30
              дней с даты получения Faradise по адресу, указанному на Платформу,
              направленного Покупателем письменного заявления об отзыве
              настоящего такого согласия.
            </li>
            <li>
              Faradise вправе использовать технологию «cookies» (технология,
              позволяющая веб-серверу посылать служебную информацию на компьютер
              пользователя и сохранять ее в браузере).
            </li>
            <li>
              Faradise получает информацию об идентификаторе устройства
              посетителя Сайта, подключённого к локальной сети и/или сети
              Интернет (IP-адресе). Данная информация используется исключительно
              в статистических целях и не используется для установления личности
              посетителя.
            </li>
            <li>
              Faradise не несет ответственности за ущерб, причиненный Покупателю
              Продавцом и/или вследствие ненадлежащего использования товаров,
              приобретенных помощью Платформы.
            </li>
            <li>
              Faradise не отвечает за убытки Покупателя, возникшие в результате
              неправомерных действий Продавца и/или третьих лиц
            </li>
            <li>
              Стороны освобождаются от ответственности за полное или частичное
              неисполнение своих обязательств, если такое неисполнение явилось
              следствием действия обстоятельств непреодолимой силы в результате
              событий чрезвычайного характера, которые Стороны не могли
              предвидеть и предотвратить разумными мерами. В иных случаях
              неисполнения или ненадлежащего исполнения своих обязательств,
              Стороны несут ответственность в соответствии с действующим
              законодательством Российской Федерации.
            </li>
          </ol>
        </OtherBox>
        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { OfferMarketplace }
