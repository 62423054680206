import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Icon } from '@ui/atoms'

const CardSelect = ({
  card, onChange, active, withCounter, onCounterChange
}) => (
  <div className={classnames('card-container', { 'with-counter': withCounter })}>
    <div onClick={() => onChange(card)} className={classnames('card', { active })}>
      {(!active || !withCounter) && (
        <div className="card-logo">
          <Icon icon={card.img} />
        </div>
      )}
      {withCounter
        && active && (
          <div className="card-counter">
            <div
              className="reduce-counter"
              onClick={(e) => {
                e.stopPropagation()
                onCounterChange({ count: (card.count ? card.count : 1) - 1, card })
              }}
            >
              -
            </div>
            <div className="counter">
              {card.count ? card.count : 1}
            </div>
            <div
              className="increase-counter"
              onClick={(e) => {
                e.stopPropagation()
                onCounterChange({ count: (card.count ? card.count : 1) + 1, card })
              }}
            >
              +
            </div>
          </div>
      )}
      <div className="card-text">
        {card.name}
      </div>
    </div>
  </div>
)

CardSelect.defaultProps = {
  withCounter: false,
  onCounterChange: () => false
}

CardSelect.propTypes = {
  card: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCounterChange: PropTypes.func,
  active: PropTypes.bool.isRequired,
  withCounter: PropTypes.bool
}

export { CardSelect }
