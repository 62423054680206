import React, { Component } from 'react'
import { pickBy } from 'lodash'
import { connect } from 'react-redux'

import {
  Button, CheckBox, CardSelectMobile, CardSelect
} from '@ui/atoms'
import ClientService from '@services/ClientService'
import roomAssignment from '@constants/rooms'
import { InfoBox } from './info.style'
import { whyCheckBox } from './config/whyCheckbox'
import { typeOfDwelling } from './config/typeOfDwelling'

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId
})

@connect(mapStateToProps)
class InfoMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      other: '',
      step: 1,
      currentRoom: [],
      currentDwelling: [],
      checked: whyCheckBox.reduce(
        (a, c) => ({
          ...a,
          [c.id]: false
        }),
        {}
      ),
      ...props.stepState
    }
  }

  goNext = () => {
    const { goNext } = this.props

    goNext(this.state)
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  setRoom = (card) => {
    const { currentRoom } = this.state
    const index = currentRoom.findIndex(r => r.model === card.model)
    if (index === -1) {
      currentRoom.push(card)
      this.setState({ currentRoom })
    }
  }

  changeCountRoom = ({ count, card, e }) => {
    e.stopPropagation()
    const { currentRoom } = this.state
    const index = currentRoom.findIndex(r => r.model === card.model)
    if (count === 0) {
      currentRoom.splice(index, 1)
    } else {
      currentRoom[index].count = count
    }
    this.setState({ currentRoom })
  }

  setDwelling = (card) => {
    this.setState({ currentDwelling: [card] })
  }

  nextStep = async () => {
    let { step } = this.state
    if (step < 3) {
      step += 1
    } else {
      await this.save()
    }
    this.setState({ step })
  }

  save = async () => {
    try {
      const { currentRoom, checked, currentDwelling } = this.state

      const { activeProjectId } = this.props

      await ClientService.setAdditionalInfo({
        id: activeProjectId,
        objectType: currentDwelling[0].model,
        rooms: currentRoom.map(room => ({
          purpose: room.model,
          count: room.count || 1
        })),
        reasons: whyCheckBox
          .filter(reason => pickBy(checked, val => val)[reason.id])
          .map(reason => ({ name: reason.model }))
      })

      this.goNext()
    } catch (e) {
      console.log(e)
    }
  }

  checkboxChange = (id, value) => {
    this.setState(state => ({
      ...state,
      checked: {
        ...state.checked,
        [id]: value
      }
    }))
  }

  render() {
    const {
      currentRoom, checked, other, currentDwelling, step
    } = this.state

    return (
      <InfoBox>
        {step === 1 && (
        <React.Fragment>
          <div className="field-title">
            <span className="title-box">
Выбери тип объекта
            </span>
            <div className="step">
              {step}
            /3
            </div>
          </div>
          <div className="cards-wrapper">
            {typeOfDwelling.map(card => (
              <CardSelect
                key={card.id}
                card={card}
                onChange={this.setDwelling}
                active={!!currentDwelling.find(c => c.id === card.id)}
              />
            ))}
          </div>
          <div className="button-container">
            <Button text="Далее" color="primary" onClick={this.nextStep} disabled={!currentDwelling.length} />
          </div>
        </React.Fragment>
        )}
        {step === 2 && (
        <React.Fragment>
          <div className="field-title">
            <span className="title-box">
               Выберите комнаты
            </span>
            <div className="step">
              {step}
            /3
            </div>
          </div>
          <div className="cards-wrapper with-counter">
            {roomAssignment.map(card => (
              <CardSelectMobile
                key={card.model}
                onCounterChange={this.changeCountRoom}
                card={card}
                withCounter
                onChange={this.setRoom}
                active={!!currentRoom.find(c => c.model === card.model)}
              />
            ))}
          </div>
          <div className="button-container">
            <Button text="Далее" color="primary" onClick={this.nextStep} disabled={!currentRoom.length} />
            <Button text="Назад" type="text" className="next-button" onClick={this.goPrev} />
          </div>
        </React.Fragment>
        )}
        {step === 3 && (
        <React.Fragment>
          {' '}
          <div className="field-title">
            <span className="title-box">
               Почему вы хотите
сменить дизайн?
            </span>
            <div className="step">
              {step}
            /3
            </div>
          </div>
          <div className="checkbox-form">
            {whyCheckBox.map(checkbox => (
              <CheckBox
                key={checkbox.id}
                onChange={value => this.checkboxChange(checkbox.id, value)
              }
                withInput={checkbox.withInput}
                label={checkbox.label}
                checked={checked[checkbox.id]}
                onInputChange={value => this.setState({ other: value })}
                inputValue={other}
                placeholder={checkbox.placeholder}
              />
            ))}
          </div>
          <div className="button-container ml-25">
            <Button
              text="Сохранить и продолжить"
              color="primary"
              onClick={this.nextStep}
              disabled={!Object.keys(pickBy(checked, value => value)).length}
            />
            <Button text="Назад" type="text" className="next-button" onClick={this.goPrev} />
          </div>
        </React.Fragment>
        )}
      </InfoBox>
    )
  }
}

export { InfoMobile }
