import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CheckBoxStyle } from './checkbox.style'

// eslint-disable-next-line
class CheckBox extends Component {
  onInnerInputChange = (event) => {
    const { onChange, checked, onInputChange } = this.props
    const { value } = event.target
    if (value === '' && checked) {
      onChange(false)
    } else {
      onChange(true)
    }
    onInputChange(value)
  }

  onInnerIconClick = () => {
    const { onIconClick, id } = this.props

    onIconClick(id)
  }

  render() {
    const {
      withInput,
      label,
      placeholder,
      onChange,
      disabled,
      checked,
      inputValue,
      onFocus,
      onMouseOver,
      icon,
      className,
      renderCustomContent,
      suffix
    } = this.props

    return (
      <CheckBoxStyle onFocus={onFocus} onMouseOver={onMouseOver} className={classnames('checkbox-container', { checked }, className)}>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={e => onChange(e.target.checked)}
              checked={checked}
              disabled={disabled}
              classes={{
                root: 'control'
              }}
            />
)}
          classes={{
            label: 'checkbox-label'
          }}
          label={label}
        />
        {icon && (
          <Icon onClick={this.onInnerIconClick} className="icon">
            {icon}
          </Icon>
        )}
        {suffix && (
        <span className="suffix">
          {suffix}
        </span>
        )}
        {withInput ? (
          <div>
            <div className="line" />
            <input type="text" className="checkbox-input" placeholder={placeholder} value={inputValue} onChange={this.onInnerInputChange} />
          </div>
        ) : (
          ''
        )}
        {renderCustomContent()}
      </CheckBoxStyle>
    )
  }
}

CheckBox.defaultProps = {
  label: '',
  placeholder: '',
  withInput: false,
  disabled: false,
  checked: false,
  onInputChange: () => false,
  inputValue: '',
  renderCustomContent: () => null,
  suffix: ''
}

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  withInput: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  renderCustomContent: PropTypes.func,
  suffix: PropTypes.string
}

export { CheckBox }
