import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'

import cash from '@assets/images/icons/cash.png'
import eCash from '@assets/images/icons/e-cash.png'
import card from '@assets/images/icons/card.png'

const HowToPayBox = styled(OtherBox)`
  .top-text {
    max-width: 1113px;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    font-family: FuturaNewBook-Reg;
  }

  .sub-title {
    margin-top: 60px;
    font-family: FuturaNewBold-Reg;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    color: ${COLORS.darkBlue};
  }

  .how-to-pay {
    display: flex;
    flex-direction: column;

    .pay-row {
      margin-top: 40px;
      height: 84px;
      display: flex;

      .img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        margin-right: 46px;
      }

      .name {
        margin-right: 30px;
        width: 160px;
        font-family: FuturaNewBold-Reg;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 2px;
        color: ${COLORS.darkBlue};
        text-transform: uppercase;
      }

      .list {
        display: flex;
        flex-direction: column;
        width: 160px;
        margin-right: 30px;

        .list-item {
          font-family: Roboto-Regular;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.75;
          letter-spacing: normal;
          color: ${COLORS.darkBlue};
          }
        }

        .description {
          width: 540px;
          opacity: 0.7;
          font-family: Roboto-Regular;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.75;
          letter-spacing: normal;
          color: #253c5e;
        }
      }
    }
}
`

@withRouter
class HowToPay extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <HowToPayBox isInnerPage={isInnerPage}>
          <h1>
Как оплатить
          </h1>

          <div className="top-text">
            Мы позаботились, чтобы способ оплаты был наиболее удобен для вас. После того, как вы оформите заказ, мы проверим наличие товара,
            соответствие условиям доставки и только после этого отправим вам ссылку на оплату.
          </div>

          <div className="sub-title">
Как вы можете оплатить по ссылке?
          </div>

          <div className="how-to-pay">
            <div className="pay-row">
              <img src={card} alt="" className="img" />
              <div className="name">
Банковские карты
              </div>
              <div className="list">
                <div className="list-item">
• Visa
                </div>
                <div className="list-item">
• MasterCard
                </div>
                <div className="list-item">
• МИР
                </div>
              </div>

              <div className="description">
                До 250 000 ₽ за один платеж
                {' '}
                <br />
                Не более 500 000 ₽ с одной карты
              </div>
            </div>

            <div className="pay-row">
              <img src={eCash} alt="" className="img" />
              <div className="name">
              Электронные деньги
              </div>
              <div className="list">
                <div className="list-item">
                •  Яндекс.Деньги
                </div>
                <div className="list-item">
                •  Qiwi Кошелек
                </div>
                <div className="list-item">
                •  WebMoney
                </div>
              </div>

              <div className="description">
              Яндекс.Деньги до 250 000 ₽ за один раз и не более 600 000 ₽/сутки
                <br />
Qiwi Кошелек до 250 000 ₽ за один раз
                <br />
WebMoney до 60 000 ₽ за один раз
              </div>
            </div>

            <div className="pay-row">
              <img src={cash} alt="" className="img" />
              <div className="name">
              Наличные деньги
              </div>
              <div className="list">
                <div className="list-item">
                •  В терминалах
                </div>
                <div className="list-item">
                •  Банкоматах
                </div>
                <div className="list-item">
                •  Салонах связи
                </div>
              </div>

              <div className="description">
                До 15 000 ₽ за один раз
              </div>
            </div>
          </div>
        </HowToPayBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { HowToPay }
