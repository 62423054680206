import {
  SET_ACTIVE_PROJECT_ID,
  SET_PROJECTS_LIST,
  UPDATE_PROJECTS_LIST,
  SET_CURRENT_PROJECT,
  SET_ACTIVE_STAGE
} from '../constants/project'

export const setActiveProjectId = (id) => {
  localStorage.setItem('activeProjectId', id)

  return {
    type: SET_ACTIVE_PROJECT_ID,
    payload: id
  }
}

export const setProjectsList = list => ({
  type: SET_PROJECTS_LIST,
  payload: list
})

export const updateProjectsList = cb => ({
  type: UPDATE_PROJECTS_LIST,
  cb
})

export const setCurrentProject = project => ({
  type: SET_CURRENT_PROJECT,
  payload: project
})

export const setActiveStage = stage => ({
  type: SET_ACTIVE_STAGE,
  payload: stage
})
