import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { COLORS } from '@constants/colors'
import { ProjectService } from '@services/ProjectService'
import { ProjectProducts } from '@ui/organisms/projectProducts'
import { Button } from '@ui/atoms';

const MudbordBox = styled.div`
  .page_content {
    margin-bottom: 14px;
  }
  .text,
  .page-info {
    font-size: 16px;
    color: ${COLORS.getBlueColor(0.54)};
    line-height: 24px;
    font-family: Roboto-Regular;

    .bold {
      font-family: Roboto-Medium;
      font-weight: 500;
    }
  }
  .page-description {
    margin-bottom: 16px;
  }
  .text {
    max-width: 350px;
  }
  .page-info {
    margin-bottom: 16px;
    color: ${COLORS.darkBlue};
  }
  .wait-box {
    margin-top: 24px;
    .page-info {
      display: inline-block;
    }
    svg {
      fill: ${COLORS.orange};
      vertical-align: top;
    }
    .page-info {
      margin-left: 18px;
      max-width: 379px;
    }
  }
  .page-footer.step2 {
    display: flex;
    justify-content: space-between;
  }

  .btn_content {
    display: flex;
    justify-content: flex-end;
    width: 730px;
    margin: 20px 165px 48px 165px;

    .all-download-btn {
      margin-right: 20px;
    }
  }
  
  .price {
    font-family: Roboto-Regular;
    font-size: 48px;
    color: ${COLORS.getBlueColor()};
  }
  
  .pay-btn {
    margin-top: 48px;
    width: 191px;
  }
  
  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 32px;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class ClientControlAndOrder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rooms: [],
    }
  }

  async componentDidMount() {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      this.setState({
        rooms: result.map(room => ({
          model: room.purpose,
          roomId: room.roomId
        })),
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { rooms } = this.state
    const { goPrev } = this.props

    return (
      <MudbordBox>
        <div className="page_content">
          <div className="page-body">
            <div className="page-title">
              Авторский надзор
              и заказ товаров
            </div>
            <div className="g_big-text mb32">
              Воу. Почти все. Если вам необходим авторский надзор от дизайнера - обсудите это с ним индивидуально.
              <br />
              <br />
              Осталось только заказать все товары, а нам доставить их во время и в срок.
              Вы можете не оплачивать сразу все товары. Выберите товары и оформите заказ.

            </div>
            <div className="buttons-row">
              <div>
                <Button
                  text="Назад"
                  onClick={goPrev}
                  type="text"
                />
              </div>
            </div>
            {!!rooms.length && (
              <ProjectProducts
                rooms={rooms}
              />
            )}
          </div>
        </div>
      </MudbordBox>
    )
  }
}

export { ClientControlAndOrder }
