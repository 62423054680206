import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'

import { Button, Radio } from '@ui/atoms'
import { CarouselBox } from './questionsCarousel.style'

class QuestionsCarouselMobile extends Component {
  state = {
    currentSlide: 0,
    slideWidth: 500,
    transition: 0
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transition: 1 })
    }, 1000)
  }

  goNext = () => {
    this.setState(state => ({ currentSlide: state.currentSlide + 1 }))
  }

  goPrev = () => {
    this.setState(state => ({ currentSlide: state.currentSlide - 1 }))
  }

  handleContainerRef = (ref) => {
    if (ref) {
      this.setState({
        slideWidth: parseInt(getComputedStyle(ref).width, 10) - 30
      })
    }
  }

  render() {
    const {
      questions, onAnswerChange, answers, onAllAnswered
    } = this.props
    const { currentSlide, slideWidth, transition } = this.state

    return (
      <CarouselBox>
        <div className="slides-container" ref={this.handleContainerRef}>
          <div
            className="slick-track"
            style={{
              width: slideWidth * questions.length,
              display: 'flex',
              transform: `translateX(-${slideWidth * currentSlide}px)`,
              transition: `${transition}s`
            }}
          >
            {questions.map(slide => (
              <div
                className={classnames('carousel-slide')}
                key={slide.id}
                style={{ width: slideWidth }}
              >
                <div className="slide-title">
                  <span className="title-box">
                    {slide.text}
                  </span>
                  <div className="carousel-counter">
                    {`${currentSlide + 1}/${questions.length}`}
                  </div>
                </div>
                <Radio
                  value={`${answers[slide.id]}`}
                  onChange={(e) => {
                    onAnswerChange(slide.id, e.target.value)
                  }}
                  items={slide.answers.map(answer => ({
                    value: `${answer.id}`,
                    label: answer.text
                  }))}
                />
              </div>
            ))}
          </div>
          {currentSlide + 1 === questions.length ? (
            <Button
              text="Продолжить"
              className="btn-next"
              onClick={() => onAllAnswered()}
            />
          ) : (
            <Button text="Далее" className="btn-next" onClick={this.goNext} />
          )}
          <Button
            text="Назад"
            type="text"
            size="large"
            className="prev-btn"
            onClick={this.goPrev}
          />
        </div>
      </CarouselBox>
    )
  }
}

QuestionsCarouselMobile.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  onAllAnswered: PropTypes.func.isRequired
}

export { QuestionsCarouselMobile }
