import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'

import { MainFooter } from '@ui/organisms/mainFooter'
import { Header, OtherBox } from '@pages/other/style'

@withRouter
class Rates extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
            Тарифы Платформы (платформа-сервис агрегатор мебели в дополненной
            реальности Faradise)
          </h1>
          <p>
            Российская Федерация, город Москва
          </p>
          <p>
            Дата размещения: 12.04.2018
          </p>
          <p>
            Дата вступления в силу: 12.04.2018
          </p>
          <span>
            Настоящие Тарифы Платформы (далее - Тарифы) на использование
            платформы-сервиса агрегатора мебели в дополненной реальности
            Faradise (далее - &quot;Платформа&quot;) представляют собой
            стоимостную оценку всех услуг Платформы предоставляемых Обществом с
            ограниченной ответственностью &quot;Центр технологий и медиа
            групп&quot; (далее - &quot;Faradise&quot;) на условиях
            предусмотренных Пользовательским соглашением (офертой) на
            использование платформы-сервиса агрегатор мебели в дополненной
            реальности &quot;Faradise&quot; (далее - &quot;Оферта&quot;)
          </span>
          <ol>
            <li>
              Тарифы Платформы - рекламные возможности
            </li>
            <table>
              <colgroup>
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>
Поле
                    </span>
                  </th>
                  <th>
                    <span>
Производитель
                    </span>
                  </th>
                  <th>
                    <span>
Дистрибьютор
                    </span>
                  </th>
                  <th>
                    <span>
Производитель и Дистрибьютор
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  Размещение товаров на Платформе
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Использование собственных 3D-моделей согласно Техническим
                  требованиям Инструкции
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Получение доступа к Образам 3D-моделей, имеющимся на
                  Платформе
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  3 900 (три тысячи девятьсот) 00 копеек.
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Комиссия с поступившего заказа на Платформе
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Передача определенной информации о Партнере (информация о
                  сайте)
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Передача определенной информации о Партнере (информация о
                  телефоне)
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Передача определенной информации о Партнере (информация об
                  адресе)
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
                <tr>
                  <td>
                  Коммуникация с пользователем Faradise через онлайн-чат
                  </td>
                  <td>
                  Недоступно
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                  <td>
                  0 руб. 00 копеек.
                  </td>
                </tr>
              </tbody>
            </table>

            <li>
              Тарифы Платформы на разработку 3D-моделей и доработку 3D-моделей.
              Стоимость может меняться в зависимости от сложности разработки
              моделей.
            </li>
            <table>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>
Категория товара
                    </span>
                  </th>
                  <th>
                    <span>
Стоимость разработки 3D-моделей (руб.)
                    </span>
                  </th>
                  <th>
                    <span>
Стоимость доработки 3D-моделей (руб.)
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
Диваны
                  </td>
                  <td>
От 3500 руб.
                  </td>
                  <td>
От 1800 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Кровати
                  </td>
                  <td>
От 3500 руб.
                  </td>
                  <td>
От 1800 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Шкафы, комоды,
                  стеллажи
                  </td>
                  <td>
От 3000 руб.
                  </td>
                  <td>
От 1200 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Стулья и кресла
                  </td>
                  <td>
От 2400 руб.
                  </td>
                  <td>
От 1200 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Столы и
                  письменные столы
                  </td>
                  <td>
От 1800 руб.
                  </td>
                  <td>
От 1200 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Аксессуары для
                  хранения и порядка
                  </td>
                  <td>
От 1800 руб.
                  </td>
                  <td>
От 1200 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Кухонная мебель
                  и сантехника
                  </td>
                  <td>
От 3500 руб.
                  </td>
                  <td>
От 1800 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Текстиль и
                  ковры
                  </td>
                  <td>
От 1200 руб.
                  </td>
                  <td>
От 600 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Зеркала
                  </td>
                  <td>
От 1200 руб.
                  </td>
                  <td>
От 600 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Освещение
                  </td>
                  <td>
От 1800 руб.
                  </td>
                  <td>
От 1200 руб.
                  </td>
                </tr>
                <tr>
                  <td>
Оформление
                  интерьера
                  </td>
                  <td>
От 1200 руб.
                  </td>
                  <td>
От 600 руб.
                  </td>
                </tr>
              </tbody>
            </table>
          </ol>
          <div className="sub-title">
            Реквизиты Faradise
          </div>
          <span>
Общество с ограниченной ответственностью &quot;Центр технологий и медиа групп&quot;
            <br />
Адрес (юридический): 107564, г. Москва, ул. Краснобогатырская, д.6, стр.2, ком. 18
          </span>
          <p>
            ОГРН: 1167746194626
          </p>
          <p>
          ИНН 7718299492
          </p>
          <p>
            Р/с (расчетный счет): 40702810510000203351
          </p>
          <p>
            Банк: АО &quot;Тинькофф Банк&quot;
          </p>
          <p>
            БИК: 044525974
          </p>
          <p>
            Корр. счет: 30101810145250000974
          </p>
          <p>
Тел.: +7(495)132-74-62
          </p>
          <p>
Форма обратной связи:
          </p>
          <p>
            E-mail: privet@faradise.ru
          </p>
        </OtherBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Rates }
