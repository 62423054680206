import * as React from 'react'
import { withRouter } from 'react-router'

import '@ui/style.global'

import { rootRoutes } from './routes'

const App = () => (
  <React.Fragment>
    {rootRoutes()}
  </React.Fragment>
)

const RoutedApp = withRouter(App)

export { RoutedApp as App }
