import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const SubcategoriesBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(37, 60, 94, 0.12);

  .title {
    width: 100%;
    margin-bottom: 16px;
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
  }

  .subcategory-wrapper {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 195px;
    height: 64px;
    border-radius: 8px;
    background-color: rgba(37, 60, 94, 0.06);
    padding: 14px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .name {
      width: 112px;
      font-family: FuturaNewBook-Reg;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.13;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }

    .count {
      margin-left: 8px;
      opacity: 0.38;
      font-family: Roboto-Regular;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: right;
      color: ${COLORS.darkBlue};
    }
  }
`

export { SubcategoriesBox }
