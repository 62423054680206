import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const Card = styled.div`
  position: relative;
  margin-bottom: 24px;
  margin-right: 25px;
  box-sizing: border-box;
  width: 241px;
  height: 178px;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  border-radius: 8px;
  border: solid 1px ${COLORS.grey};
  background: url(${({ src }) => src}) 100% 100% no-repeat;
  background-size: cover;

  &:hover {
    border: solid 1px ${COLORS.orange};
    box-shadow: 0 12px 32px 0 rgba(0, 122, 255, 0.2);
  }

  .choose-icon {
    position: absolute;
    left: 13px;
    top: 10px;
    width: 29px;
    height: 29px;
  }

  .comment-icon {
    position: absolute;
    right: 13px;
    top: 10px;
    width: 29px;
    height: 29px;
  }

  .comment-wrapper {
    position: absolute;
    z-index: 100;
    top: 25%;
    left: 5%;
    width: 90%
    height: 70%;
    display: none;

    &.active {
      display: block;
    }

    textarea {
      font-family: FuturaNewBook-Reg;
      padding: 10px;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 14px;
      color: ${COLORS.text};
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      border: solid 1px #e8e9ec;
    }
  }

  .card-text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: rgba(70, 72, 92, 0.5);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .card-text {
    position: absolute;
    font-family: FuturaNewMedium-Reg;
    font-size: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
  }
`

export { Card }
