import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const PreloaderBox = styled.div`
  top: 49%;
  left: 49%;
  position: absolute;
`

const Preloader = ({ className }) => (
  <PreloaderBox className={className}>
    <CircularProgress />
  </PreloaderBox>
)

export { Preloader }
