import React, { Component } from 'react'
import { renderRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { withRouter } from 'react-router'

import { MainHeader } from '@ui/organisms/mainHeader'
import { MainFooter } from '@ui/organisms/mainFooter'
import { MarketplaceService } from '@services/MarketplaceService'
import {
  setCategories,
  setActiveCategory,
  setSearchPhrase,
  setActiveSubcategory
} from '@redux/actions/marketplace'
import { Preloader } from '@ui/atoms'
import { Search } from '@pages/Marketplace/Search'
import { Main } from '@pages/Marketplace/Main'
import { isEqual } from 'lodash'
import { MarketplaceBox } from './marketplace.style'
import { routes } from './configs/routes'
import { setLocationSearch } from '@helpers'
import { ROUTES } from '@constants'
import { Controls } from './common/Controls'

const mapStateToProps = state => ({
  categories: state.marketplace.categories,
  activeCategory: state.marketplace.activeCategory,
  searchPhrase: state.marketplace.searchPhrase
})

const mapDispatchToProps = dispatch => ({
  setCategories: categories => dispatch(setCategories(categories)),
  setActiveCategory: category => dispatch(setActiveCategory(category)),
  setActiveSubcategory: category => dispatch(setActiveSubcategory(category)),
  setSearchPhrase: phrase => dispatch(setSearchPhrase(phrase))
})

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Marketplace extends Component {
  state = {
    loading: true
  }

  async componentDidMount() {
    try {
      const {
        setCategories,
        location,
        setActiveCategory,
        setSearchPhrase,
        setActiveSubcategory
      } = this.props

      const response = await MarketplaceService.getCategories()

      setCategories(response.result)

      const {
        category: categoryId,
        subcategory: subcategoryId,
        search
      } = queryString.parse(location.search)

      if (categoryId) {
        setActiveCategory(
          response.result.find(category => category.id === Number(categoryId))
        )
      }

      if (subcategoryId) {
        const response = await MarketplaceService.getCategoryDescription(
          subcategoryId
        )

        setActiveSubcategory(response.result.category)
      }

      if (search) {
        setSearchPhrase(search)
      }

      this.setState({ loading: false })
    } catch (error) {
      console.error(error)
    }
  }

  componentDidUpdate(prevProps) {
    const { location, setSearchPhrase } = this.props

    const { search } = queryString.parse(location.search)

    if (!isEqual(prevProps.location, location) && !search) {
      setSearchPhrase('')
    }

    if (!isEqual(prevProps.location, location) && search) {
      setSearchPhrase(search)
    }
  }

  selectCategory = (id) => {
    const { setActiveCategory } = this.props

    setLocationSearch('category', id, ROUTES.marketplace)

    setActiveCategory(id)
  }

  render() {
    const { location, searchPhrase, activeCategory } = this.props

    const { loading } = this.state

    return (
      <MarketplaceBox>
        {loading ? (
          <Preloader />
        ) : (
          <React.Fragment>
            <MainHeader activeTab="marketplace">
              <Controls className="controls" fullWidth={!activeCategory} />
            </MainHeader>
            <div className="page-wrapper">
              {searchPhrase && location.pathname === ROUTES.marketplace ? (
                <Search searchPhrase={searchPhrase} />
              ) : activeCategory ? (
                renderRoutes(routes)
              ) : (
                <Main />
              )}
            </div>
            <MainFooter className="first-step-footer" showBeta />
          </React.Fragment>
        )}
      </MarketplaceBox>
    )
  }
}

export { Marketplace }
