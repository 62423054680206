import React from 'react';
import { mapValues, map, pickBy } from 'lodash';
import { connect } from 'react-redux';

import { Column, Row } from '@ui/atoms/layout';
import {
  Button, CheckBox, Input, Textarea
} from '@ui/atoms';
import { AddFoto } from '@ui/atoms/addFoto';
import { Select } from '@ui/atoms/select';
import DesignerService from '@services/DesignerService';
import { setUserData } from '@redux/actions/user';
import { validateLink } from '@helpers/validators';
import { TellAboutYourselfBox } from './tellAboutYourself.style'

const STYLES = {
  0: { name: 'Эклетика', model: 'Eclecticism' },
  1: { name: 'Современная класика', model: 'ModernClassic' },
  2: { name: 'Прованс', model: 'Provence' },
  3: { name: 'Скандинавский', model: 'Scandinavian' },
  4: { name: 'Фьюжн', model: 'Fusion' },
  5: { name: 'Лофт', model: 'Industrial' },
  6: { name: 'Арт-Деко', model: 'ArtDeco' },
  7: { name: 'Современный', model: 'Modern' },
  8: { name: 'Кантри', model: 'Country' },
  9: { name: 'Минимализм', model: 'Minimalism ' },
};

const mapStateToProps = state => ({
  userData: state.user.userData
})

const mapDispatchToProps = dispatch => ({
  setUserInfo: data => dispatch(setUserData(data))
})

@connect(mapStateToProps, mapDispatchToProps)
class TellAboutYourself extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      surname: '',
      age: '',
      sex: '',
      city: '',
      contactPhone: props.userData.phone,
      email: '',
      experience: '',
      link: '',
      styles: mapValues(STYLES, style => ({ ...style, selected: false })),
      story: '',
      photo: null,
      ...props.stepState
    }
  }

  isValid = () => {
    const {
      name, surname, age, sex, city, contactPhone, email, experience, link, story, photo, styles,
    } = this.state;
    /* eslint-disable */
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-enable */

    return name && surname && age && sex && city && contactPhone.replace(/\D+/g, '').length === 11
            && emailRegEx.test(email.toLowerCase())
            && experience && !validateLink(link) && story && photo && Object.keys(pickBy(styles, val => val !== null)).length > 0;
  };

  save = async () => {
    const { goNext, changeGlobalState, setUserInfo } = this.props

    const {
      name, surname, age, sex, city, contactPhone, email, experience, link, styles, story,
    } = this.state

    try {
      const response = await DesignerService.setBasicInfo({
        basic: {
          name,
          surname,
          age: parseInt(age, 10),
          email,
          phone: contactPhone.replace(/\D+/g, ''),
          about: story,
          city,
          gender: sex,
          workExperience: experience,
        },
        styles: map(pickBy(styles, style => style.selected), style => ({ name: style.model })),
        portfolioLink: link,
      });

      if (response) {
        changeGlobalState({ name, email })

        const info = {
          name, email, phone: contactPhone.replace(/\D+/g, ''), city, role: 'Designer', surname
        }

        localStorage.setItem('userData', JSON.stringify(info))

        setUserInfo(info)
        goNext(this.state);
      }
    } catch (e) {
      console.log(e);
    }
  }

  addAvatar = async (files) => {
    if (files[0]) {
      await DesignerService.addAvatar(files[0])

      this.setState({
        photo: {
          file: files[0],
          link: URL.createObjectURL(files[0])
        }
      });
    }
  }


  render() {
    const {
      name, surname, age, sex, city, contactPhone, email, experience, link, styles, story, photo,
    } = this.state;

    return (
      <TellAboutYourselfBox className="page-content">
        <div className="page-body">
          <div className="page-title">
            Расскажите нам о себе
          </div>
          <div className="page-description">
            Нам очень интересно узнать о вас побольше, для этого, пожалуйста,
            {' '}
            заполните все поля ниже и укажите с какими стилями вам нравится работать больше всего.
          </div>
          <div className="page-small-title">
            Контактная информация
          </div>
          <div className="contacts-wrapper">
            <Row className="contacts-form">
              <Column lg={6}>
                <Input
                  label="Имя"
                  onChange={e => this.setState({ name: e.target.value })}
                  value={name}
                  full
                />
              </Column>
              <Column lg={6}>
                <Input
                  label="Фамилия"
                  onChange={e => this.setState({ surname: e.target.value })}
                  full
                  value={surname}
                />
              </Column>
              <Column lg={6}>
                <Input
                  label="Возраст"
                  onChange={e => this.setState({ age: e.target.value })}
                  mask="999"
                  full
                  value={age}
                />
              </Column>
              <Column lg={6}>
                <Select
                  value={sex}
                  items={[
                    { value: 'Male', label: 'Мужской' },
                    { value: 'Female', label: 'Женский' },
                  ]}
                  id="sex-select"
                  label="Пол"
                  onChange={event => this.setState({ sex: event.target.value })}
                />
              </Column>
              <Column>
                <Input
                  label="Ваш город"
                  onChange={e => this.setState({ city: e.target.value })}
                  full
                  value={city}
                />
              </Column>
              <Column lg={6}>
                <Input
                  label="Телефон"
                  onChange={e => this.setState({ contactPhone: e.target.value })}
                  full
                  mask="+7 (\999) 999 - 99 - 99"
                  value={contactPhone}
                  className="g_disabled"
                />
              </Column>
              <Column lg={6}>
                <Input
                  label="Email"
                  onChange={e => this.setState({ email: e.target.value })}
                  full
                  value={email}
                />
              </Column>
              <Column lg={6}>
                <Select
                  value={experience}
                  items={[
                    { value: 'LessOneYear', label: 'Меньше года' },
                    { value: 'OneToThreeYears', label: 'От 1 до 3 лет' },
                    { value: 'ThreeToFiveYears', label: 'От 3 до 5 лет' },
                    { value: 'MoreThanFiveYears', label: 'Больше 5 лет' },
                  ]}
                  id="sex-select"
                  label="Ваш стаж"
                  onChange={event => this.setState({ experience: event.target.value })}
                />
              </Column>
              <Column lg={6}>
                <Input
                  label="Ссылка на ваше портфолио"
                  onChange={e => this.setState({ link: e.target.value })}
                  full
                  value={link}
                  error={link ? validateLink(link) : ''}
                />
              </Column>
            </Row>
            <AddFoto
              className="contacts-add-foto"
              image={photo ? photo.link : undefined}
              hideCotrols
              accept="image/jpeg, image/png, image/jpg"
              text="Добавить фото"
              multiply={false}
              onChange={this.addAvatar}
            />
          </div>
          <div className="page-small-title">
            Расскажите о себе и вашем бесценном опыте
          </div>
          <div className="page-subtitle">
            Можно не скромничать, чем интересней будет рассказ, тем сильнее вами заинтересуется будущий клиент
          </div>
          <Textarea
            onChange={e => this.setState({ story: e.target.value })}
            value={story}
            placeholder="Расскажите о себе"
            className="story"
          />
          <div className="page-small-title">
            Выберите стили с которыми работаете
          </div>
          <Row>
            {map(STYLES, (style, index) => (
              <Column lg={6} key={`style-column-${index}`}>
                <CheckBox
                  onChange={(value) => {
                    styles[index].selected = value;

                    this.setState({ styles });
                  }}
                  label={style.name}
                  value={style.id}
                  checked={styles[index].selected}
                />
              </Column>
            ))}
          </Row>
        </div>
        <div className="page-footer">
          <div className="policy">
            Нажимая кнопку
            {' '}
            <span className="button-name">
              “Сохранить и продолжить”
            </span>
            {' '}
            вы соглашаетесь с
            {' '}
            <a href="/design/agreement/" className="link" target="_blank" rel="noopener noreferrer">
              Политикой конфиденциальности
            </a>
          </div>
          <Button
            text="Сохранить и продолжить"
            onSave={this.save}
            disabled={!this.isValid()}
          />
        </div>
      </TellAboutYourselfBox>
    )
  }
}

export { TellAboutYourself }
