import styled from 'styled-components'

const HeaderBox = styled.header`
  width: 100%;
  height: 88px;
  padding: 0 104px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(164, 165, 189, 0.08), 0 1px 2px 0 rgba(164, 165, 189, 0.2);
`

export { HeaderBox }
