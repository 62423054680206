import styled from 'styled-components'

const FirstDesignerStepsBox = styled.div`
  .logo {
    height: 22px;
    margin-right: 32px;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  .first-step-footer {
    margin-left: 270px;
    margin-top: 48px;
    width: calc(100vw - 270px);

    .top-row,
    .bottom-row,
    .social-row {
      padding-left: 63px;
      padding-right: 60px;

      & > div {
        max-width: 1000px;
      }
    }
  }

  .page-content {
    min-height: calc(100vh - 48px);
  }

  .page {
    margin-left: 270px;
  }
`

export { FirstDesignerStepsBox }
