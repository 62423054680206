import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Formik } from 'formik'

import { Button, Input } from '@ui/atoms'
import { createUser as createUserAction } from '@redux/actions/client'
import { Modal, CodeInput } from '@ui/molecules';
import { UserService } from '@services/UserService';
import { HelloBox, ModalBody } from './hello.style'
import { validatePhone } from '@helpers';
import { ROUTES } from '@constants'

const mapStateToProps = state => ({
  userData: state.user.userData
})

const mapDispatchToProps = dispatch => ({
  createUser: data => dispatch(createUserAction(data))
})

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class HelloMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      step: props.userData.phone ? 3 : 1,
      phone: props.userData.phone || '',
      name: props.userData.name || '',
      code: '',
      isCodeWrong: false
    }
  }

  async componentDidMount() {
    const { userData } = this.props

    if (userData.phone) {
      this.requestCode()
    }
  }

  onSubmit = async (values) => {
    const { createUser } = this.props

    const response = await UserService.checkPhone(values.phone)

    if (response.result) {
      this.setState({ isModalOpen: true })

      return
    }

    this.setState({
      name: values.name,
      phone: values.phone,
      step: 3
    })

    // this.requestCode()

    try {
      await createUser({ ...values, role: 'Client' })
    } catch (e) {
      console.error(e)
    }
  }

  requestCode = async () => {
    try {
      const { phone } = this.state

      await UserService.requestCode(phone.replace(/\D+/g, ''))

      this.setState({ step: 3, isCodeWrong: false })
    } catch (error) {
      console.error(error)
    }
  }

  sendCode = async () => {
    try {
      const { goNext } = this.props
      const {
        phone, code
      } = this.state

      await UserService.bindPhone(phone.replace(/\D+/g, ''), code)

      goNext()
    } catch (error) {
      if (error === 'invalid_code') {
        this.setState({ isCodeWrong: true })
      }

      console.error(error)
    }
  }

  render() {
    const {
      isModalOpen, step, name, isCodeWrong, phone, code
    } = this.state

    const { userData, history } = this.props

    const projectNameTip = 'Спальня для Гоши'

    return (
      <HelloBox>
        {step === 1 && (
        <React.Fragment>
          <div className="step-title">
          Привет!
            {' '}
            <br />
          Мы рады вас видеть.
          </div>
          <div className="step-title-description">
               Давайте для начала познакомимся
          </div>
          <div className="info-text">
        После заполнения анкеты в течение 24 часов мы подберем для вас минимум двух дизайнеров на выбор.
            <br />
            <br />

Если дизайнер вам не понравится в ходе работы - вы сможете его заменить.
          </div>
          <Formik
            initialValues={{
              name: '',
              phone: '',
              city: '',
              projectName: ''
            }}
            validate={(values) => {
              const errors = {}

              Object.keys(values).forEach((key) => {
                if (!values[key]) {
                  errors[key] = 'Обязательное поле'
                }
              })


              if (validatePhone(values.phone)) {
                errors.phone = validatePhone(values.phone)
              }

              return errors
            }}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isInitialValid,
              touched,
              setValues
            }) => (
              <React.Fragment>
                <Input
                  className="info-input"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Как вас зовут?"
                  disabled={!!userData.name}
                  error={touched.name && errors.name}
                />
                <Input
                  className="info-input"
                  name="phone"
                  value={userData.phone || values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Номер телефона"
                  mask="+7 (\999) 999-99-99"
                  maskChar="_"
                  error={touched.phone && errors.phone}
                  disabled={!!userData.phone}
                />
                <Input
                  label="Из какого вы города?"
                  className="info-input"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!!userData.city}
                  error={touched.city && errors.city}
                />
                <Input
                  name="projectName"
                  className="info-input"
                  value={values.projectName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.projectName && errors.projectName}
                  label="Придумайте название проекта"
                  renderExample={() => (
                    <span className="example">
            Например,
                      {' '}
                      <span className="blue project-name-tip" onClick={() => setValues({ ...values, projectName: projectNameTip })}>
                        {projectNameTip}
                      </span>
                    </span>
                  )}
                />
                <div className="form-footer">
                  <Button
                    text="Начать мой проект"
                    className="next-button"
                    onClick={handleSubmit}
                    disabled={!isInitialValid && !isValid}
                    color="primary"
                  />
                  <div className="policy-text">
                  Нажимая кнопку
                    {' '}
                    <span className="begin">
&quot;Начать&quot;
                    </span>
                    {' '}
                  вы соглашаетесь с
                    {' '}
                    <a href="/design/agreement/" className="link" rel="noopener noreferrer" target="_blank">
                    Политикой конфиденциальности
                    </a>
                    {' '}
                  и
                    {' '}
                    <a href="#" className="link">
                    Пользовательским соглашением
                    </a>
                  </div>
                </div>

                <Modal isOpen={isModalOpen} onCancel={() => this.setState({ isModalOpen: false })}>
                  <ModalBody>
                    <div className="title">
                    Кажется мы знакомы?
                    </div>
                    <div className="text">
                    Данный номер телефона уже зарегистрирован. Пройдите авторизацию или используйте другой номер телефона.
                    </div>
                    <div className="buttons-wrapper">
                      <Button text="Авторизоваться" onClick={() => history.replace(ROUTES.main)} />
                      <Button
                        text="Ввести другой номер"
                        type="bordered"
                        onClick={() => {
                          setValues({ ...values, phone: '' })

                          this.setState({ isModalOpen: false })
                        }}
                      />
                    </div>
                  </ModalBody>
                </Modal>
              </React.Fragment>
            )}
          />
        </React.Fragment>
        )}

        {step === 2 && (
        <React.Fragment>
          <div className="step-title">
          Подтвердите вашу заявку на проект
          </div>
          <div className="step-title-description">
            {name}
, для того чтобы ваш проект сохранился, необходимо привязать его к вашему номеру телефона.
          </div>

          <Input
            className="info-input"
            name="phone"
            value={phone}
            onChange={e => this.setState({ phone: e.target.value })}
            label="Номер телефона"
            mask="+7 (\999) 999-99-99"
            maskChar="_"
          />

          <div className="send-code-wrapper">
            <Button
              text="Выслать проверочный код"
              className="send-code"
              onSave={this.requestCode}
              disabled={phone.replace(/\D+/g, '').length < 11}
              color="primary"
            />
          </div>
        </React.Fragment>)}

        {step === 3 && (
        <React.Fragment>
          <div className="step-title">
          Подтвердите вашу заявку на проект
          </div>
          <div className="step-title-description">
          Мы отправили вам SMS с кодом подтверждения, на указанный ранее номер
            {` ${phone}`}
          </div>

          <CodeInput onFilled={code => this.setState({ code })} resendCode={this.requestCode} error={isCodeWrong} />

          <span className="change-number" onClick={() => this.setState({ phone: '', step: 2 })}>
У вас другой номер?
          </span>

          <div className="send-code-wrapper mt-40">
            <Button
              text="Подтвердить и продолжить"
              className="send-code big"
              onSave={this.sendCode}
              disabled={!code}
              color="primary"
            />

          </div>
        </React.Fragment>)}
      </HelloBox>
    )
  }
}

export { HelloMobile }
