import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

import { COLORS } from '@constants/colors'

const ModalBox = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;

  .modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: ${COLORS.darkBlue};
  }

  .modal-body {
    position: absolute;
    padding: 30px 0 30px 30px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    
    .content {
      padding-right: 30px;
    }

    .close-icon {
      position: absolute;
      right: -65px;
      top: 0;
      color: white;
      font-size: 45px;
      cursor: pointer;
    }
  }
`

class Modal extends Component {
  render() {
    const {
      children, isOpen, onCancel, getModalContainer
    } = this.props

    const body = (
      <ModalBox>
        <div className="modal-overlay" onClick={onCancel} />
        <div className="modal-body">
          <i className="material-icons close-icon" onClick={onCancel}>
            highlight_off
          </i>
          <Scrollbars
            autoHeight
            autoHeightMax={document.documentElement.clientHeight * 0.9 - 60}
            renderTrackHorizontal={() => <div />}
          >
            <div className="content">
              {children}
            </div>
          </Scrollbars>
        </div>
      </ModalBox>
    )

    return isOpen ? ReactDOM.createPortal(body, getModalContainer()) : null
  }
}

Modal.defaultProps = {
  isOpen: false,
  getModalContainer: () => document.body
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  getModalContainer: PropTypes.func
}

export { Modal }
