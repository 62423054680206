import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'
import allDocsImg from '@assets/images/allDocs.png'

const AllDocsBox = styled(OtherBox)`
  position: relative;

  .top-text {
    width: 680px;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    font-family: FuturaNewBook-Reg;
    margin-bottom: 32px;
  }

  .right-image {
    position: absolute;
    right: 0;
    width: 515px;
    height: 594px;
    object-fit: contain;
    top: 120px;
  }

  .text {
    margin-top: 32px;
    width: 635px;
    opacity: 0.7;
    font-family: Roboto-Regular;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    }
  }
  
  .links-container{
    margin-bottom: 40px;
    
    .links-title{
      margin-bottom: 20px;
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      color: ${COLORS.darkBlue};
    }
    
    a{
      margin: 0 0 8px;
      border-bottom: 1px solid ${COLORS.getBlueColor(0.12)};
      display: table;
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 1.75;
      color: ${COLORS.getBlueColor(0.7)};
      &:hover{
        color: ${COLORS.orange};
        border-color: ${COLORS.orange};
      }
    }
  }
`

@withRouter
class AllDocs extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <AllDocsBox isInnerPage={isInnerPage}>
          <img src={allDocsImg} alt="" className="right-image" />

          <h1>
            Правовые документы
          </h1>

          <div className="top-text">
            Для удобства мы собрали все документы в едином месте
          </div>
          <p>
            Дата размещения: 29.08.2018
          </p>
          <p>
            Дата вступления в силу: 29.08.2018
          </p>
          <div className="links-container">
            <div className="links-title">
              Для поставщиков:
            </div>
            <a href="/design/instruction" target="_blank">
              Инструкция
            </a>
            <a href="/design/rates" target="_blank">
              Тарифы
            </a>
            <a href="/design/offer" target="_blank">
              Публичная оферта
            </a>
          </div>
          <div className="links-container">
            <div className="links-title">
              Для клиентов:
            </div>
            <a href="/design/rates_client_design" target="_blank">
              Тарифы на разработку дизайна-интерьера
            </a>
            <a href="/design/offer_client_design" target="_blank">
              Публичная оферта на разработку дизайна-интерьера
            </a>
            <a href="/design/offer_client_order" target="_blank">
              Публичная оферта на покупку мебели
            </a>
          </div>
        </AllDocsBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { AllDocs }
