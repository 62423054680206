import React, { Component } from 'react'
import classnames from 'classnames'
import { withRouter } from 'react-router'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { ProductBox } from '@pages/Marketplace/Product/product.style'
import { breakPriceToDigits } from '@helpers'
import { ProjectsSelect } from '@pages/Marketplace/common/ProjectsSelect'
import { MarketplaceService } from '@services/MarketplaceService'
import { Preloader } from '@ui/atoms'
import { ROUTES } from '@constants'
import { setActiveProduct } from '@redux/actions/marketplace'


const mapDispatchToProps = dispatch => ({
  setActiveProduct: product => dispatch(setActiveProduct(product))
})

@connect(null, mapDispatchToProps)
@withRouter
class Product extends Component {
  constructor(props) {
    super(props)

    this.productId = get(props, 'match.params.id', null)


    this.state = {
      loading: true,
      product: {
        id: 0,
        photoUrls: [
        ],
        description: '',
        previousPrice: 0,
        price: 0,
        delivery: false,
        waranty: false,
        modelNumber: '',
        hasInStorage: false,
        color: '',
        vendor: ''
      },

      currentImageIndex: 0
    }
  }

  async componentDidMount() {
    const { productId, isModal, setActiveProduct } = this.props

    let id = productId

    if (!isModal) {
      id = this.productId
    }

    try {
      const response = await MarketplaceService.getProduct(id)

      if (!isModal) {
        setActiveProduct(response.result)
      }

      this.setState({ product: response.result, loading: false })
    } catch (error) {
      console.error(error)
    }
  }

  componentWillUnmount() {
    const { setActiveProduct } = this.props

    setActiveProduct(null)
  }

  render() {
    const { product, currentImageIndex, loading } = this.state

    const {
      className, isModal, productId, history
    } = this.props

    if (loading) {
      return (
        <ProductBox className={className}>
          <Preloader />
        </ProductBox>
      )
    }

    return (
      <ProductBox className={className} isModal={isModal}>
        <div className="left-side">
          <div className="img-wrapper">
            <img className="img" src={product.photoUrls[currentImageIndex]} alt="" />
            <div
              className={classnames('arrow-wrapper left', {
                disabled: currentImageIndex === 0
              })}
            >
              <i className="material-icons arrow" onClick={() => this.setState({ currentImageIndex: currentImageIndex - 1 })}>
                chevron_left
              </i>
            </div>

            <div
              className={classnames('arrow-wrapper right', {
                disabled: currentImageIndex === product.photoUrls.length - 1
              })}
            >
              <i className="material-icons arrow" onClick={() => this.setState({ currentImageIndex: currentImageIndex + 1 })}>
                chevron_right
              </i>
            </div>
          </div>

          <div className="images-slider">
            <i
              className={classnames('material-icons arrow left', {
                disabled: currentImageIndex === 0
              })}
              onClick={() => this.setState({ currentImageIndex: currentImageIndex - 1 })}
            >
              chevron_left
            </i>
            <i
              className={classnames('material-icons arrow right', {
                disabled: currentImageIndex === product.photoUrls.length - 1
              })}
              onClick={() => this.setState({ currentImageIndex: currentImageIndex + 1 })}
            >
              chevron_right
            </i>
            <div className="slides-wrapper">
              <div
                className="slides"
                style={{
                  transform: `translateX(-${(currentImageIndex - 4 <= 0 ? 0 : currentImageIndex - 4) * 91}px)`
                }}
              >
                {product.photoUrls.map((photo, photoIndex) => (
                  <div
                    className={classnames('img-preview-wrapper', { active: currentImageIndex === photoIndex })}
                    onClick={() => this.setState({ currentImageIndex: photoIndex })}
                    key={photo}
                  >
                    <img src={photo} alt="" className="img-preview" />
                    <div className="img-preview-overlay" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="right-side">
          <div className="product-name">
            {product.name}
          </div>

          <div className="article">
            Артикул:
            {' '}
            {product.modelNumber}
          </div>

          <div className="price">
            {`${breakPriceToDigits(product.price)} ₽`}
          </div>

          <div className="additional-info">
            <div className="color">
              <div className="info-title">
Цвет
              </div>
              {product.color}
            </div>

            <div className="count">
              <div className="info-title">
В наличии
              </div>
              10 и более шт.
            </div>
          </div>

          <ProjectsSelect className="project-select" productId={isModal ? productId : this.productId} />

          <div className="info-title">
Доставка
          </div>
          <div className="info-text">
            {product.delivery ? 'Есть' : 'Нет'}
          </div>

          <div className="info-title">
Производитель
          </div>
          <div className="info-text">
            {product.vendor}
          </div>

          <div className="info-title">
Подробнее
          </div>
          <div className="info-text">
            {product.description}
          </div>

          {isModal && (
          <div className="show-more" onClick={() => history.push(`${ROUTES.product}${productId}`)}>
Показать больше
          </div>
          )}
        </div>
      </ProductBox>
    )
  }
}

export { Product }
