import React, { Component } from 'react'


import fLogo from '@assets/images/icons/f-logo.png'
import { Modal } from '@ui/molecules'
import { Column, Row } from '@ui/atoms/layout'
import { Icon, Button } from '@ui/atoms'
import { FooterBox, ModalBody } from './mainFooter.style'

class MainFooter extends Component {
  state = {
    isModalOpen: false
  }

  render() {
    const { className, showBeta } = this.props

    const { isModalOpen } = this.state

    return (
      <FooterBox className={className}>
        {showBeta && (
        <div className="beta-wrapper">
          <div className="beta">
β
          </div>
          <div className="text">
Мы находимся на стадии beta-тестирования
          </div>

          <div className="info" onClick={() => this.setState({ isModalOpen: true })}>
Что это значит?
          </div>
        </div>
        )}

        <div className="top-row">
          <Row>
            <Column>
              <div className="navigation">
                <a className="f-logo" href="/">
                  <img src={fLogo} alt="logo" />
                </a>
                <a href="/design/hero" target="_blank" className="link">
                  О Faradise
                </a>
                <a href="/design/just_work" target="_blank" className="link">
                  Как это работает?
                </a>
                <a href="/design/rates_client_design" target="_blank" className="link">
                  Тарифы
                </a>
              </div>
            </Column>
          </Row>
        </div>
        <div className="bottom-row">
          <div>
            <div className="title">
КОНТАКТЫ
            </div>
            <a className="phone" href="tel:+74951327462">
              +7 (495) 132 - 74 - 62
            </a>
            <a className="email" href="mailto:privet@faradise.ru">
              privet@faradise.ru
            </a>
            <div className="address">
              ООО «Центр технологий и медиа групп»,
              {' '}
              <br />
              г.Москва,
              <br />
              ул. Ленинская Слобода, д.19, к.1, с.2
            </div>
            <div className="inn">
ИНН 7718299492, ОГРН 1167746194626
            </div>
          </div>
          <div>
            <div className="title">
ИНФОРМАЦИЯ
            </div>
            <a href="/design/care" target="_blank" className="link">
              Уход за мебелью
            </a>
            <a href="/design/how_to_pay" target="_blank" className="link">
              Как оплатить
            </a>
            <a href="/design/delivery" target="_blank" className="link">
              Доставка
            </a>
            <a href="/design/return" target="_blank" className="link">
              Условия возврата
            </a>
          </div>
          <div>
            <div className="title hidden">
ИНФОРМАЦИЯ
            </div>
            <a href="/design/suppliers" target="_blank" className="link">
              Для поставщиков
            </a>
            <a href="http://crm.faradise.ru" target="_blank" className="link" rel="noopener noreferrer">
              Для дизайнеров
            </a>
            <a href="/design/agreement" className="link" target="_blank" rel="noopener noreferrer">
              Политика конфиденциальности
            </a>
            <a href="/design/allDocs" className="link" target="_blank" rel="noopener noreferrer">
              Правовые документы
            </a>
          </div>
          <div>
            <div className="title">
НАШЕ ПРИЛОЖЕНИЕ
            </div>
            <a
              className="download"
              href="https://itunes.apple.com/ru/app/faradise/id1315339948?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="39" viewBox="0 0 32 39">
                <path
                  fill="#253C5E"
                  fillRule="nonzero"
                  // eslint-disable-next-line
                  d="M28.665 33.487c-1.576 2.313-3.248 4.57-5.793 4.607-2.546.056-3.363-1.473-6.25-1.473-2.906 0-3.799 1.436-6.211 1.53-2.489.093-4.37-2.463-5.965-4.72-3.248-4.607-5.736-13.095-2.393-18.803 1.653-2.835 4.616-4.626 7.826-4.682 2.431-.037 4.749 1.623 6.25 1.623 1.481 0 4.292-1.996 7.237-1.697 1.234.056 4.691.485 6.914 3.693-.171.112-4.122 2.388-4.084 7.107.057 5.634 5.034 7.518 5.09 7.536-.056.13-.797 2.686-2.62 5.28zM17.82 3.642C19.206 2.093 21.504.918 23.404.844c.246 2.182-.646 4.383-1.976 5.95-1.31 1.586-3.476 2.817-5.604 2.649-.284-2.145.78-4.384 1.995-5.801z"
                  opacity=".868"
                />
              </svg>
              <div className="download-text">
                <div className="top">
Загрузите в
                </div>
                <div className="bottom">
App Store
                </div>
              </div>
            </a>

            <a
              className="download"
              href="https://play.google.com/store/apps/details?id=com.awespace.faradise"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 34 37">
                <path
                  fill="#FFF"
                  fillRule="nonzero"
                  // eslint-disable-next-line
                  d="M.57 33.62V2.66C.57 1.584 1.195.637 2.113.2l18.1 17.94-18.1 17.938A2.744 2.744 0 0 1 .57 33.62zm25.376-9.798L6.174 35.15l15.6-15.462 4.172 4.134zm6.156-7.85c.625.492 1.084 1.257 1.084 2.168 0 .91-.404 1.639-1.047 2.149l-4.208 2.404-4.594-4.553 4.594-4.553 4.17 2.385zM6.174 1.13l19.772 11.328-4.171 4.135-15.6-15.463z"
                  opacity=".869"
                />
              </svg>
              <div className="download-text">
                <div className="top">
Доступно в
                </div>
                <div className="bottom">
Google Play
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="social-row">
          <Row>
            <Column>
              <div className="socials">
                {/* <a href="#" target="_blank">
              <Icon icon="vkontakte" className="icon" />
            </a> */}
                <a href="https://www.facebook.com/faradiseapp/" target="_blank" rel="noopener noreferrer">
                  <Icon icon="facebook" className="icon" />
                </a>
                <a
                  href="https://instagram.com/faradiseapp?utm_source=ig_profile_share&igshid=17zh4y2ai2lx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="instagram" className="icon" />
                </a>
              </div>
            </Column>
          </Row>
        </div>

        <Modal isOpen={isModalOpen} onCancel={() => this.setState({ isModalOpen: false })}>
          <ModalBody>
            <div className="title">
Мы находимся на стадии beta-тестирования
            </div>

            <div className="text">
              Если во время работы что-то пошло не так — сразу пишите
              {' '}
              <a href="mailto:support@faradise.ru" className="link">
support@faradise.ru
              </a>
. Мы ответим и исправим ошибку.
            </div>

            <div className="text">
Если у вас есть предложения по удобству — пишите сюда
              {' '}
              <a href="mailto:better@faradise.ru" className="link">
better@faradise.ru
              </a>
. Мы внесем изменения.
            </div>

            <Button text="Понятно" className="button" onClick={() => this.setState({ isModalOpen: false })} />
          </ModalBody>
        </Modal>
      </FooterBox>
    )
  }
}

export { MainFooter }
