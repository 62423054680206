import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { COLORS } from '@constants'
import { MainFooter } from '@ui/organisms/mainFooter'
import { StickyContainer, Sticky } from 'react-sticky'
import Scrollspy from 'react-scrollspy'

const CareBox = styled(OtherBox)`
  .care-container {
    margin-top: 60px;
  }

  .menu {
    position: absolute;
    padding: 25px 60px;
    background-color: #fbfbfc;
    border-radius: 16px;
    height: 275px;

    ul {
      padding-left: 0;
    }

    a {
      cursor: pointer;
      margin: 14px 0;
      display: block;
      width: 195px;
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 1.5;
      color: ${COLORS.getBlueColor(0.7)};
      &:hover {
        color: ${COLORS.orange};
      }
      &.is-current {
        color: ${COLORS.orange};
      }
    }
  }

  .part {
    margin-left: 350px;
    .title {
      margin-bottom: 40px;
      font-family: FuturaNewBold-Reg;
      font-size: 32px;
      font-weight: bold;
      line-height: 1.06;
      letter-spacing: -1.3px;
      color: ${COLORS.darkBlue};
    }

    .sub-part {
      margin: 40px 0;
      .sub-title {
        margin-bottom: 16px;
        font-family: FuturaNewBold-Reg;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        color: ${COLORS.darkBlue};
      }
    }
  }

  .care-box {
    margin-left: 95px;
  }
`

@withRouter
class Care extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <CareBox isInnerPage={isInnerPage}>
          <h1>
Уход за мебелью
          </h1>
          <StickyContainer>
            <div className="care-container">
              <Sticky>
                {({ style }) => (
                  <div style={style}>
                    <div className="menu">
                      <Scrollspy
                        items={[
                          'section-1',
                          'section-2',
                          'section-3',
                          'section-4',
                          'section-5'
                        ]}
                        currentClassName="is-current"
                      >
                        <a href="#section-1">
Мебель из дерева
                        </a>
                        <a href="#section-2">
Мебель из МДФ
                        </a>
                        <a href="#section-3">
Зеркальная и стеклянная мебель
                        </a>
                        <a href="#section-4">
Мебель из металла
                        </a>
                        <a href="#section-5">
Мебель из пластика
                        </a>
                      </Scrollspy>
                    </div>
                  </div>
                )}
              </Sticky>
              <div className="care-box">
                <div id="section-1" className="part">
                  <div className="title">
Мебель из дерева
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Уход
                    </div>
                    <span>
                      Если мебель покрыта лаком - периодически протирайте ее
                      немного влажной, а далее сухой хлопчатобумажной тканью.
                      Чтобы «довести до блеска» уже чистую поверхность, протрите
                      мягкой тряпкой с полиролью. Если ее нет под рукой,
                      лимонный сок - настоящая замена.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Использование
                    </div>
                    <span>
                      Хотите, чтобы дерево начало разбухать и деформироваться?
                      Мы тоже нет. Поэтому берегите кк от влаги и перепадов
                      температуры. Горячие кастрюли и чашки ставим только на
                      специальные подставки. А всевозможные химикаты и вовсе
                      держим подальше от любимого деревянного столика.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Важное уточнение
                    </div>
                    <span>
                      От батарей и отопительных приборов мебель из массива
                      должны быть дальше, чем 1 метр.
                    </span>
                  </div>
                </div>
                <div id="section-2" className="part">
                  <div className="title">
Мебель из МДФ
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Уход
                    </div>
                    <span>
                      Удаляйте пыль с МДФ-покрытия сухой мягкой тканью, не
                      допуская сильных застарелых загрязнений. Подойдет,
                      например, сукно, фланель, плюш. Для серьезных пятен
                      используйте легкий мыльный раствор или моющее средство с
                      безопасным составом.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Использование
                    </div>
                    <span>
                      Мебель из МДФ нельзя подвергать воздействию высоких
                      температур, поэтому уборка с пароочистителем и близко
                      расположенные нагревательные приборы – запрет. Жесткие
                      щетки, губки с металлическим или пластиковым покрытием
                      отложите в дальний ящик; чистящие средства с абразивными
                      частицами и хлором спрячьте туда же.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Важное уточнение
                    </div>
                    <span>
                      Мебель из МДФ рекомендуется эксплуатировать в помещениях с
                      относительной влажностью около 70% и температурой воздуха
                      не ниже 10°С.
                    </span>
                  </div>
                </div>
                <div id="section-3" className="part">
                  <div className="title">
Стеклянная, зеркальная мебель
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Уход
                    </div>
                    <span>
                      Понадобится мягкая тканевая салфетка и специальное
                      средство для чистки зеркал и стекол. Но влажной уборки
                      здесь точно будет мало – чтобы избежать разводов, придется
                      натирать стеклянную и зеркальную поверхность еще и сухой
                      тряпкой.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Использование
                    </div>
                    <span>
                      Нельзя использовать грубый материал с абразивными
                      волокнами для уборки, иначе можно оставить царапины на
                      поверхности. Зато стеклянную и зеркальную мебель можно
                      смело использовать в ванной – она не боится повышенной
                      влажности
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Важное уточнение
                    </div>
                    <span>
                      Ставить горячую еду на стеклянную поверхность не
                      рекомендуется. Лучше не рисковать, а запастись салфетками,
                      скатертью и специальными подставками.
                    </span>
                  </div>
                </div>
                <div id="section-4" className="part">
                  <div className="title">
Мебель из металла
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Уход
                    </div>
                    <span>
                      Раз в неделю смахивайте пыль и грязь мягкими губками или
                      тряпками, а влажную уборку проводите не реже, чем один раз
                      в месяц. Для этого используйте жидкие и гелиевые чистящие
                      средства и напрочь забудьте об использовании чистящих
                      железных щеток и губок
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Использование
                    </div>
                    <span>
                      К сожалению, многие виды металла подвержены появлению
                      ржавчине и коррозии. Чтобы избежать неприятных моментов, в
                      ваших интересах обеспечить невысокий уровень влажности в
                      помещении, где будет находиться мебель из металла.
                      Несомненным плюсом будет периодическое пропитывание
                      металла антикоррозийными средствами.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Важное уточнение
                    </div>
                    <span>
                      Металлическая мебель точно не нуждается в механической
                      шлифовке и воздействии наждачной бумаги. А вот различные
                      составы, которые предназначены для мягкого и бережного
                      шлифования царапин и неровностей, в вашем доме пригодятся.
                    </span>
                  </div>
                </div>
                <div id="section-5" className="part">
                  <div className="title">
Мебель из пластика
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Уход
                    </div>
                    <span>
                      Убирайте пыль просто влажной тряпкой, а для удаления пятен
                      пригодится мягкое моющее средство. Ни в коем случае не
                      используйте щетки, скребки и любые средства со шлифующим
                      эффектом, иначе царапин никак не избежите.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Использование
                    </div>
                    <span>
                      Мебель из пластика боится высоких температур, поэтому
                      располагайте ее подальше от открытого огня. Не стоит
                      постоянно ставить на пластиковый стул или стол грузные
                      предметы, в обратном случае ножки начнут разъезжаться и
                      мебель потеряет свою устойчивость.
                    </span>
                  </div>
                  <div className="sub-part">
                    <div className="sub-title">
Важное уточнение
                    </div>
                    <span>
                      Оставлять зимой пластиковую мебель на улице –
                      преступление, из-за которого материал может потерять свою
                      упругость и потрескаться.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </StickyContainer>
        </CareBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Care }
