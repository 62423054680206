import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Breadcrumbs } from '@pages/Marketplace/common/Breadcrumbs';
import { setSearchPhrase } from '@redux/actions/marketplace';
import { setLocationSearch } from '@helpers';
import { ROUTES } from '@constants';
import { COLORS } from '@constants/colors';
import classNames from 'classnames';

const ControlsBox = styled.div`
  width: 1110px;
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  padding-left: 10px;
  z-index: 1100;
  margin-top: 1px;

  &.fullWidth {
    margin-left: 0;
    padding-left: 0;
    .left-side,
    .search-input-wrapper {
      width: 100%;
      margin: 0;
    }
  }

  .left-side {
    display: flex;
  }

  .show-search {
    cursor: pointer;
    margin-right: 250px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: white;
    border: solid 1px rgba(37, 60, 94, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-search {
      font-size: 22px;
    }
  }

  .search-input-wrapper {
    position: relative;
    margin-right: 35px;
    width: 255px;

    &.short {
      width: 40px;
      cursor: pointer;
    }

    .search-input {
      width: 100%;
      height: 48px;
      background-color: rgba(37, 60, 94, 0.05);
      border: solid 1px rgba(37, 60, 94, 0.12);
      outline: none;
      padding-left: 40px;
      font-family: Roboto-Regular;
      font-size: 14px;
      color: ${COLORS.darkBlue};
    }

    .icon-search {
      position: absolute;
      left: 10px;
      top: 13px;
      font-size: 22px;
    }
  }
`;

const mapStateToProps = state => ({
  categories: state.marketplace.categories,
  searchPhrase: state.marketplace.searchPhrase
});

const mapDispatchToProps = dispatch => ({
  setSearchPhrase: phrase => dispatch(setSearchPhrase(phrase))
});

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Controls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullSearch: !props.hideSearch
    };
  }

  onSearch = (value) => {
    const { setSearchPhrase } = this.props;

    setLocationSearch('search', value, ROUTES.marketplace);
    setSearchPhrase(value);
  };

  render() {
    const {
      searchPhrase,
      children,
      className,
      inputRef = () => false,
      product,
      fullWidth
    } = this.props;

    const { fullSearch } = this.state;

    return (
      <ControlsBox className={classNames(className, { fullWidth })}>
        <div className="left-side">
          {fullSearch ? (
            <div className="search-input-wrapper">
              <input
                className="search-input"
                placeholder="Поиск ..."
                onChange={e => this.onSearch(e.target.value)}
                value={searchPhrase}
                ref={elem => inputRef(elem)}
              />
              <i className="material-icons icon-search">
search
              </i>
            </div>
          ) : (
            <div
              className="search-input-wrapper short"
              onClick={() => this.setState({ fullSearch: true })}
            >
              <input
                className="search-input"
                placeholder="Поиск ..."
                onChange={e => this.onSearch(e.target.value)}
                value={searchPhrase}
                ref={elem => inputRef(elem)}
              />
              <i className="material-icons icon-search">
search
              </i>
            </div>
          )}
          {fullWidth || <Breadcrumbs product={product} />}
        </div>

        {children}
      </ControlsBox>
    );
  }
}

export { Controls };
