import React from 'react';

import { Logo } from '@ui/atoms';
import { HeaderBox } from './header.style';

const Header = () => (
  <HeaderBox>
    <Logo />
  </HeaderBox>
);

export { Header };
