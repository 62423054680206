import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { get } from 'lodash'

import { COLORS } from '@constants/colors'
import { setActiveCategory } from '@redux/actions/marketplace'
import { setLocationSearch } from '@helpers'
import { ROUTES } from '@constants'

const CategoriesBox = styled.div`
  width: 100%;

  .category-item {
    margin-top: 2px;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: solid 1px transparent;
    display: flex;
    align-items: center;

    &.active {
      border-radius: 8px;
      background-color: rgba(37, 60, 94, 0.05);
      border: solid 1px ${COLORS.darkBlue};
    }

    &:hover {
      border-radius: 8px;
      background-color: rgba(37, 60, 94, 0.05);
      border: solid 1px ${COLORS.darkBlue};
    }

    img {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }

    .name {
      margin-left: 10px;
      color: ${COLORS.darkBlue};
      font-family: Roboto-Regular;
      font-size: 14px;
    }
  }

  .show-more {
    margin-top: 5px;
    cursor: pointer;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 8px;
      background-color: rgba(37, 60, 94, 0.05);
    }

    i {
      margin-left: 10px;
      color: ${COLORS.darkBlue};
    }

    .text {
      margin-left: 10px;
      color: ${COLORS.darkBlue};
      font-family: Roboto-Regular;
      font-size: 14px;
    }
  }
`

class Categories extends Component {
  state = {
    showMore: false
  }

  initialCount = 8

  selectCategory = (category) => {
    const { setActiveCategory } = this.props

    setLocationSearch('category', category.id, ROUTES.marketplace)

    setActiveCategory(category)
  }

  render() {
    const { categories, activeCategory } = this.props
    const { showMore } = this.state

    return (
      <CategoriesBox>
        {!showMore ? (
          <React.Fragment>
            {categories.slice(0, this.initialCount).map(category => (
              <div
                className={classnames('category-item', { active: get(activeCategory, 'id', null) === category.id })}
                key={category.id}
                onClick={() => this.selectCategory(category)}
              >
                <img src={category.imageUrl} alt="" />
                <div className="name">
                  {category.name}
                </div>
              </div>
            ))}
            <div className="show-more" onClick={() => this.setState({ showMore: true })}>
              <i className="material-icons">
keyboard_arrow_down
              </i>
              <div className="text">
Ещё
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {categories.map(category => (
              <div
                className={classnames('category-item', { active: get(activeCategory, 'id', null) === category.id })}
                key={category.id}
                onClick={() => this.selectCategory(category.id)}
              >
                <img src={category.imageUrl} alt="" />
                <div className="name">
                  {category.name}
                </div>
              </div>
            ))}
            <div className="show-more" onClick={() => this.setState({ showMore: false })}>
              <i className="material-icons">
keyboard_arrow_up
              </i>
              <div className="text">
Скрыть
              </div>
            </div>
          </React.Fragment>
        )}
      </CategoriesBox>
    )
  }
}

const mapStateToProps = state => ({
  categories: state.marketplace.categories,
  activeCategory: state.marketplace.activeCategory
})

const mapDispatchToProps = dispatch => ({
  setActiveCategory: category => dispatch(setActiveCategory(category))
})

const enchancer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Categories)

export { enchancer as Categories }
