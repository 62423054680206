const pages = {
  1: 'stage1_client_hello',
  2: 'stage1_client_aboutProject',
  3: 'stage1_client_style',
  4: 'stage1_client_budget',
  5: 'stage1_client_aboutDesigner',
  6: 'stage1_client_projectConfirmation',
  7: 'stage1_client_designerSearchPreparations',
  8: 'stage1_client_waitingForDesigner',
  9: 'stage1_client_designersChoice'
}

export { pages }
