import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const DesignerCardBox = styled.header`
  position: relative;
  margin-top: 31px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${COLORS.lineLight};

  .info-container {
    height: 240px;
    position: relative;
  }

  .info-box,
  .image-box {
    height: 225px;
    float: left;
  }

  .image-box {
    width: 225px;
    overflow: hidden;
    border-radius: 8px;
    & img {
      height: 100%;
      object-fit: contain;
    }
  }

  .chose-button {
    padding-right: 30px;
  }

  .info-box {
    margin-left: 20px;
    max-width: 225px;

    .name,
    .gender,
    .city,
    .completedProjects {
      font-family: Roboto-Regular;
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }

    .city {
      margin-bottom: 70px;
    }

    .name {
      margin-bottom: 10px;
    }
    .gender,
    .city {
      opacity: 0.38;
      font-size: 14px;
      line-height: 1.43;
    }
    .completedProjects {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 32px;
      margin-bottom: 15px;
    }
  }

  .about-container {
    margin-top: 20px;
    max-width: 540px;

    & .about-title,
    & span {
      font-family: Roboto-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: ${COLORS.darkBlue};
      letter-spacing: 0.3px;
    }
    & span {
      margin-top: 2px;
      font-family: Roboto-Regular;
      opacity: 0.7;
      font-weight: 100;
      letter-spacing: normal;
    }
  }
  .functions-container {
    position: absolute;
    top: 0;
    right: 0;
  }
  .rating-box {
    padding-right: 5px;
  }

  .reviews-container {
    padding-left: 5px;
  }

  .divider {
    margin: 0 15px;
    display: inline-block;
    width: 1px;
    height: 47px;
    background-color: rgba(37, 60, 94, 0.12);
  }

  .rating-box,
  .reviews-container {
    margin-top: 24px;
    display: inline-block;
    text-align: center;
    .rating-value,
    .review,
    & span {
      font-family: Roboto-Regular;
      font-size: 33px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.73;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }
    & span {
      margin-top: 8px;
      display: block;
      opacity: 0.38;
      font-size: 14px;
      line-height: 1.71;
    }
  }
`

export { DesignerCardBox }
