import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const TellAboutYourselfBox = styled.div`
  min-height: 800px;

  .page-footer {
    display: flex;
    justify-content: space-between;
  }

  .policy {
    color: ${COLORS.getBlueColor(0.38)};
    font-size: 12px;
    line-height: 20px;
    font-family: Roboto-Regular;
    width: 350px;

    .button-name {
      font-family: Roboto-Medium;
    }

    .link {
      cursor: pointer;
      border-bottom: 1px solid ${COLORS.getBlueColor(0.12)};
    }
  }

  .contacts-wrapper {
    display: flex;
  }

  .contacts-form {
    flex: 1;
  }

  .contacts-add-foto {
    margin-left: 31px;
  }
  
  .story {
    height: 110px;
  }
`

export { TellAboutYourselfBox }
