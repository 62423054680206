import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Lightbox from 'react-image-lightbox'

import checkIcon from '@assets/images/icons/check.png'
import checkIconActive from '@assets/images/icons/check-active.png'
import commentIcon from '@assets/images/icons/comment.png'
import commentIconActive from '@assets/images/icons/comment-active.png'

import { Card } from './styleCard.style'

class StyleCard extends Component {
  state = {
    isLightboxOpened: false,
    isCommentFieldActive: false
  }

  openLightbox = (event) => {
    if (event.target.classList.contains('card')) {
      this.setState({ isLightboxOpened: true })
    }
  }

  render() {
    const {
      src, isActive, onCheck, text, withComment, comment, onCommentChange, ...rest
    } = this.props

    const { isLightboxOpened, isCommentFieldActive } = this.state

    return (
      <React.Fragment>
        <Card src={src} {...rest} className={classnames('card', { active: isActive })} onClick={this.openLightbox}>
          {withComment
            && isActive && (
              <img
                className="comment-icon"
                src={comment ? commentIconActive : commentIcon}
                alt="comment"
                onClick={() => this.setState(state => ({ isCommentFieldActive: !state.isCommentFieldActive }))}
              />
          )}

          <img className="choose-icon" src={isActive ? checkIconActive : checkIcon} alt="check" onClick={onCheck} />

          <div className={classnames('comment-wrapper', { active: isCommentFieldActive })}>
            <textarea
              placeholder="Добавьте комментарий"
              value={comment}
              onChange={event => onCommentChange(event.target.value)}
            />
          </div>
          <div className="card-text-overlay" />
          <div className="card-text">
            {text}
          </div>
        </Card>
        {isLightboxOpened && (
          <Lightbox
            mainSrc={src}
            onCloseRequest={() => this.setState({ isLightboxOpened: false })}
            enableZoom={false}
          />
        )}
      </React.Fragment>
    )
  }
}

StyleCard.defaultProps = {
  withComment: false,
  comment: '',
  onCommentChange: () => false
}

StyleCard.propTypes = {
  src: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  withComment: PropTypes.bool,
  comment: PropTypes.string,
  onCommentChange: PropTypes.func
}

export { StyleCard }
