import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { Button, Icon, Input } from '@ui/atoms'
import { PicturesContainer } from '@ui/atoms/picture'
import DesignerService from '@services/DesignerService'
import { PortfolioBox } from './portfolio.style'


const mapStateToProps = state => ({
  fullInfo: state.designer.fullInfo
});

@connect(mapStateToProps)
class Portfolio extends React.Component {
  state = {
    projects: {},
  };

  async componentDidMount() {
    try {
      const { fullInfo } = this.props

      const portfolios = get(fullInfo, 'portfolio.portfolios', [])

      if (portfolios.length) {
        this.setState({
          projects: portfolios.reduce(
            (res, elem) => ({
              ...res,
              [elem.id]: {
                name: elem.name || '',
                description: elem.description || '',
                photoLinks: elem.photoLinks.map(photo => ({ id: photo.id, link: photo.photoLink }))
              }
            }), {}
          )
        })

        return
      }

      const createPortfolioResponse = await DesignerService.createPortfolio();
      const { projects } = this.state;

      this.setState({
        projects: { ...projects, [createPortfolioResponse.result]: { name: '', description: '', photoLinks: [] } },
      });
    } catch (e) {
      console.log(e);
    }
  }

  checkProjects() {
    const { projects } = this.state

    let isValid = true

    Object.values(projects).forEach((project) => {
      if (!project.name) {
        isValid = false
      }

      if (!project.description) {
        isValid = false
      }

      if (!project.photoLinks || !project.photoLinks.length) {
        isValid = false
      }
    })

    return isValid
  }

  onAddPhoto = async (fileList, key) => {
    const { projects: projectFromState } = this.state

    const projects = { ...projectFromState }

    try {
      const promises = fileList.map(file => DesignerService.addPortfolioPhoto({ id: key, photo: file }))

      const responses = await Promise.all(promises)

      responses.forEach((response, index) => projects[key].photoLinks.push({
        file: fileList[index],
        link: fileList[index].preview,
        id: response.result.id,
        isPdf: fileList[index].name.includes('.pdf')
      }))

      this.setState({ projects })
    } catch (e) {
      console.log(e)
    }
  }

  renderProjects =() => {
    const { projects } = this.state;

    return Object.keys(projects).map(key => (
      <div className="project" key={`project-${key}`}>
        <div className="info-block">
          <Input
            onChange={(e) => {
              projects[key].name = e.target.value;

              this.setState({ projects })
            }}
            onBlur={async () => {
              if (!projects[key].name || !projects[key].description) {
                return;
              }

              try {
                await DesignerService.setPortfolioDescription({
                  name: projects[key].name,
                  description: projects[key].description,
                  id: projects[key].id
                })
              } catch (e) {
                console.log(e)
              }
            }}
            value={projects[key].name}
            label="Название проекта"
            full
          />
          <Input
            onChange={(e) => {
              projects[key].description = e.target.value;

              this.setState({ projects })
            }}
            value={projects[key].description}
            label="Описание проекта"
            full
            onBlur={async () => {
              if (!projects[key].name || !projects[key].description) {
                return;
              }

              try {
                await DesignerService.setPortfolioDescription({
                  name: projects[key].name,
                  description: projects[key].description,
                  id: key
                })
              } catch (e) {
                console.log(e)
              }
            }}
          />
          <PicturesContainer
            items={projects[key].photoLinks}
            onRemove={async (photoIndex) => {
              await DesignerService.removePortfolioPhoto({
                photoId: projects[key].photoLinks[photoIndex].id,
                portfolioId: key
              })

              projects[key].photoLinks.splice(photoIndex, 1);

              this.setState({ projects });
            }}
            onAdd={fileList => this.onAddPhoto(fileList, key)}
            accept="image/jpeg, image/jpg, image/png"
            hideCotrols
          />
        </div>
        <div>
          <Button
            text="Удалить"
            type="text"
            color="primary"
            className="delete-button"
            onSave={async () => {
              try {
                await DesignerService.removePortfolio({ id: key });

                delete projects[key];

                this.setState({ projects });
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </div>
      </div>
    ));
  };

  render() {
    const { goNext } = this.props;
    const { projects } = this.state;

    return (
      <PortfolioBox className="page-content">
        <div className="page-body">
          <div className="page-title">
            Покажите и расскажите нам
            о ваших крутых проектах
            {' '}
            <span role="img" aria-label="gansgta">
              😎
            </span>
          </div>
          <div className="page-description">
          А теперь добавьте свое портфолио. Если в портфолио будут фотографии спроектированных вами интерьеров – это прекрасно,
          если будут визуализации- хорошо,
          а если ещё будут коллажи и чертежи с планировочными решениями - замечательно! В идеале будет достаточно 3 проекта,
          в которых будет фото или визуализация, коллажи или мудборд настроения, план до планировки и после!
          </div>
          {this.renderProjects()}
          <button
            type="button"
            className="add-button"
            onClick={async () => {
              try {
                const createPortfolioResponse = await DesignerService.createPortfolio();

                this.setState({
                  projects: {
                    ...projects,
                    [createPortfolioResponse.result]: { name: '', description: '', photoLinks: [] }
                  },
                });
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <Icon
              icon="circledCross"
              className="icon"
            />
            Добавить еще проект
          </button>
        </div>
        <div className="page-footer">
          <div className="policy">
            Нажимая кнопку
            {' '}
            <span className="button-name">
              “Сохранить и продолжить”
            </span>
            {' '}
            вы соглашаетесь с
            {' '}
            <a href="/design/agreement/" className="link" target="_blank" rel="noopener noreferrer">
              Политикой конфиденциальности
            </a>
          </div>
          <Button
            text="Сохранить и продолжить"
            onClick={goNext}
            disabled={!this.checkProjects()}
          />
        </div>
      </PortfolioBox>
    )
  }
}

export { Portfolio }
