import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'

const ReturnBox = styled(OtherBox)`
  .top-text {
    max-width: 1110px;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    font-family: FuturaNewBook-Reg;
  }

  .main-block {
    display: flex;
    margin-top: 60px;

    .text-block {
      width: 700px;

      .title {
        font-family: FuturaNewBold-Reg;
        font-size: 32px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.06;
        letter-spacing: -1.3px;
        color: ${COLORS.darkBlue};
      }

      .text {
        margin-bottom: 40px;
        margin-top: 16px;
        opacity: 0.7;
        font-family: Roboto-Regular;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: ${COLORS.darkBlue};
      }

      .sub-title {
        margin-top: 40px;
        font-family: FuturaNewBold-Reg;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: ${COLORS.darkBlue};
      }

      .list {
        margin-top: 16px;
        margin-bottom: 40px;

        .list-item {
          display: flex;

          .number {
            margin-right: 22px;
            font-family: Roboto-Regular;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.75;
            letter-spacing: normal;
            color: ${COLORS.orange};
          }

          .dot {
            font-size: 25px;
            line-height: 0.5;
            margin-right: 22px;
            font-family: Roboto-Regular;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: ${COLORS.orange};
          }

          .list-text {
            opacity: 0.7;
            font-family: Roboto-Regular;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.75;
            letter-spacing: normal;
            color: ${COLORS.darkBlue};

            .link {
              font-weight: 500;
              color: #ef4e22;
              font-size: 20px;
            }
          }
        }
      }
    }

    .additional-block {
      margin-left: 94px;
      width: 315px;
      height: 234px;
      border-radius: 16px;
      background-color: rgba(37, 60, 94, 0.02);
      box-sizing: border-box;
      padding: 30px;

      .title {
        margin-bottom: 30px;
        font-family: FuturaNewBold-Reg;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: ${COLORS.orange};
      }

      .download {
        width: 255px;
        height: 56px;
        border-radius: 7.8px;
        background-color: #ffffff;
        border: solid 1.3px rgba(37, 60, 94, 0.12);
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        text-decoration: none;
        margin: 8px 0;

        &:hover {
          .for {
            color: ${COLORS.orange};
          }
        }

        i {
          transform: rotate(180deg);
          font-size: 28px;
        }

        .inner-text {
          margin-left: 12px;
          font-family: Roboto-Medium;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: 0.3px;
          color: ${COLORS.getBlueColor(0.53)};

          .for {
            font-size: 16px;
            opacity: 1;
          }
        }
      }
    }
  }
`

@withRouter
class Return extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <ReturnBox isInnerPage={isInnerPage}>
          <h1>
Условия возврата
          </h1>

          <div className="top-text">
            Мы руководствуемся законом, поэтому вы имеете право вернуть или обменять купленный у нас товар в течение 7 календарных дней на
            основании Закона о Защите прав потребителей (от 07.02.1992 N 2300-1, ст. 26.1, п. 4)
          </div>

          <div className="main-block">
            <div className="text-block">
              <div className="title">
Что вам следует сделать при приеме товара?
              </div>
              <div className="text">
                Самое главное - при получении заказа вам необходимо убедиться, что доставленный товар соответствует вашим ожиданиям.
              </div>

              <div className="title">
Что делать, если что-то пошло не так?
              </div>

              <div className="sub-title">
Товар был поврежден или привезен не полной комплектации
              </div>

              <div className="list">
                <div className="list-item">
                  <div className="number">
1.
                  </div>
                  <div className="list-text">
                    В случае обнаружения повреждений или неполной комплектации заполните акт (сделайте отметку в накладной) до отъезда
                    курьера.
                    {' '}
                  </div>
                </div>

                <div className="list-item">
                  <div className="number">
2.
                  </div>
                  <div className="list-text">
Сфотографируйте любые повреждения.
                    {' '}
                  </div>
                </div>
              </div>

              <div className="sub-title">
Вы хотите вернуть или обменять товар
              </div>

              <div className="list">
                <div className="list-item">
                  <div className="number">
1.
                  </div>
                  <div className="list-text">
                    Для возврата денежных средств необходимо заполнить заявление (образец можно скачать здесь) и выслать через чат вашему
                    менеджеру.
                    {' '}
                  </div>
                </div>

                <div className="list-item">
                  <div className="number">
2.
                  </div>
                  <div className="list-text">
                    К заявлению необходимо приложить документ, подтверждающий факт покупки (накладная, кассовый чек).
                    {' '}
                  </div>
                </div>

                <div className="list-item">
                  <div className="number">
3.
                  </div>
                  <div className="list-text">
                    Денежные средства за возвращенный товар перечисляются безналичным переводом на счет покупателя, указанный в заявлении
                    при оформлении возврата с вашим персональным менеджером. Срок возврата денежных средств составляет 7 рабочих дней после
                    получения нами правильно оформленного и подписанного заявления.
                    {' '}
                  </div>
                </div>

                <div className="list-item">
                  <div className="number">
4.
                  </div>
                  <div className="list-text">
                    По любым вопросам возврата товара вы можете обращаться по телефону
                    <br />
                    {' '}
+7 (495) 132 - 74 - 62 (Пн – Пт с 11:00 до
                    20:00 по Московскому времени) или электронной почте
                    {' '}
                    <a href="mailto:privet@faradise.ru" className="link">
                      privet@faradise.ru
                    </a>
                    {' '}
                  </div>
                </div>
              </div>

              <div className="title">
              Обмену и возврату не подлежат:
              </div>

              <div className="list">
                <div className="list-item">
                  <div className="dot">
.
                  </div>
                  <div className="list-text">
                  Товары, изготовленные по индивидуальным размерам и выбором материалов;
                    {' '}
                  </div>
                </div>

                <div className="list-item">
                  <div className="dot">
.
                  </div>
                  <div className="list-text">
                  Мебель после полной либо частичной сборки;
                    {' '}
                  </div>
                </div>

                <div className="list-item">
                  <div className="dot">
.
                  </div>
                  <div className="list-text">
                  Товары, надлежащего качества, если не сохранены его потребительские свойства,
                  товарный вид (оригинальная упаковка, пломбы, ярлыки)
                  и документ, подтверждающий факт покупки (товарный или кассовый чек).
                    {' '}
                  </div>
                </div>
              </div>
            </div>

            <div className="additional-block">
              <div className="title">
            Заявление о возврате:
              </div>

              <a className="download" href="https://faradise.ru/doc_return">
                <i className="material-icons">
publish
                </i>
                <div className="inner-text">
                  СКАЧАТЬ
                  {' '}
                  <br />
                  <span className="for">
                  Для физических лиц
                  </span>
                </div>
              </a>
            </div>
          </div>
        </ReturnBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Return }
