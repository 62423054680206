import styled from 'styled-components'
import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

// language=LESS
const InfoBox = styled.div`
  & {
    margin-top: 48px;
  }
  .field-title {
    position: relative;
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: ${COLORS.getBlueColor()};
    & .title-box {
      max-width: 238px;
      display: block;
    }
    & .step {
      color: ${COLORS.orange};
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .sub-field-title {
    margin-top: 8px;
    font-family: FuturaNewBold-Reg;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: ${COLORS.textLight};
  }
  .checkbox-form {
    margin: 16px 0 80px 0px;
  }
  .cards-wrapper {
    position: relative;
    margin-top: 16px;
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    &.with-counter {
      margin-bottom: 16px;
    }
  }
  .card-container {
    &.with-counter {
      height: 230px;
    }
    margin-right: 13px;
    .card {
      box-sizing: border-box;
      width: 136px;
      height: 136px;
      background-color: white;
      cursor: pointer;
      border-radius: 8.3px;
      border: solid 1px rgba(0, 0, 0, 0);

      &:hover {
        background-color: rgba(103, 147, 199, 0.12);

        .card-logo svg path {
          fill: ${COLORS.getBlueColor()};
        }
      }

      &.active {
        border: solid 1px ${COLORS.getBlueColor()};
        background-color: ${COLORS.getBlueColor(0.05)};

        .card-logo svg path {
          fill: ${COLORS.getBlueColor()};
        }

        .card-text {
          font-family: Roboto-Medium;
          font-weight: 500;
        }
      }
      .card-logo {
        text-align: center;
        display: block;
        margin: 16px auto 0;
        min-width: 61px;

        & svg path {
          fill: ${COLORS.getBlueColor(0.7)};
        }
      }
      .card-text {
        height: 24px;
        margin-top: 16px;
        font-family: Roboto-Regular;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: ${COLORS.getBlueColor()};
      }
    }
    .card-counter {
      width: 136px;
      height: 40px;
      border-radius: 8px;
      margin-top: 33px;
      margin-bottom: 28px;
      position: relative;

      .increase-counter,
      .reduce-counter,
      .counter {
        color: ${COLORS.darkBlue};
        position: absolute;
        font-size: 25px;
        top: 4px;
      }

      .increase-counter,
      .reduce-counter {
        z-index: 2;
        cursor: pointer;
      }

      .counter {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 8px;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        font-size: 24px;
        font-family: Roboto-Medium;
        color: ${COLORS.darkBlue};
      }

      .increase-counter {
        right: 0;
        width: 50px;
        padding-right: 16px;
        padding-left: 16px;
      }

      .reduce-counter {
        width: 43px;
        padding-left: 16px;
      }
    }
  }
  input {
    margin-top: 24px;
    min-width: 485px;
  }
  .button-container {
    justify-content: flex-end;
    display: flex;
  }

  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    width: 300px;

    .field-title {
      font-size: 18px;
      width: 275px;
    }
    .card-container {
      margin-right: 7px;
      &.with-counter {
        height: 132px;
        .card {
          width: 126px;
          height: 126px;
        }
      }
      .card {
        width: 130px;
        height: 130px;
        &.active {
          margin-right: 0;
        }
        .card-text {
          margin-top: 6px;
        }
      }
      .card-counter {
        width: 126px;
        margin-top: 21px;
        margin-bottom: 29px;
        background: ${COLORS.transparent};
      }
    }

    .button-container {
      width: 275px;
      flex-wrap: wrap;

      button {
        width: 100%;
      }

      &.ml-25 {
        margin-left: 25px;
      }
    }
  }
`

export { InfoBox }
