export const questions1 = [
  {
    text: 'Возможность выезжать на место в своем городе',
    id: 'goToPlaceInYourCity',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  },
  {
    text: 'Возможность выезжать на место в другой город',
    id: 'goToPlaceInAnotherCity',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  },
  {
    text: 'Возможность авторского надзора',
    id: 'personalControl',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  },
  {
    text: 'Возможность организации очной встречи',
    id: 'personalMeeting',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  },
  {
    text: 'Возможность организации skype-встречи',
    id: 'skypeMeeting',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  },
  {
    text: 'Я могу выехать и сделать замеры помещения',
    id: 'roomMeasurements',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  },
  {
    text: 'Делаете ли вы рабочие чертежи?',
    id: 'blueprints',
    answers: [
      {
        id: '1',
        text: 'Да'
      },
      {
        id: '2',
        text: 'Нет'
      },
    ]
  }
];
