import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setActiveSubcategory } from '@redux/actions/marketplace'
import { setLocationSearch } from '@helpers'
// import { ROUTES } from '@constants'
import { MarketplaceService } from '@services/MarketplaceService'
import { breakPriceToDigits } from '@helpers/breakPriceToDigits'
import { SubcategoriesBox } from './subcategories.style'

class Subcategories extends Component {
  state = {
    subcategories: []
  }

  async componentDidMount() {
    const { activeCategory, activeSubcategory } = this.props

    const response = await MarketplaceService.getSubcategories(activeSubcategory ? activeSubcategory.id : activeCategory.id)

    this.setState({ subcategories: response.result })
  }

  selectCategory = (category) => {
    const { setActiveSubcategory } = this.props

    setLocationSearch('subcategory', category.id)

    setActiveSubcategory(category)
  }

  render() {
    const { className } = this.props
    const { subcategories } = this.state

    return (
      <SubcategoriesBox className={className}>
        <div className="title">
Уточнить категорию
        </div>
        {!!subcategories.length
          && subcategories.map(category => (
            <div className="subcategory-wrapper" onClick={() => this.selectCategory(category)} key={category.id}>
              <div className="name">
                {category.name}
              </div>
              <div className="count">
                {breakPriceToDigits(category.products)}
              </div>
            </div>
          ))}
      </SubcategoriesBox>
    )
  }
}

const mapStateToProps = state => ({
  activeCategory: state.marketplace.activeCategory,
  activeSubcategory: state.marketplace.activeSubcategory
})

const mapDispatchToProps = dispatch => ({
  setActiveSubcategory: categories => dispatch(setActiveSubcategory(categories))
})

const enchancer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Subcategories)

export { enchancer as Subcategories }
