const pages = {
  1: 'stage1_designer_authorization',
  2: 'stage1_designer_familiarity',
  3: 'stage1_designer_task',
  4: 'stage1_designer_portfolio',
  5: 'stage1_designer_interview',
  6: 'stage1_designer_workFormat'
}

export { pages }
