import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import { Button } from '@ui/atoms/index'
import { RoomTabs } from '@ui/organisms/roomTabs'
import { COLORS } from '@constants/colors'
import { ProjectService } from '@services/ProjectService'
import { rooms as constRooms } from '@constants/rooms'
import { CardSelect, Icon } from '@ui/atoms'
import { InfoBox } from '@pages/client/FirstUserSteps/Info/info.style'
import { breakPriceToDigits } from '@helpers';
import { Modal } from '@ui/molecules';
import { ProjectProducts } from '@ui/organisms/projectProducts'
import { EmailModal } from '@pages/client/Development/common/EmailModal';

const PayModalBox = styled.div`
  width: 540px;
  
  .modal-btns {
    margin-top: 80px;
    display: flex;
    
    .btn {
      width: 255px;
      padding: 0 20px;
      
      &:first-child {
        margin-right: 30px;
      }
    }
  }
`

const MudbordBox = styled.div`
  .page_content {
    margin-bottom: 14px;
  }
  .text,
  .page-info {
    font-size: 16px;
    color: ${COLORS.getBlueColor(0.54)};
    line-height: 24px;
    font-family: Roboto-Regular;

    .bold {
      font-family: Roboto-Medium;
      font-weight: 500;
    }
  }
  .page-description {
    margin-bottom: 16px;
  }
  .text {
    max-width: 350px;
  }
  .page-info {
    margin-bottom: 16px;
    color: ${COLORS.darkBlue};
  }
  .wait-box {
    margin-top: 24px;
    .page-info {
      display: inline-block;
    }
    svg {
      fill: ${COLORS.orange};
      vertical-align: top;
    }
    .page-info {
      margin-left: 18px;
      max-width: 379px;
    }

    .cross {
      vertical-align: super;
      font-size: 48px;
      color: ${COLORS.orange};
    }
  }
  .page-footer.step2 {
    display: flex;
    justify-content: space-between;
  }

  .btn_content {
    display: flex;
    justify-content: space-between;
    margin-top: 96px;
    margin-bottom: 48px;

    .all-download-btn {
      margin-right: 20px;
    }
  }
  
  .price {
    font-family: Roboto-Regular;
    font-size: 48px;
    color: ${COLORS.getBlueColor()};
  }
  
  .pay-btn {
    margin-top: 48px;
    width: 191px;
  }
  
  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 32px;
  }
  
  .visual {
    margin-top: 32px;
  }
`

const mapStateToProps = state => ({
  userData: state.user.userData,
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class Client3DVisual extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: (() => {
        if (props.stage.stage > props.step) {
          return 3
        }

        if (!props.stage.isPaid) {
          return 1
        }

        if (!props.stage.designerIsReady) {
          return 2
        }

        return 3
      })(),
      activeRoom: 0,
      rooms: [],
      allRooms: [],
      selectedRooms: [],
      payModalOpened: false,
      emailModalOpen: false
    }
  }

  componentDidMount() {
    this.getInfo()
  }

  getInfo = async () => {
    const { activeProject, stage } = this.props
    const { step } = this.state

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      if (stage.needPayment) {
        const orderedRoomResponse = await ProjectService.getOrderedVisualizationRooms(activeProject)

        this.setState({ selectedRooms: orderedRoomResponse.result })
      }

      if (step !== 3) {
        const response = await Promise.all(
          result.map(room => ProjectService.getRoomCollage(activeProject, room.roomId))
        )

        this.setState({
          rooms: result.map((room, roomIndex) => ({
            model: room.purpose,
            roomPhotos: response[roomIndex].result.collagePhotos.map(photo => ({ src: photo.fileLink, alt: `photo-${photo.fileId}` })),
            roomId: room.roomId
          })),
          allRooms: result.map(room => ({
            model: room.purpose,
            roomId: room.roomId
          })),
        })
      }

      if (step === 3) {
        const orderedRoomResponse = await ProjectService.getOrderedVisualizationRooms(activeProject)
        const orderedRooms = result.filter(room => orderedRoomResponse.result.includes(room.roomId))

        const roomVisualizationsResponse = await Promise.all(
          orderedRooms.map(room => ProjectService.getRoomVisualization(activeProject, room.roomId))
        )

        this.setState({
          rooms: orderedRooms.map((room, index) => {
            const code = roomVisualizationsResponse[index].result.visualizationCode
            const visualization = typeof code === 'string' ? code.replace(new RegExp('“|”', 'g'), '"') : null

            return {
              ...room,
              model: room.purpose,
              roomId: room.roomId,
              visualization,
            }
          }),
          allRooms: result.map(room => ({
            model: room.purpose,
            roomId: room.roomId
          })),
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({
        step: (() => {
          if (nextProps.stage.stage > nextProps.step) {
            return 3
          }

          if (!nextProps.stage.isPaid) {
            return 1
          }

          if (!nextProps.stage.designerIsReady) {
            return 2
          }

          return 3
        })()
      }, () => this.getInfo())
    }
  }

  getArchive = async () => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      const { result } = await ProjectService.getMoodboardArhive(activeProject, rooms[activeRoom].roomId)

      console.log(result)
    } catch (e) {
      console.log(e)
    }
  }

  confirmStage = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.confirmDevelopmentStage(activeProject, 'Visualization')

    goNext(true)
  }

  selectRoom = (room) => {
    const { selectedRooms } = this.state
    const targetIndex = selectedRooms.findIndex(sr => sr === room.roomId)
    const newSelectedRooms = selectedRooms.slice(0)

    if (targetIndex !== -1) {
      newSelectedRooms.splice(targetIndex, 1)
    } else {
      newSelectedRooms.push(room.roomId)
    }

    this.setState({ selectedRooms: newSelectedRooms })
  }

  getSum = () => {
    const { selectedRooms } = this.state

    return `${breakPriceToDigits(7900 * selectedRooms.length)} ₽`
  }

  skipStep = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.skipDevelopmentStage(activeProject, 'Visualization')

    goNext(true)
  }

  orderVisualization = async () => {
    try {
      const { activeProject, userData } = this.props
      const { selectedRooms } = this.state

      if (!userData.email) {
        this.setState({ emailModalOpen: true })

        return
      }

      const { result } = await ProjectService.orderRoomVisualization({
        projectId: activeProject,
        roomsId: selectedRooms
      })

      this.setState({ emailModalOpen: false, payModalOpened: false })

      window.location.href = result
    } catch (e) {
      console.log(e)
    }
  }

  renderFirstStep = () => {
    const { rooms, selectedRooms, payModalOpened } = this.state
    const { goPrev, stage } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
              3D-визуализация квартиры дает возможность увидеть будущий интерьер, как фотореалистичную картинку.
        </div>
        <div className="g_text dark">
              Пожалуйста выберите комнаты для которых вы хотите заказать 3D-визуализацию.
              Напоминаем вам, что данный этап оплачивается отдельно — 7900 ₽/1 комната.
          <br />
          <br />
              Если вам не нужно делать 3D-визуализацию, то вы можете пропустить этот шаг.
              По всем вопросам вас проконсультирует дизайнер в чате.

        </div>

        {
          !stage.isPaid && stage.needPayment && !stage.paymentFailed && (
          <React.Fragment>
            <div className="g_small-title mt48">
              Выберите тип объекта
            </div>
            <InfoBox style={{ marginTop: 0 }}>
              <div className="cards-wrapper">
                {rooms.map(room => (
                  <CardSelect
                    key={room.roomId}
                    card={{ ...room, ...constRooms.find(cr => cr.model === room.model) }}
                    onChange={this.selectRoom}
                    active={selectedRooms.includes(room.roomId)}
                  />
                ))}
              </div>
            </InfoBox>
            <div className="g_small-title mt48">
              Сумма для оплаты
            </div>
            <div className="price">
              {this.getSum()}
            </div>
          </React.Fragment>
          )
        }


        {
          !stage.isPaid && !stage.needPayment && !stage.paymentFailed && (
          <div className="wait-box">
            <Icon icon="watch" width={48} height={48} />
            <div className="page-info">
            Ждем пока деньги дойдут.
              {' '}
              <br />
              {' '}
Это может занять какое-то время.
            </div>
          </div>
          )
        }

        {
          stage.paymentFailed && (
          <div className="wait-box">
            <i className="material-icons cross">
highlight_off
            </i>
            <div className="page-info">
            Платеж не прошел.
              {' '}
              <br />
              {' '}
Пожалуйста, попробуйте еще раз.
            </div>
          </div>
          )
        }

        {
          stage.needPayment && !stage.paymentFailed && (
            <Button
              text="Оплатить"
              disabled={!selectedRooms.length}
              className="pay-btn"
              onClick={() => this.setState({ payModalOpened: true })}
            />
          )
        }

        {
          stage.needPayment && stage.paymentFailed && (
            <Button
              text="Повторить"
              disabled={!selectedRooms.length}
              className="pay-btn"
              onClick={() => this.setState({ payModalOpened: true })}
            />
          )
        }


        <div className="buttons-row">
          <div>
            <Button
              type="text"
              text="Назад"
              onClick={goPrev}
            />
          </div>
          {
            !stage.isPaid && stage.needPayment && !stage.paymentFailed
              && (
              <Button
                type="bordered"
                text="Пропустить шаг"
                onClick={this.skipStep}
              />
              )
          }
        </div>
        <Modal
          isOpen={payModalOpened}
          onCancel={() => this.setState({ payModalOpened: false })}
        >
          <PayModalBox>
            <div className="page-title no-before">
              Готовы оплатить?
            </div>
            <div className="g_big-text">
              Оплата будет продолжена на сайте платёжной системы Яндекс.Деньги
            </div>
            <div className="modal-btns">
              <Button
                type="bordered"
                text="Отменить"
                onClick={() => this.setState({ payModalOpened: false })}
                className="btn"
              />
              <Button
                text="Подтвердить и продолжить"
                className="btn"
                onSave={this.orderVisualization}
              />
            </div>
          </PayModalBox>
        </Modal>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { updateStage, goPrev } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Дизайнер приступил к созданию 3D-визуализации.
        </div>
        <div className="g_text dark">
          В среднем этот этап займет до 10 дней, но как правило меньше.
          Вы можете общаться с вашим дизайнером в чате.
          <br />
          <br />
          После того, как Дизайнер загрузит файлы у вас будет 4 дня на согласование и обсуждение Мудборда.
        </div>
        <div className="wait-box">
          <Icon icon="watch" width={48} height={48} />
          <div className="page-info">
            Дизайнер готовит материалы. Пожалуйста, дождитесь пока он их
            загрузит на платформу, вам придёт оповещение.
          </div>
        </div>
        <div className="buttons-row">
          <div>
            <Button
              text="Назад"
              onClick={goPrev}
              type="text"
            />
          </div>
          <div>
            <Button
              text="Проверить"
              onClick={() => updateStage()}
              type="bordered"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderThirdStep = () => {
    const {
      rooms,
      activeRoom,
    } = this.state
    const {
      goPrev, stage, step, goNext
    } = this.props

    if (!rooms.length) {
      return null
    }

    return (
      <React.Fragment>
        <div className="page-description">
          Вау! Посмотрите, как будет выглядеть ваша квартира!
          <br />
          <br />
          Это впечатляет!
        </div>
        <div className="g_small-title mb16">
            Выберите комнату
        </div>
        <RoomTabs
          items={rooms}
          active={activeRoom}
          onChange={index => this.setState({ activeRoom: index })}
        />
        <div className="visual" dangerouslySetInnerHTML={{ __html: rooms[activeRoom].visualization }} />
        <div className="btn_content">
          <Button text="Назад" type="text" onClick={goPrev} />
          <div>
            {stage.stage === step ? (
              <Button
                className="all-approve-btn"
                type="filled"
                text="Согласовать и продолжить"
                onClick={this.confirmStage}
              />
            ) : (
              <Button
                text="Вперед"
                onClick={() => goNext()}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { step, allRooms, emailModalOpen } = this.state

    return (
      <MudbordBox>
        <div className="page_content">
          <div className="page-body">
            <div className="page-title">
              3D-визуализация
            </div>
            {step === 1 && this.renderFirstStep()}
            {step === 2 && this.renderSecondStep()}
            {step === 3 && this.renderThirdStep()}
            {!!allRooms.length && (
              <ProjectProducts
                rooms={allRooms}
              />
            )}

            <EmailModal
              isOpen={emailModalOpen}
              confirm={this.orderVisualization}
              onCancel={() => this.setState({ emailModalOpen: false })}
            />
          </div>
        </div>
      </MudbordBox>
    )
  }
}

export { Client3DVisual }
