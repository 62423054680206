import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const PortfolioBox = styled.div`
  min-height: 800px;

  .page-footer {
    display: flex;
    justify-content: space-between;
  }

  .policy {
    color: ${COLORS.getBlueColor(0.38)};
    font-size: 12px;
    line-height: 20px;
    font-family: Roboto-Regular;
    width: 350px;

    .button-name {
      font-family: Roboto-Medium;
    }

    .link {
      cursor: pointer;
      border-bottom: 1px solid ${COLORS.getBlueColor(0.12)};
    }
  }

  .project {
    margin-bottom: 32px;
    border-bottom: 1px solid ${COLORS.getBlueColor(0.12)};
    padding-bottom: 32px;
    display: flex;
    justify-content: space-between;
  }

  .info-block {
    width: 540px;
  }

  .add-button {
    font-size: 16px;
    color: ${COLORS.getBlueColor()};
    font-weight: 500;
    font-family: Roboto-Medium;
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.getBlueColor()};
    border-radius: 8px;
    padding: 15px 20px 14px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;

    svg {
      fill: ${COLORS.getBlueColor()};
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
  }

  .delete-button {
    padding: 15px 23px !important;
    line-height: 1;

    &:hover {
      background-color: ${COLORS.getBlueColor(0.05)};
    }
  }
`

export { PortfolioBox }
