import React from 'react'
import styled from 'styled-components'
import { Slider } from 'antd'
import 'antd/lib/slider/style/css'

import { COLORS } from '@constants/colors'

const SliderBox = styled.div`
  .ant-slider-handle {
    border: 2px solid ${COLORS.orange};
    background-color: ${COLORS.orange};
  }

  .ant-slider-track {
    background-color: ${COLORS.orange};
  }
`

const Interval = ({ className, ...rest }) => (
  <SliderBox className={className}>
    <Slider {...rest} />
  </SliderBox>
)

export { Interval }
