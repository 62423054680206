import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import ProjectService from '@services/ProjectService'
import { Button } from '@ui/atoms'
import { COLORS, ROUTES } from '@constants'
import { RoomItems } from '@pages/designer/roomProducts'

const PageBox = styled.div`
  &.page_content {
    width: 790px;
  }

  .market-block {
    border-top: 1px solid ${COLORS.getBlueColor(0.12)};
    margin-top: 32px;
    padding-top: 32px;
  }

  .market-button {
    width: 100%;
    margin-top: 24px;
  }

  .buttons-row {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }

  .textarea {
    height: 300px;
    margin-top: 16px;
  }

  .files-block {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .file-input {
    display: none;
  }

  .files {
    display: flex;
    width: 456px;
    flex-flow: row wrap;
  }

  .file {
    width: 220px;
    border-radius: 6px;
    background-color: ${COLORS.getBlueColor(0.05)};
    padding: 10px 12px;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 20px;
    color: ${COLORS.getBlueColor(0.37)};
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .file-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    fill: ${COLORS.getBlueColor(0.54)};
  }

  .remove-file {
    transform: translateY(-50%) rotate(45deg);
    fill: ${COLORS.orange};
    position: absolute;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 50%;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@withRouter
@connect(mapStateToProps)
class DesignerControlAndOrder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rooms: []
    }
  }

  async componentDidMount() {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      this.setState({
        rooms: result.map(room => ({
          model: room.purpose,
          roomId: room.roomId
        }))
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { rooms } = this.state
    const { history, goPrev } = this.props

    return (
      <PageBox className="page_content">
        <div className="page-body">
          <div className="page-title">
Авторский надзор и заказ товаров
          </div>
          <div className="g_big-text mb32">
            Воу. Почти все. Если вы можете вести авторский надзор - обсудите это с Клиентом индивидуально.
            <br />
            <br />
            Вы можете рекомендовать Клиенту покупать мебель через наш Маркетплейс. Напоминаем, что вы получите процент с покупки Клиента.
          </div>
          <div className="buttons-row">
            <div>
              <Button
                text="Назад"
                type="text"
                onClick={goPrev}
              />
            </div>
          </div>
          <div className="market-block">
            <div className="g_big-text">
              Добавьте товары из нашего магазина к данному проекту. В дальнейшем, клиент сможет приобрести их у нас.
            </div>
            <div className="g_text">
Если нужного товара нет, сообщите нам об этом
            </div>
            <Button
              text="Перейти в магазин FARADISE"
              color="additional"
              type="filled"
              className="market-button"
              onClick={() => history.push(ROUTES.marketplace)}
            />
          </div>
          {!!rooms.length && <RoomItems rooms={rooms} />}
        </div>
      </PageBox>
    )
  }
}

export { DesignerControlAndOrder }
