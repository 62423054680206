import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ui/atoms/icon';
import { COLORS } from '@constants/colors';

const DownloadButtonBox = styled.div`
  button {
    background-color: ${COLORS.getBlueColor()};
    color: ${COLORS.white};
    padding: 12px 20px 12px 12px;
    line-height: 24px;
    font-size: 16px;
    font-family: Roboto-Medium;
    font-weight: 500;
    border-radius: 8px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    height: 56px;
    cursor: pointer;
  }
  
  svg {
    fill: ${COLORS.white};
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  
  .document-name {
    margin-top: 2px;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 20px;
    color: ${COLORS.getBlueColor(0.38)};
  }
`;

const DownloadButton = ({ text, name, onClick }) => (
  <DownloadButtonBox>
    <button type="button" onClick={onClick}>
      <Icon icon="download" />
      {text}
    </button>
    <div className="document-name">
      {name}
    </div>
  </DownloadButtonBox>
);

export { DownloadButton }
