import React from 'react';
import styled from 'styled-components'
import { omit } from 'lodash'
import Dropzone from 'react-dropzone'

import { COLORS } from '@constants/colors';
import { Icon } from '@ui/atoms/icon';
import { Preloader } from '@ui/atoms/preloader';
import { Lightbox } from '@ui/organisms';

const AddFotoBox = styled.div`
  /* background-color: ${COLORS.getBlueColor(0.05)};
  height: 160px;
  width: 160px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.getBlueColor(0.7)};
  font-family: Roboto-Regular;
  cursor: pointer;
  position: relative;
  border: ${({ image }) => (image ? '0' : `2px dashed ${COLORS.getBlueColor(0.16)}`)};
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  width: 160px;
  height: 160px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    .fullscreen-icon-wrapper, .download-icon-wrapper {
      display: block;
    }
  }

  .icons-wrapper {
    position: absolute;
      bottom: 6px;
      right: 6px;
      display: flex;
      justify-content: flex-end;
      z-index: 3;
  }

  .fullscreen-icon-wrapper {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      opacity: 0.7;
      background-color: ${COLORS.darkBlue};
      display: none;
      margin-left: 10px;

      i {
        color: white;
        font-size: 33px;
        cursor: pointer;
      }
    }

    .download-icon-wrapper {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      opacity: 0.7;
      background-color: ${COLORS.darkBlue};
      display: none;

      i {
        color: white;
        font-size: 30px;
        cursor: pointer;
        transform: rotate(180deg);
      }
    }
  
  .add-btn {
    fill: ${COLORS.getBlueColor(0.54)};
    width: 40px;
    height: 40px;
    display: ${({ image }) => (image ? 'none' : '')};
  }

  .download {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }
  
  .remove-btn {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -8px;
    background-color: white;
    border-radius: 100%;
    z-index: 10;
    transform: rotate(45deg);
    fill: ${COLORS.orange};
    width: 24px;
    height: 24px;
    z-index: 3;
    display: ${({ image }) => (image ? '' : 'none')};
  }
  
  .text {
    margin-top: 16px;
    display: ${({ image }) => (image ? 'none' : '')};
  }
  
  input {
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
  
  .preloader {
    transform: translate(-50%, -50%);
  }

  .icon {
    transform: rotate(270deg);
    font-size: 45px;
    color: ${COLORS.darkBlue};
    opacity: 0.7
  }

  .text {
    width: 120px;
    opacity: 0.7;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: ${COLORS.darkBlue};
    text-align: center;
  }
`;

const DropzoneBox = styled(Dropzone)`
  background-color: ${COLORS.getBlueColor(0.05)};
  height: 160px;
  width: 160px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.getBlueColor(0.7)};
  font-family: Roboto-Regular;
  cursor: ${({ image, disableOnComplete }) => (image && disableOnComplete ? 'default' : 'pointer')};
  position: relative;
  border: ${({ image }) => (image ? '0' : `2px dashed ${COLORS.getBlueColor(0.16)}`)};
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute!important;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
`

class AddFoto extends React.Component {
  state = {
    processing: false,
    showFullImage: false
  }

  onDrop = async (files) => {
    const { onChange, max } = this.props
    let result = files

    if (max) {
      result = files.slice(0, max)
    }

    this.setState({ processing: true })

    await onChange(result)

    this.setState({ processing: false })
  }

  render() {
    const {
      image, multiple, text, allowRemove, onRemove, download, hideCotrols, isPdf, disableOnComplete, disabled, ...rest
    } = this.props

    const { processing, showFullImage } = this.state

    const props = omit(rest, [
      'onChange'
    ])

    return (
      <AddFotoBox {...props} image={image}>
        <DropzoneBox
          style={{ backgroundImage: `url(${image})` }}
          image={image}
          className="dropzone"
          onDrop={this.onDrop}
          disabled={(!!image && disableOnComplete) || disabled}
          accept={props.accept || 'image/jpeg, image/jpg, image/png'}
          multiple={props.multiple || true}
        />
        { processing && (
          <Preloader className="preloader" />
        )}
        {' '}
        {!processing && !image && (
        // <Icon icon="add" className="add-btn" />
        <i className="material-icons icon">
reply
        </i>
        )}
        {!processing && !image && (
          <div className="text">
            {!text ? (
              <span className="text">
              Перетащите сюда
файлы
              </span>
            ) : text
            }
          </div>
        )}

        { (isPdf || (image && image.includes('.pdf'))) && (
          <div className="pdf">
PDF
          </div>
        )
            }

        {
          image && !hideCotrols
          && (
          <React.Fragment>
            <Lightbox
              isOpen={showFullImage}
              currentImageIndex={0}
              images={[image]}
              onCancel={() => this.setState({ showFullImage: false })}
            />

            <div className="icons-wrapper">
              <a href={image} download className="download-icon-wrapper">
                <i className="material-icons">
publish
                </i>
              </a>

              {(!isPdf && !(image && image.includes('.pdf'))) && (
              <div
                className="fullscreen-icon-wrapper"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.setState({ showFullImage: true })
                }
            }
              >
                <i className="material-icons">
fullscreen
                </i>
              </div>
              )}
            </div>
          </React.Fragment>
          )
        }
        { allowRemove
        && (
          <Icon
            icon="add"
            className="remove-btn"
            onClick={(event) => {
              event.preventDefault()

              onRemove()
            }}
          />
        )}
      </AddFotoBox>
    )
  }
}

export { AddFoto }
