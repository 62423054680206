import {
  SET_ACTIVE_PROJECT_ID,
  SET_PROJECTS_LIST,
  SET_CURRENT_PROJECT,
  SET_ACTIVE_STAGE
} from '../constants/project'

const initialState = {
  activeProjectId: Number(localStorage.getItem('activeProjectId')) || null,
  list: [],
  currentProject: {},
  activeStage: null
}

export default function projectState(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PROJECT_ID:
      return { ...state, activeProjectId: action.payload }
    case SET_PROJECTS_LIST:
      return { ...state, list: action.payload }
    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.payload }
    case SET_ACTIVE_STAGE:
      return { ...state, activeStage: action.payload }
    default:
      return state
  }
}
