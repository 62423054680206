import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Icon } from '@ui/atoms'

const CardSelectMobile = ({
  card,
  onChange,
  active,
  withCounter,
  onCounterChange
}) => (
  <div
    className={classnames('card-container', { 'with-counter': withCounter })}
  >
    <div
      onClick={() => onChange(card)}
      className={classnames('card', { active })}
    >
      <div className="card-logo">
        {withCounter
        && active && (
          <div className="card-counter">
            <div
              className="reduce-counter"
              onClick={e => onCounterChange({
                count: (card.count ? card.count : 1) - 1,
                card,
                e
              })
              }
            >
              -
            </div>
            <div className="counter">
              {card.count ? card.count : 1}
            </div>
            <div
              className="increase-counter"
              onClick={e => onCounterChange({
                count: (card.count ? card.count : 1) + 1,
                card,
                e
              })
              }
            >
              +
            </div>
          </div>
        )}

        {!active && <Icon icon={card.img} />}
      </div>
      <div className="card-text">
        {card.name}
      </div>
    </div>
  </div>
)

CardSelectMobile.defaultProps = {
  withCounter: false,
  onCounterChange: () => false
}

CardSelectMobile.propTypes = {
  card: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCounterChange: PropTypes.func,
  active: PropTypes.bool.isRequired,
  withCounter: PropTypes.bool
}

export { CardSelectMobile }
