import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const ProductCardBox = styled.div`
  width: 255px;
  height: 385px;
  padding: 16px;
  cursor: pointer;
  border: solid 1px transparent;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:hover {
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    background-color: #ffffff;
    border: solid 1px rgba(37, 60, 94, 0.12);

    .price {
      color: ${COLORS.orange};
    }

    .image-wrapper .show {
      display: block;
    }

    .project-select {
      display: block;
    }
  }

  .image-wrapper {
    width: 209px;
    height: 209px;
    background: white;
    position: relative;

    .image {
      width: 209px;
      height: 209px;
      object-fit: contain;
    }

    .show {
      display: none;
      position: absolute;
      width: 196px;
      height: 48px;
      border-radius: 8px;
      background-color: rgba(37, 60, 94, 0.54);
      color: white;
      top: 104px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      padding-top: 13px;
      font-size: 16px;
      font-family: Roboto-Medium;
    }
  }

  .price {
    margin-top: 8px;
    font-family: FuturaNewBold-Reg;
    color: ${COLORS.darkBlue};
    font-size: 24px;
    text-align: left;
    width: 100%;
  }

  .discount {
    font-family: FuturaNewBook-Reg;
    font-size: 14px;
    height: 10px;
    color: ${COLORS.darkBlue};
    text-decoration: line-through;
    opacity: 0.7;
    width: 100%;
  }

  .name {
    margin-top: 3px;
    font-family: Roboto-Regular;
    font-size: 14px;
    color: ${COLORS.darkBlue};
    opacity: 0.38;
    width: 100%;
    height: 40px;
    overflow: hidden;
  }

  .project-select {
    margin-top: 10px;
    display: none;
  }
`

const ModalBody = styled.div`
  .product {
    .left-side {
      .img-wrapper {
        width: 524px;
        height: 624px;
      }
    }

    .right-side {
      .info-text {
        overflow: hidden;
        max-height: 218px;
      }
    }
  }
`

export { ModalBody, ProductCardBox }
