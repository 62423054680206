import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import Loadable from 'react-loadable'

import { Steps } from '@ui/organisms/steps'
import { ImageBox } from '@pages/client/FirstUserSteps/firstUserSteps.style'
import { Logo, Preloader } from '@ui/atoms'
import leftImage from '@assets/images/left-image.jpg'
import { MainFooter } from '@ui/organisms/mainFooter'
import { FirstDesignerStepsBox } from '@pages/designer/FirstDesignerSteps/firstDesignerSteps.style'
import { MainHeader } from '@ui/organisms/mainHeader'
import { RESPONSIVE } from '@constants/responsive'
import { Stub } from '@ui/organisms/stub'

const About = Loadable({
  loader: () => import('@pages/client/CreateNewProject/About'),
  loading: Preloader
})

const Style = Loadable({
  loader: () => import('@pages/client/CreateNewProject/Style'),
  loading: Preloader
})

const DesignerChoice = Loadable({
  loader: () => import('@pages/client/CreateNewProject/DesignerChoice'),
  loading: Preloader
})

const steps = [
  {
    name: 'О проекте',
    renderComponent: ({ state, ...rest }) => (
      <About stepState={state} {...rest} />
    )
  },
  {
    name: 'Стиль',
    renderComponent: ({ state, ...rest }) => (
      <Style stepState={state} {...rest} />
    )
  },
  {
    name: 'Дизайнер',
    renderComponent: ({ state, ...rest }) => (
      <DesignerChoice stepState={state} {...rest} />
    )
  }
];

const mapStateToProps = state => ({
  currentProject: state.project.currentProject,
  activeProject: state.project.activeProjectId
});

const stepsForSteps = steps.filter(step => step.visible !== false);

@withRouter
@connect(mapStateToProps)
class CreateNewProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsData: steps.reduce((res, el, index) => ({ ...res, [index]: {} }), {}),
      currentStep: 1
    };
  }

  componentDidMount() {
    const { currentProject } = this.props;

    if (currentProject.finishFill) {
      this.setState({ currentStep: 3 });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentProject } = this.props

    if (currentProject.projectId !== nextProps.currentProject.projectId) {
      this.setState({
        currentStep: nextProps.currentProject.finishFill ? 3 : 1
      });
    }
  }

  goNext = () => {
    this.setState(state => ({ currentStep: state.currentStep + 1 }))
  };

  goPrev = () => {
    this.setState(state => ({ currentStep: state.currentStep - 1 }))
  };

  render() {
    const { stepsData, currentStep } = this.state
    const { activeProject } = this.props

    return (
      <FirstDesignerStepsBox>
        <MediaQuery minWidth={RESPONSIVE.TABLET}>
          <ImageBox src={leftImage}>
            <Logo className="logo" />
          </ImageBox>
          <div className="page">
            <MainHeader short activeTab="projects">
              <div className="header-container">
                <Steps
                  steps={stepsForSteps}
                  currentStep={currentStep}
                  maxStep={3}
                  className="main-steps"
                />
              </div>
            </MainHeader>

            <React.Fragment key={activeProject}>
              {steps[currentStep - 1].renderComponent({
                goNext: this.goNext,
                state: stepsData[currentStep],
                goPrev: this.goPrev,
              })}
            </React.Fragment>
          </div>
          <MainFooter className="first-step-footer" />
        </MediaQuery>
        <MediaQuery maxWidth={RESPONSIVE.TABLET}>
          <Stub />
        </MediaQuery>
      </FirstDesignerStepsBox>
    );
  }
}

export { CreateNewProject };
