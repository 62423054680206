import {
  SET_CATEGORIES,
  SET_ACTIVE_CATEGORY,
  SET_SEARCH_PHRASE,
  SET_ACTIVE_SUBCATEGORY,
  SET_ACTIVE_PRODUCT
} from '../constants/marketplace'

export const setCategories = categories => ({
  type: SET_CATEGORIES,
  payload: categories
})

export const setActiveCategory = id => ({
  type: SET_ACTIVE_CATEGORY,
  payload: id
})

export const setSearchPhrase = phrase => ({
  type: SET_SEARCH_PHRASE,
  payload: phrase
})

export const setActiveSubcategory = category => ({
  type: SET_ACTIVE_SUBCATEGORY,
  payload: category
})

export const setActiveProduct = product => ({
  type: SET_ACTIVE_PRODUCT,
  payload: product
})
