import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { isEqual } from 'lodash'

import { COLORS } from '@constants/colors'
import { setActiveCategory, setActiveSubcategory } from '@redux/actions/marketplace'
import { setLocationSearch } from '@helpers'
import { ROUTES } from '@constants/routes'
import { MarketplaceService } from '@services/MarketplaceService'

const BreadcrumbsBox = styled.div`
  height: 48px;
  display: flex;
  align-items: center;

  .item {
    cursor: pointer;
    margin-right: 8px;
    opacity: 0.38;
    font-family: Roboto-Regular;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    border-bottom: 1px solid transparent;

    &:hover {
      opacity: 1;
      color: ${COLORS.orange};
      border-bottom: 1px solid ${COLORS.orange};
    }

    &.active {
      opacity: 1;
    }

    &.product {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 207px;
    }
  }

  .home {
    cursor: pointer;
    margin-right: 6px;
    font-size: 22px;
    border-bottom: 1px solid transparent;
    opacity: 0.5;
    color: ${COLORS.darkBlue};

    &:hover {
      opacity: 1;
      color: ${COLORS.orange};
      border-bottom: 1px solid ${COLORS.orange};
    }
  }

  .arrow-right {
    opacity: 0.38;
    margin-right: 8px;
    font-size: 18px;
    color: ${COLORS.darkBlue};
  }
`

const mapStateToProps = state => ({
  categories: state.marketplace.categories,
  activeCategory: state.marketplace.activeCategory,
  activeSubcategory: state.marketplace.activeSubcategory,
  product: state.marketplace.activeProduct
})

const mapDispatchToProps = dispatch => ({
  setActiveCategory: category => dispatch(setActiveCategory(category)),
  setActiveSubcategory: category => dispatch(setActiveSubcategory(category))
})

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Breadcrumbs extends Component {
  state = {
    subcategories: []
  }

  selectCategory = (category) => {
    const { setActiveCategory } = this.props

    setLocationSearch('category', category ? category.id : null, ROUTES.marketplace)

    setActiveCategory(category)

    window.scroll(window, 0)
  }

  selectSubCategory = (category) => {
    const { setActiveSubcategory, activeCategory } = this.props

    setLocationSearch('category', activeCategory.id, ROUTES.marketplace)
    setLocationSearch('subcategory', category.id, `${ROUTES.marketplace}?category=${activeCategory.id}`)

    setActiveSubcategory(category)

    window.scroll(window, 0)
  }

  async componentDidMount() {
    const { activeSubcategory } = this.props

    if (activeSubcategory) {
      const response = await MarketplaceService.getCategoryPath(activeSubcategory.id)

      this.setState({ subcategories: response.result.slice(1) })
    }
  }

  async componentDidUpdate(prevProps) {
    const { activeSubcategory } = this.props

    if (!isEqual(prevProps.activeSubcategory, activeSubcategory) && activeSubcategory) {
      const response = await MarketplaceService.getCategoryPath(activeSubcategory.id)

      this.setState({ subcategories: response.result.slice(1) })
    }
  }

  render() {
    const { activeCategory, activeSubcategory, product } = this.props
    const { subcategories } = this.state

    return (
      <BreadcrumbsBox>
        <i className="material-icons home" onClick={() => this.selectCategory(null)}>
          home
        </i>

        {activeCategory && (
          <React.Fragment>
            <i className="material-icons arrow-right">
keyboard_arrow_right
            </i>
            <div
              className={classnames('item', { active: !product && !activeSubcategory })}
              onClick={() => this.selectCategory(activeCategory)}
            >
              {activeCategory.name}
            </div>
          </React.Fragment>
        )}

        {activeSubcategory && (
          subcategories.map((category, index) => (
            <React.Fragment key={category.id}>
              <i className="material-icons arrow-right">
keyboard_arrow_right
              </i>
              <div
                className={classnames('item', { active: !product && index === subcategories.length - 1 })}
                onClick={() => this.selectSubCategory(category)}
              >
                {category.name}
              </div>
            </React.Fragment>
          ))
        )}

        {product && (
          <React.Fragment>
            <i className="material-icons arrow-right">
keyboard_arrow_right
            </i>
            <div className="item active product">
              {product.name}
            </div>
          </React.Fragment>
        )}
      </BreadcrumbsBox>
    )
  }
}

export { Breadcrumbs }
