import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import {
  Button, Input, Radio
} from '@ui/atoms'
import { Column, Row } from '@ui/atoms/layout';
import DesignerService from '@services/DesignerService';
import { updateDesignerInfo } from '@redux/actions/designer'
import { FormatBox } from './format.style'
import { ROUTES } from '@constants'

const mapStateToProps = state => ({
  userData: state.user.userData
})

const mapDispatchToProps = dispatch => ({
  updateDesignerInfo: () => dispatch(updateDesignerInfo())
})

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Format extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      format: 'Personal',
      name: props.userData.name,
      surname: props.userData.surname,
      fatherName: '',
      phone: props.userData.phone,
      passportSerial: '',
      passportNumber: '',
      issuePlace: '',
      issueDate: '',
      code: '',
      registration: '',
      factAddress: '',
      email: props.userData.email || '',
      organization: '',
      ogrn: '',
      inn: '',
      kpp: '',
      bank: '',
      bankAccount: '',
      bik: '',
      workerPosition: '',
      workerName: '',
    }
  }

  renderPhysicFormat = () => {
    const {
      name, surname, fatherName, phone, issuePlace, registration,
      passportNumber, passportSerial, code, email, factAddress, issueDate
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-small-title">
          Контактная информация
        </div>
        <Row>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ name: e.target.value })}
              value={name}
              label="Имя"
              full
            />
          </Column>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ surname: e.target.value })}
              value={surname}
              label="Фамилия"
              full
            />
          </Column>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ fatherName: e.target.value })}
              value={fatherName}
              label="Отчество"
              full
            />
          </Column>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ phone: e.target.value })}
              value={phone}
              label="Телефон"
              mask="+7 (\999) 999 - 99 - 99"
              full
              className="g_disabled"
            />
          </Column>
        </Row>
        <div className="page-small-title">
          Паспортные данные
        </div>
        <Row>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ passportSerial: e.target.value })}
              value={passportSerial}
              label="Серия"
              mask="9999"
              full
            />
          </Column>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ passportNumber: e.target.value })}
              value={passportNumber}
              label="Номер"
              full
              mask="999999"
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ issuePlace: e.target.value })}
              value={issuePlace}
              label="Кем выдан"
              full
            />
          </Column>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ issueDate: e.target.value })}
              value={issueDate}
              label="Когда выдан"
              full
              mask="99.99.9999"
            />
          </Column>
          <Column lg={6}>
            <Input
              onChange={e => this.setState({ code: e.target.value })}
              value={code}
              label="Код подразделения"
              full
              mask="999-999"
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ registration: e.target.value })}
              value={registration}
              label="Адрес регистрации"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ factAddress: e.target.value })}
              value={factAddress}
              label="Фактический адрес"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ email: e.target.value })}
              value={email}
              label="Email"
              full
            />
          </Column>
        </Row>
      </React.Fragment>
    )
  };

  renderLawFormat = () => {
    const {
      organization, ogrn, inn, kpp, bank, bankAccount, bik, workerName, workerPosition,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-small-title">
          Юридическая информация
        </div>
        <Row>
          <Column>
            <Input
              onChange={e => this.setState({ organization: e.target.value })}
              value={organization}
              label="Название организации"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ ogrn: e.target.value })}
              value={ogrn}
              label="ОГРН (или ОГРНИП)"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ inn: e.target.value })}
              value={inn}
              label="ИНН"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ kpp: e.target.value })}
              value={kpp}
              label="КПП"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ bank: e.target.value })}
              value={bank}
              label="Название банка"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ bankAccount: e.target.value })}
              value={bankAccount}
              label="Расчетный счет"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ bik: e.target.value })}
              value={bik}
              label="БИК"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ workerPosition: e.target.value })}
              value={workerPosition}
              label="Название единоличного исполнительного органа"
              full
            />
          </Column>
          <Column>
            <Input
              onChange={e => this.setState({ workerName: e.target.value })}
              value={workerName}
              label="ФИО единоличного исполнителя органа"
              full
            />
          </Column>
        </Row>
      </React.Fragment>
    )
  };

  send = async () => {
    const {
      bik, workerPosition, workerName, format, organization, ogrn, inn, kpp, bank, bankAccount,
      surname, name, fatherName, passportSerial, registration, email, factAddress, code, issuePlace, issueDate,
      phone, passportNumber
    } = this.state

    const { updateDesignerInfo, history } = this.props

    try {
      await DesignerService.setLegal({
        legalType: format,
        companyLegalInfo: {
          name: organization,
          ogrn,
          inn,
          kpp,
          bankName: bank,
          checkingAccount: bankAccount,
          bic: bik,
          soleExecutiveBody: workerPosition,
          nameSoleExecutiveBody: workerName
        },
        personalLegalInfo: {
          surname,
          name,
          middleName: fatherName,
          passportSeries: `${passportSerial} ${passportNumber}`,
          registrationAddress: registration,
          actualAddress: factAddress,
          email,
          passportIssuedBy: issuePlace,
          departmentCode: code,
          dateOfIssue: issueDate,
          phone: phone.replace(/\D+/g, '')
        }
      })

      history.replace(ROUTES.main)
      updateDesignerInfo()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { goPrev } = this.props;
    const { format } = this.state;

    return (
      <FormatBox className="page-content">
        <div className="page-body">
          <div className="page-title">
            Осталось определиться
            <br />
            в каком формате мы будем
            <br />
            с вами работать
          </div>
          <div className="page-description">
            Выберите удобный формат для нашей совместной работы
            <br />
            и заполните все поля с паспортными данными
          </div>
          <div className="data-box">
            <div className="page-small-title">
              Выберите удобный для вас формат
            </div>
            <Radio
              value={format}
              onChange={e => this.setState({ format: e.target.value })}
              items={[
                { value: 'Personal', label: 'Физическое лицо' },
                { value: 'Company', label: 'Юридическое лицо' },
              ]}
            />
            {format === 'Personal' && this.renderPhysicFormat()}
            {format === 'Company' && this.renderLawFormat()}
          </div>
        </div>
        <div className="page-footer">
          <div className="policy">
            Нажимая кнопку
            {' '}
            <span className="button-name">
              “Сохранить и продолжить”
            </span>
            {' '}
            вы соглашаетесь с
            {' '}
            <a href="/design/agreement/" className="link" target="_blank" rel="noopener noreferrer">
              Политикой конфиденциальности
            </a>
            {' '}
          </div>
          <div>
            <Button
              type="text"
              text="Назад"
              onClick={goPrev}
            />
            <Button
              text="Сохранить и продолжить"
              onSave={this.send}
            />
          </div>
        </div>
      </FormatBox>
    )
  }
}

export { Format }
