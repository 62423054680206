import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { Button } from '@ui/atoms'
import { Modal } from '@ui/molecules'
import { ClientService } from '@services/ClientService'
import { updateProjectsList } from '@redux/actions/project'
import { DesignerCardBox } from './designerCard.style'
import { Portfolio } from './portfolio'

const genders = {
  0: 'Пол не указан',
  1: 'Ж',
  2: 'М'
}

const mapDispatchToProps = dispatch => ({
  updateProjectsList: () => dispatch(updateProjectsList())
})

@withRouter
@connect(
  null,
  mapDispatchToProps
)
class DesignerCard extends Component {
  state = {
    isModalOpen: false
  }

  selectDesigner = async () => {
    const { id, projectId, updateProjectsList } = this.props

    await ClientService.selectDesigner({ projectId, designerId: id })

    updateProjectsList()
  }

  render() {
    const {
      imgUrl, name, gender, age, city, about, id
    } = this.props

    const { isModalOpen } = this.state

    return (
      <DesignerCardBox>
        <div className="info-container">
          <div className="image-box">
            <img src={imgUrl} alt="img" align="center" />
          </div>
          <div className="info-box">
            <div className="name">
              {name}
            </div>
            <div className="gender">
              {genders[gender]}
              ,
              {' '}
              {`${age} лет`}
            </div>
            <div className="city">
              {city}
            </div>
            {/* <div className="completedProjects">
              Завершенных проектов:
              {completedProjects}
            </div> */}
            <Button text="Смотреть портфолио" type="bordered" onClick={() => this.setState({ isModalOpen: true })} />
          </div>
        </div>
        <div className="about-container">
          <div className="about-title">
            {' '}
О дизайнере
          </div>
          <span>
            {about}
          </span>
        </div>
        <div className="functions-container">
          <Button text="Выбрать" icon="done" className="chose-button" onClick={this.selectDesigner} />
          {/* <div className="rating-container">
            <div className="rating-box">
              <div className="rating-value">
4,5
              </div>
              <span>
Рейтинг
              </span>
            </div>
            <div className="divider" />
            <div className="reviews-container">
              <div className="review">
132
              </div>
              <span>
Отзывов
              </span>
            </div>
          </div> */}
        </div>

        <Modal isOpen={isModalOpen} onCancel={() => this.setState({ isModalOpen: false })}>
          <Portfolio name={name} id={id} />
        </Modal>
      </DesignerCardBox>
    )
  }
}

DesignerCard.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  gender: PropTypes.number.isRequired,
  age: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired
}

export { DesignerCard }
