import React from 'react'
import 'antd/lib/radio/style/css'
import styled from 'styled-components'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialRadio from '@material-ui/core/Radio';

import { COLORS } from '@constants/colors'

const RadioBox = styled.div`
  .radio-option {
    margin: 0;
    
    .label {
      color: ${COLORS.getBlueColor()};
      font-size: 16px;
      line-height: 24px;
      font-family: Roboto-Regular;
      padding: 12px 16px 12px 0;
    }
    
    .control {
      color: ${COLORS.getBlueColor(0.38)};
    }
    
    &.checked {
      .label {
        font-weight: 500;
        font-family: Roboto-Medium;
      }
      
      .control {
        color: ${COLORS.getBlueColor()};
      }   
    }
  }
`

const Radio = ({ items, value, ...rest }) => (
  <RadioBox>
    <RadioGroup value={value} {...rest}>
      {items.map(item => (
        <FormControlLabel
          value={item.value}
          key={`${item.label}-${item.value}`}
          control={<MaterialRadio classes={{ root: 'control' }} />}
          label={item.label}
          classes={{
            label: 'label',
            root: `radio-option${value === item.value ? ' checked' : ''}`,
          }}
        />
      ))}
    </RadioGroup>
  </RadioBox>
)

Radio.Radio = ({ value, label }) => (
  <FormControlLabel value={value} control={<MaterialRadio />} label={label} />
)

export { Radio }
