import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { COLORS } from '@constants/colors'
import { Button } from '@ui/atoms'

const LightboxBox = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;

  .lightbox-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  .image-wrapper {
    position: absolute;
    width: 70%;
    height: 100%;
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      object-fit: contain;
      max-height: 95vh;
    }
  }

  .back-button {
    position: absolute;
    left: 30px;
    top: 16px;
    cursor: pointer;
    background-color: rgba(37, 60, 94, 0.54);
    border: none;

    i {
      font-size: 29px;
      display: inline-block;
      padding-top: -5px;
      padding-bottom: 2px;
      vertical-align: middle;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    color: white;
    font-size: 50px;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.disabled {
      cursor: default;
      color: ${COLORS.textGrey};
      pointer-events: none;
    }
  }
`

class Lightbox extends Component {
  render() {
    const {
      isOpen, onCancel, getContainer, images, getNext, getPrev, currentImageIndex
    } = this.props

    const body = (
      <LightboxBox>
        <div className="lightbox-overlay" />
        <div className="image-wrapper">
          <img src={images[currentImageIndex]} alt="" />
        </div>
        {images.length > 1 && (
          <i className={classnames('material-icons arrow left', { disabled: currentImageIndex === 0 })} onClick={getPrev}>
            chevron_left
          </i>
        )}
        {images.length > 1 && (
          <i
            className={classnames('material-icons arrow right', {
              disabled: currentImageIndex === images.length - 1
            })}
            onClick={getNext}
          >
            chevron_right
          </i>
        )}
        <Button className="back-button" text="Вернуться назад" icon="chevron_left" onClick={onCancel} />
      </LightboxBox>
    )

    return isOpen ? ReactDOM.createPortal(body, getContainer()) : null
  }
}

Lightbox.defaultProps = {
  isOpen: false,
  getContainer: () => document.body
}

Lightbox.propTypes = {
  isOpen: PropTypes.bool,
  getContainer: PropTypes.func,
  images: PropTypes.array.isRequired
}

export { Lightbox }
