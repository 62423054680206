import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import ProjectService from '@services/ProjectService'
import { Button, Icon, Textarea } from '@ui/atoms'
import { COLORS, ROUTES } from '@constants'
import { RoomItems } from '@pages/designer/roomProducts'
import { isEqual } from 'lodash'
import { FreelanceService } from '@services/FreelanceService'

const PageBox = styled.div`
  &.page_content {
    width: 790px;
  }
  
  .market-block {
    border-top: 1px solid ${COLORS.getBlueColor(0.12)};
    margin-top: 32px;
    padding-top: 32px;
  }
  
  .market-button {
    width: 100%;
    margin-top: 24px;
  }
  
  .buttons-row {
    margin-top: 80px;
    display: flex;
    justify-content: space-between
  }
  
  .textarea {
    height: 300px;
    margin-top: 16px;
  }
  
  .files-block {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .file-input {
    display: none;
  }
  
  .files {
    display: flex;
    width: 456px;
    flex-flow: row wrap;
  }
  
  .file {
    width: 220px;
    border-radius: 6px;
    background-color: ${COLORS.getBlueColor(0.05)};
    padding: 10px 12px;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 20px;
    color: ${COLORS.getBlueColor(0.37)};
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .file-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    fill: ${COLORS.getBlueColor(0.54)}
  }
  
  .remove-file {
    transform: translateY(-50%) rotate(45deg);
    fill: ${COLORS.orange};
    position: absolute;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 50%;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@withRouter
@connect(mapStateToProps)
class DesignerBrigade extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: (() => {
        if (props.stage.stage > props.step) {
          return 1
        }

        return !props.stage.userMadeDecision ? 2 : props.stage.designerIsReady ? 3 : 1
      })(),
      rooms: [],
      TZ: '',
      TZFiles: [],
    }
  }

  componentDidMount() {
    this.getInfo()
  }

  getInfo = async () => {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      const brigadesResponse = await FreelanceService.brigadeGetTechSpec(activeProject)

      this.setState({
        rooms: result.map(room => ({
          model: room.purpose,
          roomId: room.roomId,
        })),
        TZFiles: brigadesResponse.result.files.map(file => ({
          id: file.fileId,
          link: file.fileLink,
          name: file.fileName || 'Файл'
        })),
        TZ: brigadesResponse.result.description || '',
      })
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({
        step: (() => {
          if (nextProps.stage.stage > nextProps.step) {
            return 1
          }

          return !nextProps.stage.userMadeDicision ? 2 : nextProps.stage.designerIsReady ? 3 : 1
        })
      }, () => this.getInfo())
    }
  }

  addFile = async (event) => {
    const { activeProject } = this.props
    const { TZFiles } = this.state
    const fileList = event.target.files

    try {
      const uploadFileResponse = await FreelanceService.brigadeAddFile({
        ProjectId: activeProject,
        File: fileList[0]
      })

      TZFiles.push({
        id: uploadFileResponse.result,
        name: fileList[0].name,
        link: URL.createObjectURL(fileList[0]),
      });

      this.setState({ TZFiles });
    } catch (e) {
      console.log(e)
    }
  }

  removeFile = async (fileIndex) => {
    const { activeProject } = this.props
    const { TZFiles } = this.state

    try {
      await FreelanceService.brigadeRemoveFile({
        projectId: activeProject,
        fileId: TZFiles[fileIndex].id,
      })

      TZFiles.splice(fileIndex, 1);

      this.setState({ TZFiles });
    } catch (e) {
      console.log(e)
    }
  }

  sendTechSpec = async () => {
    try {
      const { activeProject } = this.props
      const { TZ } = this.state

      await FreelanceService.brigadeSetTechSpec({
        projectId: activeProject,
        description: TZ
      })
    } catch (e) {
      console.log(e)
    }
  }

  publishTechSpec = async () => {
    try {
      const { activeProject } = this.props
      const { TZ } = this.state

      await FreelanceService.brigadeSetTechSpec({
        projectId: activeProject,
        description: TZ
      })

      await FreelanceService.brigadePublishTechSpec({
        projectId: activeProject,
      })

      ProjectService.confirmDevelopmentStage(activeProject, 'Brigade')

      this.setState({ step: 3 })
    } catch (e) {
      console.log(e)
    }
  }

  getIconByExtention = (fileName) => {
    const extention = fileName.split('.').pop()

    switch (extention) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <Icon icon="imageFile" className="file-icon" />
      default:
        return <Icon icon="imageFile" className="file-icon" />
    }
  }

  renderFirstStep = () => {
    const {
      rooms, step, TZ, TZFiles
    } = this.state
    const {
      goPrev, stage, goNext, ...props
    } = this.props
    const passedStep = stage.stage > props.step

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Настал момент воплотить дизайн интерьера в жизнь. Если у вас нет рабочей бригады, мы готовы для вас
          подобрать ее среди проверенных подрядчиков.
          <br />
          <br />
          Составьте вместе с Клиентом подробное описание для рабочих, а после они свяжутся с Клиентом.
        </div>
        {!!rooms.length && (
          <React.Fragment>
            <div className="g_small-title mt48">
              Напишите ТЗ для рабочей бригады
            </div>
            <div className="g_text">
              Пишите грамотно и понятно.
            </div>
            <Textarea
              className="textarea"
              value={TZ}
              onChange={e => this.setState({ TZ: e.target.value })}
              disabled={passedStep}
            />
            <div className="files-block">
              <div className="files">
                {TZFiles.map((file, fileIndex) => (
                  <div className="file" key={`file-${fileIndex}`}>
                    {this.getIconByExtention(file.name)}
                    {file.name}
                    <Icon
                      icon="add"
                      onClick={() => this.removeFile(fileIndex)}
                      className="remove-file"
                    />
                  </div>
                ))}
              </div>
              <div>
                <Button
                  text="Прикрепить файл"
                  type="filled"
                  color="additional"
                  onClick={() => this.fileInput.click()}
                  disabled={passedStep}
                />
                <input
                  type="file"
                  className="file-input"
                  ref={ref => this.fileInput = ref}
                  onChange={this.addFile}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="buttons-row">
          <Button
            type="text"
            text="Назад"
            onClick={() => {
              if (step === 3) {
                this.setState({ step: 2 })
                return
              }

              goPrev()
            }}
          />
          <div>
            {!passedStep ? (
              <React.Fragment>
                <Button
                  text="Сохранить прогресс"
                  type="bordered"
                  color="orange"
                  style={{ marginRight: 20 }}
                  onClick={this.sendTechSpec}
                />
                <Button
                  text="Отправить клиенту на согласование"
                  disabled={!!rooms.length && !TZ}
                  onClick={this.publishTechSpec}
                />
              </React.Fragment>
            ) : (
              <Button
                text="Вперед"
                onClick={() => goNext()}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { goPrev, updateStage } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Это окно ожидания. Клиент принимает решение нужна ли ему бригада на ремонт.
          <br />
          <br />
          Если Клиенту нужна рабочая бригада, вы будете отправлены в окно заполнения Технического задания.
          <br />
          <br />
          Если Клиент откажется от этого этапа, вы перейдете в следующий.
        </div>
        <div className="buttons-row">
          <div>
            <Button
              text="Назад"
              onClick={goPrev}
              type="text"
            />
          </div>
          <div>
            <Button
              text="Проверить"
              type="bordered"
              onClick={() => updateStage()}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderThirdStep = () => {
    const { goPrev, updateStage } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Это окно ожидания, оно появляется когда от клиента требуется принять какое-либо решение относительно того,
          {' '}
          что он планирует дальше делать. Это будет на этом шаге, затем на чертежах, а потом для бригады. В общем ждите.
        </div>
        <div className="buttons-row">
          <div>
            <Button
              text="Назад"
              onClick={goPrev}
              type="text"
            />
          </div>
          <div>
            <Button
              text="Проверить"
              type="bordered"
              onClick={() => updateStage()}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { step, rooms } = this.state
    const { history } = this.props

    return (
      <PageBox className="page_content">
        <div className="page-body">
          <div className="page-title">
            Бригада на ремонт
          </div>
          { step === 1 && this.renderFirstStep() }
          { step === 2 && this.renderSecondStep() }
          { step === 3 && this.renderThirdStep() }
          <div className="market-block">
            <div className="g_big-text">
              Добавьте товары из нашего магазина к данному проекту. В дальнейшем, клиент сможет приобрести их у нас.
            </div>
            <div className="g_text">
              Если нужного товара нет, сообщите нам об этом
            </div>
            <Button
              text="Перейти в магазин FARADISE"
              color="additional"
              type="filled"
              className="market-button"
              onClick={() => history.push(ROUTES.marketplace)}
            />
          </div>
          {!!rooms.length && (
            <RoomItems
              rooms={rooms}
            />
          )}
        </div>
      </PageBox>
    )
  }
}

export { DesignerBrigade }
