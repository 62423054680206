import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const RowBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  position: relative;
`

const ColumnBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 ${({ lg }) => (lg / 12) * 100}%;
  max-width: ${({ lg }) => (lg / 12) * 100}%;
  margin-left: ${({ offsetLg }) => (offsetLg / 12) * 100}%;
`

const Row = ({ children, ...rest }) => (
  <RowBox {...rest}>
    {children}
  </RowBox>
)

const Column = ({ children, ...rest }) => (
  <ColumnBox {...rest}>
    {children}
  </ColumnBox>
)

Column.defaultProps = {
  lg: 12,
  offsetLg: 0
}

Column.propTypes = {
  lg: PropTypes.number,
  offsetLg: PropTypes.number
}

export { Column, Row }
