import { API } from '@constants/api'
import { HttpService } from '@services/HttpsService'

class MarketplaceService extends HttpService {
  getSugestions = (params = {}) => this.get(API.MARKETPLACE.getSugestions, params)

  getPossibleProjects = productId => this.get(API.MARKETPLACE.getPossibleProjects, { productId })

  getCategories = () => this.get(API.MARKETPLACE.getCategories)

  getProduct = productId => this.get(API.MARKETPLACE.getProduct, { productId })

  getProducts = params => this.get(API.MARKETPLACE.getProducts, params)

  getRooms = () => this.get(API.MARKETPLACE.getRooms)

  getRoomProduct = params => this.get(API.MARKETPLACE.getRoomProduct, params)

  addProductToProject = data => this.post(API.MARKETPLACE.addProductToProject, data)

  removeProductFromProject = data => this.post(API.MARKETPLACE.removeProductFromProject, data)

  getOrderedProductsForRoom = (ProjectId, RoomId) => this.get(API.MARKETPLACE.getOrderedProductsForProjectRoom, { ProjectId, RoomId })

  issueOrderForProject = data => this.post(API.MARKETPLACE.issueOrderForProject, data)

  getSubcategories = id => this.get(API.MARKETPLACE.getSubcategories, { id })

  getFilters = categoryId => this.get(API.MARKETPLACE.getFilters, { categoryId })

  getCategoryPath = categoryId => this.get(API.MARKETPLACE.getCategoryPath, { categoryId })

  getCategoryDescription = categoryId => this.get(API.MARKETPLACE.getCategoryDescription, { categoryId })
}

const marketplaceService = new MarketplaceService()

export { marketplaceService as MarketplaceService }
export default marketplaceService
