function validatePhone(value) {
  if (value.replace(/\D+/g, '').length < 11) {
    return 'Неверный формат'
  }
}

function validateLink(value) {
  const regexp = /https?:\/\/(www\.)?[-a-zA-Z\u0410-\u044F0-9@:%._+~#=]{2,256}\.[\wа-я]{2,6}/

  if (!value) {
    return 'Ссылка не валидна'
  }

  return !regexp.test(value) ? 'Ссылка не валидна' : null
}

function validateEmail(value) {
  // eslint-disable-next-line
  const regexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  return value ? (!regexp.test(value) ? 'Email не валидный' : null) : null
}

export { validatePhone, validateLink, validateEmail }
