import React, { Component } from 'react'
import { get } from 'lodash'
import classnames from 'classnames'

import { PortfolioBox, ProjectBox } from '@pages/client/FirstUserSteps/DesignerChoice/portfolio.style'
import { ClientService } from '@services/ClientService';
import { Lightbox } from '@ui/organisms';

class Portfolio extends Component {
  state = {
    currentProject: null,
    currentImageIndex: 0,
    portfolios: [],
    showFullImage: false
  }

  async componentDidMount() {
    try {
      const { id } = this.props

      const response = await ClientService.getDesignerPortfolio(id)

      this.setState({ portfolios: get(response, 'result.portfolios', []) })
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { name } = this.props
    const {
      currentProject, currentImageIndex, portfolios, showFullImage
    } = this.state

    return currentProject === null ? (
      <PortfolioBox>
        <div className="title">
          Портфолио —
          {` ${name}`}
        </div>
        <div className="cards-wrapper">
          {portfolios.map((portfolio, index) => (
            <div className="card" key={portfolio.id}>
              {
              get(portfolio, 'photoLinks[0].photoLink', '') ? (
                <div className="img-wrapper" onClick={() => this.setState({ currentProject: index })}>
                  <img className="img" src={get(portfolio, 'photoLinks[0].photoLink', '')} alt={portfolio.description} />
                  <div className="img-overlay">
                    {`+ ${portfolio.photoLinks.length - 1}`}
                    {' '}
Фото
                  </div>
                </div>
              ) : (
                <div className="img-wrapper">
                  <div className="empty">
Фотографии проекта отсутствуют
                  </div>
                </div>
              )
            }
              <div className="name">
                {portfolio.name}
              </div>
            </div>
          ))}
        </div>
        {currentProject}
      </PortfolioBox>
    ) : (
      <ProjectBox>
        <i
          className={classnames('material-icons big-arrow left', { disabled: currentProject === 0 })}
          onClick={() => this.setState({ currentProject: currentProject - 1, currentImageIndex: 0 })}
        >
          chevron_left
        </i>
        <i
          className={classnames('material-icons big-arrow right', {
            disabled: currentProject === portfolios.length - 1
          })}
          onClick={() => this.setState({ currentProject: currentProject + 1, currentImageIndex: 0 })}
        >
          chevron_right
        </i>

        <div className="title">
          <i
            className="material-icons icon-back"
            onClick={() => this.setState({ currentProject: null, currentImageIndex: 0 })}
          >
            arrow_back_ios
          </i>
          {portfolios[currentProject].name}
        </div>
        <div className="main-info">
          <div className="img-wrapper">
            <img className="img" src={portfolios[currentProject].photoLinks[currentImageIndex].photoLink} alt="" />
            <div className="fullscreen-icon-wrapper" onClick={() => this.setState({ showFullImage: true })}>
              <i className="material-icons">
fullscreen
              </i>
            </div>
          </div>
          <div className="description">
            <div className="about-project">
О проекте
            </div>
            {portfolios[currentProject].description}
          </div>
        </div>
        <div className="images-slider">
          <i
            className={classnames('material-icons arrow left', { disabled: currentImageIndex === 0 })}
            onClick={() => this.setState({ currentImageIndex: currentImageIndex - 1 })}
          >
            chevron_left
          </i>
          <i
            className={classnames('material-icons arrow right', {
              disabled: currentImageIndex === portfolios[currentProject].photoLinks.length - 1
            })}
            onClick={() => this.setState({ currentImageIndex: currentImageIndex + 1 })}
          >
            chevron_right
          </i>
          <div className="slides-wrapper">
            <div
              className="slides"
              style={{
                transform: `translateX(-${(currentImageIndex - 3 <= 0 ? 0 : currentImageIndex - 3) * 91}px)`
              }}
            >
              {portfolios[currentProject].photoLinks.map((photo, photoIndex) => (
                <div
                  className={classnames('img-preview-wrapper', { active: currentImageIndex === photoIndex })}
                  onClick={() => this.setState({ currentImageIndex: photoIndex })}
                  key={photo.photoLink}
                >
                  <img src={photo.photoLink} alt="" className="img-preview" />
                  <div className="img-preview-overlay" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <Lightbox
          isOpen={showFullImage}
          currentImageIndex={currentImageIndex}
          images={get(portfolios, `${currentProject}.photoLinks`, []).map(photo => photo.photoLink)}
          getPrev={() => this.setState({ currentImageIndex: currentImageIndex - 1 })}
          getNext={() => this.setState({ currentImageIndex: currentImageIndex + 1 })}
          onCancel={() => this.setState({ showFullImage: false })}
        />
      </ProjectBox>
    )
  }
}

export { Portfolio }
