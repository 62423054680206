import { API } from '@constants/api'
import { HttpService } from '@services/HttpsService'

class ClientService extends HttpService {
  createClient = () => this.post(API.AUTHENTICATION.createClient)

  setBasicInfo = data => this.post(API.CLIENT.setBasicInfo, data)

  createProject = () => this.post(API.CLIENT.createProject)

  setAdditionalInfo = data => this.post(API.CLIENT.setAdditionalInfo, data)

  setStyles = data => this.post(API.CLIENT.setStyles, data)

  setBudget = data => this.post(API.CLIENT.setBudget, data)

  setDesignerRequirements = data => this.post(API.CLIENT.setDesignerRequirements, data)

  getQuestions = () => this.get(API.CLIENT.getQuestions)

  getProjectsList = () => this.get(API.CLIENT.getProjectsList)

  getProject = projectId => this.get(API.CLIENT.getProject, { projectId })

  getDesigners = projectId => this.get(API.CLIENT.getDesigners, { projectId })

  selectDesigner = ({ projectId, designerId }) => this.post(API.CLIENT.selectDesigner, { projectId, designerId })

  getDesignerPortfolio = designerId => this.get(API.CLIENT.getDesignerPortfolio, { designerId })

  getProjectShortDescription = projectId => this.get(API.CLIENT.getProjectShortDescription, { projectId })
}

const clientService = new ClientService()

export { clientService as ClientService }
export default clientService
