export const typeOfDwelling = [
  {
    img: 'apartment',
    model: 'Apartment',
    name: 'Квартира',
    id: 1
  },
  {
    img: 'house',
    model: 'CityHouse',
    name: 'Дом',
    id: 2
  },
  {
    img: 'vacationHome',
    model: 'VacationHouse',
    name: 'Загородный \n дом',
    id: 3
  },
  {
    img: 'office',
    model: 'Office',
    name: 'Офис',
    id: 4
  },
  {
    img: 'cafe',
    model: 'Cafe',
    name: 'Кафе',
    id: 5
  }
]

const typeOfDwellingIconsMap = {
  Apartment: { icon: 'apartment', name: 'Квартира' },
  CityHouse: { icon: 'house', name: 'Дом' },
  VacationHouse: { icon: 'vacationHome', name: 'Загородный дом' },
  Office: { icon: 'office', name: 'Офис' },
  Cafe: { icon: 'cafe', name: 'Кафе' }
}

export { typeOfDwellingIconsMap }
