import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const FurnitureChoiceBox = styled.div`
  background-color: ${COLORS.getBlueColor(0.02)};
  padding: 30px;
  border-radius: 8px;
  position: relative;
  margin: 0 -30px 16px -30px;
  border: solid 1px rgba(37, 60, 94, 0.12);
  
  .title {
    .hide {
      position: absolute;
      cursor: pointer;
      top: 36px;
      right: 30px;
      opacity: 0.54;
      font-family: Roboto-Regular;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      & svg {
        margin-right: 6px;
        max-width: 12px;
        max-height: 8px;
      }
      &.hidden svg {
        transform: rotate(180deg);
      }
    }
    span {
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }
  }
  .checkbox-container label {
    vertical-align: top;
    margin-top: 15px;
  }
  
  .list-box {
    margin-top: 4px;

    .type-box {
      margin-bottom: 15px;
      margin-top: 26px;
      
      .type {
        cursor: pointer;
        font-family: Roboto-Regular;
        font-size: 14px;
        line-height: 1.29;
        color: ${COLORS.getBlueColor(0.7)};
        padding: 7px 15px;
        display: inline-block;
        border-radius: 4px;
        &:hover{
          background-color: ${COLORS.getBlueColor(0.05)};
        }
        &.active {
          background-color: ${COLORS.darkBlue};
          color: ${COLORS.white};
        }
      }
    }

    .checkbox-container {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .item-box {
      display: inline-flex;
      width: 660px;
      
      .item-name {
        font-family: Roboto-Regular;
        font-size: 16px;
        line-height: 24px;
        color: ${COLORS.getBlueColor(0.54)};
      }
      
      .item-color {
        font-size: 14px;
        font-family: Roboto-Regular;
        color: ${COLORS.getBlueColor()};
        line-height: 24px;
      }
      
      .count-controls {
        display: flex;
        align-items: center;
      }
      
      .remove-btn {
        font-family: Roboto-Medium;
        font-size: 14px;
        line-height: 24px;
        color: ${COLORS.orange}
        font-weight: 500;
        cursor: pointer;
      }
      
      .count-block {
        height: 40px;
        width: 125px;
        border-radius: 8px;
        background-color: ${COLORS.getBlueColor(0.05)};
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        letter-spacing: -0,4px;
        font-family: SFPROText-Semibold;
        color: ${COLORS.getBlueColor()};
        font-weight: 600;
        align-items: center;
        margin-top: 8px;
        margin-right: 24px;
        
        .count-icon {
          width: 24px;
          height: 24px;
          margin: 8px;
          cursor: pointer;
        }
      }
      
      .description,
      .color,
      .delivery {
        font-family: Roboto-Regular;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: normal;
        color: ${COLORS.darkBlue};
      }
      
      .delivery {
        color: ${COLORS.getBlueColor(0.54)};
      }
      
      .img-box {
        width: 96px;
        height: 96px;
        
        & img {
          width: 100%;
          height: 100%;
        }
      }
      
      .additional-box {
        margin-right: 16px;
      }
      
      .item-price {
        font-size: 20px;
        font-family: FuturaNewBold-Reg;
        color: ${COLORS.getBlueColor(0.87)};
        font-weight: 600;
      }
      
      .center-box {
        margin-left: 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .subtotal-box {
    border-top: solid 1px ${COLORS.getBlueColor(0.12)};
    border-bottom: solid 1px ${COLORS.getBlueColor(0.12)};
    padding: 17px 0px;
    
    .text,
    .amount {
      display: inline-block;
    }
    
    .amount {
      text-align: right;
      float: right;
      font-family: FuturaNewBold-Reg;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      text-align: right;
      margin-right: 16px;
      color: ${COLORS.darkBlue};
    }
    
    .text {
      font-family: Roboto-Regular;
      font-size: 14px;
      line-height: 24px;
      color: ${COLORS.getBlueColor(0.7)};
    }
  }
  .total-box {
    padding-bottom: 48px;
    .left-panel,
    .right-panel {
      display: inline-block;
    }
    
    .right-panel {
      text-align: right;
      float: right;
      padding-top: 16px;
      margin-right: 16px;
      
      .total-text {
        font-family: Roboto-Regular;
        font-size: 16px;
        color: ${COLORS.darkBlue};
        line-height: 1.5;
      }
      .total-amount {
        font-family: FuturaNewBold-Reg;
        font-size: 32px;
        line-height: 0.75;
        color: ${COLORS.orange};
        margin-top: 8px;
      }
    }
    .left-panel {
      padding-top: 4px;
    }
  }

  .button-box {
    button {
      width: 100%;
      margin-bottom: 8px;
      text-align: center;
    }
  }
  .hide-box {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }
`

const OrderModalBox = styled.div`
  width: 540px;
  
  .order-btn {
    width: 100%;
    margin-top: 24px;
    height: 48px;
  }
`

export { FurnitureChoiceBox, OrderModalBox }
