import { createStore, applyMiddleware } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'

import { rootReducer } from './reducers'
// import { rootEpic } from './epics'
import { rootSaga } from './sagas'

const history = createBrowserHistory()
const epicMiddleware = createEpicMiddleware()
const sagaMiddleware = createSagaMiddleware()

const middlewares = [epicMiddleware, routerMiddleware(history), sagaMiddleware]

const connectedRouter = connectRouter(history)

const store = createStore(connectedRouter(rootReducer), composeWithDevTools({})(applyMiddleware(...middlewares)))

// epicMiddleware.run(rootEpic)
sagaMiddleware.run(rootSaga)

export { store, history }
