import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'
import classnames from 'classnames'

import { StyledInput, InputBox } from './input.style'

class Input extends Component {
  beforeMaskedValueChange = (newState, caretAlwaysAtEnd) => {
    const { value } = newState
    const selection = caretAlwaysAtEnd ? { start: value.length, end: value.length } : newState.selection

    return {
      value,
      selection
    }
  }

  render() {
    const {
      full,
      value,
      className,
      size,
      mask,
      maskChar,
      renderExample,
      placeholder,
      suffix,
      caretAlwaysAtEnd,
      error,
      onChange,
      ...rest
    } = this.props

    return (
      <InputBox className={className} full={full}>
        {mask && (
          <React.Fragment>
            <MaskedInput
              {...rest}
              mask={mask}
              maskChar={maskChar}
              onChange={onChange}
              value={value}
              size={size}
              formatChars={{ 8: '[1-9]', 9: '[0-9]' }}
              placeholder={placeholder}
              beforeMaskedValueChange={newState => this.beforeMaskedValueChange(newState, caretAlwaysAtEnd)}
            >
              {inputProps => (
                <StyledInput
                  className={classnames({ error })}
                  InputProps={{
                    classes: {
                      underline: 'input-underline',
                      root: `input-root ${size}`,
                      focused: 'focused',
                    }
                  }}
                  InputLabelProps={{
                    className: 'label'
                  }}
                  {...inputProps}
                />
              )}
            </MaskedInput>
            <div className="input-error">
              {error}
            </div>
          </React.Fragment>
        )}
        {suffix && (
        <div className="suffix">
          {suffix}
        </div>
        )}

        {!mask && (
          <React.Fragment>
            <StyledInput
              className={classnames({ error })}
              InputProps={{
                classes: {
                  underline: 'input-underline',
                  root: `input-root ${size}`,
                  focused: 'focused',
                }
              }}
              InputLabelProps={{
                className: 'label'
              }}
              {...rest}
              placeholder={placeholder}
              size={size}
              value={value}
              onChange={onChange}
            />
            <div className="input-error">
              {error}
            </div>
          </React.Fragment>
        )}

        {renderExample() !== null && (
        <div className="input-example">
          {renderExample()}
        </div>
        )}
      </InputBox>
    )
  }
}

Input.defaultProps = {
  size: 'small',
  suffix: '',
  mask: '',
  maskChar: '',
  className: '',
  placeholder: '',
  label: '',
  caretAlwaysAtEnd: false,
  renderExample: () => null
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  renderExample: PropTypes.func,
  placeholder: PropTypes.string,
  caretAlwaysAtEnd: PropTypes.bool,
  label: PropTypes.string
}

export { Input }
