import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { CheckBox } from '@ui/atoms'
import { FilterSelectBox } from './filterSelect.style'


class FilterSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      search: ''
    }
  }

  async componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideFilterSelect)
  }

  handleDropdown = async (event) => {
    event.stopPropagation()

    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideFilterSelect)
  }

  handleClickOutsideFilterSelect = (event) => {
    if (this.filtersDropdown && !this.filtersDropdown.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  onSelect(value) {
    const { onSelect, selectedValues } = this.props

    if (selectedValues.includes(value)) {
      onSelect([...selectedValues.splice(0, selectedValues.indexOf(value)), ...selectedValues.splice(selectedValues.indexOf(value) + 1)])

      return
    }

    onSelect([...selectedValues, value])
  }

  reset() {
    const { onSelect } = this.props

    onSelect([])
  }

  render() {
    const {
      className, selectedValues, items, title, withSearch
    } = this.props

    const { isOpen, search } = this.state

    const toggleText = selectedValues && selectedValues.length
      ? items.filter(item => selectedValues.includes(item.value)).reduce((res, item) => `${res}${item.name}, `, '').slice(0, -2)
      : 'Все'

    const selectedItems = selectedValues && selectedValues.length
      ? items.filter(item => selectedValues.includes(item.value))
      : null

    return (
      <FilterSelectBox className={className} innerRef={elem => this.filtersDropdown = elem}>
        <div className="control-title">
          {title}

          <i className="material-icons delete" onClick={() => this.reset()}>
delete
          </i>
        </div>

        <div className="toggle" onClick={event => this.handleDropdown(event)}>
          <span className="current-values">
            {toggleText}
          </span>
          {
            isOpen ? (
              <i className="material-icons">
            arrow_drop_up
              </i>
            ) : (
              <i className="material-icons">
arrow_drop_down
              </i>
            )
          }
        </div>

        {selectedItems
        && (
        <div className="selected-items">
          {selectedItems.map(item => (
            <div className="item" key={item.value}>
              {item.name}
              <i className="material-icons clear" onClick={() => this.onSelect(item.value)}>
clear
              </i>
            </div>
          ))}
        </div>
        )}

        {isOpen && (

        <div className="dropdown" onClick={event => event.stopPropagation()}>
          <Scrollbars
            autoHeight
            autoHeightMax={350}
          >
            {withSearch && (
            <div className="search-wrapper">
              <input
                className="search"
                value={search}
                onChange={event => this.setState({ search: event.target.value })}
                placeholder="Введите название..."
              />

              <i className="material-icons icon-search">
search
              </i>
            </div>
            )}

            {
                items.map(item => (
                  <div className="checkboxes-wrapper" key={item.value}>
                    {((search && item.name.toLowerCase().startsWith(search.toLowerCase())) || !search) && (
                    <div className="room-info">
                      <CheckBox
                        onFocus={() => undefined}
                        label={(
                          <div className="label-content">
                            {item.hex && <div className="hex" style={{ backgroundColor: item.hex }} />}
                            {item.image && <img className="preview" src={item.image} alt="" />}

                            {item.name}
                          </div>
)}
                        onChange={() => this.onSelect(item.value)}
                        checked={selectedValues.includes(item.value)}
                        reverse
                      />
                    </div>
                    )}
                  </div>
                ))
              }
          </Scrollbars>
        </div>
        )}
      </FilterSelectBox>
    )
  }
}

export { FilterSelect }
