import styled from 'styled-components'
import { COLORS } from '@constants/colors'
import { ProductsBox } from '../Products/products.style'

const MainBox = styled(ProductsBox)`
  .searchBox {
    box-shadow: 0 1px 2px 0 rgba(37, 60, 94, 0.12);
  }

  .controls-wrapper {
    margin-top: 10px;
  }

  .products-container {
    margin-top: 50px;
    margin-left: 31px;
    min-height: 50vh;
    width: 825px;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next,
  .slick-prev {
    z-index: 9;
    width: 20px;
    height: 20px;

    & svg {
      fill: ${COLORS.darkBlue};
      width: 20px;
      height: 20px;
    }
  }

  .slider {
    overflow: hidden;

    .slick-slider {
      padding: 0;
    }

    .slide-1 {
      border-radius: 8px;
      position: relative;
      width: 825px;
      height: 480px;
      background-color: ${COLORS.getBlueColor(0.03)};
      padding: 60px;

      .title {
        font-family: FuturaNewBold-Reg;
        font-size: 48px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -1px;
        color: ${COLORS.orange};
        max-width: 440px;
        margin-bottom: 30px;
      }

      .sub-title {
        font-family: FuturaNewBook-Reg;
        font-size: 28px;
        font-weight: 200;
        line-height: 1.14;
        color: ${COLORS.darkBlue};
        max-width: 440px;
        margin-bottom: 72px;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 320px;
      }
    }
  }
  .slick-dots li button:before {
    font-size: 22px;
  }

  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 32px;
    line-height: 1;
    letter-spacing: -0.5px;
    text-align: center;
    font-weight: 200;
    color: ${COLORS.darkBlue};
  }

  .go-to-more {
    cursor: pointer;
    margin-top: 48px;
    font-family: FuturaNewBold-Reg;
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 1.5px;
    text-align: center;
    color: ${COLORS.orange};
    text-transform: uppercase;
    i {
      vertical-align: top;
      font-size: 33px;
    }
  }

  .popular-container {
    margin-bottom: 88px;
    width: 100%;

    .title {
      margin-bottom: 22px;
    }
  }

  .banners {
    margin-bottom: 80px;
    img {
      width: 400px;
      &:first-child {
        margin-right: 25px;
      }
    }
  }

  .compilation {
    text-align: center;
    width: 100%;
    margin-bottom: 88px;

    .title {
      max-width: 465px;
      margin: 0 auto 32px auto;
    }
    ul.menu {
      padding-left: 0;
      list-style: none;

      li {
        cursor: pointer;
        display: inline-block;
        padding: 0 16px;
        font-family: Roboto-Regular;
        font-size: 14px;
        line-height: 2.86;
        color: ${COLORS.getBlueColor(0.7)};
        &.active,
        &:hover {
          color: #ffffff;
          border-radius: 6px;
        }
        &:hover {
          background-color: ${COLORS.getBlueColor(0.25)};
        }
        &.active {
          background-color: ${COLORS.darkBlue};
        }
      }
    }

    .compilation-slider {
      overflow: hidden;

      .slick-next {
        right: 0px;
      }

      .slick-prev {
        left: 0px;
      }

      .slick-slider {
        padding: 0;
      }
      
      .slick-list{
        width: calc(100% - 40px);
        height: 400px;
        margin: 0 auto;
        overflow: hidden;
      }
    }
  }
  .apps-links {
    background-color: ${COLORS.getBlueColor(0.03)};
    position: relative;
    height: 250px;
    border-radius: 8px;
    padding-left: 450px;
    padding-top: 30px;
    text-align: left;

    .links {
      margin-top: 34px;
      display: flex;
      & a:first-child {
        margin-right: 6px;
      }
    }

    img {
      top: -30px;
      left: 0;
      position: absolute;
      max-width: 370px;
    }

    .title {
      text-align: left;
      margin-bottom: 12px;
      max-width: 250px;
      font-family: FuturaNewBold-Reg;
      font-size: 22px;
      line-height: 1;
      letter-spacing: -0.3px;
      color: ${COLORS.darkBlue};
    }

    .sub-title {
      opacity: 0.54;
      max-width: 260px;
      font-family: FuturaNewBook-Reg;
      font-size: 18px;
      line-height: 1.33;
      color: ${COLORS.getBlueColor(0.54)};
    }
    .download {
      cursor: pointer;
      width: 150px;
      height: 48px;
      border-radius: 6px;
      background-color: #ffffff;
      border: solid 1.6px rgba(37, 60, 94, 0.12);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-top: 15px;

      img {
        width: 19px;
        height: 23px;
        object-fit: contain;
        opacity: 0.87;
        margin-left: 12px;
        margin-right: 12px;
      }

      svg {
        width: 19px;
        height: 23px;
        margin-right: 12px;
        margin-left: 12px;

        path {
          fill: #253c5e;
        }
      }

      &:hover {
        background-color: #253c5e;

        .download-text {
          .top,
          .bottom {
            color: white;
            opacity: 0.87;
          }
        }

        svg {
          path {
            fill: white;
            opacity: 0.87;
          }
        }
      }
      .download-text {
        .top {
          opacity: 0.53;
          font-family: Roboto-Medium;
          font-size: 8px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.83;
          letter-spacing: 0.4px;
          color: #253c5e;
          text-transform: uppercase;
          margin-bottom: -4px;
        }

        .bottom {
          font-family: Roboto-Medium;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #253c5e;
        }
      }
    }
  }
  .brands {
    margin-top: 81px;
    margin-bottom: 80px;
    .title {
      font-size: 32px;
      line-height: 1.06;
      letter-spacing: -1px;
      max-width: 338px;
      margin: 0 auto 40px;
    }
    .img {
      margin-bottom: 60px;
      display: inline-block;
      height: 105px;
      width: 255px;
      background-repeat: no-repeat;
      background-size: 100%;
      &:hover {
        border-radius: 8px;
      }
    }
  }
`

export { MainBox }
