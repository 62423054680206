import { get } from 'lodash'
import { push } from 'connected-react-router'
import queryString from 'query-string'

import { store } from '@redux/store'
import { ROLES } from '@constants/roles'

const isDesigner = () => get(store.getState(), 'user.userData.role', null) === ROLES.designer
const isClient = () => get(store.getState(), 'user.userData.role', null) === ROLES.client
const activeProject = () => get(store.getState(), 'project.activeProjectId', null)

const setLocationSearch = (name, value, path) => {
  if (path) {
    const params = {
      [name]: value
    }

    if (!value) {
      delete params[name]
    }

    store.dispatch(push({ pathname: path, search: queryString.stringify(params) }))

    return
  }

  const params = queryString.parse(get(store.getState(), 'router.location.search', ''))

  params[name] = value

  if (!value) {
    delete params[name]
  }

  store.dispatch(push({ search: queryString.stringify(params) }))
}

export {
  isDesigner, activeProject, isClient, setLocationSearch
}
