import React from 'react'
import styled from 'styled-components'
import { COLORS } from '@constants/colors'
import { Icon } from '@ui/atoms'

import fLogo from '@assets/images/icons/f-logo.png'

const FooterBox = styled.div`
  background-color: rgba(37, 60, 94, 0.05);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  margin-top: 20px;

  .column-2 {
    display: none;
  }

  .column-3 {
    display: none;
  }

  .top-row-wrapper {
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    box-shadow: inset 0 -1px 0 0 rgba(37, 60, 94, 0.12);

    .f-logo-mobile {
      img {
        margin-top: 18px;
        height: 67px;
        margin-left: 15px;
      }
    }
  }

  .top-row {
    display: none;
  }

  .bottom-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 38px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .navigation {
    display: flex;

    .link {
      display: block;
      padding: 25px 0;
      margin-right: 32px;
      color: ${COLORS.darkBlue};
      opacity: 0.87;
      font-family: Roboto-Regular;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        color: #ec431c;
      }
    }
  }

  .link {
    font-family: Roboto-Regular;
    font-size: 14px;
    line-height: 28px;
    color: ${COLORS.darkBlue};
    opacity: 0.7;
    display: block;
    text-decoration: none;

    &:hover {
      color: #ec431c;
    }
  }

  .title {
    font-family: Roboto-Medium;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.7px;
    color: ${COLORS.darkBlue};
    opacity: 0.38;

    &.hidden {
      visibility: hidden;
    }
  }

  .phone {
    color: ${COLORS.darkBlue};
    opacity: 0.87;
    font-family: Roboto-Regular;
    font-size: 14px;
    line-height: 28px;
  }

  .email {
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 22px;
    color: ${COLORS.darkBlue};
    opacity: 0.7;
    display: block;
  }

  .inn {
    font-family: Roboto-Regular;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    opacity: 0.54;
    color: ${COLORS.darkBlue};
    margin-top: 10px;
  }

  .address {
    color: ${COLORS.darkBlue};
    opacity: 0.54;
    font-size: 10px;
    line-height: 20px;
    font-family: Roboto-Regular;
    margin-top: 17px;
  }

  .social-row {
    width: 100%;
    height: 48px;
    background-color: rgba(37, 60, 94, 0.05);
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .socials {
      margin-top: -9px;
      display: flex;
      align-items: center;

      a {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        display: inline-block;
        margin-top: 10px;

        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }

      .icon {
        opacity: 0.54;
        width: 24px;
        height: 24px;
      }
    }

    .copyright {
      opacity: 0.38;
      font-family: Roboto-Regular;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }
  }

  .download {
    cursor: pointer;
    width: 150px;
    height: 48px;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1.6px rgba(37, 60, 94, 0.12);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 10px;

    img {
      width: 19px;
      height: 23px;
      object-fit: contain;
      opacity: 0.87;
      margin-left: 12px;
      margin-right: 12px;
    }

    svg {
      width: 19px;
      height: 23px;
      margin-right: 12px;
      margin-left: 12px;

      path {
        fill: ${COLORS.darkBlue};
      }
    }

    &:hover {
      background-color: ${COLORS.darkBlue};

      .download-text {
        .top,
        .bottom {
          color: white;
          opacity: 0.87;
        }
      }

      svg {
        path {
          fill: white;
          opacity: 0.87;
        }
      }
    }

    .download-text {
      .top {
        opacity: 0.53;
        font-family: Roboto-Medium;
        font-size: 8px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.83;
        letter-spacing: 0.4px;
        color: ${COLORS.darkBlue};
        text-transform: uppercase;
        margin-bottom: -7px;
      }

      .bottom {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: ${COLORS.darkBlue};
      }
    }
  }
`

const MainFooterMobile = () => (
  <FooterBox>
    <div className="top-row-wrapper">
      <a className="f-logo-mobile" href="/">
        <img src={fLogo} alt="logo" />
      </a>

      <div className="top-row">
        <div className="navigation">
          <a className="f-logo" href="/">
            <img src="src/images/f-logo.png" alt="logo" />
          </a>

          <a href="#" className="link">
            О Faradise
          </a>
          <a href="#" className="link">
            Как это работает?
          </a>
          <a href="#" className="link">
            Наши дизайнеры
          </a>
          <a href="#" className="link">
            Тарифы
          </a>
          <a href="#" className="link">
            Отзывы
          </a>
        </div>
      </div>
    </div>
    <div className="bottom-row">
      <div className="column-1">
        <div className="title">
КОНТАКТЫ
        </div>
        <a className="phone" href="tel:+74951327462">
          +7 (495) 132 - 74 - 62
        </a>
        <a className="email" href="mailto:privet@faradise.ru">
          privet@faradise.ru
        </a>
        <div className="address">
          ООО «Центр технологий и медиа групп»,
          {' '}
          <br />
          г.Москва,
          <br />
          ул. Ленинская Слобода, д.19, к.1, с.2
        </div>
        <div className="inn">
ИНН 7718299492, ОГРН 1167746194626
        </div>
      </div>
      <div className="column-2">
        <div className="title">
ИНФОРМАЦИЯ
        </div>
        <a href="#" className="link">
          Уход за мебелью
        </a>
        <a href="#" className="link">
          Как оплатить
        </a>
        <a href="#" className="link">
          Доставка
        </a>
        <a href="#" className="link">
          Условия возврата
        </a>
      </div>
      <div className="column-3">
        <div className="title hidden">
ИНФОРМАЦИЯ
        </div>
        <a href="#" className="link">
          Для поставщиков
        </a>
        <a href="#" className="link">
          Для дизайнеров
        </a>
        <a href="#" className="link">
          Политика конфиденциальности
        </a>
        <a href="#" className="link">
          Договор оферты
        </a>
      </div>
      <div className="column-4">
        <div className="title">
НАШЕ ПРИЛОЖЕНИЕ
        </div>
        <a className="download" href="https://itunes.apple.com/ru/app/faradise/id1315339948?mt=8" target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="39" viewBox="0 0 32 39">
            <path
              fillRule="nonzero"
              // eslint-disable-next-line
              d="M28.665 33.487c-1.576 2.313-3.248 4.57-5.793 4.607-2.546.056-3.363-1.473-6.25-1.473-2.906 0-3.799 1.436-6.211 1.53-2.489.093-4.37-2.463-5.965-4.72-3.248-4.607-5.736-13.095-2.393-18.803 1.653-2.835 4.616-4.626 7.826-4.682 2.431-.037 4.749 1.623 6.25 1.623 1.481 0 4.292-1.996 7.237-1.697 1.234.056 4.691.485 6.914 3.693-.171.112-4.122 2.388-4.084 7.107.057 5.634 5.034 7.518 5.09 7.536-.056.13-.797 2.686-2.62 5.28zM17.82 3.642C19.206 2.093 21.504.918 23.404.844c.246 2.182-.646 4.383-1.976 5.95-1.31 1.586-3.476 2.817-5.604 2.649-.284-2.145.78-4.384 1.995-5.801z"
              opacity=".868"
            />
          </svg>
          <div className="download-text">
            <div className="top">
Загрузите в
            </div>
            <div className="bottom">
App Store
            </div>
          </div>
        </a>

        <a
          className="download"
          href="https://play.google.com/store/apps/details?id=com.awespace.faradise"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 34 37">
            <path
              fill="#FFF"
              fillRule="nonzero"
              // eslint-disable-next-line
              d="M.57 33.62V2.66C.57 1.584 1.195.637 2.113.2l18.1 17.94-18.1 17.938A2.744 2.744 0 0 1 .57 33.62zm25.376-9.798L6.174 35.15l15.6-15.462 4.172 4.134zm6.156-7.85c.625.492 1.084 1.257 1.084 2.168 0 .91-.404 1.639-1.047 2.149l-4.208 2.404-4.594-4.553 4.594-4.553 4.17 2.385zM6.174 1.13l19.772 11.328-4.171 4.135-15.6-15.463z"
              opacity=".869"
            />
          </svg>
          <div className="download-text">
            <div className="top">
Доступно в
            </div>
            <div className="bottom">
Google Play
            </div>
          </div>
        </a>
      </div>
    </div>
    <div className="social-row">
      <div className="socials">
        <a href="https://www.facebook.com/faradiseapp/" target="_blank" rel="noopener noreferrer">
          <Icon icon="facebook" className="icon" />
        </a>
        <a
          href="https://instagram.com/faradiseapp?utm_source=ig_profile_share&igshid=17zh4y2ai2lx"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="instagram" className="icon" />
        </a>
      </div>
      <div className="copyright">
© 2017-2018. Все права защищены
      </div>
    </div>
  </FooterBox>
)

export { MainFooterMobile }
