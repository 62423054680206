import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';

import { Logo, Input, Button } from '@ui/atoms'
import leftImage from '@assets/images/left-image.jpg'
import { MainFooter } from '@ui/organisms/mainFooter'
import { MainFooterMobile } from '@ui/organisms/mainFooter.mobile'
import UserService from '@services/UserService'
import { CodeInput } from '@ui/molecules'
import { ROUTES } from '@constants';
import { RESPONSIVE } from '@constants/responsive'
import MediaQuery from 'react-responsive'
import { LoginBox, ImageBox } from './login.style'


const mapDispatchToProps = dispatch => ({
  login: data => dispatch({ type: 'LOGIN_REQUEST', payload: data })
})

@withRouter
@connect(
  null,
  mapDispatchToProps
)
class Login extends Component {
  state = {
    step: 1,
    code: '',
    phone: '',
    isCodeWrong: false,
    phoneError: ''
  }

  requestCode = async () => {
    try {
      const { phone } = this.state

      const response = await UserService.checkPhone(phone)

      if (response.result === false) {
        this.setState({ phoneError: 'Введенный номер телефона не зарегистрирован' })

        return
      }

      await UserService.requestCode(phone)

      this.setState({ step: 2 })
    } catch (error) {
      console.error(error)
    }
  }

  login = async () => {
    try {
      const { phone, code } = this.state
      const { login } = this.props

      const loginResponse = await UserService.login({ phone, code })

      const { token, user } = loginResponse.result

      localStorage.setItem('token', token)
      localStorage.setItem('userData', JSON.stringify(user))

      login(user)
    } catch (error) {
      if (error === 'invalid_code') {
        this.setState({ isCodeWrong: true })

        return
      }

      console.error(error)
    }
  }

  render() {
    const {
      step, phone, code, isCodeWrong, phoneError
    } = this.state

    const { history } = this.props

    return (
      <LoginBox>
        <ImageBox src={leftImage}>
          <Logo className="logo" />
        </ImageBox>
        <div className="content">
          <div className="page-title">
            Привет!
            {' '}
            <span role="img" aria-label="hi">
              🖐
            </span>
            <br />
            Мы рады вас видеть
          </div>

          <div className="description">
            Если вы уже зарегистрированны, то для того чтобы войти напишите свой номер телефона, на который мы пришлём код подтверждения.
          </div>

          {step === 1 && (
            <React.Fragment>
              <div className="input-title">
Введите ваш номер телефона
              </div>
              <Input
                size="big"
                mask="+7 (\999) 999 - 99 - 99"
                maskChar="_"
                value={phone}
                onChange={e => this.setState({ phone: e.target.value.replace(/\D+/g, '') })}
                error={phoneError}
              />

              <div className="buttons-wrapper">
                <Button text="Выслать проверочный код" onSave={this.requestCode} disabled={phone.length < 11} />
              </div>

              <div className="new-project">
                Вы впервые?
                {' '}

                <span className="link" onClick={() => history.replace(ROUTES.firstProject)}>
Начните проект прямо сейчас!
                </span>
              </div>
            </React.Fragment>
          )}
          {step === 2 && (
            <React.Fragment>
              <div className="page-small-title">
Введите проверочный код из SMS
              </div>
              <div className="code-box">
                <CodeInput onFilled={value => this.setState({ code: value })} error={isCodeWrong} resendCode={this.requestCode} />
              </div>
              <div className="buttons-wrapper">
                <Button text="Подтвердить и продолжить" onSave={this.login} disabled={code.length < 4} />
                <Button text="Другой номер" type="text" className="orange-button" onClick={() => this.setState({ step: 1 })} />
              </div>
            </React.Fragment>
          )}

        </div>
        <MediaQuery maxWidth={RESPONSIVE.MOBILE}>
          <MainFooterMobile className="login-footer" />
        </MediaQuery>
        <MediaQuery minWidth={RESPONSIVE.MOBILE}>
          <MainFooter className="login-footer" />
        </MediaQuery>
      </LoginBox>
    )
  }
}

export { Login }
