import React from 'react'
import { renderRoutes } from 'react-router-config'

import { Main } from '@pages/Main'
import { CreateNewProject } from '@pages/client/CreateNewProject'
import { Page404 } from '@pages/404'
import { Agreement } from '@pages/other/аgreement'
import { Rates } from '@pages/other/partner/rates'
import { Instruction } from '@pages/other/partner/instruction'
import { HowToPay } from '@pages/other/HowToPay'
import { RatesDesign } from '@pages/other/client/ratesDesign'
import { Offer } from '@pages/other/partner/offer'
import { OfferDesign } from '@pages/other/client/offerDesign'
import { OfferMarketplace } from '@pages/other/client/OfferMarketplace'
import { Delivery } from '@pages/other/Delivery'
import { Return } from '@pages/other/Return'
import { AllDocs } from '@pages/other/AllDocs'
import { Suppliers } from '@pages/other/Suppliers'
import { Hero } from '@pages/other/hero'
import { Care } from '@pages/other/care'
import { JustWork } from '@pages/other/JustWork'

const MainForDesignerFirstProject = () => <Main isDesignerFirstProject />
const MainForClientFirstProject = () => <Main isClientFirstProject />

const routes = [
  {
    path: '/design/designer_registration',
    component: MainForDesignerFirstProject,
    exact: true
  },
  {
    path: '/design/first_project',
    component: MainForClientFirstProject,
    exact: true
  },
  {
    path: '/design/new_project',
    component: CreateNewProject,
    exact: true
  },
  {
    path: '/design',
    component: Main,
    exact: true
  },
  {
    path: '/design/marketplace',
    component: Main
  },
  {
    path: '/design/agreement',
    component: Agreement,
    exact: true
  },
  {
    path: '/design/rates_client_design',
    component: RatesDesign,
    exact: true
  },
  {
    path: '/design/rates',
    component: Rates,
    exact: true
  },
  {
    path: '/design/instruction',
    component: Instruction,
    exact: true
  },
  {
    path: '/design/offer',
    component: Offer,
    exact: true
  },
  {
    path: '/design/offer_client_design',
    component: OfferDesign,
    exact: true
  },
  {
    path: '/design/offer_client_order',
    component: OfferMarketplace,
    exact: true
  },
  {
    path: '/design/how_to_pay',
    component: HowToPay,
    exact: true
  },
  {
    path: '/design/delivery',
    component: Delivery,
    exact: true
  },
  {
    path: '/design/return',
    component: Return,
    exact: true
  },
  {
    path: '/design/allDocs',
    component: AllDocs,
    exact: true
  },
  {
    path: '/design/suppliers',
    component: Suppliers,
    exact: true
  },
  {
    path: '/design/hero',
    component: Hero,
    exact: true
  }, {
    path: '/design/care',
    component: Care,
    exact: true
  },
  {
    path: '/design/just_work',
    component: JustWork,
    exact: true
  },
  { component: Page404 }
]

export const rootRoutes = () => (
  <React.Fragment>
    {renderRoutes(routes)}
  </React.Fragment>
)
