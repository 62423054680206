import { API } from '@constants/api'
import { HttpService } from '@services/HttpsService'

class DesignerService extends HttpService {
  createDesigner = () => {
    const url = API.AUTHENTICATION.createDesigner

    return this.post(url)
  }

  setBasicInfo = data => this.post(API.DESIGNER.setBasicInfo, data)

  getBasicInfo = () => this.get(API.DESIGNER.basicInfo)

  addAvatar = file => this.post(API.DESIGNER.addAvatar, { Photo: file }, 'file')

  setTestLink = data => this.post(API.DESIGNER.setTestLink, data)

  createPortfolio = () => this.get(API.DESIGNER.createPortfolio)

  removePortfolio = data => this.post(API.DESIGNER.removePortfolio, data)

  setPortfolioDescription = ({ name, description, id }) => {
    const data = { name, description, id }
    this.post(API.DESIGNER.setPortfolioDescription, data)
  }

  addPortfolioPhoto = ({ id, photo }) => this.post(
    API.DESIGNER.addPortfolioPhoto,
    {
      PortfolioId: id,
      Photo: photo
    },
    'file'
  )

  getQuestions = () => this.get(API.DESIGNER.getQuestions)

  setPossibilities = data => this.post(API.DESIGNER.setPossibilities, data)

  setTestResults = questions => this.post(API.DESIGNER.setTestResults, { questions })

  setLegal = data => this.post(API.DESIGNER.setLegal, data)

  getFullInfo = () => this.get(API.DESIGNER.getFullInfo)

  removePortfolioPhoto = data => this.post(API.DESIGNER.removePortfolioPhoto, data)
}

const designerService = new DesignerService()

export { designerService as DesignerService }
export default designerService
