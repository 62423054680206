export const COLORS = {
  orange: '#ef4e22',
  skyBlue: '#6793c7',
  darkBlue: '#253c5e',
  checkBoxChecked: '#35577a',
  grey: '#b4b4be',
  textDark: '#46485c',
  text: '#2d577d',
  textLight: 'rgba(45, 87, 125, 0.4)',
  textGrey: '#a4a5bd',
  lineLight: '#ededf2',
  borderLight: 'rgba(45, 87, 125, 0.12)',
  backgroundLight: '#d8d8d8',
  backgroundSearch: '#f9fafb',
  getBlueColor: (opacity = 1) => `rgba(37, 60, 94, ${opacity})`,
  getWhiteColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  getOrangeColor: (opacity = 1) => `rgba(239, 78, 34, ${opacity})`,
  white: '#FFF',
  transparent: 'transparent',
  green: '#43a047',
  lightGrey: '#e4e7eb'
}
