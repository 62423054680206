import React, { Component } from 'react'
import { get } from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import Loadable from 'react-loadable'

import { Steps } from '@ui/organisms/steps'
import { MainHeader } from '@ui/organisms/mainHeader'
import { MainFooter } from '@ui/organisms/mainFooter'
import { ProjectService } from '@services/ProjectService'
import { setActiveStage } from '@redux/actions/project'
import { RESPONSIVE } from '@constants/responsive'
import { Stub } from '@ui/organisms/stub'
import { Preloader } from '@ui/atoms'

const HelloPage = Loadable({
  loader: () => import('@pages/client/ClientStageTwo/Hello'),
  loading: Preloader
})
const MeasurementPage = Loadable({
  loader: () => import('@pages/client/ClientStageTwo/Measurement'),
  loading: Preloader
})
const Style = Loadable({
  loader: () => import('@pages/client/ClientStageTwo/Style'),
  loading: Preloader
})
const Budget = Loadable({
  loader: () => import('@pages/client/ClientStageTwo/Budget'),
  loading: Preloader
})
const OtherInfoPage = Loadable({
  loader: () => import('@pages/client/ClientStageTwo/OtherInfo'),
  loading: Preloader
})
const WhatLeftPage = Loadable({
  loader: () => import('@pages/client/ClientStageTwo/WhatLeft'),
  loading: Preloader
})

const Chat = Loadable({
  loader: () => import('@ui/organisms/chat'),
  loading: () => null
})

const steps = [
  {
    name: 'Знакомство',
    stepForStepsNumber: 1,
    disableReturn: true,
    renderComponent: ({ state, ...rest }) => <HelloPage stepState={state} {...rest} />
  },
  {
    name: 'Фото и размеры',
    stepForStepsNumber: 2,
    renderComponent: ({ state, ...rest }) => <MeasurementPage stepState={state} {...rest} />
  },
  {
    name: 'Стиль',
    stepForStepsNumber: 3,
    renderComponent: ({ state, ...rest }) => <Style stepState={state} {...rest} />
  },
  {
    name: 'Бюджет',
    stepForStepsNumber: 4,
    renderComponent: ({ state, ...rest }) => <Budget stepState={state} {...rest} />
  },
  {
    name: 'Доп. информация',
    stepForStepsNumber: 5,
    renderComponent: ({ state, ...rest }) => <OtherInfoPage stepState={state} {...rest} />
  },
  {
    name: 'Что оставить',
    stepForStepsNumber: 6,
    renderComponent: ({ state, ...rest }) => <WhatLeftPage stepState={state} {...rest} />
  }
]

const stepsForSteps = steps.filter(step => step.visible !== false)

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId,
  projectsList: state.project.list
})

const mapDispatchToProps = dispatch => ({
  setActiveStage: stage => dispatch(setActiveStage(stage))
})

const STAGES = {
  Meeting: 0,
  PhotoAndProportions: 1,
  Style: 2,
  Budget: 3,
  AdditionalInfo: 4,
  WhatToSave: 5
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ClientStageTwo extends Component {
  state = {
    stepsData: steps.reduce((res, el, index) => ({ ...res, [index]: {} }), {}),
    currentStage: {
      stage: 0,
      clientIsReady: false
    },
    currentStep: 0,
    activeStage: 0,
    loadingData: true,
  }

  interval = null

  componentDidMount() {
    this.updateStage().then(() => this.setState({ loadingData: false }))

    // this.interval = setInterval(this.updateStage, 1000 * 60)
  }

  componentDidUpdate(prevProps) {
    const { activeProject } = this.props

    if (activeProject !== prevProps.activeProject) {
      this.updateStage(true)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateStage = async (forceChangeStep = false) => {
    const { activeProject } = this.props
    const { activeStage } = this.state

    try {
      const response = await ProjectService.getCurrentStage(activeProject)
      const stage = STAGES[get(response, 'result.stage', 0)]

      this.setState(prevState => ({
        currentStage: {
          stage,
          clientIsReady: response.result.clientIsReady
        },
        currentStep: stage > activeStage || forceChangeStep ? stage : prevState.currentStep
      }))

      if (stage > activeStage || forceChangeStep) {
        this.setState({ activeStage: stage })
      }
    } catch (e) {
      console.log(e)
    }
  }

  goNext = async (update = false) => {
    if (update) {
      this.updateStage()
    } else {
      this.setState(state => ({ currentStep: state.currentStep + 1 }))
    }

    window.scroll(window, 0)
  }

  goPrev = () => {
    this.setState(state => ({ currentStep: state.currentStep - 1 }))

    window.scroll(window, 0)
  }

  goTo = (step) => {
    this.setState({ currentStep: step })

    window.scroll(window, 0)
  }

  render() {
    const {
      stepsData, currentStage, currentStep, activeStage, loadingData
    } = this.state
    const { activeProject, setActiveStage, projectsList } = this.props

    return (
      <div className="page_container">
        <MediaQuery minWidth={RESPONSIVE.TABLET}>
          <MainHeader activeTab="projects">
            {!!activeProject && (
            <Steps
              steps={stepsForSteps}
              currentStep={steps[currentStep].stepForStepsNumber}
              maxStep={6}
              className="main-steps"
              onSelect={this.goTo}
              activeStage={activeStage}
              nextStage={{
                name: 'Стадия 2',
                onClick: () => setActiveStage('Development'),
                active: projectsList.find(p => p.id === activeProject).stage === 'Development'
              }}
            />
            )}
          </MainHeader>
          {activeProject && <Chat activeProjectId={activeProject} />}
          <div className="page_content">
            {!loadingData && (
              <React.Fragment key={activeProject}>
                {steps[currentStep].renderComponent({
                  goNext: this.goNext,
                  state: stepsData[currentStep + 1],
                  goPrev: this.goPrev,
                  stage: currentStage,
                  step: currentStep,
                  updateStage: this.updateStage,
                })}
              </React.Fragment>
            )}
          </div>

          <MainFooter showBeta />
        </MediaQuery>
        <MediaQuery maxWidth={RESPONSIVE.TABLET}>
          <Stub />
        </MediaQuery>
      </div>
    )
  }
}

export { ClientStageTwo }
