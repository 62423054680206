import React, { Component } from 'react';
import styled from 'styled-components';
import { COLORS } from '@constants/colors';

const TimerBox = styled.div`
  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
  }
   
  .time {
    font-family: Roboto-Regular;
    font-size: 48px;
    color: ${COLORS.getBlueColor()};
    
    & [role="img"] {
      margin-left: 8px;
      font-size: 40px;
    }
  }
`;

const formatUnitOfTime = unitOfTime => (
  unitOfTime < 10 ? `0${unitOfTime}`.substring(0, 2) : unitOfTime.toString()
    .substring(0, 2)
);

const getTime = (timeInMilliseconds) => {
  let time = timeInMilliseconds;

  const hours = formatUnitOfTime(Math.floor(time / (60 * 60 * 1000)));

  time %= (60 * 60 * 1000);
  const minutes = formatUnitOfTime(Math.floor(time / (60 * 1000)));

  time %= (60 * 1000);
  const seconds = formatUnitOfTime(Math.floor(time / 1000));

  return `${hours}:${minutes}:${seconds}`;
};

class Timer extends Component {
  state = {
    time: 86400 * 1000,
  };

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startTimer = () => {
    const { time } = this.state;

    this.interval = setInterval(() => {
      if (time !== 0) {
        this.setState(prevState => ({ time: prevState.time - 10 }));
      } else {
        this.setState(() => ({ seconds: 0, time: 0 }));

        clearInterval(this.interval);
      }
    }, 10);
  };

  render() {
    const { time } = this.state;

    return (
      <TimerBox>
        <div className="title">
          Осталось, примерно
        </div>
        <div className="time">
          {getTime(time)}
          <span role="img" aria-label="time">
           🕒
          </span>
        </div>
      </TimerBox>
    );
  }
}

export default Timer;
export { Timer }
