import styled from 'styled-components'
import { COLORS, RESPONSIVE } from '@constants'

const CheckBoxStyle = styled.div`
  &:hover {
    border-radius: 8px;
    background-color: ${COLORS.getBlueColor(0.05)};

    .checkbox-input {
      background-color: #f5f6f7;
    }
  }
  position: relative;
  padding-left: 16px;
  margin-bottom: 8px;
  border: 1px solid transparent;

  .control span {
    color: ${COLORS.getBlueColor(0.38)};
  }

  &.checked {
    span {
      font-family: Roboto-Medium;
      font-weight: 500;
      color: ${COLORS.getBlueColor()};
    }

    .control span {
      color: ${COLORS.getBlueColor()};
    }
  }

  span {
    font-size: 16px;
    font-family: Roboto-Regular;
    color: ${COLORS.getBlueColor()};
  }

  .line {
    margin-bottom: 10px;
    height: 1px;
    border-top: 1px solid ${COLORS.getBlueColor()};
    margin-left: 34px;
  }

  & .checkbox-input {
    border: none;
    width: 90%;
    margin: 0 0 0 34px;
    display: block;
    min-width: auto;
    font-size: 16px;
    line-height: 28px;
    font-family: Roboto-Regular;
    color: ${COLORS.getBlueColor()};
    outline: none;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .suffix {
    font-size: 14px;
    color: ${COLORS.darkBlue};
    opacity: 0.5;
    position: absolute;
    right: 5px;
    top: 14px;
  }
  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    .checkbox-input{
      font-size: 14px;
    }
  }
  
`

export { CheckBoxStyle }
