import React, { Component } from 'react'

import { Button, Input } from '@ui/atoms'
import UserService from '@services/UserService'
import { DesignerSearchBox } from '@pages/client/FirstUserSteps/DesignerSearch/designerSearch.style'

class DesignerSearch extends Component {
  state = {
    email: ''
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  goNext = () => {
    const { goNext } = this.props

    goNext(this.state)
  }

  notify = async () => {
    const { email } = this.state

    try {
      await UserService.setEmail(email)

      this.goNext()
    } catch (e) {
      console.log(e)
    }
  }

  isValidEmail = () => {
    /* eslint-disable */
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /* eslint-enable */
    const { email } = this.state

    return emailRegEx.test(email.toLowerCase())
  }

  render() {
    const { email } = this.state
    const { step } = this.props

    return (
      <DesignerSearchBox>
        <div className="text">
          Мы уже ищем для вас лучшего дизайнера. Это может занять до 24 часов, но как правило меньше. Мы оповестим вас о результатах по SMS
          и напишем вам письмо на Email.
        </div>
        {step === 1 && (
          <React.Fragment>
            <Input
              className="input-field"
              placeholder="Введите ваш Email"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
            />

            <Button text="Оповестить в письме" className="next-button" onSave={this.notify} disabled={!this.isValidEmail()} />
            <Button text="Оповестить в письме" className="next-button-mobile mt" onSave={this.notify} disabled={!this.isValidEmail()} />
          </React.Fragment>
        )}

        {step === 2 && (
          <React.Fragment>
            <div className="text small">
              Мы хотим подобрать вам дизайнера, который будет отвечать всем вашим запросам. Пройдите короткий опрос - это займет меньше
              минуты!
            </div>

            <Button text="Пройти опрос" className="next-button" onSave={this.goNext} />
            <Button text="Пройти опрос" className="next-button-mobile mt" onSave={this.goNext} />
          </React.Fragment>
        )}

        {step === 3 && (
          <React.Fragment>
            <div className="thanks">
              <i className="material-icons">
check_circle_outline
              </i>
              <span>
Спасибо что уделили время на опрос. Это поможет лучше нам понять вашу задумку и найти подходящего дизайнера.
              </span>
            </div>
          </React.Fragment>
        )}

        {step === 1 && (
          <footer className="page-footer">
            <Button text="Пропустить" type="text" className="next-button" onClick={this.goNext} />
            <Button type="bordered" text="Пропустить шаг" className="next-button-mobile" onClick={this.goNext} />
          </footer>
        )}
      </DesignerSearchBox>
    )
  }
}

export { DesignerSearch }
