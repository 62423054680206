import React, { Component } from 'react'
import classnames from 'classnames'

import { PaginationBox } from './pagination.style'

// eslint-disable-next-line
class Pagination extends Component {
  render() {
    const { onChangePage, perPage, total } = this.props
    let { currentPage } = this.props

    currentPage = Number(currentPage)

    const pagesCount = Math.ceil(total / perPage)

    let numbersToDisplay = []

    if (currentPage < 4 && pagesCount > 4) {
      numbersToDisplay = [1, 2, 3, 4]
    }

    if (currentPage <= 4 && pagesCount >= 4) {
      numbersToDisplay = Array(4)
        .fill('')
        .map((elem, index) => index + 1)
    }

    if (currentPage < 4 && pagesCount < 4) {
      numbersToDisplay = Array(pagesCount)
        .fill('')
        .map((elem, index) => index + 1)
    }

    if (currentPage >= 4 && currentPage < pagesCount) {
      numbersToDisplay = Array(4)
        .fill('')
        .map((elem, index) => currentPage - 2 + index)
    }

    if (currentPage > 4 && currentPage === pagesCount) {
      numbersToDisplay = Array(4)
        .fill('')
        .map((elem, index) => currentPage - 3 + index)
    }

    if (perPage > total) {
      return <div />
    }

    return (
      <PaginationBox>
        <div
          className={classnames('navigation-button left', { disabled: currentPage === 1 })}
          onClick={() => onChangePage(currentPage - 1)}
        >
          <i className="material-icons">
chevron_left
          </i>
          <span>
Назад
          </span>
        </div>

        {numbersToDisplay.map(number => (
          <div className={classnames('page-number', { active: number === currentPage })} key={number} onClick={() => onChangePage(number)}>
            {number}
          </div>
        ))}

        <div
          className={classnames('navigation-button right', { disabled: currentPage === pagesCount })}
          onClick={() => onChangePage(currentPage + 1)}
        >
          <span>
Далее
          </span>
          <i className="material-icons">
chevron_right
          </i>
        </div>
      </PaginationBox>
    )
  }
}

export { Pagination }
