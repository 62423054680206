import queryString from 'query-string'

import { TOKEN_KEY } from '@constants/common'

const createFormData = (data) => {
  const formData = new FormData()

  Object.keys(data).forEach(key => formData.append(key, data[key]))

  return formData
}

class HttpService {
  headers = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json'
  }

  get(url, data, withToken = true) {
    let path = url

    if (data && Object.keys(data).length) {
      path += `?${queryString.stringify(data)}`
    }

    const config = {
      headers: {
        ...this.headers
      },
      method: 'get'
    }

    if (withToken) {
      config.headers.token = window.localStorage.getItem(TOKEN_KEY) || ''
    }

    return fetch(path, config)
      .then(response => response.json())
      .then((response) => {
        if (response.code === 'ok') {
          return response
        }

        throw response.reason || 'Unexpected error'
      })
  }

  post(url, data, type, withToken = true) {
    const path = url

    const config = {
      headers: {
        ...this.headers
      },
      method: 'post'
    }

    if (withToken) {
      config.headers.token = window.localStorage.getItem(TOKEN_KEY) || ''
    }

    if (type === 'file') {
      config.body = createFormData(data)
    } else {
      config.body = JSON.stringify(data)
      config.headers['Content-Type'] = 'application/json'
    }

    return fetch(path, config)
      .then(response => response.json())
      .then((response) => {
        if (response.code === 'ok') {
          return response
        }

        throw response.reason || 'Unexpected error'
      })
  }
}

export { HttpService }
