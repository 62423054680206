import React from 'react'

import { Page404 } from '@pages/404'
import { Products } from '@pages/Marketplace/Products'
import { Product } from '@pages/Marketplace/Product'

const routes = [
  {
    path: '/design/marketplace',
    component: Products,
    exact: true
  },
  {
    path: '/design/marketplace/product/:id',
    component: Product,
    exact: true
  },
  {
    component: () => <Page404 isInnerPage />
  }
]

export { routes }
