import React from 'react'
import { PropTypes } from 'prop-types'
import Slider from 'react-slick'
import styled from 'styled-components'
import { COLORS } from '@constants/colors'

import { Icon } from '@ui/atoms'
import { SlickStyleBox } from '../../assets/slick-style/index.style'

const PhotoCarouselBox = styled.div`
  margin-bottom: 80px;
  margin-top: 16px;

  .slick-dots li {
    & button:before {
      font-size: 24px;
    }
    margin-left: 0;
    margin-right: 0;
  }
  svg {
    opacity: 0.54;
    height: 24px;
    width: 20px;
    fill: #ffffff;
  }
  .slick-slide {
    opacity: 0.7;
    transition-property: opacity;
    transition-duration: 300ms;
    &.slick-current {
      opacity: 1;
    }
  }
  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;
    left: 163px;
    z-index: 9;
    background-color: ${COLORS.getBlueColor(0.54)};
    &:hover {
      background-color: ${COLORS.getBlueColor(0.8)};
    }
    & svg {
      margin-left: 14px;
      margin-top: 12px;
    }
  }
  .slick-next {
    left: 904px;
    
    & svg {
      margin-left: 17px;
      margin-top: 12px;
    }
  }
  .photoContainer {
    position: relative;
    i {
      cursor: pointer;
      position: absolute;
      color: white;
      border-radius: 4px;
      padding: 4px;
      right: 28px;
      bottom: 16px;
      background-color: ${COLORS.getBlueColor(0.38)};
      &:hover {
        background-color: ${COLORS.getBlueColor(0.8)};
      }
    }
  }
  
  .photo {
    height: 550px;
    width: 790px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    border-radius: 8px;
    margin-right: 15px;
    max-width: 790px;
  }
`

const PrevButton = ({ onClick }) => (
  <div onClick={onClick} className="slick-prev">
    <Icon icon="chevronLeft" />
  </div>
)

const NextButton = ({ onClick }) => (
  <div onClick={onClick} className="slick-next">
    <Icon icon="chevronRight" />
  </div>
)

const settings = {
  centerPadding: '20%',
  dots: true,
  speed: 250,
  slidesToShow: 1,
  infinite: false,
  variableWidth: true,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />
}

const PhotoCarousel = ({ photos, imgOnClick }) => (
  <SlickStyleBox>
    <PhotoCarouselBox>
      <Slider {...settings}>
        {photos.map((slide, index) => (
          <div className="photoContainer">
            <div className="photo" style={{ backgroundImage: `url(${slide.src})` }} />
            <i className="material-icons" onClick={() => imgOnClick(index)}>
              crop_free
            </i>
          </div>
        ))}
      </Slider>
    </PhotoCarouselBox>
  </SlickStyleBox>
)

PhotoCarousel.propTypes = {
  photos: PropTypes.array.isRequired,
  imgOnClick: PropTypes.func.isRequired
}

export { PhotoCarousel }
