import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import ProjectService from '@services/ProjectService'
import { RoomTabs } from '@ui/organisms/roomTabs'
import {
  Button, Icon, Textarea, WatchVideo
} from '@ui/atoms'
import { COLORS, ROUTES } from '@constants'
import { RoomItems } from '@pages/designer/roomProducts'
import { isEqual } from 'lodash'
import { FreelanceService } from '@services/FreelanceService'

const PageBox = styled.div`
  &.page_content {
    width: 790px;
  }

  .market-block {
    border-top: 1px solid ${COLORS.getBlueColor(0.12)};
    margin-top: 32px;
    padding-top: 32px;
  }

  .market-button {
    width: 100%;
    margin-top: 24px;
  }

  .buttons-row {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }

  .textarea {
    height: 300px;
    margin-top: 16px;
  }

  .files-block {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .file-input {
    display: none;
  }

  .files {
    display: flex;
    width: 456px;
    flex-flow: row wrap;
  }

  .file {
    width: 220px;
    border-radius: 6px;
    background-color: ${COLORS.getBlueColor(0.05)};
    padding: 10px 12px;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 20px;
    color: ${COLORS.getBlueColor(0.37)};
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .file-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    fill: ${COLORS.getBlueColor(0.54)};
  }

  .remove-file {
    transform: translateY(-50%) rotate(45deg);
    fill: ${COLORS.orange};
    position: absolute;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 50%;
  }

  .visual {
    margin-top: 32px;
  }

  .watch-video {
    margin-top: 20px;
  }
`

const statusMap = {
  Created: 0,
  Published: 1,
  Completed: 2
}

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@withRouter
@connect(mapStateToProps)
class Designer3DVisual extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: (() => {
        if (props.stage.stage > props.step) {
          return 2
        }

        return !props.stage.userMadeDecision || !props.stage.isPaid ? 1 : 2
      })(),
      rooms: [],
      allRooms: [],
      activeRoom: 0,
      allTZSent: false
    }
  }

  componentDidMount() {
    this.getStepInfo()
  }

  getStepInfo = async () => {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      const orderedRoomResponse = await ProjectService.getOrderedVisualizationRooms(activeProject)
      const orderedRooms = result.filter(room => orderedRoomResponse.result.includes(room.roomId))

      const visualizationsResponse = await Promise.all(orderedRooms.map(room => FreelanceService.getTechSpec(activeProject, room.roomId)))

      this.setState(
        {
          rooms: orderedRooms.map((room, index) => ({
            model: room.purpose,
            roomId: room.roomId,
            roomTZ: visualizationsResponse[index].result.description || '',
            roomTZFiles: visualizationsResponse[index].result.files.map(file => ({
              id: file.fileId,
              link: file.fileLink,
              name: file.fileName || 'Файл'
            })),
            disabled: visualizationsResponse[index].result.status !== statusMap.Created
          })),
          allRooms: result.map(room => ({
            model: room.purpose,
            roomId: room.roomId
          }))
        },
        async () => {
          const { rooms } = this.state
          const activeRoom = rooms.findIndex(r => !r.disabled)

          if (activeRoom === -1) {
            this.setState({ allTZSent: true })

            const roomVisualizationsResponse = await Promise.all(
              rooms.map(room => ProjectService.getRoomVisualization(activeProject, room.roomId))
            )

            const activeRoom = roomVisualizationsResponse.findIndex(res => !!res.result.visualizationCode)

            this.setState({
              rooms: rooms.map((room, index) => {
                const code = roomVisualizationsResponse[index].result.visualizationCode
                const visualization = typeof code === 'string' ? code.replace(new RegExp('“|”', 'g'), '"') : null

                return {
                  ...room,
                  visualization,
                  disabled: visualization === null,
                  activeRoom
                }
              })
            })
          } else {
            this.setState({ activeRoom })
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState(
        {
          step: (() => {
            if (nextProps.stage.stage > nextProps.step) {
              return 2
            }

            return !nextProps.stage.userMadeDecision || !nextProps.stage.isPaid ? 1 : 2
          })(),
          allTZSent: false
        },
        () => this.getStepInfo()
      )
    }
  }

  addFile = async (event) => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state
    const fileList = event.target.files
    const { roomId } = rooms[activeRoom]

    try {
      const uploadFileResponse = await FreelanceService.visualizationAddFile({
        ProjectId: activeProject,
        RoomId: roomId,
        File: fileList[0]
      })

      rooms[activeRoom].roomTZFiles.push({
        id: uploadFileResponse.result,
        name: fileList[0].name,
        link: URL.createObjectURL(fileList[0])
      })

      this.setState({ rooms })
    } catch (e) {
      console.log(e)
    }
  }

  removeFile = async (fileIndex) => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      await FreelanceService.visualizationRemoveFile({
        projectId: activeProject,
        roomId: rooms[activeRoom].roomId,
        fileId: rooms[activeRoom].roomTZFiles[fileIndex].id
      })

      rooms[activeRoom].roomTZFiles.splice(fileIndex, 1)

      this.setState({ rooms })
    } catch (e) {
      console.log(e)
    }
  }

  sendTechSpec = async () => {
    try {
      const { activeProject } = this.props
      const { activeRoom, rooms } = this.state

      await FreelanceService.setTechSpec({
        projectId: activeProject,
        roomId: rooms[activeRoom].roomId,
        description: rooms[activeRoom].roomTZ
      })
    } catch (e) {
      console.log(e)
    }
  }

  publishTechSpec = async () => {
    try {
      const { activeProject } = this.props
      const { rooms, activeRoom } = this.state

      await FreelanceService.setTechSpec({
        projectId: activeProject,
        roomId: rooms[activeRoom].roomId,
        description: rooms[activeRoom].roomTZ
      })

      await FreelanceService.publishTechSpec({
        projectId: activeProject,
        roomId: rooms[activeRoom].roomId
      })

      this.getStepInfo()
    } catch (e) {
      console.log(e)
    }
  }

  getIconByExtention = (fileName) => {
    const extention = fileName.split('.').pop()

    switch (extention) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <Icon icon="imageFile" className="file-icon" />
      default:
        return <Icon icon="imageFile" className="file-icon" />
    }
  }

  confirmStage = async () => {
    const { activeProject } = this.props

    await ProjectService.confirmDevelopmentStage(activeProject, 'Visualization')

    this.setState({ step: 1 })
  }

  renderFirstStep = () => {
    const { goPrev, updateStage } = this.props

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          Это окно ожидания. Клиент принимает решение относительно 3D-визуализация. Данный этап Клиент оплачивает отдельно.
          <br />
          <br />
          Если Клиент оплатит этот этап, вам необходимо будет написать Техническое задание нашему визуализатору. Если Клиент откажется от
          этого этапа, вы перейдете в следующий.
        </div>
        <WatchVideo link="https://youtu.be/LLe4YPONDAg" className="watch-video" />
        <div className="buttons-row">
          <Button type="text" text="Назад" onClick={goPrev} />
          <div>
            <Button text="Проверить" type="bordered" onClick={() => updateStage()} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { rooms, activeRoom, allTZSent } = this.state
    const {
      goPrev, updateStage, stage, step, goNext
    } = this.props

    if (!rooms.length) {
      return null
    }

    if (allTZSent) {
      if (rooms.findIndex(r => r.visualization !== null) === -1) {
        return (
          <React.Fragment>
            <div className="g_big-text mb32">
Это окно ожидания. Визуализатор выполняет свою работу. Это может занять до 10 дней.
            </div>
            <WatchVideo link="https://youtu.be/LLe4YPONDAg" className="watch-video" />
            <div className="buttons-row">
              <Button type="text" text="Назад" onClick={goPrev} />
              <div>
                <Button text="Проверить" type="bordered" onClick={() => updateStage()} />
              </div>
            </div>
          </React.Fragment>
        )
      }

      return (
        <React.Fragment>
          <div className="g_big-text mb32">
3D-визуализация готова. Дождитесь обратной связи от Клиента.
          </div>
          <WatchVideo link="https://youtu.be/LLe4YPONDAg" className="watch-video" />
          <div className="g_small-title mt48 mb16">
Выберите комнату
          </div>
          <RoomTabs items={rooms} active={activeRoom} onChange={index => this.setState({ activeRoom: index })} />
          <div className="visual" dangerouslySetInnerHTML={{ __html: rooms[activeRoom].visualization }} />
          <div className="buttons-row">
            <Button type="text" text="Назад" onClick={goPrev} />
            <div>
              {stage.stage === step ? (
                <Button text="Проверить" type="bordered" onClick={() => updateStage()} />
              ) : (
                <Button text="Вперед" onClick={() => goNext()} />
              )}
            </div>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div className="g_big-text mb32">
          3D-визуализация квартиры дает возможность увидеть будущий интерьер, как фотореалистичную картинку. Напишите Техническое задание
          для визуализатора и прикрепите необходимые файлы.
        </div>
        <div className="g_small-title mt48 mb16">
Выберите комнату
        </div>
        <RoomTabs items={rooms} active={activeRoom} onChange={index => this.setState({ activeRoom: index })} />
        <div className="g_small-title mt48">
Напишите ТЗ для дизайнера который подготовит 3D-визуализацию
        </div>
        <div className="g_text">
Пишите грамотно и понятно. Используйте смайлики, наши визуализаторы это любят.
        </div>
        <Textarea
          className="textarea"
          value={rooms[activeRoom].roomTZ || ''}
          onChange={(e) => {
            const { value } = e.target

            rooms[activeRoom].roomTZ = value
            this.setState({ rooms })
          }}
        />
        <div className="files-block">
          <div className="files">
            {rooms[activeRoom].roomTZFiles.map((file, fileIndex) => (
              <div className="file" key={`file-${fileIndex}`}>
                {this.getIconByExtention(file.name)}
                {file.name}
                <Icon icon="add" onClick={() => this.removeFile(fileIndex)} className="remove-file" />
              </div>
            ))}
          </div>
          <div>
            <Button text="Прикрепить файл" type="filled" color="additional" onClick={() => this.fileInput.click()} />
            <input type="file" className="file-input" ref={ref => (this.fileInput = ref)} onChange={this.addFile} />
          </div>
        </div>
        <div className="buttons-row">
          <Button type="text" text="Назад" onClick={goPrev} />
          <div>
            <Button text="Сохранить прогресс" type="bordered" style={{ marginRight: 20 }} onClick={this.sendTechSpec} />
            <Button text="Отправить" disabled={!rooms[activeRoom].roomTZ} onClick={this.publishTechSpec} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { step, allRooms } = this.state
    const { history } = this.props

    return (
      <PageBox className="page_content">
        <div className="page-body">
          <div className="page-title">
3D-визуализация
          </div>
          {step === 1 && this.renderFirstStep()}
          {step === 2 && this.renderSecondStep()}
          <div className="market-block">
            <div className="g_big-text">
              Добавьте товары из нашего магазина к данному проекту. В дальнейшем, клиент сможет приобрести их у нас.
            </div>
            <div className="g_text">
Если нужного товара нет, сообщите нам об этом
            </div>
            <Button
              text="Перейти в магазин FARADISE"
              color="additional"
              type="filled"
              className="market-button"
              onClick={() => history.push(ROUTES.marketplace)}
            />
          </div>
          {!!allRooms.length && <RoomItems rooms={allRooms} />}
        </div>
      </PageBox>
    )
  }
}

export { Designer3DVisual }
