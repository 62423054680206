import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const FilterSelectBox = styled.div`
  width: 255px;
  position: relative;

  .toggle {
    height: 40px;
    position: relative;
    padding: 9px 16px;
    color: ${COLORS.darkBlue};
    display: flex;
    justify-content: space-between;
    font-family: Roboto-Regular;
    font-size: 14px;
    border-radius: 6px;
    background-color: white;
    border: solid 1px rgba(37, 60, 94, 0.54);
    cursor: pointer;

    .current-values {
      width: 207px;
      text-overflow: ellipsis;
      white-space: nowrap; /* Запрещаем перенос строк */
      overflow: hidden;
    }
  }

  .control-title {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .delete {
      opacity: 0.4;
      cursor: pointer;
      font-size: 20px;

      &:hover {
        opacity: 1;
        color: ${COLORS.orange};
      }
    }
  }

  .selected-items {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;

    .item {
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 7px 8px 7px 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      background-color: rgba(37, 60, 94, 0.03);
      align-items: center;
      font-family: Roboto-Medium;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: ${COLORS.getBlueColor(0.87)};

      .clear {
        margin-left: 8px;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }

  .dropdown {
    z-index: 1000;
    width: 255px;
    position: absolute;
    top: 72px;
    left: 0;
    padding: 10px 8px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(37, 60, 94, 0.24);
    background-color: #ffffff;
    border: solid 1px rgba(37, 60, 94, 0.12);
    overflow: auto;
    max-height: 350px;

    .checkboxes-wrapper {
      label {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        padding-left: 14px;

        .label-content {
          display: flex;
          align-items: center;

          .hex {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            display: inline-flex;
            margin-right: 7px;
          }

          .preview {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            display: inline-flex;
            margin-right: 7px;
          }
        }
      }
    }

    .search-wrapper {
      margin-top: -11px;
      width: 100%;
      position: relative;

      .search {
        outline: none;
        width: 100%;
        height: 40px;
        padding-left: 37px;
        box-shadow: 0 1px 0 0 rgba(37, 60, 94, 0.12);
        background-color: white;
        border: none;
      }

      .icon-search {
        position: absolute;
        top: 10px;
        left: 5px;
        color: ${COLORS.darkBlue};
        opacity: 0.5;
        font-size: 22px;
      }
    }

    .project-title {
      color: ${COLORS.orange};
      font-family: Roboto-Regular;
      font-size: 14px;
      padding-left: 9px;
    }

    .checkboxes-wrapper {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .control {
      width: 35px;
      height: 35px;
    }

    .checbox-label {
      font-size: 14px;
    }

    .room-info {
      .control {
        width: 35px !important;
        height: 35px !important;
      }

      .checkbox-container {
        padding-left: 12px;
      }

      .checkbox-label {
        font-size: 14px !important;
      }

      .suffix {
        top: 8px;
      }
    }
  }
`

export { FilterSelectBox }
