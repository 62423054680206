import React, { Component } from 'react'
import { pickBy } from 'lodash'
import { connect } from 'react-redux'

import { Button, Radio, Input } from '@ui/atoms'

import { QuestionsCarousel } from '@ui/organisms'
import ClientService from '@services/ClientService'
import { DesignerBox, CarouselBox } from './designer.style'

const mapStateToProps = state => ({
  activeProjectId: state.project.activeProjectId
})

@connect(mapStateToProps)
class Designer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sex: 'Unknown',
      age: [18, 90],
      personalMeeting: 'true',
      authorSupervision: 'true',
      questions: [],
      answers: [],
      ...props.stepState
    }
  }

  async componentDidMount() {
    try {
      const questionResponse = await ClientService.getQuestions()

      this.setState({
        questions: questionResponse.result,
        answers: questionResponse.result.reduce((res, question) => ({ ...res, [question.id]: null }), {})
      })
    } catch (e) {
      console.log(e)
    }
  }

  goNext = () => {
    const { goNext } = this.props

    goNext(this.state)
  }

  goPrev = () => {
    const { goPrev } = this.props

    goPrev(this.state)
  }

  onAgeIntervalChange = (values) => {
    this.setState({ age: values })
  }

  onAnswerChange = (name, value) => {
    this.setState(state => ({ answers: { ...state.answers, [name]: value } }))
  }

  render() {
    const {
      sex, age, personalMeeting, authorSupervision, answers, questions
    } = this.state
    const { activeProjectId } = this.props

    return (
      <React.Fragment>
        <DesignerBox>
          <div className="field-title">
Пол дизайнера
          </div>
          <Radio
            value={sex}
            onChange={e => this.setState({ sex: e.target.value })}
            items={[{ value: 'Unknown', label: 'Не важно' }, { value: 'Male', label: 'Мужчина' }, { value: 'Female', label: 'Женщина' }]}
          />

          <div className="field-title">
Укажите возрастной промежуток
          </div>
          <div>
            <Input
              className="age-input"
              size="big"
              value={String(age[0])}
              onChange={(e) => {
                const { value } = e.target

                this.setState(state => ({ age: [value, state.age[1]] }))
              }
              }
              mask="999"
            />
            <Input
              className="age-input"
              size="big"
              value={String(age[1])}
              onChange={(e) => {
                const { value } = e.target

                this.setState(state => ({ age: [state.age[0], value] }))
              }
              }
              mask="999"
            />
          </div>

          <div className="field-title">
Понадобится ли личная встреча с дизайнером?
          </div>
          <Radio
            value={personalMeeting}
            onChange={e => this.setState({ personalMeeting: e.target.value })}
            items={[{ value: 'true', label: 'Да, конечно' }, { value: 'false', label: 'Нет, не понадобится' }]}
          />

          <div className="field-title">
Нужет будет авторский надзор во время ремонта?*
          </div>
          <Radio
            value={authorSupervision}
            onChange={e => this.setState({ authorSupervision: e.target.value })}
            items={[{ value: 'true', label: 'Да, это важно' }, { value: 'false', label: 'Нет, не нужен' }]}
          />

          <div className="foot-note">
          Авторский надзор – это когда дизайнер интерьера следит за качеством выполняемых работ ремонтной бригадой.
          Авторский надзор предоставляется дизайнером интерьера индивидуально, вы сможете обсудить все вопросы далее.
          </div>

          <div className="slider-title">
Помоги лучше понять, что ты ждёшь от дизайна, ответив на несколько вопросов:
          </div>
        </DesignerBox>

        <CarouselBox>
          <QuestionsCarousel questions={questions} answers={answers} onAnswerChange={this.onAnswerChange} />
        </CarouselBox>

        <DesignerBox>
          <div className="buttons">
            {/* <Button text="Назад" className="next-button" onClick={this.goPrev} type="text" /> */}
            <div />
            <Button
              text="Сохранить и продолжить"
              color="primary"
              className="next-button"
              onClick={async () => {
                try {
                  await ClientService.setDesignerRequirements({
                    id: activeProjectId,
                    gender: sex,
                    ageFrom: age[0],
                    ageTo: age[1],
                    needPersonalMeeting: personalMeeting === 'true',
                    needPersonalControl: authorSupervision === 'true',
                    testing: {
                      questions: questions.map(quest => ({ index: quest.id, answer: parseInt(answers[quest.id], 10) }))
                    }
                  })

                  this.goNext()
                } catch (e) {
                  console.log(e)
                }
              }}
              disabled={Object.keys(pickBy(answers, val => val === null)).length > 0}
            />
          </div>
        </DesignerBox>
      </React.Fragment>
    )
  }
}

export { Designer }
