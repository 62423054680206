const rooms = [
  {
    img: 'hallway',
    name: 'Прихожая',
    model: 'Hallway'
  },
  {
    img: 'bathroom',
    name: 'Туалет и Ванная',
    model: 'Bathroom'
  },
  {
    img: 'kitchen',
    name: 'Кухня',
    model: 'Kitchen'
  },
  {
    img: 'livingRoom',
    name: 'Гостиная',
    model: 'LivingRoom'
  },
  {
    img: 'bedroom',
    name: 'Спальня',
    model: 'BedRoom'
  },
  {
    img: 'nursery',
    name: 'Детская',
    model: 'ChildrenRoom'
  },
  {
    img: 'wardrobe',
    name: 'Гардероб',
    model: 'Wardrobe'
  },
  {
    img: 'cabinet',
    name: 'Кабинет',
    model: 'Parlor'
  },
  {
    img: 'loggia',
    name: 'Лоджия и балкон',
    model: 'LoggiaOrBalcony'
  },
  {
    img: 'other',
    name: 'Другое',
    model: 'Custom'
  }
]

export default rooms
export { rooms }
