import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import { COLORS } from '@constants/colors'
import { ROUTES } from '@constants'

import img from '@assets/images/404.png'
import { MainFooter } from '@ui/organisms/mainFooter'

const Page404Box = styled.div`
  padding-left: ${({ isInnerPage }) => (!isInnerPage ? '165px' : '0')};
  padding-top: ${({ isInnerPage }) => (!isInnerPage ? '110px' : '50px')};
  display: flex;

  .left-side {
    width: 476px;

    .title {
      font-family: FuturaNewBold-Reg;
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      color: ${COLORS.orange};
      width: 390px;
    }

    .description {
      margin-top: 30px;
      font-family: FuturaNewBook-Reg;
      font-size: 28px;
      color: ${COLORS.darkBlue};
    }

    .text {
      margin-top: 16px;
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 1.5;
      color: ${COLORS.darkBlue};
    }

    .code {
      width: fit-content;
      margin-top: 40px;
      padding-bottom: 24px;
      border-bottom: 1px solid ${COLORS.darkBlue};
      font-family: Roboto-Regular;
      font-size: 32px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.75;
      letter-spacing: 18px;
      color: ${COLORS.darkBlue};
    }
  }

  .right-side {
    margin-left: 189px;
  }
`

const Header = styled.div`
  padding-left: 165px;
  padding-top: 23px;
  height: 72px;
  box-shadow: 0 2px 4px 0 rgba(37, 60, 94, 0.08);

  .logo {
    cursor: pointer;
    width: 120px;
    height: 32px;
    font-family: FuturaNewExtraBold-Reg;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.6;
    letter-spacing: 2.5px;
    color: #253c5e;
  }
`

@withRouter
class Page404 extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <Page404Box isInnerPage={isInnerPage}>
          <div className="left-side">
            <div className="title">
              Вот так удача! Вы попали сюда не случайно
              {' '}
              <span role="img" aria-label="hi">
                🔮
              </span>
            </div>

            <div className="description">
По счастливому стечению обстоятельств вы попали на страницу которой нет
            </div>

            {/* <div className="text">
              За это мы дарим вам волшебный промокод, который даёт скидку 5% на дизайн-проект или на покупку товаров из нашего магазина.
            </div>

            <div className="code">
FZF-404
            </div> */}
          </div>

          <div className="right-side">
            <img src={img} alt="404" />
          </div>
        </Page404Box>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Page404 }
