import { Marketplace } from '@pages/Marketplace'
import { Page404 } from '@pages/404'

const routes = [
  {
    path: '/design/marketplace',
    component: Marketplace
  },
  {
    component: Page404
  }
]

export { routes }
