import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const FooterBox = styled.div`
  background-color: ${COLORS.getBlueColor(0.05)};

  .beta-wrapper {
    padding: 0 165px;
    height: 48px;
    border-radius: 8px;
    background-color: rgba(239, 78, 34, 0.06);
    display: flex;
    align-items: center;

    .beta {
      margin-right: 15px;
      font-family: Roboto-Regular;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${COLORS.orange};
    }

    .text {
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${COLORS.orange};
    }

    .info {
      margin-left: 24px;
      cursor: pointer;
      opacity: 0.54;
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: ${COLORS.darkBlue};
      border-bottom: 1px solid rgba(37, 60, 94, 0.12);

      &:hover {
        opacity: 1;
        color: ${COLORS.orange};
        border-color: ${COLORS.orange};
      }
    }
  }

  .top-row {
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 ${COLORS.getBlueColor(0.12)};
    padding: 0 165px;

    .f-logo {
      img {
        height: 53px;
        position: relative;
        top: 31px;
        margin-right: 140px;
      }
    }
  }

  .bottom-row {
    padding: 24px 165px 32px 165px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .navigation {
    display: flex;

    .link {
      display: block;
      padding: 25px 0;
      margin-right: 32px;
      color: ${COLORS.getBlueColor(0.87)};
      font-family: Roboto-Regular;
      font-size: 16px;
      text-decoration: none;

      &:hover {
        color: ${COLORS.orange};
      }
    }
  }

  .link {
    font-family: Roboto-Regular;
    font-size: 16px;
    line-height: 28px;
    color: ${COLORS.getBlueColor(0.7)};
    display: block;
    text-decoration: none;
    margin-top: 9px;

    &:hover {
      color: ${COLORS.orange};
    }
  }

  .title {
    font-family: Roboto-Medium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.7px;
    color: ${COLORS.getBlueColor(0.38)};

    &.hidden {
      visibility: hidden;
    }
  }

  .phone {
    color: ${COLORS.getBlueColor(0.87)};
    font-family: Roboto-Regular;
    font-size: 16px;
    line-height: 28px;
    margin-top: 5px;
    display: block;

    &:hover {
      color: ${COLORS.orange};
    }
  }

  .email {
    display: block;
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 28px;
    color: ${COLORS.getBlueColor(0.7)};

    &:hover {
      color: ${COLORS.orange};
    }
  }

  .address {
    color: ${COLORS.getBlueColor(0.54)};
    font-size: 12px;
    line-height: 20px;
    font-family: Roboto-Regular;
    margin-top: 5px;
  }

  .inn {
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    opacity: 0.54;
    color: ${COLORS.darkBlue};
    margin-top: 5px;
  }

  .social-row {
    padding: 12px 165px;
    background-color: ${COLORS.getBlueColor(0.05)};
  }

  .socials {
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      fill: ${COLORS.getBlueColor(0.54)};
    }
  }

  .download {
    cursor: pointer;
    width: 150px;
    height: 48px;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1.6px rgba(37, 60, 94, 0.12);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 15px;

    img {
      width: 19px;
      height: 23px;
      object-fit: contain;
      opacity: 0.87;
      margin-left: 12px;
      margin-right: 12px;
    }

    svg {
      width: 19px;
      height: 23px;
      margin-right: 12px;
      margin-left: 12px;

      path {
        fill: #253c5e;
      }
    }

    &:hover {
      background-color: #253c5e;

      .download-text {
        .top,
        .bottom {
          color: white;
          opacity: 0.87;
        }
      }

      svg {
        path {
          fill: white;
          opacity: 0.87;
        }
      }
    }

    .download-text {
      .top {
        opacity: 0.53;
        font-family: Roboto-Medium;
        font-size: 8px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.83;
        letter-spacing: 0.4px;
        color: #253c5e;
        text-transform: uppercase;
        margin-bottom: -4px;
      }

      .bottom {
        font-family: Roboto-Medium;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #253c5e;
      }
    }
  }
`

const ModalBody = styled.div`
  width: 700px;

  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    color: ${COLORS.orange};
  }

  .text {
    margin-top: 32px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};

    .link {
      color: ${COLORS.orange};
      text-decoration: underline;
    }
  }

  .button {
    margin-top: 80px;
    width: 100%;
  }
`

export { FooterBox, ModalBody }
