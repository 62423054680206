import { combineReducers } from 'redux'

import user from './user'
import project from './project'
import designer from './designer'
import marketplace from './marketplace'

export const rootReducer = combineReducers({
  user,
  project,
  designer,
  marketplace
})
