import { put, takeLatest } from 'redux-saga/effects'

import { replace } from 'connected-react-router'
import {
  LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS
} from '../constants/user'
import { ROUTES } from '@constants'

import { setUserData } from '../actions/user'

function* loginUser({ payload }) {
  try {
    yield put(setUserData(payload))

    return yield put({ type: LOGIN_SUCCESS, payload })
  } catch (e) {
    console.log(e)

    return yield put({ type: LOGIN_ERROR })
  }
}

function* loginUserWatcher() {
  yield takeLatest(LOGIN_REQUEST, loginUser)
}

function* logoutUser() {
  try {
    localStorage.clear()

    yield put({ type: LOGOUT_SUCCESS })
    yield put(replace(ROUTES.main))

    return null
  } catch (error) {
    return null
  }
}

function* logoutUserWatcher() {
  yield takeLatest(LOGOUT_REQUEST, logoutUser)
}

export { loginUserWatcher, logoutUserWatcher }
