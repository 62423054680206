import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const HelloBox = styled.div`
  margin-top: 10px;
  width: 930px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    flex-direction: column;
    align-items: center;
    width: auto;

    .step-title {
      width: 275px;
      font-family: FuturaNewBold-Reg;
      font-size: 32px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: -0.7px;
      color: ${COLORS.orange};
    }

    .step-title-description.step-title-description {
      width: 275px;
      margin-top: 16px;
      margin-bottom: 16px;
      opacity: 0.87;
      font-family: FuturaNewBook-Reg;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }

    .info-text {
      width: 275px;
      margin-bottom: 30px;
      opacity: 0.7;
      font-family: Roboto-Regular;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }

    .info-input.info-input {
      width: 275px;
    }

    .send-code-wrapper.send-code-wrapper {
      margin-top: 243px;
      width: 290px;

      .send-code {
        width: 100%;
      }

      &.mt-40 {
        margin-top: 40px;
      }
    }

    .change-number {
      margin-top: 100px;
      font-family: Roboto-Medium;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: ${COLORS.darkBlue};
      border-bottom: 1px solid ${COLORS.getBlueColor(0.37)};
      cursor: pointer;
    }
  }

  .step-title-description {
    margin-top: 32px;
    width: 100%;
    margin-bottom: 50px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
  }

  .info-input {
    display: block;
    margin-bottom: 5px;
    width: 255px;
  }

  .inputs-block {
    display: flex;
    flex-wrap: wrap;
    width: 550px;
    justify-content: space-between;
  }

  .info {
    margin-top: 32px;
    margin-left: 115px;
    width: 255px;
    opacity: 0.7;
    font-family: Roboto-Regular;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
  }

  .send-code-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    .send-code {
      display: block;
      width: 255px;

      &.big {
        width: 280px;
      }
    }

    .change-number {
      cursor: pointer;
      margin-left: 25px;
      font-family: Roboto-Medium;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }
  }

  .example {
    .blue {
      color: ${COLORS.orange};
    }
  }

  .form-footer {
    width: 620px;
    display: flex;
    margin-top: 48px;

    @media (max-width: ${RESPONSIVE.MOBILE}px) {
      width: 275px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .next-button {
        width: 275px;
        margin-right: 0;
      }

      .policy-text {
        margin-top: 10px;
        margin-right: 0;
        width: 270px;
        text-align: center;
      }
    }
  }

  .next-button {
    margin-right: 45px;
    width: 500px;
  }

  .policy-text {
    color: ${COLORS.getBlueColor(0.5)};
    font-size: 12px;
    line-height: 20px;
    margin-right: -40px;

    .begin {
      font-family: Roboto-Medium;
    }

    .link {
      color: ${COLORS.getBlueColor(0.8)};
      font-weight: 500;
      border-bottom: 1px solid ${COLORS.getBlueColor(0.2)};
    }
  }

  .project-name-tip {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  max-width: 570px;

  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: bold;
    color: ${COLORS.orange};
  }

  .text {
    margin-bottom: 48px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    font-weight: normal;
    color: ${COLORS.darkBlue};
    line-height: 1.14;
  }

  .buttons-wrapper {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;

    button {
      width: 255px;
    }
  }
`

export { HelloBox, ModalBody }
