import React from 'react';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';

import { COLORS } from '@constants/colors';

const SelectBox = styled.div`
  .select {
    padding-left: 4px;
    background-color: #ffffff;
    border: none;
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    outline: none;
    box-shadow: none;
  }
  
  .form-control {
    width: 100%;
  }
  
  .root:focus {
    background: none;
  }
  
  .input-root {
    font-family: Roboto-Regular;
  }
  
  .label {
    color: ${COLORS.getBlueColor()};
    font-family: Roboto-Regular;
    z-index: 10;
    pointer-events: none;

    &[data-shrink='true'] {
      color: ${COLORS.getBlueColor(0.87)};
    }
  }
  
  & > div > div {
    display: block;
    height: 40px;
    margin-bottom: 16px;
    
    &:before, &:after {
      border-bottom: 1px solid ${COLORS.getBlueColor(0.54)} !important;
    }
    
    &.focused {
      &:before, &:after {
        border-bottom-color: ${COLORS.orange} !important;
      }
    }
    
    &:hover {
      &:before, &:after {
        border-bottom-color: ${COLORS.orange}!important;
      }
    }
  }
`;


const Select = ({
  items, label, id, ...rest
}) => (
  <SelectBox>
    <FormControl className="form-control">
      <InputLabel htmlFor={id} className="label">
        {label}
      </InputLabel>
      <MuiSelect
        {...rest}
        classes={{
          root: 'select',
          select: 'root'
        }}
        inputProps={{
          id,
          classes: {
            root: 'input-root',
          }
        }}
      >
        {items.map(item => (
          <MenuItem value={item.value} key={`select-item-${item.value}`}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  </SelectBox>
);

export { Select }
