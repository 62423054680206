import { put, takeLatest, call } from 'redux-saga/effects'
import DesignerService from '@services/DesignerService'
import { TOKEN_KEY } from '@constants/common'
import { setDesignerBasicInfo, setDesignerFullInfo } from '../actions/designer'

import {
  CREATE_DESIGNER_REQUEST,
  CREATE_DESIGNER_SUCCESS,
  CREATE_DESIGNER_ERROR,
  UPDATE_DESIGNER_REQUEST
} from '../constants/designer'

/*eslint-disable*/
function* createDesigner(action) {
  try {
    const data = yield call(DesignerService.createDesigner)
    const token = data.result

    localStorage.setItem(TOKEN_KEY, token)

    return yield put({ type: CREATE_DESIGNER_SUCCESS })
  } catch (error) {
    return yield call({ type: CREATE_DESIGNER_ERROR })
  }
}

function* updateDesignerInfo(action) {
  try {
    const { result } = yield call(DesignerService.getBasicInfo)
    const { result: fullInfo } = yield call(DesignerService.getFullInfo)

    yield put(setDesignerBasicInfo(result))
    yield put(setDesignerFullInfo(fullInfo))
  } catch (error) {
    console.error(error)
  }
}

function* createDesignerWatcher() {
  yield takeLatest(CREATE_DESIGNER_REQUEST, createDesigner)
}

function* updateDesignerInfoWatcher() {
  yield takeLatest(UPDATE_DESIGNER_REQUEST, updateDesignerInfo)
}

export { createDesignerWatcher, updateDesignerInfoWatcher }
