import React, { Component } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { COLORS } from '@constants/colors'

import stub from '@assets/images/stub.png'
import { Header } from '@pages/other/style'
import { MainFooterMobile } from '@ui/organisms/mainFooter.mobile'
import { withRouter } from 'react-router'

const StubBox = styled.div`
  padding: 0 30px;
  &.inner {
    padding: 40px 30px;
  }
  h1 {
    font-family: FuturaNewBold-Reg;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.7px;
    color: ${COLORS.orange};
    margin-bottom: 16px;
  }

  span {
    font-family: FuturaNewBook-Reg;
    font-size: 18px;
    line-height: 1.22;
    color: ${COLORS.getBlueColor(0.87)};
  }

  img {
    display: block;
    margin-top: 48px;
    max-width: 220px;
  }
`

@withRouter
class Stub extends Component {
  render() {
    const { isInnerPage } = this.props
    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}
        <StubBox className={classNames({ inner: !isInnerPage })}>
          <h1>
- Стало тесновато и мы стали чуточку ближе
          </h1>
          <span>
            Кажется наши отношения уже вышли на новый уровень, а это значит что
            пора взглянуть на нас более широко.
            <br />
            <br />
            Пожалуйста, попробуйте открыть сайт на компьютере или планшете.
          </span>
          <img src={stub} alt="" />
        </StubBox>
        {!isInnerPage && <MainFooterMobile className="first-step-footer" />}
      </React.Fragment>
    )
  }
}

export { Stub }
