import {
  CREATE_DESIGNER_REQUEST,
  SET_DESIGNER_BASIC_INFO,
  SET_DESIGNER_FULL_INFO,
  UPDATE_DESIGNER_REQUEST
} from '../constants/designer'

export const createDesigner = phone => ({
  type: CREATE_DESIGNER_REQUEST,
  payload: phone
})

export const setDesignerBasicInfo = data => ({
  type: SET_DESIGNER_BASIC_INFO,
  payload: data
})

export const setDesignerFullInfo = data => ({
  type: SET_DESIGNER_FULL_INFO,
  payload: data
})

export const updateDesignerInfo = () => ({
  type: UPDATE_DESIGNER_REQUEST
})
