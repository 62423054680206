import { injectGlobal } from 'styled-components'
import 'react-image-lightbox/style.css'

import FuturaNewBold from '@assets/fonts/FuturaNewBold.otf'
import FuturaNewLight from '@assets/fonts/FuturaNewLight.otf'
import FuturaNewBook from '@assets/fonts/FuturaNewBook.otf'
import FuturaNewExtraBold from '@assets/fonts/FuturaNewExtraBold.otf'
import FuturaNewMedium from '@assets/fonts/FuturaNewMedium.otf'
import MaterialIcons from '@assets/fonts/MaterialIcons.woff2'

import RobotoReg from '@assets/fonts/Roboto-Regular.ttf'
import RobotoMed from '@assets/fonts/Roboto-Medium.ttf'
import { COLORS } from '@constants/colors'

// eslint-disable-next-line
injectGlobal` 
  @font-face {
    font-family: FuturaNewBold-Reg;
    src: url(${FuturaNewBold});
  }

  @font-face {
    font-family: FuturaNewLight-Reg;
    src: url(${FuturaNewLight});
  }

  @font-face {
    font-family: FuturaNewBook-Reg;
    src: url(${FuturaNewBook});
  }

  @font-face {
    font-family: FuturaNewExtraBold-Reg;
    src: url(${FuturaNewExtraBold})
  }

  @font-face {
    font-family: FuturaNewMedium-Reg;
    src: url(${FuturaNewMedium});
  }

  @font-face {
    font-family: Roboto-Regular;
    src: url(${RobotoReg});
  }
  
  @font-face {
    font-family: Roboto-Medium;
    src: url(${RobotoMed});
  }

  @font-face {
    font-family: Material Icons;
    src: url(${MaterialIcons}) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin: 0;
    font-family: FuturaNew-Reg;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.textDark};
  }

  /* lightbox */
  .ril__outer {
    background-color: rgba(180, 180, 190, 0.65);
  }
  
  .page {
    margin-left: 355px;
    padding-left: 95px;
    width: calc(100vw - 270px);
    min-height: 70vh;
    min-width: 805px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .page-body {
    margin-bottom: 80px;
  }
  
  .page-content {
    padding-top: 183px;
    max-width: 730px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .page-title {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1px;
    color: ${COLORS.orange};
    position: relative;
    margin-bottom: 32px;
    
    [role="img"] {
      font-size: 40px;
    }
    
    :before {
      content: '—';
      position: absolute;
      transform: translateX(calc(-100% - 10px));
    }
    
    &.no-before:before {
      content: none;
    }
  }
  
  .page-small-title {
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 34px;
  }
  
  .page-subtitle {
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 24px;
    color: ${COLORS.getBlueColor(0.54)};
    margin-top: -8px;
  }
  
  .page-description {
    font-size: 28px;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
    font-family: FuturaNewBook-Reg;
    margin-bottom: 48px;
  }
  
  .page_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
  }
  
  .page_content {
    margin: 192px 165px 48px 165px;
    width: 790px;
    flex: 1;
    flex-direction: column;
    display: flex;
    min-height: calc(100vh - 288px);
    
    .page-title:before {
      content: none;
    }
    
    .no-mh {
      min-height: auto;
    }
  }
  
  .g_small-title {
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    line-height: 32px;
    color: ${COLORS.getBlueColor()};
    font-weight: 600;
    
    &.mb16 {
      margin-bottom: 16px;
    }

    &.mb32 {
      marigin-bottom: 32px;
    }
    
    &.mt48 {
      margin-top: 34px;
    }
  }
  
  .g_text {
    font-size: 16px;
    color: ${COLORS.getBlueColor(0.54)};
    line-height: 24px;
    font-family: Roboto-Regular;

    &.dark {
      color: ${COLORS.getBlueColor()};
    }
    
    .bold {
      font-family: Roboto-Medium;
      font-weight: 500;
    }
  }

  .g_big-text {
    font-family: FuturaNewBook-Reg;
    color: ${COLORS.getBlueColor()};
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;
    
    &.mb32 {
      margin-bottom: 32px;
    }
  }
  
  .g_note {
    font-family: Roboto-Regular;
    font-size: 14px;
    line-height: 24px;
    color: ${COLORS.getBlueColor(0.7)};
    padding-left: 15px;
    position: relative;
    
    &.mw280 {
      max-width: 280px;
    }
    
    &:before {
      content: "*";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  
  .g_disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  .g_content-footer {
    &.fsb {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
`
