import React from 'react';
import styled from 'styled-components';
import { pickBy } from 'lodash';

import { Button } from '@ui/atoms';
import { questions1 } from '@pages/designer/FirstDesignerSteps/Survey/questions';
import { QuestionsCarousel } from '@ui/organisms';
import { CarouselBox } from '@pages/client/FirstUserSteps/Designer/designer.style';
import DesignerService from '@services/DesignerService';

const SurveyBox = styled.div`
  .page-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

class Survey extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      surveyAnswers1: questions1.reduce((res, question) => ({ ...res, [question.id]: null }), {}),
      questions2: [],
      surveyAnswers2: {},
      ...props.stepState
    }
  }

  componentDidMount() {
    this.getQuestions()
  }

  getQuestions = async () => {
    try {
      const questionResponse = await DesignerService.getQuestions()

      this.setState({
        questions2: questionResponse.result,
        surveyAnswers2: questionResponse.result.reduce((res, question) => ({ ...res, [question.id]: null }), {}),
      })
    } catch (e) {
      console.log(e)
    }
  }

  sendData = async () => {
    try {
      const { surveyAnswers1, questions2, surveyAnswers2 } = this.state
      const { goNext } = this.props
      const possibilities = {};

      [
        'goToPlaceInYourCity',
        'goToPlaceInAnotherCity',
        'personalСontrol',
        'personalMeeting',
        'skypeMeeting',
        'roomMeasurements',
        'blueprints'
      ].forEach((i) => {
        possibilities[i] = surveyAnswers1[i] === '1'
      })

      await Promise.all([
        DesignerService.setPossibilities(possibilities),
        DesignerService.setTestResults(
          questions2.map(quest => ({ index: quest.id, answer: parseInt(surveyAnswers2[quest.id], 10) }))
        )
      ])

      goNext(this.state)
    } catch (e) {
      console.log(e)
    }
  }

  onAnswerChange = (name, value, slider) => {
    this.setState(state => ({ [`surveyAnswers${slider}`]: { ...state[`surveyAnswers${slider}`], [name]: value } }))
  };

  render() {
    const { surveyAnswers1, surveyAnswers2, questions2 } = this.state;

    return (
      <SurveyBox className="page-content">
        <div className="page-body">
          <div className="page-title">
            Ответьте на несколько вопросов
          </div>
          <div className="page-description">
          Первый ряд вопросов – это ваша подвижность и возможности. Выполнение замера – даст вам более точные чертежи объекта.
          Авторский надзор – приблизит объект к его реализации. Если вы не умеете делать 3D или чертежи,
          что не беда, мы поможем вам со специалистами.
          Второй ряд вопросов - ответы на которые будут влиять на совместимость вас с Клиентом, поэтому
          если хотите Клиента близкого по духу отвечайте искренне.
          </div>
          <div className="page-small-title">
            Помоги оценить твои возможности, ответив на несколько вопросов:
          </div>
          <CarouselBox>
            <QuestionsCarousel
              questions={questions1}
              answers={surveyAnswers1}
              onAnswerChange={(name, value) => this.onAnswerChange(name, value, 1)}
            />
          </CarouselBox>
          <div className="page-small-title">
            Помоги лучше понять, как ты видишь дизайн, ответив на несколько вопросов:
          </div>
          <CarouselBox>
            <QuestionsCarousel
              questions={questions2}
              answers={surveyAnswers2}
              onAnswerChange={(name, value) => this.onAnswerChange(name, value, 2)}
            />
          </CarouselBox>
        </div>
        <div className="page-footer">
          <Button
            text="Сохранить и продолжить"
            onSave={this.sendData}
            disabled={
              Object.keys(pickBy(surveyAnswers1, val => val === null)).length > 0
              || Object.keys(pickBy(surveyAnswers2, val => val === null)).length > 0
            }
          />
        </div>
      </SurveyBox>
    )
  }
}

export { Survey }
