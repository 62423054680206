import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { AddFoto } from '@ui/atoms/addFoto'
import { Lightbox } from '@ui/organisms'

const PicturesContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;

  .picture {
    width: 172px;
    height: 129px;
    border-radius: 8px;
    margin-top: 12px;
    position: relative;
    margin-right: 12px;
    background-size: cover;
    cursor: pointer;
  }

  .remove-picture {
    transform: rotate(45deg);
    position: absolute;
    width: 24px;
    height: 24px;
    right: -9px;
    top: -8px;
    cursor: pointer;
    border-radius: 100%;
    background-color: ${COLORS.white};
    fill: ${COLORS.orange};
  }

  .add-photo {
    width: 172px;
    height: 129px;
    border-radius: 8px;
    margin-top: 12px;
    margin-right: 12px;
  }
`

class PicturesContainer extends React.Component {
  state = {
    currentImage: 0,
    showFullImage: false
  }

  render() {
    const {
      items, onRemove, onAdd, multiple, max, hideCotrols, accept, disabled
    } = this.props

    const { showFullImage, currentImage } = this.state

    const photoBoxes = [...items]

    if (!disabled && (!max || items.length < max)) {
      photoBoxes.push({ randomKey: Math.random() })
    }

    let currentMax

    if (max) {
      currentMax = max - items.length
    }

    return (
      <PicturesContainerBox>
        {photoBoxes.map((item, pictureIndex) => (
          <AddFoto
            isPdf={item.isPdf}
            key={item.id || item.randomKey}
            className="add-photo"
            image={item.link}
            multiple={multiple}
            onChange={onAdd}
            allowRemove={!disabled}
            onRemove={() => onRemove(pictureIndex)}
            hideCotrols={hideCotrols}
            accept={accept}
            max={currentMax}
            disabled={false}
            disableOnComplete
          />
        ))}
        {/* {(!max || items.length < max) && <AddFoto className="add-photo" multiple={multiple} onChange={onAdd} />} */}
        <Lightbox
          isOpen={showFullImage}
          currentImageIndex={currentImage}
          images={items.map(item => item.link)}
          getPrev={() => this.setState({ currentImage: currentImage - 1 })}
          getNext={() => this.setState({ currentImage: currentImage + 1 })}
          onCancel={() => this.setState({ showFullImage: false })}
        />
      </PicturesContainerBox>
    )
  }
}

PicturesContainer.defaultProps = {
  disabled: false,
}

PicturesContainer.propTypes = {
  disabled: PropTypes.bool,
}

export { PicturesContainer }
