import styled from 'styled-components'

import andersen from '@assets/images/brands/andersen.png'
import andersenHover from '@assets/images/brands/andersen-hover.png'

import hoff from '@assets/images/brands/hoff.png'
import hoffHover from '@assets/images/brands/hoff-hover.png'

import divcvet from '@assets/images/brands/divcvet.png'
import divcvetHover from '@assets/images/brands/divcvet-hover.png'

import zov from '@assets/images/brands/zov.png'
import zovHover from '@assets/images/brands/zov-hover.png'

import maria from '@assets/images/brands/maria.png'
import mariaHover from '@assets/images/brands/maria-hover.png'

import angstrem from '@assets/images/brands/angstrem.png'
import angstremHover from '@assets/images/brands/angstrem-hover.png'

import askona from '@assets/images/brands/askona.png'
import askonaHover from '@assets/images/brands/askona-hover.png'

import mri from '@assets/images/brands/mri.png'
import mriHover from '@assets/images/brands/mri-hover.png'

import pinsk from '@assets/images/brands/pinsk.png'
import pinskHover from '@assets/images/brands/pinsk-hover.png'

import boconcept from '@assets/images/brands/boconcept.png'
import boconceptHover from '@assets/images/brands/boconcept-hover.png'

import mrdoors from '@assets/images/brands/mrdoors.png'
import mrdoorsHover from '@assets/images/brands/mrdoors-hover.png'

import opmatek from '@assets/images/brands/opmatek.png'
import opmatekHover from '@assets/images/brands/opmatek-hover.png'

const BrandsBox = styled.div`
  .andersen {
    background-image: url(${andersen});
    
    &:hover {
      background-image: url(${andersenHover});
    }
  }

  .hoff {
    background-image: url(${hoff});
    
    &:hover {
      background-image: url(${hoffHover});
    }
  }

  .divcvet {
    background-image: url(${divcvet});
    
    &:hover {
      background-image: url(${divcvetHover});
    }
  }

  .zov {
    background-image: url(${zov});
    
    &:hover {
      background-image: url(${zovHover});
    }
  }

  .maria {
    background-image: url(${maria});
    
    &:hover {
      background-image: url(${mariaHover});
    }
  }

  .angstrem {
    background-image: url(${angstrem});
    
    &:hover {
      background-image: url(${angstremHover});
    }
  }

  .askona {
    background-image: url(${askona});
    
    &:hover {
      background-image: url(${askonaHover});
    }
  }

  .mri {
    background-image: url(${mri});
    
    &:hover {
      background-image: url(${mriHover});
    }
  }

  .pinsk {
    background-image: url(${pinsk});
    
    &:hover {
      background-image: url(${pinskHover});
    }
  }

  .boconcept {
    background-image: url(${boconcept});
    
    &:hover {
      background-image: url(${boconceptHover});
    }
  }

  .mrdoors {
    background-image: url(${mrdoors});
    
    &:hover {
      background-image: url(${mrdoorsHover});
    }
  }

  .opmatek {
    background-image: url(${opmatek});
    
    &:hover {
      background-image: url(${opmatekHover});
    }
  }
`

export { BrandsBox }
