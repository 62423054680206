import styled from 'styled-components';

import { COLORS, RESPONSIVE } from '@constants';

const LoginBox = styled.div`
  display: flex;
  flex-wrap: wrap;

  .content {
    margin-left: 270px;
    padding-left: 95px;
    padding-top: 168px;
    width: calc(100vw - 270px);
    min-height: calc(100vh - 48px);
  }

  .description {
    margin-top: 32px;
    margin-bottom: 48px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    color: ${COLORS.darkBlue};
    line-height: 1.14;
    max-width: 730px;
  }

  .input-title {
    margin-bottom: 16px;
    font-family: FuturaNewBold-Reg;
    font-size: 20px;
    font-weight: bold;
    color: ${COLORS.darkBlue};
  }

  .resend-code {
    font-family: Roboto-Regular;
    font-size: 14px;
    cursor: pointer;
    color: ${COLORS.darkBlue};
  }

  .buttons-wrapper {
    margin-top: 106px;

    .orange-button {
      color: ${COLORS.orange};
    }
  }

  .new-project {
    margin-top: 81px;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: ${COLORS.darkBlue};

    .link {
      font-family: Roboto-Medium;
      font-size: 16px;
      color: ${COLORS.orange};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .login-footer {
    margin-left: 270px;
    margin-top: 48px;
    width: calc(100vw - 270px);

    .top-row,
    .bottom-row,
    .social-row {
      padding-left: 63px;
      padding-right: 60px;

      & > div {
        max-width: 1000px;
      }
    }
  }

  @media (max-width: ${RESPONSIVE.TABLET}px) {

    .content {
     margin-left: 70px;
     padding-top: 68px;
    }
    .login-footer{
      width: 100%;
      margin-left: 0;
    }
  }
  @media (max-width: ${RESPONSIVE.MOBILE}px) {

    .content {
    padding-left: 0;
     margin: 0 auto;
     padding-top: 68px;
     width: 80%;
    }
  }
`;

const ImageBox = styled.div`
  position: fixed;
  width: 270px;
  height: 100vh;
  background: url(${({ src }) => src}) 100% 100% no-repeat;
  background-size: cover;

  &:after {
    background: transparent;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0)
    );
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .logo {
    margin-left: 110px;
    margin-top: 21px;
  }
  @media (max-width: ${RESPONSIVE.TABLET}px) {
    display: none;
  }
`;

export { LoginBox, ImageBox };
