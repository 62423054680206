import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import ProjectService from '@services/ProjectService'
import { RoomTabs } from '@ui/organisms/roomTabs'
import { PicturesContainer } from '@ui/atoms/picture'
import { Button, WatchVideo } from '@ui/atoms'
import { COLORS, ROUTES } from '@constants'
import { RoomItems } from '@pages/designer/roomProducts'
import { isDesigner } from '@helpers/helpers';

const PageBox = styled.div`
  &.page_content {
    width: 790px;
  }
  
  .market-block {
    border-top: 1px solid ${COLORS.getBlueColor(0.12)};
    margin-top: 32px;
    padding-top: 32px;
  }
  
  .market-button {
    width: 100%;
    margin-top: 24px;
  }
  
  .buttons-row {
    margin-top: 80px;
    display: flex;
    justify-content: space-between
  }

  .check {
    margin-right: 20px;
  }

  .watch-video {
    margin-top: 20px;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@withRouter
@connect(mapStateToProps)
class DesignerZoning extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rooms: [],
      activeRoom: 0,
      step: props.stage.stage === props.step && !props.stage.designerIsReady ? 1 : 2,
    }
  }

  async componentDidMount() {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      const response = await Promise.all(result.map(room => ProjectService.getRoomZoning(activeProject, room.roomId)))

      this.setState({
        rooms: result.map((room, index) => ({
          model: room.purpose,
          roomPhotos: response[index].result.zonePhotos.map(photo => ({
            link: photo.fileLink,
            id: photo.fileId
          })),
          roomId: room.roomId
        }))
      })
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({ step: nextProps.stage.stage === nextProps.step && !nextProps.stage.designerIsReady ? 1 : 2 })
    }
  }

  addRoomPhoto = async (fileList, roomId) => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      const promises = fileList.map(file => ProjectService.uploadZonePhoto({
        ProjectId: activeProject,
        RoomId: roomId,
        File: file
      }))

      const responses = await Promise.all(promises)

      responses.forEach(({ result }, index) => rooms[activeRoom].roomPhotos.push({
        link: fileList[index].preview,
        id: result
      }))

      this.setState({ rooms });
    } catch (e) {
      console.log(e)
    }
  }

  removeRoomPhoto = async (photoIndex) => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      await ProjectService.removeZonePhoto({
        projectId: activeProject,
        fileId: rooms[activeRoom].roomPhotos[photoIndex].id,
        roomId: rooms[activeRoom].roomId
      })

      rooms[activeRoom].roomPhotos.splice(photoIndex, 1);

      this.setState({ rooms });
    } catch (e) {
      console.log(e)
    }
  }

  confirmStage = async () => {
    const { activeProject } = this.props

    await ProjectService.confirmDevelopmentStage(activeProject, 'Zoning')

    this.setState({ step: 2 })
  }

  renderFirstStep = () => {
    const { rooms, activeRoom, step } = this.state
    const {
      stage, goPrev, goNext, updateStage, ...props
    } = this.props
    const passedPage = stage.stage > props.step

    return (
      <React.Fragment>
        {step === 1 ? (
          <React.Fragment>
            <div className="g_big-text mb32">
              Пора приступить к Зонированию!
            </div>
            <div className="g_text dark">
              В среднем создание Зонирования для одной комнаты должно у вас занять не более 2 дней.
              Во время этого периода вы можете общаться с клиентом в чате.
              <br />
              <br />
              После того, как вы загрузите файлы у вас будет 2 дня на согласование и обсуждение Зонирования с Клиентом.
            </div>
          </React.Fragment>
        ) : (
          <div className="g_big-text mb32">
            Вы отправили Зонирование Клиенту!
            <br />
            <br />
            Вы можете получить комментарии в чате и сразу внести изменения - просто загрузите новый файл.
            <br />
            <br />
            Рекомендуем вам пройти этот этап в течении двух дней!
          </div>
        )}

        {isDesigner() && <WatchVideo link="https://youtu.be/oWq-D2Vuhqs" className="watch-video" />}

        {!!rooms.length && (
          <div>
            <div className="g_small-title mt48 mb16">
              Выберите комнату
            </div>
            <RoomTabs
              items={rooms}
              active={activeRoom}
              onChange={index => this.setState({ activeRoom: index })}
            />
            <div className="g_small-title mt48">
              Загрузите изображения
            </div>
            <div className="g_text">
              Вы можете загрузить не более 4 фотографий
            </div>
            <PicturesContainer
              items={rooms[activeRoom].roomPhotos}
              onRemove={this.removeRoomPhoto}
              onAdd={files => this.addRoomPhoto(files, rooms[activeRoom].roomId)}
              max={4}
              disabled={passedPage}
            />
          </div>
        )}
        <div className="buttons-row">
          <Button
            type="text"
            text="Назад"
            onClick={goPrev}
          />
          <div>
            {!passedPage ? (
              <React.Fragment>
                {step === 1 && (
                <Button
                  text="Отправить клиенту на согласование"
                  disabled={!!rooms.find(room => room.roomPhotos.length === 0)}
                  onClick={this.confirmStage}
                />
                )}
                {step === 2 && (
                <React.Fragment>
                  <Button
                    text="Проверить"
                    type="bordered"
                    onClick={() => updateStage()}
                    className="check"
                  />
                  <Button
                    text="Отправить еще раз"
                    disabled={!!rooms.find(room => room.roomPhotos.length === 0)}
                    onClick={this.confirmStage}
                  />
                </React.Fragment>
                )
              }
              </React.Fragment>
            ) : (
              <Button
                text="Вперед"
                onClick={() => goNext()}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { updateStage } = this.props

    return (
      <React.Fragment>

        <div className="buttons-row">
          <div>
            <Button
              text="Назад"
              onClick={() => this.setState({ step: 1 })}
              type="text"
            />
          </div>
          <div>
            <Button
              text="Проверить"
              onClick={() => updateStage()}
              type="bordered"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { rooms } = this.state
    const { history } = this.props

    return (
      <PageBox className="page_content">
        <div className="page-body">
          <div className="page-title">
            Зонирование
          </div>
          { this.renderFirstStep() }
          <div className="market-block">
            <div className="g_big-text">
              Добавьте товары из нашего магазина к данному проекту. В дальнейшем, клиент сможет приобрести их у нас.
            </div>
            <div className="g_text">
              Если нужного товара нет, сообщите нам об этом
            </div>
            <Button
              text="Перейти в магазин FARADISE"
              color="additional"
              type="filled"
              className="market-button"
              onClick={() => history.push(ROUTES.marketplace)}
            />
          </div>
          {!!rooms.length && (
            <RoomItems
              rooms={rooms}
            />
          )}
        </div>
      </PageBox>
    )
  }
}

export { DesignerZoning }
