import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual, get } from 'lodash'
import { withRouter } from 'react-router'
import { renderRoutes } from 'react-router-config'

import { Login } from '@pages/Login'
import { Preloader } from '@ui/atoms'
import { ClientService } from '@services/ClientService'
import { FirstUserSteps } from '@pages/client/FirstUserSteps'
import {
  setActiveProjectId,
  setProjectsList as setProjectsListAction,
  setCurrentProject as setCurrentProjectAction,
  updateProjectsList
} from '@redux/actions/project'
import { ClientStageTwo } from '@pages/client/ClientStageTwo'
import { DesignerDevelopment } from '@pages/designer/Development'
import { FirstDesignerSteps } from '@pages/designer/FirstDesignerSteps'
import { updateDesignerInfo } from '@redux/actions/designer'
import {
  DESIGNER_STAGES, CLIENT_STAGES, PROJECT_STAGES, ROUTES
} from '@constants'
import { CreateNewProject } from '@pages/client/CreateNewProject'
import { isClient } from '@helpers/helpers'
import { ClientDevelopment } from '@pages/client/Development'
import UserService from '@services/UserService';
import { TOKEN_KEY } from '@constants/common';
import { routes } from './configs/routes'

const mapStateToProps = state => ({
  userData: state.user.userData,
  designer: state.designer,
  projectsList: state.project.list,
  activeProject: state.project.activeProjectId,
  activeStage: state.project.activeStage
})

const mapDispatchToProps = dispatch => ({
  setActiveProject: id => dispatch(setActiveProjectId(id)),
  setProjectsList: list => dispatch(setProjectsListAction(list)),
  updateDesignerInfo: () => dispatch(updateDesignerInfo()),
  setCurrentProject: project => dispatch(setCurrentProjectAction(project)),
  updateProjectsList: () => dispatch(updateProjectsList()),
  logout: () => dispatch({ type: 'LOGOUT_REQUEST' })
})

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Main extends Component {
  state = {
    userStatus: null
  }

  async componentDidMount() {
    const {
      setProjectsList, userData, logout, history, setActiveProject
    } = this.props

    const token = localStorage.getItem(TOKEN_KEY)

    if (token) {
      const response = await UserService.validateToken(token)

      if (response.result === false) {
        logout()
        history.push(ROUTES.main)

        window.location.reload()

        return
      }
    }

    if (!userData.role) {
      return
    }

    const { result } = await ClientService.getProjectsList()
    const { projects } = result

    if (!projects.length) {
      setActiveProject(null)
    }

    setProjectsList(projects)

    if (isClient()) {
      this.getProjectInfo()
    }

    this.checkUserInfo()
  }

  async componentDidUpdate(prevProps) {
    const {
      userData, activeProject, projectsList, setProjectsList, setActiveProject
    } = this.props

    if (prevProps.userData.role !== userData.role) {
      const { result } = await ClientService.getProjectsList()
      const { projects } = result

      setProjectsList(projects)

      if (isClient()) {
        this.getProjectInfo()
      }

      this.checkUserInfo()

      return
    }

    if (!isEqual(prevProps.userData, userData) || !isEqual(prevProps.projectsList, projectsList)) {
      this.checkUserInfo()

      if (projectsList && !projectsList.length) {
        setActiveProject(null)
      }
    }

    if (activeProject !== prevProps.activeProject) {
      this.getProjectInfo()
    }
  }

  getProjectInfo = async () => {
    const {
      activeProject, setCurrentProject
    } = this.props

    try {
      const { result } = await ClientService.getProject(activeProject)

      setCurrentProject(result)
    } catch (e) {
      console.log(e)
    }
  }

  async checkUserInfo() {
    try {
      const {
        userData, setActiveProject, updateDesignerInfo, projectsList, activeProject
      } = this.props

      if (userData.role === 'Client') {
        if (!projectsList.length) {
          this.setState({ userStatus: 'new' })
        }

        if (projectsList.length === 1) {
          if (!projectsList.find(project => project.id === activeProject)) {
            setActiveProject(projectsList[0].id)
          }

          switch (projectsList[0].stage) {
            case CLIENT_STAGES.Preparetions:
              this.setState({ userStatus: 'common' })
              break
            case CLIENT_STAGES.Development:
              this.setState({ userStatus: 'common' })
              break
            default:
              this.setState({ userStatus: 'new' })
          }
        }

        if (projectsList.length > 1) {
          if (!projectsList.find(project => project.id === activeProject)) {
            setActiveProject(projectsList[0].id)
          }
          this.setState({ userStatus: 'common' })
        }
      }

      if (userData.role === 'Designer') {
        updateDesignerInfo()

        if (projectsList.length) {
          if (!projectsList.find(project => project.id === activeProject)) {
            setActiveProject(projectsList[0].id)
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {
      userData, designer, isDesignerFirstProject, isClientFirstProject, activeProject, projectsList, activeStage
    } = this.props

    const { userStatus } = this.state

    if (isDesignerFirstProject && !userData.role) {
      return <FirstDesignerSteps />
    }

    if (isClientFirstProject && !userData.role) {
      return <FirstUserSteps />
    }

    if (!userData.role) {
      return <Login />
    }

    if (userData.role === 'Client') {
      if (userStatus === 'new') {
        return <FirstUserSteps />
      }

      if (userStatus === 'common') {
        let currentProject = null

        if (projectsList.length) {
          currentProject = projectsList.find(project => project.id === activeProject)
        }

        if (currentProject && currentProject.stage === PROJECT_STAGES.Description) {
          return renderRoutes([
            { path: ROUTES.main, component: CreateNewProject, exact: true },
            ...routes])
        }

        if (activeStage === PROJECT_STAGES.Preparetions) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientStageTwo, exact: true },
            { path: ROUTES.firstProject, component: ClientStageTwo, exact: true },
            ...routes])
        }

        if (currentProject && currentProject.stage === PROJECT_STAGES.Preparetions) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientStageTwo, exact: true },
            { path: ROUTES.firstProject, component: ClientStageTwo, exact: true },
            ...routes])
        }

        if (currentProject && currentProject.stage === PROJECT_STAGES.Development) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientDevelopment, exact: true },
            { path: ROUTES.firstProject, component: ClientDevelopment, exact: true },
            ...routes])
        }
      }
    }

    if (userData.role === 'Designer') {
      const stage = get(designer, 'basicInfo.stage', null)

      if (stage && stage !== DESIGNER_STAGES.ReadyToWork) {
        return <FirstDesignerSteps />
      }

      if (stage && stage === DESIGNER_STAGES.ReadyToWork) {
        const currentProject = projectsList.find(project => project.id === activeProject)

        if (activeStage === PROJECT_STAGES.Preparetions) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientStageTwo, exact: true },
            { path: ROUTES.designerRegistration, component: ClientStageTwo, exact: true },
            ...routes])
        }

        if (!activeProject) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientStageTwo, exact: true },
            { path: ROUTES.designerRegistration, component: ClientStageTwo, exact: true },
            ...routes])
        }

        if (!currentProject) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientStageTwo, exact: true },
            { path: ROUTES.designerRegistration, component: ClientStageTwo, exact: true },
            ...routes])
        }

        if (currentProject && currentProject.stage === PROJECT_STAGES.Preparetions) {
          return renderRoutes([
            { path: ROUTES.main, component: ClientStageTwo, exact: true },
            { path: ROUTES.designerRegistration, component: ClientStageTwo, exact: true },
            ...routes])
        }

        if (currentProject && currentProject.stage === PROJECT_STAGES.Development) {
          return renderRoutes([
            { path: ROUTES.main, component: DesignerDevelopment, exact: true },
            { path: ROUTES.designerRegistration, component: DesignerDevelopment, exact: true },
            ...routes])
        }
      }
    }

    return <Preloader />
  }
}

export { Main }
