import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const MarketplaceBox = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  
  .page-wrapper {
    margin: 80px 165px 48px 165px;
    max-width: 1200px;

    .page-title {
      font-family: FuturaNewBold-Reg;
      font-size: 48px;
      color: ${COLORS.orange};

      &::before {
        content: '';
      }
    }
  }

  .categories-wrapper {
    height: 48px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .category {
      display: flex;
      cursor: pointer;
      padding-top: 14px;
      color: ${COLORS.darkBlue};
      font-family: Roboto-Regular;
      font-size: 14px;
      margin-right: 10px;

      &.active {
        margin-bottom: -1px;
        color: ${COLORS.orange};
        border-bottom: 2px solid ${COLORS.orange};
      }

      &.all {
        position: relative;
        width: 145px;
        height: 100%;
        background: ${COLORS.orange};
        color: white;

        i {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 18px;
          display: inline-block;
          padding-top: 1px;
        }

        .categories-dropdown {
          position: absolute;
          top: 48px;
          width: 809px;
          background: white;
          height: 428px;
          box-shadow: 0 20px 20px 0 rgba(37, 60, 94, 0.2);
          border: solid 1px rgba(151, 151, 151, 0.12);
        }
      }
    }
  }
`

export { MarketplaceBox }
