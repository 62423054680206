import { API } from '@constants/api'
import { HttpService } from '@services/HttpsService'

class ProjectService extends HttpService {
  getCurrentStage = projectId => this.get(API.PROJECT.PREPARATIONS.getCurrentStage, { projectId })

  finishFilling = projectId => this.post(API.PROJECT.finishFilling, { projectId })

  abandonDesigner = ({ projectId, description }) => this.post(
    API.PROJECT.DEVELOPMENT.abandonDesigner,
    { projectId, description }
  )

  confirmDesigner = ({ projectId }) => this.post(API.PROJECT.PREPARATIONS.confirmDesigner, { projectId })

  getRoomInformation = projectId => this.get(API.PROJECT.PREPARATIONS.getRoomInformation, { projectId })

  uploadRoomPhoto = data => this.post(API.PROJECT.PREPARATIONS.uploadRoomPhoto, data, 'file')

  uploadRoomPlan = data => this.post(API.PROJECT.PREPARATIONS.uploadRoomPlan, data, 'file')

  addDesignerToProject = ({ designerId, projectId }) => this.post(
    API.PROJECT.addDesignerToProject,
    { designerId, projectId }
  )

  removeRoomPhoto = ({ projectId, photoId }) => this.post(
    API.PROJECT.PREPARATIONS.removeRoomPhoto,
    { projectId, photoId }
  )

  skipPreparationStage = (projectId, stage) => this.post(API.PROJECT.PREPARATIONS.skipStage, { projectId, stage })

  removeRoomPlan = ({ projectId, roomId }) => this.post(API.PROJECT.PREPARATIONS.removeRoomPlan, { projectId, roomId })

  confirmStage = ({ projectId, stage }) => this.post(API.PROJECT.PREPARATIONS.confirmStage, { projectId, stage })

  removeDesigners = data => this.post(API.PROJECT.removeDesigners, data)

  getMembers = projectId => this.get(API.PROJECT.getMembers, { projectId })

  setRoomInformation = data => this.post(API.PROJECT.PREPARATIONS.setRoomInformation, data)

  setFavoriteStyles = data => this.post(API.PROJECT.PREPARATIONS.setFavoriteStyles, data)

  setBudget = data => this.post(API.PROJECT.PREPARATIONS.setBudget, data)

  getBudget = projectId => this.get(API.PROJECT.PREPARATIONS.getBudget, { projectId })

  setAdditionalRoomInfo = data => this.post(API.PROJECT.PREPARATIONS.setAdditionalRoomInfo, data)

  getAdditionalRoomInfo = projectId => this.get(API.PROJECT.PREPARATIONS.getAdditionalRoomInfo, { projectId })

  uploadOldFurniturePhoto = (ProjectId, File) => this.post(
    API.PROJECT.PREPARATIONS.uploadOldFurniturePhoto, { ProjectId, File }, 'file'
  )

  uploadOldFurnishPhoto = (ProjectId, File) => this.post(
    API.PROJECT.PREPARATIONS.uploadOldFurnishPhoto, { ProjectId, File }, 'file'
  )

  removeOldFurniturePhoto = (projectId, photoId) => this.post(
    API.PROJECT.PREPARATIONS.removeOldFurniturePhoto, { projectId, photoId }
  )

  removeOldFurnishPhoto = (projectId, photoId) => this.post(
    API.PROJECT.PREPARATIONS.removeOldFurnishPhoto, { projectId, photoId }
  )

  getOldFurniture = projectId => this.get(API.PROJECT.PREPARATIONS.getOldFurniture, { projectId })

  setOldStuffReady = projectId => this.post(API.PROJECT.PREPARATIONS.setOldStuffReady, { projectId })

  getStyles = projectId => this.get(API.PROJECT.PREPARATIONS.getStyles, { projectId })

  getDevelopmentRooms = projectId => this.get(API.PROJECT.DEVELOPMENT.getRooms, { projectId })

  getRoomMoodboards = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getMoodboards, { projectId, roomId })

  uploadMoodboardPhoto = data => this.post(API.PROJECT.DEVELOPMENT.uploadMoodboardPhoto, data, 'file')

  removeMoodboardPhoto = data => this.post(API.PROJECT.DEVELOPMENT.removeMoodboardPhoto, data)

  getMoodboardArhive = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getArchiveMoodboards, { projectId, roomId })

  getRoomZoning = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getZones, { projectId, roomId })

  uploadZonePhoto = data => this.post(API.PROJECT.DEVELOPMENT.uploadZonePhoto, data, 'file')

  removeZonePhoto = data => this.post(API.PROJECT.DEVELOPMENT.removeZonePhoto, data)

  getZoneArchive = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getArchiveZones, { projectId, roomId })

  getRoomCollage = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getCollages, { projectId, roomId })

  uploadCollagePhoto = data => this.post(API.PROJECT.DEVELOPMENT.uploadCollagePhoto, data, 'file')

  removeCollagePhoto = data => this.post(API.PROJECT.DEVELOPMENT.removeCollagePhoto, data)

  getCollageArchive = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getArchiveCollages, { projectId, roomId })

  getRoomVisualization = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getVisualization, { projectId, roomId })

  uploadVisualizationFile = data => this.post(API.PROJECT.DEVELOPMENT.uploadVisualizationFile, data, 'file')

  removeVisualizationFile = data => this.post(API.PROJECT.DEVELOPMENT.removeVisualizationFile, data)

  getRoomPlans = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getPlans, { projectId, roomId })

  uploadPlansFile = data => this.post(API.PROJECT.DEVELOPMENT.uploadPlanFile, data, 'file')

  removePlansFile = data => this.post(API.PROJECT.DEVELOPMENT.removePlanFile, data)

  getPlansArchive = (projectId, roomId) => this.get(API.PROJECT.DEVELOPMENT.getArchivePlans, { projectId, roomId })

  confirmDevelopmentStage = (projectId, stage) => this.post(API.PROJECT.DEVELOPMENT.confirmStage, { projectId, stage })

  skipDevelopmentStage = (projectId, stage) => this.post(API.PROJECT.DEVELOPMENT.skipStage, { projectId, stage })

  getDevelopmentStage = projectId => this.get(API.PROJECT.DEVELOPMENT.getCurrentStage, { projectId })

  orderRoomVisualization = data => this.post(API.PROJECT.DEVELOPMENT.orderRoomVisualization, data)

  getOrderedVisualizationRooms = projectId => this.get(API.PROJECT.DEVELOPMENT.getOrderedVisualizationRooms, { projectId })

  orderRoomPlans = data => this.post(API.PROJECT.DEVELOPMENT.orderRoomPlans, data)

  getOrderedPlanRooms = projectId => this.get(API.PROJECT.DEVELOPMENT.getOrderedPlanRooms, { projectId })

  finishRoomPlans = (projectId, roomId) => this.post(API.PROJECT.DEVELOPMENT.finishRoomPlans, { projectId, roomId })

  orderBrigade = projectId => this.post(API.PROJECT.DEVELOPMENT.orderBrigade, { projectId })
}

const projectService = new ProjectService()

export { projectService as ProjectService }
export default projectService
