import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import { Button, Icon } from '@ui/atoms/index'
import { RoomTabs } from '@ui/organisms/roomTabs'
import { PhotoCarousel } from '@ui/organisms/photoCarousel'
import { COLORS } from '@constants/colors'
import { Lightbox } from '@ui/organisms'
import { ProjectService } from '@services/ProjectService'
import { ProjectProducts } from '@ui/organisms/projectProducts';
import { downloadFile } from '@helpers';

const MudbordBox = styled.div`
  .page_content {
    margin-bottom: 14px;
  }
  
  .top-content {
    min-height: auto;
  }
  
  .bottom-content {
    margin-top: 0;
  }
  
  .text,
  .page-info {
    font-size: 16px;
    color: ${COLORS.getBlueColor(0.54)};
    line-height: 24px;
    font-family: Roboto-Regular;

    .bold {
      font-family: Roboto-Medium;
      font-weight: 500;
    }
  }
  .page-description {
    margin-bottom: 16px;
  }
  .text {
    max-width: 350px;
  }
  .page-info {
    margin-bottom: 16px;
    color: ${COLORS.darkBlue};
  }
  .wait-box {
    margin-top: 24px;
    .page-info {
      display: inline-block;
    }
    svg {
      fill: ${COLORS.orange};
      vertical-align: top;
    }
    .page-info {
      margin-left: 18px;
      max-width: 379px;
    }
  }
  .page-footer.step2 {
    display: flex;
    justify-content: space-between;
  }

  .btn_content {
    display: flex;
    justify-content: flex-end;
    width: 790px;
    margin: 20px 165px 48px 165px;

    .all-download-btn {
      margin-right: 20px;
    }
  }
  
  .buttons-row {
    display: flex;
    justify-content: space-between;
  }
`

const mapStateToProps = state => ({
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class ClientMoodboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: props.stage.designerIsReady || props.stage.stage > props.step ? 2 : 1,
      activeRoom: 0,
      showFullImage: false,
      currentImageIndex: 0,
      rooms: [],
    }
  }

  componentDidMount() {
    this.getStepInfo()
  }

  getStepInfo = async () => {
    const { activeProject } = this.props

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      const response = await Promise.all(
        result.map(room => ProjectService.getRoomMoodboards(activeProject, room.roomId))
      )

      this.setState({
        rooms: result.map((room, roomIndex) => ({
          model: room.purpose,
          roomPhotos: response[roomIndex].result.moodboardPhotos.map(photo => ({ src: photo.fileLink, alt: `photo-${photo.fileId}` })),
          roomId: room.roomId
        })),
      })
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({ step: nextProps.stage.designerIsReady || nextProps.stage.stage > nextProps.step ? 2 : 1 })
      this.getStepInfo()
    }
  }

  getArchive = async () => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      const { result } = await ProjectService.getMoodboardArhive(activeProject, rooms[activeRoom].roomId)

      downloadFile(result)
    } catch (e) {
      console.log(e)
    }
  }

  confirmStage = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.confirmDevelopmentStage(activeProject, 'Moodboard')

    goNext(true)
  }

  renderFirstStep = () => {
    const { rooms } = this.state
    const { updateStage } = this.props

    return (
      <React.Fragment>
        <div className="page_content">
          <div className="page-body">
            <div className="page-title">
              Мудборд
            </div>
            <div className="g_big-text mb32">
              Дизайнер приступил к созданию мудборда.
              <br />
              <br />
              Мудборд - это набор картинок, которые отражают общее настроение. Идею.
              Показывают насколько точно дизайнер уловил ваши пожелания по стилю на основе выбранных картинок.
            </div>
            <div className="g_text dark">
              В среднем на 1 комнату потребуется до двух дней, но как правило меньше. Вы можете общаться с вашим
              дизайнером в чате.
              <br />
              <br />
              После того, как Дизайнер загрузит файлы у вас будет 2 дня на согласование и обсуждение Мудборда.
            </div>
            <div className="wait-box">
              <Icon icon="watch" width={48} height={48} />
              <div className="page-info">
                Дизайнер готовит материалы. Пожалуйста, дождитесь пока он их
                загрузит на платформу, вам придёт оповещение.
              </div>
            </div>
            <div className="buttons-row">
              <div />
              <Button
                type="bordered"
                text="Проверить"
                onClick={() => updateStage()}
              />
            </div>
          </div>
          {!!rooms.length && (
            <ProjectProducts
              rooms={rooms}
            />
          )}
        </div>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const {
      rooms, activeRoom, showFullImage, currentImageIndex
    } = this.state
    const { step, stage, goNext } = this.props

    return (
      <React.Fragment>
        <div className="page_content top-content">
          <div className="page-title">
            Мудборд
          </div>
          <div className="page-description">
            Дизайнер прикрепил Мудбоард. Пожалуйста, проверьте. У вас есть 2 дня на согласование и обсуждение данного
            этапа.
            <br />
            <br />
            Дизайнер может исправлять работу в режиме обсуждения с вами.
          </div>
          <div className="g_small-title mb16">
            Выберите комнату
          </div>
          <RoomTabs
            items={rooms}
            active={activeRoom}
            onChange={index => this.setState({ activeRoom: index })}
          />
        </div>
        {!!rooms.length && (
          <React.Fragment>
            <PhotoCarousel
              photos={rooms[activeRoom].roomPhotos}
              imgOnClick={index => this.setState({
                currentImageIndex: index,
                showFullImage: true
              })
              }
            />
            <div className="btn_content">
              <Button
                className="all-download-btn"
                color="additional"
                type="filled"
                text="Скачать одним архивом"
                icon="save_alt"
                onClick={this.getArchive}
              />
              {stage.stage === step ? (
                <Button
                  type="filled"
                  text="Согласовать и продолжить"
                  onClick={this.confirmStage}
                />
              ) : (
                <Button
                  text="Вперед"
                  onClick={() => goNext()}
                />
              )}
            </div>
            <div className="page_content bottom-content">
              <ProjectProducts
                rooms={rooms}
              />
            </div>
            <Lightbox
              isOpen={showFullImage}
              currentImageIndex={currentImageIndex}
              images={rooms[activeRoom].roomPhotos.map(f => f.src)}
              getPrev={() => this.setState({ currentImageIndex: currentImageIndex - 1 })}
              getNext={() => this.setState({ currentImageIndex: currentImageIndex + 1 })}
              onCancel={() => this.setState({ showFullImage: false })}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { step } = this.state

    return (
      <MudbordBox>
        {step === 1 && this.renderFirstStep()}
        {step === 2 && this.renderSecondStep()}
      </MudbordBox>
    )
  }
}

export { ClientMoodboard }
