import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'

import { MainFooter } from '@ui/organisms/mainFooter'
import { Header, OtherBox } from '@pages/other/style'

@withRouter
class OfferDesign extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
            Публичная оферта по предложению заключить договор на оказание услуг
            маркетплейса мебели в дополненной реальности «Faradise»
          </h1>
          <span>
            Общество с ограниченной ответственностью «Центр технологий и медиа
            групп», именуемое в дальнейшем «Faradise», предлагает свои Услуги,
            размещенные на Платформе, любому дееспособному
            физическому/юридическому лицу/индивидуальному предпринимателю,
            именуемому в дальнейшем «Покупатель», в случае принятия последним
            условий настоящей Оферты (безусловный акцепт). Настоящая Оферта
            заключается в соответствии с Пользовательским соглашением (оферта)
            на использование платформы-сервиса маркетплейса мебели в дополненной
            реальности «Faradise» (далее – «Пользовательское оглашение»)
            размещенным в сети интернет и доступным по адресу:
            www.faradise.ru/design/offer_client_design, при этом настоящая Оферта
            является самостоятельным юридическим правоустанавливающим документом
            и дополняет Пользовательское соглашение и использует и применяет
            терминологию и определения изложенные и зафиксированные в
            {' '}
            <strong>
п. 1.1.
            </strong>
            {' '}
Пользовательского соглашения. Оферта не
            требует двустороннего физического подписания, считается заключенной
            с момента её акцепта Покупателем. Под Акцептом стороны понимают
            полное и безоговорочное принятие Покупателем условий публичной
            оферты путем выражения согласия нажатием кнопки «Согласен» при
            регистрации на Платформе либо при полной или частичной оплате Услуг
            в случае оформления Заказа на услуги (далее – «Заказ») по телефону.
          </span>
          <div className="sub-title indent">
Основные условия Оферты
          </div>
          <ol className="with-sub firstSub">
            <li>
              По настоящей Оферте Faradise обязуется совершать от своего имени
              сделки на оказание услуг Покупателям, которые включают в себя
              следующие услуги оказываемые Faradise:
              <ol className="with-sub">
                <li>
                  Мудбоард, состоящий из стилистических решений, примеров
                  реализации схожих дизайнов, передаваемый Покупателю в виде
                  файлов формата .jpeg.
                </li>
                <li>
                  Зонирование, состоящие из документации по размещению предметов
                  мебели, декора, бытовой техники и прочих составляющих дизайна
                  интерьера в комнатах Клиента, передаваемый Покупателю в виде
                  файлов формата .jpeg.
                </li>
                <li>
                  Коллаж, состоящий из документации по предполагаемому
                  визуальному виду помещения Клиента, передаваемый Покупателю в
                  виде файлов формата .jpeg.
                </li>
                <li>
                  3D визуализация, состоящая из интерактивного 3D-тура в режиме
                  360°, передаваемый Покупателю в виде интерактивной части
                  платформы и фотографий 3D-тура в виде файлов формата .jpeg.
                </li>
                <li>
                  Разработка рабочих чертежей, состоящие из всей необходимой
                  документации по мнению Faradise, необходимой для ремонта
                  помещения Клиента, передаваемый Покупателю в виде файлов
                  формата .pdf.
                </li>
                <li>
                  Заказ бригады на ремонт, состоящие из списка предлагаемых
                  подрядчиков, специализирующихся на ремонте, передаваемый
                  Покупателю в виде файлов формата .pdf.
                </li>
              </ol>
            </li>
            <li>
              Faradise обязуется совершить сделку на оказание Услуг, а
              Покупатель обязуется оплатить и принять Услуги, заказанные на
              Платформе и подтвержденные Faradise.
            </li>
            <li>
              Совершая Заказ, Покупатель гарантирует, что полностью ознакомился
              с текстом настоящей Оферты, полностью понимает и принимает ее
              условия.
            </li>
            <li>
              Оформление Заказа осуществляется через Платформу путем заполнения
              и отправки Faradise формы с указанием параметров заказа изложенном
              на каждом шаге работы с платформой Faradise, находящуюся по адресу
              www.faradise.ru. Обязательным условием формирования заказа
              является предоставление Покупателем следующей информации и
              материалов:
              <ol className="with-sub no-list">
                <li>
а) Ф.И.О. покупателя;
                </li>
                <li>
б) контактный телефон;
                </li>
                <li>
в) адрес электронной почты;
                </li>
                <li>
                  г) предпочитаемый Покупателем стиль оформления (в виде
                  текстового описания в свободной форме);
                </li>
                <li>
                  д) предполагаемой бюджет на покупку мебели, декора и бытовой
                  техники;
                </li>
                <li>
е) предполагаемый бюджет на ремонт;
                </li>
                <li>
                  ж) дополнительная информация, запрашиваемая представителем
                  Faradise.
                </li>
                <li>
з) Фотографии помещения (не менее 8 шт.);
                </li>
              </ol>
            </li>
            <li>
              Faradise не несет ответственности за точность и правильность
              информации, предоставленной Покупателем. В случае неисполнения
              Faradise обязательств по причине сообщения Покупателем
              недостоверных сведений о себе Faradise не несет ответственности за
              неисполнение Подтвержденного Заказа.
            </li>
            <li>
              Информация (Инструкция) по исполнению заказа доступна на Платформе
              по адресу: www.faradise.ru/design/just_work
            </li>
            <li>
              Информация об Услугах, включая сроки их оказания, представленная
              на Платформе, носит справочный характер и подлежит уточнению
              Faradise при подтверждении Заказа.
            </li>
            <li>
              В течение 24 часов с момента оформления Заказа Faradise
              подтверждает Покупателю получение Заказа посредством
              информирования через Платформу, согласовывает с Покупателем
              возможность оказания услуги, сроки исполнения заказа. После
              согласования указанных условий заказ считается подтвержденным.
            </li>
            <li>
              В случае аннулирования подтвержденного и предоплаченного заказа по
              инициативе Faradise стоимость аннулированного заказа возвращается
              Покупателю на расчетный счет, с которого произведена оплата, без
              каких-либо штрафных санкций и выплат компенсационного характера.
            </li>
            <li>
              Покупатель имеет возможность вносить изменения в подтвержденный
              заказ путем внутренних сообщений через личный кабинет на Платформе
              до момента оказания услуг. После внесения изменений в
              Подтвержденный Заказ срок оказания услуг начинает исчисляться с
              момента внесения таких изменений.
            </li>
            <li>
              В случае, если после размещения заказа у Покупателя изменились
              сведения, имеющие значение для надлежащего исполнения Faradise
              своих обязательств, он обязан незамедлительно, уведомить об этом
              Faradise по телефону, указанному на Платформе либо путем
              внутренних сообщений через личный кабинет на Платформе.
            </li>
            <li>
              Покупатель вправе выбрать один из способов оплаты услуг, описание
              и условия совершения которых расположены на Платформе и доступны
              по адресу: www.faradise.ru/design/how_to_pay
            </li>
            <li>
              Окончательная стоимость конкретного заказа подтверждается
              Faradise, при этом стоимость указывается в российских рубля и
              рассчитывается на основании тарифов на оказание услуг размещенных
              на Платформе и доступных по адресу:
              www.faradise.ru/design/rates_client_design. Стоимость услуг,
              представленных на Платформе, может быть изменена Faradise в
              одностороннем порядке, при этом цена на подтвержденный Заказ
              изменению не подлежит.
            </li>
            <li>
              Заказ считается выполненным и обязательства Faradise перед
              Покупателем считаются исполненными в момент, когда Покупатель
              подтверждает это на Платформе путем нажатия на кнопку «Подтвердить
              и продолжить». В случае, если Покупатель не подтверждает принятие
              заказа в течение 48 часов с момента исполнения Заказа, такой Заказ
              считается автоматически принятым.
            </li>
            <li>
              При приемке Услуг Покупатель обязан проверить результат оказанных
              услуг в соответствии с п. 1 настоящей Оферты, а также данным,
              указанным в подтвержденном заказе. В случае обнаружения
              недостатков результата оказанных Услуг и/или его несоответствия
              данным, указанным на Платформе или в подтвержденном Заказе, путем
              внутренних сообщений через личный кабинет на Платформе, в
              противном случае, услуги считаются принятым без претензий по
              качеству и срокам оказания.
            </li>
            <li>
              Faradise обязуется приложить все усилия для соблюдения условий
              оказания услуг в подтвержденном заказе, тем не менее, задержки в
              сроках оказания услуг возможны ввиду непредвиденных обстоятельств,
              произошедших не по вине Faradise.
            </li>
            <li>
              Покупатель вправе предъявить претензии в отношении качества
              оказанных услуг в порядке и сроки, установленные действующим
              законодательством РФ, оформив свои требования в письменной форме.
            </li>
            <li>
              Не подлежат удовлетворению требования Покупателей о возврате
              стоимости оказанных услуг, основанные на вкусовых и стилистических
              различиях и несоответствии ожиданий Покупателя не связанных с
              ненадлежащим качество оказания услуг.
            </li>
            <li>
              В случае, если услуги были оплачены безналичным способом, возврат
              денежных средств осуществляется на основании оформленной заявки на
              возврат, при этом возврат денежных средств производится на тот же
              расчетный счет, с которого производилась оплата.
            </li>
            <li>
              Возврат денежных средств определяется Faradise индивидуально, но
              не может превышать тридцати пяти календарных дней с моменты
              признания соответствующего требования.
            </li>
            <li>
              Акцептуя оферту, Покупатель подтверждает, что он ознакомлен и
              согласен с условиями Политикой конфиденциальности Платформы,
              размещенной по адресу: www.faradise.ru/design/agreement
            </li>
            <li>
              Акцептуя оферту, Покупатель дает разрешение Faradise, его
              уполномоченным представителям и его партнерам, в целях
              информирования о товарах и услугах Faradise, заключения и
              исполнения различных Договоров обрабатывать – собирать,
              записывать, систематизировать, накапливать, хранить, уточнять
              (обновлять, изменять), извлекать, использовать, передавать (в том
              числе, поручать обработку другим лицам), обезличивать,
              блокировать, удалять, уничтожать персональные данные Покупателя к
              которым относятся: фамилию, имя, пол, дату рождения, номера
              телефонов и адрес электронной почты.
            </li>
            <li>
              Акцептуя оферту, Покупатель дает разрешение Faradise в целях
              информирования о товарах, услугах, услугах направлять на указанный
              Покупателем номер мобильного телефона и/или адрес электронной
              почты рекламу и информацию о товарах, работах, услугах Faradise и
              его партнеров, в том числе, в виде смс-сообщений, входящих вызовов
              и e-mail рассылок.
            </li>
            <li>
              Faradise гарантирует конфиденциальность и защиту полученной
              информации в соответствии с действующим законодательством РФ.
            </li>
            <li>
              В случаях, предусмотренных действующим законодательством РФ,
              Покупатель вправе отозвать свое согласие на обработку персональных
              данных, при этом согласие считается отозванным по истечении 30
              дней с даты получения Faradise по адресу, указанному на Платформу,
              направленного Покупателем письменного заявления об отзыве
              настоящего такого согласия.
            </li>
            <li>
              Faradise вправе использовать технологию «cookies» (технология,
              позволяющая веб-серверу посылать служебную информацию на компьютер
              пользователя и сохранять ее в браузере).
            </li>
            <li>
              Faradise получает информацию об идентификаторе устройства
              посетителя Сайта, подключённого к локальной сети и/или сети
              Интернет (IP-адресе). Данная информация используется исключительно
              в статистических целях и не используется для установления личности
              посетителя.
            </li>
            <li>
              Faradise не отвечает за убытки Покупателя, возникшие в результате
              неправомерных действий третьих лиц.
            </li>
            <li>
              Стороны освобождаются от ответственности за полное или частичное
              неисполнение своих обязательств, если такое неисполнение явилось
              следствием действия обстоятельств непреодолимой силы в результате
              событий чрезвычайного характера, которые Стороны не могли
              предвидеть и предотвратить разумными мерами. В иных случаях
              неисполнения или ненадлежащего исполнения своих обязательств,
              Стороны несут ответственность в соответствии с действующим
              законодательством Российской Федерации.
            </li>
          </ol>
        </OtherBox>
        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { OfferDesign }
