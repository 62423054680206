import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'
import { Icon } from '@ui/atoms/icon'

import bigLogo from '@assets/images/big-logo.png'
import appsExample from '@assets/images/apps-example.png'
import free from '@assets/images/hero/free.png'
import delivery from '@assets/images/hero/delivery.png'
import shop from '@assets/images/hero/shop.png'
import rbc from '@assets/images/hero/rbc.png'
import village from '@assets/images/hero/village.png'
import inc from '@assets/images/hero/inc.png'

const HeroBox = styled.div`
  .header-box {
    background-color: ${COLORS.getBlueColor(0.04)};

    .header-container {
      margin: 0 165px;
      min-height: 670px;
      position: relative;
      padding-bottom: 48px;

      .logo-image {
        margin-top: 120px;
        width: 445px;
      }

      .subtitle {
        margin: 80px 0;
        max-width: 445px;
        font-family: FuturaNewBook-Reg;
        font-size: 28px;
        line-height: 1.29;
        color: ${COLORS.darkBlue};
      }

      .right-img {
        width: 570px;
        position: absolute;
        right: 20px;
        bottom: 0;
      }
    }

    .store-buttons {
      display: flex;
      justify-content: space-between;
      width: 445px;

      .download {
        cursor: pointer;
        width: 204px;
        height: 64px;
        border-radius: 6px;
        background-color: #ffffff;
        border: solid 1.6px rgba(37, 60, 94, 0.12);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin-top: 15px;

        svg {
          width: 24px;
          height: 30px;
          margin-right: 17px;
          margin-left: 16px;

          path {
            fill: #253c5e;
          }
        }

        &:hover {
          background-color: #253c5e;

          .download-text {
            .top,
            .bottom {
              color: white;
              opacity: 0.87;
            }
          }

          svg {
            path {
              fill: white;
              opacity: 0.87;
            }
          }
        }

        .download-text {
          .top {
            opacity: 0.53;
            font-family: Roboto-Medium;
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.83;
            letter-spacing: 0.4px;
            color: #253c5e;
            text-transform: uppercase;
            margin-bottom: -4px;
          }

          .bottom {
            font-family: Roboto-Medium;
            font-size: 23px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #253c5e;
          }
        }
      }
    }

    .social-links {
      display: flex;
      margin-top: 100px;

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        fill: ${COLORS.getBlueColor(0.54)};
      }
    }
  }

  .what-is-faradise {
    padding-top: 80px;
    width: 100%;
    padding-left: 165px;

    .title {
      font-family: FuturaNewBold-Reg;
      font-size: 31px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;
      letter-spacing: -1.3px;
      color: ${COLORS.darkBlue};
    }

    .row {
      margin-top: 40px;
      display: flex;

      .img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        margin-right: 46px;
      }

      .text-column {
        width: 635px;

        .sub-title {
          font-family: FuturaNewBold-Reg;
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: ${COLORS.darkBlue};
        }

        .text {
          margin-top: 16px;
          opacity: 0.7;
          font-family: Roboto-Regular;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.75;
          letter-spacing: normal;
          color: ${COLORS.darkBlue};
        }
      }

      .count-column {
        padding-left: 125px;

        .count {
          font-family: FuturaNewBold-Reg;
          font-size: 60px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.07;
          letter-spacing: normal;
          color: ${COLORS.orange};
        }

        .text {
          font-family: FuturaNewBook-Reg;
          font-size: 28px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: ${COLORS.darkBlue};
        }
      }
      }
    }
  }

  .news-about-us {
    margin-top: 80px;
    margin-left: 165px;
    border-radius: 32px;
    background-color: rgba(37, 60, 94, 0.03);
    padding-left: 95px;
    padding-top: 60px;
    padding-bottom: 66px;
    margin-bottom: 80px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .title {
      font-family: FuturaNewBold-Reg;
      font-size: 31px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;
      letter-spacing: -1.3px;
      color: ${COLORS.darkBlue};
    }

    .news {
      margin-top: 32px;
      display: flex;

      .new {
        width: 255px;
        margin-right: 125px;

        .type {
          opacity: 0.38;
          font-family: FuturaNewBold-Reg;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: 2px;
          color: ${COLORS.darkBlue};
          text-transform: uppercase;
        }

        .sub-title {
          display: block;
          font-family: FuturaNewBold-Reg;
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          letter-spacing: -0.5px;
          color: ${COLORS.darkBlue};
          height: 72px;

          &:hover {
            color: ${COLORS.orange};
          }
        }

        .date {
          margin-top: 32px;
          opacity: 0.38;
          font-family: Roboto-Regular;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          color: ${COLORS.darkBlue};
          margin-bottom: 5px;
        }

        .logo {
          height: 16px;
        }
      }
    }
  }
`

@withRouter
class Hero extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <HeroBox isInnerPage={isInnerPage}>
          <div className="header-box">
            <div className="header-container">
              <img src={bigLogo} alt="" className="logo-image" />
              <div className="subtitle">
                Faradise - платформа, с помощью которой пользователь может &quot;примерить&quot; мебель, товары для дома и дизайн интерьера
                еще до покупки благодаря технологии дополненной реальности
              </div>

              <div className="store-buttons">
                <a
                  className="download"
                  href="https://itunes.apple.com/ru/app/faradise/id1315339948?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="39" viewBox="0 0 32 39">
                    <path
                      fill="#253C5E"
                      fillRule="nonzero"
                      // eslint-disable-next-line
                      d="M28.665 33.487c-1.576 2.313-3.248 4.57-5.793 4.607-2.546.056-3.363-1.473-6.25-1.473-2.906 0-3.799 1.436-6.211 1.53-2.489.093-4.37-2.463-5.965-4.72-3.248-4.607-5.736-13.095-2.393-18.803 1.653-2.835 4.616-4.626 7.826-4.682 2.431-.037 4.749 1.623 6.25 1.623 1.481 0 4.292-1.996 7.237-1.697 1.234.056 4.691.485 6.914 3.693-.171.112-4.122 2.388-4.084 7.107.057 5.634 5.034 7.518 5.09 7.536-.056.13-.797 2.686-2.62 5.28zM17.82 3.642C19.206 2.093 21.504.918 23.404.844c.246 2.182-.646 4.383-1.976 5.95-1.31 1.586-3.476 2.817-5.604 2.649-.284-2.145.78-4.384 1.995-5.801z"
                      opacity=".868"
                    />
                  </svg>
                  <div className="download-text">
                    <div className="top">
Загрузите в
                    </div>
                    <div className="bottom">
App Store
                    </div>
                  </div>
                </a>

                <a
                  className="download"
                  href="https://play.google.com/store/apps/details?id=com.awespace.faradise"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 34 37">
                    <path
                      fill="#FFF"
                      fillRule="nonzero"
                      // eslint-disable-next-line
                      d="M.57 33.62V2.66C.57 1.584 1.195.637 2.113.2l18.1 17.94-18.1 17.938A2.744 2.744 0 0 1 .57 33.62zm25.376-9.798L6.174 35.15l15.6-15.462 4.172 4.134zm6.156-7.85c.625.492 1.084 1.257 1.084 2.168 0 .91-.404 1.639-1.047 2.149l-4.208 2.404-4.594-4.553 4.594-4.553 4.17 2.385zM6.174 1.13l19.772 11.328-4.171 4.135-15.6-15.463z"
                      opacity=".869"
                    />
                  </svg>
                  <div className="download-text">
                    <div className="top">
Доступно в
                    </div>
                    <div className="bottom">
Google Play
                    </div>
                  </div>
                </a>
              </div>

              <div className="social-links">
                <a href="https://www.facebook.com/faradiseapp/" target="_blank" rel="noopener noreferrer">
                  <Icon icon="facebook" className="icon" />
                </a>
                <a
                  href="https://instagram.com/faradiseapp?utm_source=ig_profile_share&igshid=17zh4y2ai2lx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="instagram" className="icon" />
                </a>
              </div>
              <img src={appsExample} alt="" className="right-img" />
            </div>
          </div>

          <div className="what-is-faradise">
            <div className="title">
Что такое Faradise?
            </div>

            <div className="row">
              <img src={free} alt="" className="img" />
              <div className="text-column">
                <div className="sub-title">
Бесплатный дизайн интерьера
                </div>
                <div className="text">
                  Мы подобрали лучших дизайнеров, которые сделают дизайн интерьера бесплатно. Единственное за что вы можете заплатить -
                  3D-визуализация и Рабочие чертежи.
                </div>
              </div>
              <div className="count-column">
                <div className="count">
200+
                </div>
                <div className="text">
Партнеров
                </div>
              </div>
            </div>

            <div className="row">
              <img src={delivery} alt="" className="img" />
              <div className="text-column">
                <div className="sub-title">
                Доставка и гарантии
                </div>
                <div className="text">
                Мы самостоятельно работаем со всеми производителями и отслеживаем ваш заказ. Вам не нужно думать,
                как найти того или иного поставщика - вы работаете через нас.
                А мы работаем как часы. Если вам не понравится привезенный товары - вы можете от него отказаться.
                </div>
              </div>
              <div className="count-column">
                <div className="count">
300000+
                </div>
                <div className="text">
Товаров
                </div>
              </div>
            </div>

            <div className="row">
              <img src={shop} alt="" className="img" />
              <div className="text-column">
                <div className="sub-title">
                Магазин будущего
                </div>
                <div className="text">
                &quot;Примеряйте&quot; товары из нашего магазина в дополненной реальности прямо у себя в комнате.
                Уже сейчас вы можете скачать наше приложение и попробовать.
                Теперь вы точно будете знать поместится ли тумбочка между кроватью и стеной,
                или влезет ли холодильник на кухню еще до покупки!
                </div>
              </div>
              <div className="count-column">
                <div className="count">
100+
                </div>
                <div className="text">
Дизайнеров
                </div>
              </div>
            </div>
          </div>

          <div className="news-about-us">
            <div className="title">
              О нас пишут
            </div>

            <div className="news">
              <div className="new">
                <div className="type">
Видео
                </div>
                <a
                  className="sub-title"
                  href="http://tv.rbc.ru/archive/network/5b0e8ec52ae596c57a02d210"
                  target="_blank"
                  rel="noopener noreferrer"
                >
              В Рунете запустился первый маркетплейс с AR
                </a>

                <div className="date">
30 мая
                </div>
                <img src={rbc} alt="logo" className="logo" />
              </div>

              <div className="new">
                <div className="type">
статья
                </div>
                <a
                  className="sub-title"
                  href="https://www.the-village.ru/village/service-shopping/design-hack/321703-prilozheniya-dlya-remonta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                10 мобильных приложений для ремонта
                </a>

                <div className="date">
14 Августа
                </div>
                <img src={village} alt="logo" className="logo" />
              </div>

              <div className="new">
                <div className="type">
статья
                </div>
                <a
                  className="sub-title"
                  // eslint-disable-next-line
                  href="https://incrussia.ru/understand/kejs-faradise-zapustit-mebelnyj-marketplejs-podsadit-proizvoditelej-divanov-na-dopolnennuyu-realnost-i-otkazatsya-ot-ico"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                Запустить мебельный маркетплейс, подсадить производителей …
                </a>

                <div className="date">
30 Августа
                </div>
                <img src={inc} alt="logo" className="logo" />
              </div>
            </div>
          </div>
        </HeroBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Hero }
