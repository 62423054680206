import React from 'react'
import styled from 'styled-components'

import { COLORS } from '@constants/colors'

import bg from '@assets/images/icons/bg.png'

const WatchVideoBox = styled.a`
  display: block;
  display: flex;
  width: 250px;
  align-items: center;
  margin-top: -35px;
  margin-bottom: 48px;
  text-decoration: none;

  .wrapper-1 {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: solid 1px ${COLORS.getOrangeColor(0.12)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 27px;
    border: solid 1px ${COLORS.getOrangeColor(0.38)};
  }

  .wrapper-3 {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: solid 1px ${COLORS.getOrangeColor(0.54)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper-4 {
    border-radius: 50%;
    border: solid 1px ${COLORS.getOrangeColor(0.54)};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    i {
      z-index: 2;
      color: white;
      font-size: 20px;
      opacity: 0.85;
    }
  }

  .text {
    .top {
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }

    .bottom {
      font-family: Roboto-Medium;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${COLORS.orange};
    }
  }
`

const WatchVideo = ({ link, className }) => (
  <WatchVideoBox href={link} target="_blank" className={className}>
    <div className="wrapper-1">
      <div className="wrapper-2">
        <div className="wrapper-3">
          <div className="wrapper-4">
            <img src={bg} alt="" />
            <i className="material-icons">
play_arrow
            </i>
          </div>
        </div>
      </div>
    </div>

    <div className="text">
      <div className="top">
Инструкция от дизайнера
      </div>
      <div className="bottom">
Смотреть
      </div>
    </div>
  </WatchVideoBox>
)

export { WatchVideo }
