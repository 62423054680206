import styled from 'styled-components'

import { COLORS } from '@constants/colors'

const DesignerChoiceBox = styled.div`
  margin-top: 33px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .choice-title {
    font-family: FuturaNewLight-Reg;
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
  }

  .show-more-box {
    margin-top: 20px;

    .text {
      margin-bottom: 32px;
      font-family: FuturaNewBook-Reg;
      font-size: 28px;
      color: ${COLORS.darkBlue};
      line-height: 1.14;
    }

    .button {
      width: 100%;
    }
  }
`

const FindOtherDesignersBox = styled.div`
  overflow: auto;
  max-height: 75vh;
  padding-right: 20px;

  .title {
    font-family: FuturaNewBold-Reg;
    font-size: 48px;
    font-weight: bold;
    color: ${COLORS.orange};
  }

  .text {
    margin-bottom: 48px;
    font-family: FuturaNewBook-Reg;
    font-size: 28px;
    font-weight: normal;
    color: ${COLORS.darkBlue};
  }

  .button {
    width: 100%;
  }

  .designer-card {
    display: flex;
    margin-bottom: 30px;

    .photo {
      width: 160px;
      height: 160px;
      border-radius: 8px;
    }

    .info {
      margin-left: 30px;

      .name {
        font-size: 24px;
        font-weight: 500;
        font-family: Roboto-Medium;
        color: ${COLORS.darkBlue};
      }

      .textarea {
        margin-top: 10px;
        width: 540px;
        height: 112px;
      }
    }
  }
`

export { DesignerChoiceBox, FindOtherDesignersBox }
