import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'

import { MainFooter } from '@ui/organisms/mainFooter'
import { Header, OtherBox } from '@pages/other/style'

@withRouter
class Instruction extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
            Инструкция по работе с платформой Faradise (платформа-сервис
            агрегатор мебели в дополненной реальности Faradise)
          </h1>
          <div className="sub-title">
            <p>
Российская Федерация, город Москва
            </p>
            <p>
Дата размещения: 12.04.2018
            </p>
            <p>
Дата вступления в силу: 12.04.2018
            </p>
          </div>
          <span>
            Настоящая Инструкция по работе с платформой Faradise (далее -
            Инструкция) представляет собой обязательное дополнение (составную
            часть) Пользовательского соглашения (оферты) на использование
            платформы-сервиса агрегатор мебели в дополненной реальности
            &quot;Faradise&quot; (далее - Оферта) Общества с ограниченной
            ответственностью &quot;Центр технологий и медиа групп&quot; (далее -
            &quot;Faradise&quot;) и содержит основополагающие и обязательные к
            исполнению правила (инструкции) соблюдение которых является
            обязательным условием использования сервиса &quot;Faradise (далее -
            &quot;Платформа&quot;)&quot;. Значения терминов и определений,
            применяемых Инструкцией, полностью совпадают с аналогичными
            терминами и определениями Оферты.
          </span>
          <ol className="with-sub firstSub">
            <li>
              Субъектный состав и регистрация
              <ol className="with-sub">
                <li>
                  Сторонами Оферты и Инструкции, принимающими на себя взаимные
                  обязательства, являются:
                </li>
                <p>
                  Общество с ограниченной ответственностью &quot;Центр
                  технологий и медиа групп&quot; - далее &quot;Faradise&quot;,
                  выступающий собственником и единственным оператором сервиса
                  Faradise;
                </p>
                <p>
                  Партнер - лицо, осуществившее Акцепт Оферты и являющееся
                  заказчиком Услуг Платформы, по заключенному на основание
                  Оферты, Договору.
                </p>
                <li>
                  Для целей правильного использования Faradise, все Партнёры
                  подразделяются на следующие статусы:
                </li>
                <ol className="with-sub">
                  <li>
                    Дистрибьютор - Партнер, не производящий мебель
                    самостоятельно, а только продающий её;
                  </li>
                  <li>
                    Производитель - Партнер, производящий мебель, но
                    самостоятельно не продающий её в розницу;
                  </li>
                  <li>
                    Дистрибьютор и Производитель - Партнер, производящий мебель
                    и самостоятельно продающий её в розницу.
                  </li>
                </ol>
              </ol>
              <li>
                Партнёр сам, при регистрации на Платформе, определяет (путем
                выбора из предложенных вариантов) свой статус, который, в
                дальнейшем, можно будет сменить в Личном кабинете. В зависимости
                от выбранного статуса Партнер должен, при регистрации, указать
                данные согласно Таблице № 1.
                {' '}
              </li>
              <li>
                Партнёр проходит регистрацию на Платформе:
                <ol className="with-sub">
                  <li>
                    Путем самостоятельного заполнения первичной регистрационной
                    формы (Таблица № 1) на Платформе. После заполнения
                    регистрационной формы (Таблица № 1), Партнеру, в течение
                    двух рабочих дней приходит Invitee на указанный при
                    заполнении E-mail, вместе с предложением произвести акцепт
                    Оферты.
                    {' '}
                  </li>
                  <li>
                    Путем регистрации Партнёра через/с помощью
                    сотрудника/представителя Faradise, с соблюдением порядка
                    предусмотренного в п.1.4.1., при этом такая регистрация
                    будет считаться завершенной только после того, как Партнёр
                    самостоятельно произведет акцепт Оферты на Платформе.
                    {' '}
                  </li>
                </ol>
              </li>
              <div>
Таблица № 1 - Первичная регистрационная форма
              </div>
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>
Поле
                    </th>
                    <th>
Производитель
                    </th>
                    <th>
Дистрибьютор
                    </th>
                    <th>
Производитель и Дистрибьютор
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
Название бренда
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Краткое описание
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Сайт
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Фамилия (контактного лица)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Имя (контактного лица)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Отчество (контактного лица)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
E-mail (контактного лица)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Телефон (контактного лица)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                </tbody>
              </table>
              <li>
                После регистрации и акцепта Оферты Партнер должен в течение двух
                рабочих дней заполнить в Личном кабинете Партнера Основные
                регистрационные данные в соответствии с Таблицей №2.
              </li>
              <div>
Таблица №2 - Основные регистрационные данные
              </div>
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>
Поле
                    </th>
                    <th>
Производитель
                    </th>
                    <th>
Дистрибьютор
                    </th>
                    <th>
Производитель и Дистрибьютор
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
Логотип
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Фамилия (сотрудника, отвечающего за обработку заказов)
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Имя (сотрудника, отвечающего за обработку заказов)
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Отчество (сотрудника, отвечающего за обработку заказов)
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
                    E-mail (сотрудника, отвечающего за обработку заказов)
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Контактный телефон (сотрудника, отвечающего за обработку
                    заказов)
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Фамилия (руководителя)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Имя (руководителя)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Отчество (руководителя)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
E-mail (руководителя)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Контактный телефон (руководителя)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Название
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Юридический адрес
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
E-mail
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
ОГРН
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
ИНН
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
КПП
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Р/с (расчетный счет)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Банк
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
БИК
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Корр. счет
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
ОКПО
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Информация о магазинах
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Название
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Город
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Улица
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Дом
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Корпус (строение/владение)
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Как добраться
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Телефон
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Время работы
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Информация о доставке по всей России
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Информация о доставке по городу
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Информация о самовывозе
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Информация о имеющихся Дистрибьюторах
                    </td>
                    <td>
+
                    </td>
                    <td>
-
                    </td>
                    <td>
+
                    </td>
                  </tr>
                </tbody>
              </table>
              <li>
                Функциональные возможности (Функции) Партнёра, отличаются в
                зависимости от выбранного Партнером статуса в соответствии с
                Таблицей №3.
              </li>
              <div>
Таблица № 3 - Функциональные возможности Партнера
              </div>
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <span>
Функции
                      </span>
                    </th>
                    <th>
                      <span>
Производитель
                      </span>
                    </th>
                    <th>
                      <span>
Дистрибьютор
                      </span>
                    </th>
                    <th>
                      <span>
Производитель и Дистрибьютор
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
Добавлять собственные товары
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Продавать товары любых производителей (подключаться к их
                    товарам, обрабатывать заказы)
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                  <tr>
                    <td>
Создавать точки продаж
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                    <td>
+
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              Пополнение баланса
              <ol className="with-sub">
                <li>
                  Пополнение баланса является одним из условий работы Партнёра
                  на Платформе и может осуществляться как путем оплаты Партнёром
                  выставленного по итогу регистрации, счета или с помощью
                  банковской карты в Личном кабинете.
                </li>
                <li>
                  Пополнение баланса:
                  <ol className="with-sub">
                    <li>
                      Осуществляется Производителем, Дистрибьютором,
                      Производителем и Дистрибьютор в случае, если им были
                      прикреплены товары, к которым необходимо
                      разработать/доработать 3D-модели, при этом стоимость такой
                      разработки/доработки он увидит в личном кабинете при
                      добавлении соответствующего товара;
                    </li>
                    <li>
                      Осуществляется Производителем, Дистрибьютором,
                      Производителем и Дистрибьютор для оплаты подключения к
                      Платформе, что дает ему право на использование любых
                      3D-моделей на Платформе, при этом любые взаиморасчёты
                      регламентируется в своей стоимости в документе Тарифы
                      Платформы.
                    </li>
                    <li>
                      Осуществляется Производителем, Дистрибьютором,
                      Производителем и Дистрибьютор для целей оплаты рекламных и
                      дополнительных возможностей Платформы.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Работа с товарами и моделями
              <ol className="with-sub">
                <li>
                  После оплаты, Дистрибьютор среди всего списка товаров может
                  выбрать именно те, которыми он действительно торгует, выбор
                  модели осуществляется из всего доступного списка.
                </li>
                <li>
                  Для начала работы с Платформой Производитель (и
                  Производитель/Дистрибьютор) должны загрузить свои товары.
                  Загрузка товаров осуществляется с помощью загрузки прайс-листа
                  или добавление каждого товара вручную. При этом
                  передавать/загружать прайс-лист на Платформу можно одним из
                  способов:
                  <ol className="with-sub">
                    <li>
                      Загружать товары вручную - Партнер самостоятельно
                      загружает информацию о каждом товаре.
                    </li>
                    <li>
                      Загружать прайс-лист через администратора Платформы в
                      личном кабинете Faradise вручную. В этом случае
                      потребуется обновлять файл минимум один раз в тридцать
                      календарных дней.
                    </li>
                  </ol>
                </li>
                <li>
                  Все данные о товаре разделены на следующие блоки:
                  <ol className="with-sub">
                    <li>
                      Информация, которая необходима для загрузки в приложение
                      для конечного пользователя. В данном блоке не нужно
                      указывать ссылок и необходимо придерживаться правилам
                      орфографии и пунктуации.
                    </li>
                    <li>
                      Информация, которая необходима только и администраторам
                      Faradise, пользователь ее видеть не будет.
                    </li>
                  </ol>
                </li>
                <li>
                  Моделям/ товарам могут быть присвоены следующие статусы:
                  <ol className="with-sub">
                    <li>
                      &quot;Новый&quot; - это товар, который Партнер только
                      создает/загружает;
                    </li>
                    <li>
                      &quot;Отправлен на модерацию&quot; - товар которые
                      проходит проверку Faradise;
                    </li>
                    <li>
                      &quot;В работе&quot; - Faradise дорабатывает или
                      разрабатывает 3D модель по заказу Партнера;
                    </li>
                    <li>
                      &quot;Опубликован&quot; - товар опубликован в приложении и
                      доступен всем пользователям;
                    </li>
                  </ol>
                </li>
                <li>
Партнер может загрузить товары следующих видов:
                </li>
                <ol className="with-sub">
                  <li>
                    У товара нет готовой 3D-модели. В этом случае, товар уходит
                    на проверку Администратору. Администратор проверяет
                    правильность заполнения всех форм и отправляет на разработку
                    или возвращает обратно Партнеру, сообщая ему, что именно не
                    верно указано.
                  </li>
                  <li>
                    У товара есть 3D-модель, но она не подходит для
                    использования на платформе Faradise В этом случае, товар
                    уходит на проверку Администратору. Администратор проверяет
                    правильность заполнения и отправляет на доработку или
                    возвращает обратно Партнеру, сообщая ему, что именно не
                    верно указано.
                  </li>
                  <li>
                    У товара есть 3D-модель, и она подходит. В этом случае,
                    товар уходит на проверку Администратору. Администратор
                    проверяет правильность работы модели и отправляет в
                    приложение (Загрузка моделей в платформу) или возвращает
                    обратно Партнеру, сообщая ему, что именно не соответствует
                    техническим требованиям Платформы.
                  </li>
                </ol>
              </ol>
              <li>
                После отправки на модерацию соответствующих моделей/материалов,
                Партнер обязан произвести оплату разработки/доработки моделей, в
                соответствии с правилами Инструкции
              </li>
              <li>
                Срок разработки/доработки моделей не должен превышать десяти
                рабочих дней, на каждые пятнадцать моделей, в отдельных случаях,
                срок может быть скорректирован в большую сторону с обязательным
                уведомлением Партнера о сдвиге срока.
              </li>
            </li>
            <li>
              Обработка входящих заказов.
              <ol className="with-sub">
                <li>
                  Заказы пользователями товаров подразделяются на следующие
                  статусы заказа:
                  <ol className="with-sub">
                    <li>
                      Новый. Это новый заказ, который адресован конкретному
                      Партнеру. Партнеру необходимо произвести следующее
                      действие (забронировать заказ и связаться с покупателем по
                      указанным в заказе контактам данным покупателя
                      товара/услуги) в течение тридцати минут, после получения
                      заказа.
                    </li>
                    <li>
                      В работе. Партнер связался с покупателем, и покупатель
                      подтвердил заказ - Партнер должен установить данный
                      статус, до отправки товара.
                    </li>
                    <li>
                      Отправлен. Партнер отправил заказ - Партнер устанавливает
                      данный статус. Пользователь будет в курсе, что заказ в
                      пути. Если в магазине Партнера доступен только самовывоз -
                      Партнер должен оставить статус &quot;В работе&quot;.
                    </li>
                    <li>
                      Доставлен. Покупатель получил товар Партнера - Партнер
                      устанавливает данный статус, при этом платформа списывает
                      комиссию с полной стоимости Заказа согласно Тарифам.
                    </li>
                    <li>
                      Отменен. Если Партнер был вынуждены отменить заказ -
                      Партнер устанавливает данный статус, при этом большое
                      количество отмененных заказов.
                    </li>
                  </ol>
                </li>
                <li>
                  Статус должен быть изменен Партнером не позднее, чем через
                  двадцать четыре часа после совершенного действия,
                  предусмотренного конкретным статусом. За исключением статусов
                  &quot;В работе&quot; и &quot;Отменен&quot;, которые должны
                  быть изменены в течение тридцати минут после совершенного
                  действия, предусмотренного конкретным статусом. В случае, если
                  действия для изменения статуса произошли вне рабочего времени,
                  то Партнер должен их изменить в первые тридцать минут
                  следующего рабочего дня.
                </li>
              </ol>
            </li>
            <li>
              Поддержка
              <ol className="with-sub">
                <li>
                  После акцепта Партнёром Оферты, Партнер начинает пользоваться
                  Платформой в стандартном режиме, включая следующие возможности
                  по поддержке партнера:
                  <ol className="with-sub">
                    <li>
                      Техническая поддержка со стороны администраторов
                      Платформы.
                    </li>
                    <li>
                      Faradise предлагает различные рекламные интеграции внутри
                      Платформы, для повышения продаж Партнера и оказывает
                      Помощь в выборе рекламных возможностей Платформы согласно
                      Тарифам.
                    </li>
                  </ol>
                </li>
                <li>
                  В рамках работы с Faradise для Партнера аккаунт-менеджер и
                  администратор являются основными лицом, с которым происходит
                  коммуникация по любым вопросам работы с Faradise.
                </li>
              </ol>
            </li>
            <li>
              Технические требования Платформы
              <ol className="with-sub">
                <li>
                  Информация, которая необходима для разработки 3D-моделей:
                  <ol className="with-sub">
                    <li>
                      нформация о Товаре:
                      <ol className="with-sub">
                        <li>
Название;
                        </li>
                        <li>
Описание;
                        </li>
                        <li>
Артикул;
                        </li>
                        <li>
Комната;
                        </li>
                        <li>
Категория;
                        </li>
                        <li>
Производитель;
                        </li>
                        <li>
Высота;
                        </li>
                        <li>
Длина;
                        </li>
                        <li>
Глубина;
                        </li>
                        <li>
Ширина.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Информация о каждом Цвете товара:
                      <ol className="with-sub">
                        <li>
Название цвета;
                        </li>
                        <li>
Название материала;
                        </li>
                        <li>
                          {' '}
Цена;
                        </li>
                        <li>
Гарантия;
                        </li>
                        <li>
Ссылка на материал;
                        </li>
                        <li>
Ссылка на рендер или фото;
                        </li>
                        <li>
Ссылка на цвет;
                        </li>
                        <li>
Ссылка на конкретный товар на сайте;
                        </li>
                        <li>
Дополнительная информация.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Дополнительная информация, которая необходима для обработки
                  3D-моделей:
                  <ol className="with-sub">
                    <li>
                      Ссылка на имеющиеся у Партнера 3D-модель. По адресу
                      указанной ссылки должны находиться следующие файлы, относящиеся
                      к 3D-модели данного товара:
                    </li>
                    <li>
                      3D-модели, форматы: .obj / .fbx / .max / .3ds;
                    </li>
                    <li>
                      Текстуры (при наличии);
                    </li>
                    <li>
Рендер (любой формат картинки).
                    </li>
                  </ol>
                </li>
                <li>
                  Дополнительная информация, в виде Ссылки на имеющиеся
                  3D-модели Партнера, которая необходима для использования
                  существующих 3D-моделей Партнера По адресу указанной ссылки должны
                  находиться следующие файлы, относящиеся к 3D-модели данного
                  товара:
                  <ol>
                    <li>
Формат модели .DAE;
                    </li>
                    <li>
Метрическая система для модели;
                    </li>
                    <li>
&quot;Пивот&quot; стоит в центре модели и опущен на
                      плоскость &quot;пола&quot;;
                    </li>
                    <li>
Объект в центре мировых координат;
                    </li>
                    <li>
Модель &quot;одним объектом&quot;, &quot;полигонаж&quot;
                      (произвольный, но не более 100 тысяч треугольников);
                    </li>
                    <li>
UV-развертка;
                    </li>
                    <li>
Текстуры в размере 2K (формат .png/.jpg);
                    </li>
                    <li>
Карты: diffuse, normal, metallic, roughness;
                    </li>
                    <li>
                      {' '}
Экспорт через Maya;
                    </li>
                    <li>
Ссылка на архив должна выглядеть следующим образом:
                      exempleshop.ru/drive/arhiveexample321321321.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
Реквизиты Faradise
            </li>
          </ol>

          <p>
            Общество с ограниченной ответственностью &quot;Центр технологий и
            медиа групп&quot;
            {' '}
          </p>
          <p>
            Адрес (юридический): 107564, г. Москва, ул. Краснобогатырская, д.6,
            стр.2, ком. 18
          </p>
          <p>
ОГРН: 1167746194626
          </p>
          <p>
ИНН 7718299492
            {' '}
          </p>
          <p>
КПП 771801001
          </p>
          <p>
Р/с (расчетный счет): 40702810510000203351
          </p>
          <p>
Банк: АО &quot;Тинькофф Банк&quot;
            {' '}
          </p>
          <p>
БИК: 044525974
          </p>
          <p>
Корр. счет: 30101810145250000974
          </p>
          <p>
Тел.: +7(495)132-74-62
          </p>
          <p>
Форма обратной связи:
            {' '}
          </p>
          <p>
E-mail: privet@faradise.ru
          </p>
        </OtherBox>
        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Instruction }
