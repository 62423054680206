import styled from 'styled-components'

import { COLORS } from '@constants/colors'
import { RESPONSIVE } from '@constants/responsive'

const DesignerSearchBox = styled.div`
  margin-top: 33px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .next-button-mobile {
    display: none;
  }

  .field-title {
    margin-top: 46px;
    margin-bottom: 24px;
    font-family: FuturaNew-Reg;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${COLORS.getBlueColor()};
  }

  .input-field {
    width: 255px;
    display: block;
  }

  .text {
    width: 731px;
    font-family: FuturaNewLight-Reg;
    opacity: 0.87;
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${COLORS.getBlueColor()};
    margin-bottom: 48px;

    &.small {
      margin-bottom: 0;
      font-family: Roboto-Regular;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: ${COLORS.getBlueColor()};
    }

    &.m-t {
      margin-top: 20px;
      margin-bottom: 140px;
    }
  }

  .next-button {
    margin-top: 48px;
    width: 255px;
  }

  .page-footer {
    margin-top: 200px;

    button {
      width: initial;
    }
  }

  .thanks {
    display: flex;
    width: 340px;

    i {
      color: ${COLORS.orange};
      font-size: 30px;
    }

    span {
      margin-left: 20px;
      opacity: 0.54;
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: ${COLORS.darkBlue};
    }
  }

  @media (max-width: ${RESPONSIVE.MOBILE}px) {
    .text {
      width: 100%;
      font-size: 18px;

      &.small {
        font-size: 12px;
        margin-top: -20px;
        margin-bottom: -40px;
      }
    }

    .next-button {
      display: none;
    }

    .next-button-mobile {
      display: block;
      width: 255px !important;

      &.mt {
        margin-top: 80px;
      }
    }

    .page-footer {
      margin-top: 10px;
    }

    .thanks {
      width: 100%;
    }
  }
`

export { DesignerSearchBox }
