import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { ROUTES } from '@constants'

import { MainFooter } from '@ui/organisms/mainFooter'
import { Header, OtherBox } from '@pages/other/style'

@withRouter
class Offer extends Component {
  render() {
    const { history, isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <div className="logo" onClick={() => history.replace(ROUTES.main)}>
              FARADISE
            </div>
          </Header>
        )}

        <OtherBox isInnerPage={isInnerPage}>
          <h1>
            Пользовательское соглашение (оферта) на использование
            платформы-сервиса маркетплейс мебели в дополненной реальности
            «Faradise»
          </h1>
          <p>
Российская Федерация, город Москва
          </p>
          <p>
Дата размещения:
          </p>
          <p>
            {' '}
Дата вступления в силу:
          </p>
          <ol className="with-sub firstSub">
            <li>
              Термины и определения
              <ol className="with-sub">
                <li>
                  В целях настоящего документа нижеприведенные термины
                  используются в следующем значении:
                </li>
                <p>
                  Акцепт Оферты — полное и безоговорочное принятие Оферты путем
                  осуществления действий, указанных в п. 6.2. и 6.4. Оферты.
                </p>
                <p>
                  3D-модель – совокупность определенных компьютерных файлов
                  (форматов: .dae, .png, .jpg) которые с помощью
                  специализированного программного обеспечения для построения
                  геометрической проекции создают Образ 3D-модели, способный
                  изобразить объёмные объекты в формате дополненной реальности
                  (AR - augmented reality).
                </p>
                <p>
                  Образ 3D-модели – создаваемое с помощью специализированного
                  программного обеспечения для построения геометрической
                  проекции отображение 3D-модели на экране устройства
                  (мобильного телефона, планшетного компьютера и персонального
                  компьютера).
                </p>
                <p>
                  База данных 3D моделей и Образов 3D моделей — представляет
                  собой информационно-справочную систему, содержащую информацию
                  о товарах и услугах, предназначенную для потенциальных
                  покупателей (потребителей) товаров (услуг) представленных в
                  Платформе;
                </p>
                <p>
                  Личный кабинет Партнёра — программный интерфейс взаимодействия
                  Партнера с системой Платформы, который содержит информацию о
                  Партнере, данные статистики и иную информацию в отношении
                  сервисов Платформы, а также предоставляет возможность
                  удаленного взаимодействия Сторон в рамках Договора. Личный
                  кабинет доступен Партнеру после авторизации с использованием
                  логина и пароля Партнера на сайте Платформы, через прикладные
                  программы (в том числе программы для мобильных устройств) или
                  API;
                </p>
                <p>
                  Данные статистики — данные систем автоматизированного учета
                  информации на Платформе, которые, в том числе, могут содержать
                  сведения о количестве Кликов и Показов, стоимости Услуг
                  Платформы и иные сведения, относящиеся к размещению Материалов
                  в электронном виде;
                </p>
                <p>
                  Договор — возмездный договор между Партнером и Faradise на
                  оказание Услуг Платформы, который заключается посредством
                  Акцепта Оферты;
                </p>
                <p>
                  Договор-соглашение – возмездный договор между Партнером и
                  Faradise заключенный в письменной форме и регулирующий
                  совершение сделок по купле-продаже Товаров Партнера;
                </p>
                <p>
                  Заказ – сформированный Пользователем на Платформе набор из
                  товара/-ов Партнера, которые Пользователь планирует приобрести
                  у Партнера через Faradise;
                </p>
                <p>
Партнер — лицо, осуществившее Акцепт Оферты;
                </p>
                <p>
                  Клик — обращение пользователя по содержащейся в Материалах
                  Ссылке;
                </p>
                <p>
                  Материалы — предоставляемые Партнером материалы, содержащие
                  сведения об ассортименте, ценах, характеристиках, наличии в
                  продаже товаров (услуг), предлагаемых Партнером потенциальным
                  покупателям в текущий период, информацию о нахождении точек
                  продаж Партнера, изображения, описания, 3D модели товаров и
                  иные согласованные Сторонами сведения о товарных предложениях
                  и/или магазине Партнера;
                </p>
                <p>
                  Оферта — настоящий документ «Пользовательское соглашение
                  (оферта) на использование платформы-сервиса маркетплейс мебели
                  в дополненной реальности «Faradise», размещенный в сети
                  Интернет по адресу (www.faradise.ru/design/offer);
                </p>
                <p>
                  Место размещения — место на веб-страницах информационных
                  ресурсов в сети Интернет и/или на Платформе, в том числе в
                  программах для мобильных устройств, определенное для
                  размещения (показа) Материалов и/или их определенных составных
                  частей (изображений, описаний и т.п.);
                </p>
                <p>
                  Показ — размещение/отображение Материалов на Местах
                  размещения;
                </p>
                <p>
                  Пользователь — лицо, являющееся
                  посетителем/пользователем/клиентом информационных ресурсов
                  (вебсайтов, приложений, программ для ЭВМ и пр.) Платформы в
                  сети интернет/программ для мобильных устройств, которое так же
                  может являться потенциальным клиентом/клиентом и/или
                  Покупателем Товаров Партнера;
                </p>
                <p>
                  Платформа — означает все веб-сайты (включая, но не
                  ограничиваясь, размещенными в сети Интернет по адресу:
                  (www.faradise.ru)), прикладные программы (в том числе
                  программы для мобильных устройств) Faradise или его
                  аффилированных лиц, предоставляющие доступ к Базе данных
                  Платформы;
                </p>
                <p>
                  Ссылка — текстовая ссылка или изображение, включенные
                  Партнером в Материалы, переадресующие обратившихся к ним
                  посредством Клика пользователей:
                </p>
                <span>
                  <p>
                    1) к информационному ресурсу (сайту) в сети Интернет (сайт
                    Партнера/магазин Партнера), адрес (URL) которого указан
                    Партнером;
                  </p>
                  <p>
                    2) на специальные разделы Платформы, содержащую информацию о
                    точках продаж Партнера или иную дополнительную и/или
                    вспомогательную информацию;
                  </p>
                  <p>
                    3) на Материалы, предоставленные Партнером, а также
                    текстовые ссылки или изображение с номером телефона,
                    предоставленным Партнером для данных Материалов, при показе
                    которого Пользователь имеет возможность осуществить звонок
                    по указанному номеру телефона, в том числе с использованием
                    программного обеспечения, обеспечивающего различные виды
                    связи.
                  </p>
                </span>
                <p>
                  Услуги Платформы — услуги Платформы, связанные с размещением
                  Материалов на Местах размещения (услуги, предусмотрены
                  пунктами 2.1. Оферты);
                </p>
                <p>
                  Тарифы Платформы — стоимость услуг Платформа, связанных с
                  размещением Материалов на Местах размещения (услуги,
                  предусмотрены пунктами 2.1. Оферты);
                </p>
                <li>
                  В Оферте могут быть использованы термины, не определенные в
                  п.1.1. Оферты. В этом случае толкование такого термина
                  производится в соответствии с текстом Оферты. В случае
                  отсутствия однозначного толкования термина в тексте Оферты
                  следует руководствоваться толкованием термина, определенным: в
                  первую очередь — законодательством РФ, во вторую очередь — на
                  платформе-сервисе маркетплейс мебели в дополненной реальности
                  «Faradise» в Оферте или иных документах перечисленных в п.
                  3.1. Оферты, затем — сложившимся (общеупотребимым) в сети
                  Интернет.
                </li>
              </ol>
            </li>
            <li>
              Предмет Договора
              <ol className="with-sub">
                <li>
                  Предметом Договора является возмездное оказание Faradise
                  Партнеру Услуг Платформы на условиях Оферты. Услуги Платформы
                  включают в себя, но не ограничиваются:
                  <ol className="with-sub">
                    <li>
                      Размещение Материалов Партнера на Местах размещения.
                    </li>
                    <li>
                      Предоставление Партнеру функционала и дополнительных
                      материалов, позволяющих Пользователю оформить заказ
                      товаров/услуг в магазине Партнера, непосредственно на
                      Платформе.
                    </li>
                    <li>
                      Функционал по предоставлению технических возможностей для
                      оформления Пользователем заказа для дальнейшей работы с
                      этим заказом и его исполнения Партером на условиях
                      настоящей Оферты и Договора-соглашения.
                    </li>
                    <li>
                      Дополнительные функциональные возможности и опции,
                      связанные с продвижением товаров Партнера на Платформе,
                      рекламными возможностями Платформы и прочим функционалом,
                      имеющимся как на момент акцепта Оферты, так и потенциально
                      возможным на Платформе в будущем.
                    </li>
                    <li>
                      Доработку Faradise 3D моделей Партнера и/или разработка 3D
                      моделей Партнера, что, в отдельных случаях,
                      предусмотренных Инструкцией, является необходимым условием
                      использования Платформы со стороны Партнера, при этом
                      вопросы применения и размещения Образов 3D моделей
                      относятся к ведению Faradise, с возможностью распоряжения
                      такими Образами 3D моделей на Платформе Партнером с
                      соблюдением правил Оферты и Инструкции, если иной порядок
                      прямо не предусмотрен Инструкцией. Разработанные по заказу
                      Партнера или доработанные по заказу Партнера 3D модели
                      принадлежат Партнеру заказавшему такую разработку или
                      доработку.
                    </li>
                    <li>
                      Предоставление Партнеру на правах коммерческого или
                      безвозмездного использования в рамках Платформа
                      собственных Образов 3D моделей и дополнительных
                      материалов, принадлежащих Faradise.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Условия оказания услуг
              <ol className="with-sub">
                <li>
                  Обязательным условием оказания Faradise Услуг Платформы
                  является принятие и соблюдение Партнером, применение к
                  отношениям сторон по Договору требований и положений,
                  изложенных в Оферте и определенных следующими документами:
                  <ol className="with-sub no-list">
                    <li>
                      а) Документ «Инструкция по работе с платформой Платформа»
                      (далее — «Инструкция»), размещенный в сети Интернет по
                      адресу: (www.faradise.ru/design/instruction);
                    </li>
                    <li>
                      б) Документ «Тарифы Платформы» (далее — «Тарифы»),
                      размещенный в сети Интернет по адресу:
                      (www.faradise.ru/design/rates);
                    </li>
                    <li>
                      в) Документ «Политика конфиденциальности», размещенный в
                      сети Интернет по адресу: (www.faradise.ru/design/agreement);
                    </li>
                  </ol>
                </li>
                <li>
                  Если иное не предусмотрено Инструкцией, Faradise может
                  приступить к оказанию Партнеру услуг, указанных в п. 2.1
                  Оферты, с момента совокупного выполнения Партнером следующих
                  условий:
                  <ol className="with-sub">
                    <li>
                      Партнер предоставил Материалы, соответствующие требованиям
                      Оферты и Инструкции;
                    </li>
                    <li>
Партнер осуществил Акцепт Оферты;
                    </li>
                    <li>
                      Партнер выполнил обязательные требования, предусмотренные
                      Инструкцией для конкретной услуги в рамках Платформы;
                    </li>
                  </ol>
                </li>
                <li>
                  Партнер самостоятельно осуществляет подготовку Материалов и
                  предоставление их Faradise, в порядке, изложенном в
                  Инструкции. Партнер самостоятельно и в полном объеме несет
                  предусмотренную законодательством ответственность как лицо,
                  осуществившее приведение информации в готовую для
                  распространения в виде рекламы форму и гарантирует
                  соответствие составленных и предоставленных им для размещения
                  Faradise, в указанном выше порядке, Материалов всем
                  требованиям действующего законодательства Российской
                  Федерации. При этом, всю необходимую для оказания услуг
                  Платформы информацию и иные сведения, могут предоставляться
                  Партнером через Личный кабинет Партнера.
                </li>
                <li>
                  . В случае если Материалы предоставлены Партнером с нарушением
                  правил и требований Оферты и/или Инструкции, Faradise вправе
                  отказать в размещении таких Материалов, приостановить или
                  прекратить оказание Услуг в отношении таких Материалов и
                  расторгнуть Договор.
                </li>
                <li>
                  Места размещения Материалов определяются в соответствии с
                  положениями Инструкции.
                </li>
                <li>
                  Партнер не вправе передавать свои права по Договору какой-либо
                  третьей стороне без предварительного согласия Faradise.
                  Обязанность подтверждения наличия такого согласия возлагается
                  на Партнера.
                </li>
                <li>
                  Услуги предоставляются Партнеру в объеме, соответствующем
                  сумме произведенной им предоплаты (если Офертой и/или
                  Инструкцией не предусмотрен иной порядок оплаты). Faradise
                  оставляет за собой право временно приостановить оказание Услуг
                  в одностороннем порядке, если стоимость Услуг, оказанных
                  Faradise по Договору, стала равной или превысила сумму,
                  перечисленную Партнером на расчетный счет Faradise в качестве
                  предоплаты за оказание Услуг до момента исполнения Партнером
                  своих обязательств по оплате, при этом срок выполнения
                  Партнером своих обязательств по оплате не должен превышать
                  пяти рабочих дней.
                  {' '}
                </li>
                <li>
                  Партнер самостоятельно несет ответственность за сохранность и
                  конфиденциальность регистрационных данных (логин и пароль).
                  Все действия, осуществленные в отношении размещения Материалов
                  через Личный кабинет Партнера с использованием логина и пароля
                  Партнера, считаются осуществленными Партнером. Партнер
                  самостоятельно несет ответственность перед третьими лицами за
                  все действия, совершенные с использованием логина и пароля
                  Партнера.
                </li>
              </ol>
            </li>
            <li>
              Права и обязанности сторон
              <ol className="with-sub">
                <li>
                  Faradise обязуется:
                  <ol className="with-sub">
                    <li>
                      Оказывать Партнеру Услуги Платформа в соответствии с
                      Договором, заключенным на условиях Оферты.
                    </li>
                    <li>
                      Размещать экспортируемые (загруженные) Партнером Материалы
                      в Базу данных Платформы при условии соблюдения Партнером
                      требований, изложенных в документах, указанных в п.3.1.
                      Оферты, при этом Faradise предпримет все необходимые
                      технические меры для программной защиты Материалов
                      Партнера (включая, но не ограничиваясь, 3D модели) с целью
                      недопущения нарушения прав и законных интересов Партнера в
                      отношении экспортируемых (загружаемых) Партнером Материалы
                      в Базу данных Платформы.
                    </li>
                    <li>
                      В случае отказа Партнера от исполнения Договора по
                      основаниям, предусмотренным Офертой, возвратить Партнеру
                      по его письменному требованию сумму предоплаты за вычетом
                      стоимости фактически оказанных Faradise к моменту
                      расторжения Договора Услуг. Возврат осуществляется в
                      течение десяти рабочих дней с момента получения Faradise
                      надлежаще оформленного письменного требования Партнера и в
                      необходимых случаях иных документов, идентифицирующих
                      Партнера.
                    </li>
                  </ol>
                </li>
                <li>
                  Faradise имеет право:
                  <ol className="with-sub">
                    <li>
                      Временно приостановить оказание Партнеру Услуг по Договору
                      по техническим, технологическим или иным причинам,
                      препятствующим оказанию Услуг, на время устранения таких
                      причин, при этом такая приостановка не засчитывается в
                      срок оказания Услуг Платформы, в случаях, когда такой срок
                      подлежит исчислению и/или учитывается и/или тарифицируется
                      в соответствии с правилами Оферты и/или иных документах
                      перечисленных в п. 3.1. Оферты.
                    </li>
                    <li>
                      Приостановить оказание Услуг по Договору и/или досрочно
                      расторгнуть Договор в одностороннем внесудебном порядке с
                      обязательного уведомления Партнера в личном кабинете
                      Партнера, в случаях:
                      <ol className="with-sub no-list">
                        <li>
                          а) нарушения Партнером обязательства, предусмотренного
                          п. 4.3 Оферты;
                          {' '}
                        </li>
                        <li>
                          б) при нарушении Партнером иных обязательств и/правил,
                          принятых в соответствии с Офертой и/или иными
                          документами перечисленными в п. 3.1. Оферты.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Осуществлять любые действия (контрольные мероприятия) по
                      проверке соответствия содержания представленных Партнером
                      Материалов требованиям Договора. Данные, полученные в
                      результате осуществления контрольных мероприятий, являются
                      достаточным, но не единственно возможным основанием для
                      установления несоответствия Материалов требованиям
                      Договора. При этом Faradise самостоятельно определяет
                      способы, периодичность и основания проведения контрольных
                      мероприятий, с обязательным предварительным уведомлением
                      Партнера в личном кабинете Партнера.
                    </li>
                    <li>
                      Приостановить оказание Услуг и/или расторгнуть Договор(-ы)
                      с Партнером в одностороннем порядке в случае, если Партнер
                      имеет задолженность по оплате Услуг Платформы (в
                      частности, нарушил срок оплаты Услуг, оказанных Партнеру
                      по Договору) как по договорам, заключенным на условиях
                      Оферты, так и по другим договорам, заключенным между
                      Faradise и Партнером.
                    </li>
                    <li>
                      При размещении Материалов на Местах размещения, показ
                      Материалов может сопровождаться дополнительными
                      информационно-справочными материалами Платформы,
                      содержание и объем которых определяет Faradise.
                    </li>
                    <li>
                      Приостановить оказание Услуг и/или расторгнуть Договор(-ы)
                      с Партнером в одностороннем порядке в случае если по
                      результатам проверки, проведенной Faradise в связи с
                      получением негативного отзыва/негативных отзывов о
                      Партнере, содержащегося/содержащихся, в частности, в
                      отзывах Пользователей и/или формах обратной связи на
                      страницах Платформы и/или ответах Пользователей на
                      системные уведомления от Платформы и/или обращениях
                      Пользователей в службу поддержки Платформы посредством
                      писем и/или телефонных звонков, Faradise придет к выводу о
                      наличии достаточных оснований для применения
                      соответствующих мер, с обязательным уведомлением Партнера,
                      в личном кабинете Партнера, при этом Faradise перед
                      применением такого рода мер, предоставит Партнеру
                      возможность исправить нарушения, послужившие основанием
                      для применения такого рода мер в срок не менее трех
                      рабочих дней, с момента соответствующего уведомления
                      Партнера, в Личном кабинете Партнера.
                    </li>
                  </ol>
                </li>
                <li>
                  Партнер обязуется:
                  <ol className="with-sub">
                    <li>
                      Осуществлять размещение Материалов с соблюдением
                      требований Оферты и/или иных документов перечисленных в п.
                      3.1. Оферты.
                    </li>
                    <li>
                      При подготовке, создании, изменении Материалов соблюдать
                      все требования определенных Инструкцией к условиям
                      размещения, обусловленным Офертой, а также все применимые
                      нормы и требования действующего законодательства, в том
                      числе Федерального Закона «О рекламе», законодательства об
                      интеллектуальной собственности, Федерального закона «О
                      конкуренции и ограничении монополистической деятельности
                      на товарных рынках», но не ограничиваясь перечисленным.
                    </li>
                    <li>
                      Предоставлять Материалы, содержание которых соответствует
                      текущим предложениям магазина Партнера, и соблюдать
                      заявленные условия продажи товаров (услуг).
                    </li>
                    <li>
                      Предоставить через Личный кабинет Партнера информацию о
                      сайте Партнера, независимо от наличия или отсутствия
                      возможности заказа/приобретения товара/услуги
                      непосредственно на сайте Партнера.
                    </li>
                    <li>
                      Обеспечить работоспособность сайта Партнера, в отношении
                      которого предоставлены Материалы, и возможность доступа к
                      нему Пользователей при обращении по соответствующей Ссылке
                      Партнера.
                    </li>
                    <li>
                      Обеспечить наличие на сайте Партнера достоверных сведений
                      о продавце и товарах (работах, услугах) в объеме,
                      требуемом законодательством РФ и Инструкцией.
                    </li>
                    <li>
                      По требованию Faradise в срок, не превышающий пяти
                      календарных дней с момента получения данного требования,
                      предоставить надлежаще заверенные копии документов,
                      подтверждающие информацию о продавце, размещенную на сайте
                      Партнера, а также переданную Faradise в составе
                      Материалов. Стороны признают, что информация о продавце не
                      является конфиденциальной.
                    </li>
                    <li>
                      Оплатить Услуги Faradise по Договору в установленные в
                      Оферте (Договоре) сроки и порядке.
                    </li>
                    <li>
                      Использовать Личный кабинет Партнера согласно
                      установленным Faradise правилам, указанным в Личном
                      кабинете Партнера и/или иных документах Оферты.
                    </li>
                    <li>
                      Партнер соглашается, что показ предоставленных им
                      Материалов может сопровождаться дополнительными
                      информационными материалами Платформы, содержание и объем
                      которых определяет Faradise.
                    </li>
                  </ol>
                </li>
                <li>
                  Партнер имеет право:
                  <ol className="with-sub">
                    <li>
                      Изменять Материалы (в том числе условия размещения) в
                      любое время с соблюдением всех установленных Договором
                      требований.
                    </li>
                    <li>
                      Приостановить и/или прекратить размещение Материалов в
                      любое время.
                    </li>
                    <li>
                      В случае несогласия с изменениями, внесенными Faradise в
                      Инструкцию и/или иные документы, указанные в п. 3.1
                      Оферты, отказаться от исполнения Договора в одностороннем
                      порядке, письменно уведомив Faradise в срок не позднее
                      пяти календарных дней с момента размещения указанных
                      изменений и оплатив Faradise стоимость фактически
                      оказанных Услуг. Договор считается расторгнутым с момента
                      получения Faradise уведомления Партнера и оплаты Faradise
                      стоимости фактически оказанных Услуг.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Стоимость услуг и порядок расчетов
              <ol className="with-sub">
                <li>
                  Стоимость Услуг, оказанных Faradise по Договору, определяется
                  в соответствии с набором конкретных Услуг оказываемых Faradise
                  и выбранных Партнером в Личном кабинет в соответствии с
                  Офертой и/или с указанными в п. 3.1 Оферты документами и/или
                  предусмотренных Договор-соглашением на использование
                  платформы-сервиса маркетплейс мебели в дополненной реальности
                  «Faradise» заключенному с Faradise.
                </li>
                <li>
                  Оплата Услуг производится Партнером в российских рублях в
                  безналичном порядке и в порядке, предусмотренном в Инструкции
                  и/или предусмотренном Договор-соглашением на использование
                  платформы-сервиса маркетплейс мебели в дополненной реальности
                  «Faradise» заключенному с Faradise.
                </li>
                <li>
                  Услуги оказываются Партнеру на условиях предварительной оплаты
                  Услуг. Партнер производит авансовый платеж в размере 100% (Ста
                  процентов) от общей стоимости заказываемых Услуг на основании
                  счета, выставленного Faradise Партнеру на оплату («Счет»), в
                  течение 5 (пяти) календарных дней с момента выставления Счета.
                  Для целей Оферты срок, исчисляемый в календарных днях, не
                  включает нерабочие праздничные дни. Оплата Партнером Счета
                  является Акцептом Оферты и влечет заключение Договора на
                  условиях предварительной оплаты (п.6.2. Оферты).
                </li>
                <li>
                  Услуги считаются оплаченными Партнером с момента получения
                  Faradise подтверждения из банка о поступлении всей суммы
                  оплаты на расчетный счет Faradise.
                  {' '}
                </li>
                <li>
                  Настоящим Стороны договорились, что в случае, если на момент
                  прекращения или расторжения Договора сумма произведенной
                  Партнером предоплаты Услуг меньше стоимости фактически
                  оказанных Партнеру по Договору Услуг, то разница между
                  указанными суммами должны быть оплачена Партнером в срок до
                  планируемой даты прекращения или расторжения Договора.
                </li>
                <li>
                  В последний день отчетного периода, при его наличии такого
                  и/или в последний день оказания Услуг по Договору (Счету),
                  и/или в иной предусмотренный Инструкцией срок Faradise
                  формирует односторонний Акт об оказанных услугах в
                  соответствии с объемом фактически оказанных в отчетном периоде
                  Услуг. Инструкцией может быть предусмотрен иной порядок и
                  сроки формирования Актов об оказанных услугах.
                </li>
                <li>
                  Услуги считаются оказанными Faradise надлежащем образом и
                  принятыми Партнером в указанном в Акте объеме, если в течение
                  семи календарных дней по завершении отчетного периода Faradise
                  не получил от Партнера мотивированных письменных возражений.
                  По истечении срока, указанного выше, претензии Партнера по
                  Акту, в том числе по количеству (объему), стоимости и качеству
                  Услуг не принимаются.
                </li>
                <li>
                  Сторонами согласовано, что в отношении любых денежных
                  обязательств Сторон по Договору законные проценты (проценты на
                  сумму долга за период пользования денежными средствами),
                  предусмотренные ст. 317.1 ГК РФ, не начисляются.
                </li>
              </ol>
            </li>
            <li>
              Акцепт оферты и заключение договора
              <ol className="with-sub">
                <li>
                  Партнер производит Акцепт Оферты:
                  <ol className="with-sub">
                    <li>
                      путем выражения согласия с заключением Договора нажатием
                      кнопки «согласен» при регистрации на Платформе и путем
                      предварительной оплаты по Счету Услуг Faradise, согласно
                      правилам, предусмотренным Инструкцией, в отношении которых
                      заключается Договор, в течение семи рабочих дней с момента
                      выставления Счета;
                    </li>
                    <li>
                      путем письменного заключения Договора-соглашение на
                      использование платформы-сервиса маркетплейс мебели в
                      дополненной реальности «Faradise»
                    </li>
                  </ol>
                </li>
                <li>
                  В случае если Партнер произвел Акцепт Оферты в срок,
                  превышающий указанный в Счете, Faradise имеет право, по своему
                  усмотрению, принять такой Акцепт и приступить к оказанию
                  Услуг, либо отказаться от принятия такого Акцепта, возвратив
                  Партнеру сумму произведенной оплаты.
                </li>
                <li>
                  Стороны соглашаются с тем, что услуги в рамках
                  соответствующего Договора (Счета) оказываются Партнеру
                  Обществом с ограниченной ответственностью «Центр технологий и
                  медиа групп» (ОГРН 1167746194626, адрес места нахождения:
                  Российская Федерация, 105120, г. Москва, ул. Нижняя
                  Сыромятническая, дом 10, строение 9, сектор D) на условиях,
                  предусмотренных настоящей Офертой.
                </li>
              </ol>
            </li>
            <li>
              Срок действия и изменение условий Оферты
              <ol className="with-sub">
                <li>
                  Оферта вступает в силу с даты, указанной в разделе «Дата
                  вступления в силу» и действует до момента отзыва Оферты
                  Faradise.
                </li>
                <li>
                  Faradise оставляет за собой право внести изменения в условия
                  Оферты (включая документы, указанные в п. 3.1 Оферты) и/или
                  отозвать Оферту в любой момент по своему усмотрению. В случае
                  внесения Faradise изменений в Оферту (и/или документы,
                  указанные в п. 3.1 Оферты), такие изменения вступают в силу с
                  момента размещения измененного текста Оферты (документов,
                  указанных в п. 3.1 Оферты) в сети Интернет по адресу
                  (www.faradise.ru/design/offer) (адресам, указанным в п. 3.1 Оферты
                  для соответствующих документов), если иной срок вступления
                  изменений в силу не определен дополнительно при таком
                  размещении.
                </li>
                <li>
                  О любых изменениях Оферты, включая полный отзыв Оферты,
                  Партнер будет уведомлен за три рабочих дня, путем направления
                  соответствующего уведомления на адрес электронной почты,
                  указанный в Личном кабинете, при этом сам факт такого
                  уведомления или его отсутствия не влияет на правила изменения
                  условий Оферты.
                </li>
                <li>
                  В случае изменения Тарифов Платформы, срок уведомления о
                  такого рода изменениях составляет не менее семи рабочих дней,
                  до срока вступления измененных Тарифов в силу. Соответствующее
                  уведомление в Личном кабинете Партнера и/или по указанному
                  Партнером при регистрации на Платформе E-mail, обязательно,
                  при этом сам факт такого уведомления или его отсутствия не
                  влияет на правила изменения условий Оферты.
                  {' '}
                </li>
                <li>
                  В случае изменений любых условий/положений Оферты и/или иных
                  документов, указанных в п. 3.1 Оферты Партнер имеет отказаться
                  от исполнения Договора в одностороннем порядке, письменно
                  уведомив Faradise в срок не позднее пяти календарных дней с
                  момента размещения указанных изменений и оплатив Faradise
                  стоимость фактически оказанных Услуг. Договор считается
                  расторгнутым с момента получения Faradise уведомления Партнера
                  и оплаты Faradise стоимости фактически оказанных Услуг.
                </li>
              </ol>
            </li>
            <li>
              Срок действия и изменение договора
              <ol className="with-sub">
                <li>
                  Акцепт Оферты Партнером, произведенный согласно ст. 6 Оферты,
                  создает Договор (статья 438 Гражданского Кодекса РФ) на
                  условиях Оферты.
                </li>
                <li>
                  Договор вступает в силу с момента Акцепта Оферты Партнером и
                  действует до момента расторжения Договора.
                </li>
                <li>
                  Партнер соглашается и признает, что внесение изменений в
                  Оферту (в том числе в документы, указанные в п. 3.1 Оферты)
                  влечет за собой внесение этих изменений в заключенный и
                  действующий между Партнером и Faradise Договор, и эти
                  изменения в Договор вступают в силу одновременно с такими
                  изменениями в Оферту (в том числе в документы, указанные в п.
                  3.1 Оферты).
                </li>
                <li>
                  В случае отзыва Оферты Faradise в течение срока действия
                  Договора, Договор считается прекращенным с момента отзыва,
                  если иное не оговорено Faradise при отзыве Оферты.
                </li>
              </ol>
            </li>
            <li>
              Расторжение договора
              <ol className="with-sub">
                <li>
                  Договор может быть расторгнут:
                  <ol className="with-sub">
                    <li>
По соглашению Сторон, в любое время.
                    </li>
                    <li>
                      По инициативе Faradise в случае нарушения Партнером
                      условий Договора в любое время, с обязательным
                      уведомлением Партнера в Личном кабинете Партнера.
                    </li>
                    <li>
                      По инициативе Партнера, в любое время, при условии
                      выполнения Партнером требований и порядка предусмотренных
                      в п.7.5 Оферты с обязательным уведомлением Faradise в
                      Личном кабинете Партнера.
                    </li>
                    <li>
                      По иным основаниям, предусмотренным настоящей Офертой
                      и/или Инструкцией и/или действующим законодательством РФ.
                    </li>
                  </ol>
                </li>
                <li>
                  Обязательства Сторон по Договору, которые в силу своей природы
                  должны продолжать действовать (включая обязательства в
                  отношении конфиденциальности, проведения взаиморасчетов,
                  использованию информации, но, не ограничиваясь указанным),
                  остаются в силе после окончания действия Договора.
                </li>
                <li>
                  Прекращение срока действия Договора по любому основанию не
                  освобождает Стороны от ответственности за нарушения условий
                  Договора, возникшие в течение срока его действия.
                </li>
              </ol>
            </li>
            <li>
              Гарантии
              <ol className="with-sub">
                <li>
                  В течение срока действия Договора Faradise предпримет все
                  усилия для устранения каких-либо сбоев и ошибок, в случае их
                  возникновения. При этом Faradise не гарантирует отсутствия
                  ошибок и сбоев при использовании Платформы, в том числе в
                  отношении работы программного обеспечения.
                </li>
                <li>
                  За исключением гарантий, прямо указанных в тексте Оферты,
                  Faradise не предоставляет никаких иных прямых или
                  подразумеваемых гарантий по Договору и прямо отказывается от
                  каких-либо гарантий или условий в отношении не нарушения прав
                  и соответствия Услуг конкретным целям Партнера.
                </li>
                <li>
                  Производя Акцепт Оферты, Партнер (или представитель Партнера,
                  в т.ч. физическое лицо, должным образом уполномоченное
                  заключить Договор от лица Партнера) подтверждает и гарантирует
                  Faradise, что:
                </li>
                <ol className="with-sub">
                  <li>
                    Партнер (представитель Партнера) указал достоверные данные,
                    в том числе персональные данные Партнера (представителя
                    Партнера) при регистрации в качестве пользователя Платформа
                    и достоверные данные, в том числе персональные, Партнера при
                    оформлении платежных документов по оплате Услуг.
                  </li>
                  <li>
                    Партнер дает согласие на обработку Faradise персональных
                    данных, указанных Партнером при регистрации на Платформа
                    и/или при заключении Договора, в том числе на совершение
                    Faradise действий, предусмотренных п. 3 ст. 3 Федерального
                    закона от 27.07.2006 года № 152-ФЗ «О персональных данных»,
                    любыми способами, для целей заключения и исполнения
                    Договора.
                  </li>
                  <li>
Партнер (представитель Партнера):
                  </li>
                  <p>
а) полностью ознакомился с условиями Оферты,
                  </p>
                  <p>
б) полностью понимает предмет Оферты и Договора,
                  </p>
                  <p>
                    в) полностью понимает значение и возможные последствия своих
                    действий в отношении заключения и исполнения Договора.
                  </p>
                  <li>
                    Партнер (представитель Партнера) обладает всеми правами и
                    полномочиями, необходимыми для заключения и исполнения
                    Договора.
                  </li>
                  <li>
                    Размещение (воспроизведение, показ), иное использование
                    Faradise Материалов по Договору, а также Материалы (их
                    содержание), к которым осуществляется переадресация
                    пользователей, не нарушает и не влечет за собой нарушение
                    каких-либо прав третьих лиц и действующего законодательства,
                    в связи с чем Партнер несет ответственность в соответствии с
                    п.п.11.5, 11.6 Оферты.
                  </li>
                  <li>
                    Товары, сведения о которых содержатся в представленных
                    Партнером Материалах, законным способом были введены в
                    гражданский оборот на территории Российской Федерации и/или
                    на территории государства, в котором осуществляется продажа
                    указанных товаров и/или в которое осуществляется доставка
                    указанных товаров.
                  </li>
                </ol>
              </ol>
            </li>
            <li>
              Ответственность и ограничение ответственности
              <ol className="with-sub">
                <li>
                  За нарушение условий Договора Стороны несут ответственность,
                  установленную Договором и/или действующим законодательством
                  РФ.
                </li>
                <li>
                  Faradise ни при каких обстоятельствах не несет никакой
                  ответственности по Договору за:
                </li>
                <p>
                  а) какие-либо косвенные убытки и/или упущенную выгоду Партнера
                  и/или третьих сторон вне зависимости от того, мог Faradise
                  предвидеть возможность таких убытков или нет;
                </p>
                <p>
                  б) использование/невозможность использования Партнером и/или
                  третьими лицами любых средств и/или способов
                  передачи/получения Материалов и/или информации.
                </p>
                <li>
                  Совокупный размер ответственности Faradise по Договору,
                  включая размер штрафных санкций (пеней, неустоек) и/или
                  возмещаемых убытков, по любому иску или претензии в отношении
                  Договора или его исполнения, ограничивается 10 % стоимости
                  Услуг по Договору.
                </li>
                <li>
                  Стороны освобождаются от ответственности за частичное или
                  полное неисполнение обязательств по настоящему Договору, если
                  это неисполнение явилось следствием обстоятельств
                  непреодолимой силы, которые возникли после заключения
                  Договора, либо если неисполнение обязательств Сторонами по
                  Договору явилось следствием событий чрезвычайного характера,
                  которые Стороны не могли ни предвидеть, ни предотвратить
                  разумными мерами.
                </li>
                <li>
Партнер несет ответственность в полном объеме за
                </li>
                <p>
                  а) соблюдение всех требований законодательства, в том числе
                  законодательства о рекламе, об интеллектуальной собственности,
                  о конкуренции, но не ограничиваясь перечисленным, в отношении
                  содержания и формы Материалов и материалов, на которые Партнер
                  устанавливает Ссылку с Материалов, использование сайта
                  (доменного имени сайта), на который установлена Ссылка, иные
                  действия;
                </p>
                <p>
                  б) достоверность сведений, указанных им при регистрации в
                  качестве пользователя на Платформа, достоверность сведений,
                  указанных в Материалах.
                </p>
                <li>
                  Принимая во внимание условия п.11.5 Оферты, Партнер обязуется
                  своими силами и за свой счет разрешать споры и урегулировать
                  претензии третьих лиц в отношении Материалов, в связи с их
                  размещением по Договору, либо возместить убытки (включая
                  судебные расходы), причиненные Faradise в связи с претензиями
                  и исками, основанием предъявления которых явилось размещение
                  Материалов Партнера по Договору. В случае если содержание,
                  форма и/или размещение Материалов Партнера по Договору явилось
                  основанием для предъявления к Faradise предписаний по уплате
                  штрафных санкций со стороны государственных органов, Партнер
                  обязуется незамедлительно по требованию Faradise предоставить
                  ему всю запрашиваемую информацию, касающуюся размещения и
                  содержания Материалов, содействовать Faradise в урегулировании
                  предписаний, а также возместить все убытки (включая расходы по
                  уплате штрафов), причиненные Faradise вследствие предъявления
                  ему предписаний в результате размещения Материалов Партнера.
                </li>
                <li>
                  В случае нарушения Партнером условий Договора Faradise вправе
                  приостановить оказание Услуг до момента устранения Партнером
                  допущенных нарушений и возмещения (компенсации) причиненных
                  Faradise таким нарушением убытков в полном объеме и/или
                  расторгнуть Договор. При расторжении Договора по указанному
                  основанию Faradise вправе путем удержания взыскать с Партнера
                  предъявленные в соответствии с Договором суммы неустоек и
                  убытков.
                </li>
              </ol>
            </li>
            <li>
              Прочие условия
              <ol className="with-sub">
                <li>
                  Договор, его заключение и исполнение регулируется действующим
                  законодательством Российской Федерации. Все вопросы, не
                  урегулированные Офертой или урегулированные не полностью,
                  регулируются в соответствии с материальным правом Российской
                  Федерации. Если споры между Партнером и Faradise в отношении
                  Договора не разрешены путем переговоров Сторон, они подлежат
                  рассмотрению в порядке, предусмотренном действующим
                  законодательством в Арбитражном суде г. Москвы. Стороны могут
                  по соглашению между собой изменить территориальную
                  подсудность, оформив такое соглашение в соответствии с
                  действующим законодательством Российской Федерации.
                </li>
                <li>
                  Стороны согласились, что условие сохранения конфиденциальности
                  регистрационных данных (включая персональные данные),
                  указанных Партнером при регистрации на Платформа и/или при
                  заключении Договора, не распространяется на случаи
                  использования Faradise таких данных в целях выставления
                  Партнеру счетов на оказание Услуг, счетов-фактур и оформления
                  с Партнером актов сдачи-приемки Услуг, а также в случае
                  передачи регистрационных данных третьим лицам в целях,
                  связанных с оказанием Услуг. В указанных документах подлежат
                  указанию данные (в том числе персональные данные) и реквизиты,
                  предоставленные Партнером.
                </li>
                <li>
                  Любые уведомления по Договору, если иной порядок прямо не
                  предусмотрен Офертой, могут направляться одной Стороной другой
                  Стороне:
                </li>
                <p>
1) по электронной почте
                </p>
                <p>
                  а) на адрес электронной почты Партнера, указанный им при
                  регистрации на Платформа, с адреса электронной почты Faradise,
                  указанного в п.13 Оферты в случае, если получателем является
                  Партнер, и
                </p>
                <p>
                  б) на адрес электронной почты Faradise, указанный в п.13
                  Оферты, с адреса электронной почты Партнера, указанного им при
                  регистрации на Платформа;
                </p>
                <p>
                  2) почтой с уведомлением о вручении или курьерской службой с
                  подтверждением доставки.
                </p>
                <li>
                  В случае если одно или более положений Оферты являются по
                  какой-либо причине недействительными, не имеющими юридической
                  силы, такая недействительность не оказывает влияния на
                  действительность любого другого положения Оферты (Договора),
                  которые остаются в силе.
                </li>
                <li>
                  . Не вступая в противоречие с условиями Оферты, Партнер и
                  Faradise вправе в любое время оформить Договор на оказание
                  Услуг в форме письменного двухстороннего документа.
                </li>
              </ol>
            </li>
            <li>
Реквизиты Faradise
            </li>
            <p>
              Общество с ограниченной ответственностью «Центр технологий и медиа
              групп»
            </p>
            <p>
              Адрес (юридический): 107564, г. Москва, ул. Краснобогатырская,
              д.6, стр.2, ком. 18
            </p>
            <p>
ОГРН: 1167746194626
            </p>
            <p>
ИНН 7718299492
            </p>
            <p>
КПП 771801001
            </p>
            <p>
Р/с (расчетный счет): 40702810510000203351
            </p>
            <p>
Банк: АО «Тинькофф Банк»
            </p>
            <p>
БИК: 044525974
            </p>
            <p>
Корр. счет: 30101810145250000974
            </p>
            <p>
Тел.: +7(495)132-74-62
            </p>
            <p>
Форма обратной связи:
            </p>
            <p>
E-mail: privet@faradise.ru
            </p>
          </ol>
        </OtherBox>
        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Offer }
