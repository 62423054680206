import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import { Button } from '@ui/atoms/index'
import { COLORS } from '@constants/colors'
import { ProjectService } from '@services/ProjectService'
import { rooms as constRooms } from '@constants/rooms'
import { CardSelect, Icon } from '@ui/atoms'
import { InfoBox } from '@pages/client/FirstUserSteps/Info/info.style'
import { breakPriceToDigits, downloadFile } from '@helpers'
import { Modal } from '@ui/molecules'
import { ProjectProducts } from '@ui/organisms/projectProducts'
import { RoomTabs } from '@ui/organisms/roomTabs';
import { PhotoCarousel } from '@ui/organisms/photoCarousel';
import { Lightbox } from '@ui/organisms';
import { EmailModal } from '@pages/client/Development/common/EmailModal';

const PayModalBox = styled.div`
  width: 540px;
  
  .modal-btns {
    margin-top: 80px;
    display: flex;
    
    .btn {
      width: 255px;
      padding: 0 20px;
      
      &:first-child {
        margin-right: 30px;
      }
    }
  }
`

const MudbordBox = styled.div`
  .page_content {
    margin-bottom: 14px;
  }
  
  .top-content {
    min-height: auto;
  }
  
  .bottom-content {
    margin-top: 0;
  }
  
  .text,
  .page-info {
    font-size: 16px;
    color: ${COLORS.getBlueColor(0.54)};
    line-height: 24px;
    font-family: Roboto-Regular;

    .bold {
      font-family: Roboto-Medium;
      font-weight: 500;
    }
  }
  .page-description {
    margin-bottom: 16px;
  }
  .text {
    max-width: 350px;
  }
  .page-info {
    margin-bottom: 16px;
    color: ${COLORS.darkBlue};
  }
  .wait-box {
    margin-top: 24px;
    .page-info {
      display: inline-block;
    }
    svg {
      fill: ${COLORS.orange};
      vertical-align: top;
    }
    .page-info {
      margin-left: 18px;
      max-width: 379px;
    }

    .cross {
      vertical-align: super;
      font-size: 48px;
      color: ${COLORS.orange};
    }
  }
  .page-footer.step2 {
    display: flex;
    justify-content: space-between;
  }

  .btn_content {
    display: flex;
    justify-content: space-between;
    width: 790px;
    margin: 20px 165px 48px 165px;

    .all-download-btn {
      margin-right: 20px;
    }
  }
  
  .price {
    font-family: Roboto-Regular;
    font-size: 48px;
    color: ${COLORS.getBlueColor()};
  }
  
  .pay-btn {
    margin-top: 48px;
    width: 191px;
  }
  
  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }
`

const mapStateToProps = state => ({
  userData: state.user.userData,
  activeProject: state.project.activeProjectId
})

@connect(mapStateToProps)
class ClientPlans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: (() => {
        if (props.stage.stage > props.step || props.stage.designerIsReady) {
          return 3
        }

        if (props.stage.isPaid) {
          return 2
        }

        return 1
      })(),
      rooms: [],
      selectedRooms: [],
      payModalOpened: false,
      activeRoom: 0,
      emailModalOpen: false
    }
  }

  componentDidMount() {
    this.getStepInfo()
  }

  getStepInfo = async () => {
    const { activeProject, stage } = this.props
    const { step } = this.state

    try {
      const { result } = await ProjectService.getDevelopmentRooms(activeProject)

      if (stage.needPayment) {
        const orderedRoomResponse = await ProjectService.getOrderedPlanRooms(activeProject)

        this.setState({ selectedRooms: orderedRoomResponse.result })
      }

      if (step !== 3) {
        this.setState({
          rooms: result.map(room => ({
            model: room.purpose,
            roomId: room.roomId,
            roomPhotos: []
          })),
        })
      }

      if (step === 3) {
        const orderedRoomResponse = await ProjectService.getOrderedPlanRooms(activeProject)
        const orderedRooms = result.filter(room => orderedRoomResponse.result.includes(room.roomId))

        const plansResponse = await Promise.all(
          orderedRooms.map(room => ProjectService.getRoomPlans(activeProject, room.roomId))
        )

        this.setState({
          rooms: orderedRooms.map((room, roomIndex) => ({
            model: room.purpose,
            roomPhotos: plansResponse[roomIndex].result.planFiles.map(photo => ({
              src: photo.fileLink,
              alt: `photo-${photo.fileId}`
            })),
            roomId: room.roomId
          })),
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stage } = this.props

    if (!isEqual(stage, nextProps.stage)) {
      this.setState({
        step: (() => {
          if (nextProps.stage.stage > nextProps.step || nextProps.stage.designerIsReady) {
            return 3
          }

          if (nextProps.stage.isPaid) {
            return 2
          }

          return 1
        })()
      }, () => this.getStepInfo())
    }
  }

  confirmStage = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.confirmDevelopmentStage(activeProject, 'Plan')

    goNext(true)
  }

  orderPlans = async () => {
    try {
      const { activeProject, userData } = this.props
      const { selectedRooms } = this.state

      if (!userData.email) {
        this.setState({ emailModalOpen: true })

        return
      }

      const { result } = await ProjectService.orderRoomPlans({
        projectId: activeProject,
        roomsId: selectedRooms
      })

      this.setState({ emailModalOpen: false, payModalOpened: false })

      window.location.href = result
    } catch (e) {
      console.log(e)
    }
  }

  selectRoom = (room) => {
    const { selectedRooms } = this.state
    const targetIndex = selectedRooms.findIndex(sr => sr === room.roomId)
    const newSelectedRooms = selectedRooms.slice(0)

    if (targetIndex !== -1) {
      newSelectedRooms.splice(targetIndex, 1)
    } else {
      newSelectedRooms.push(room.roomId)
    }

    this.setState({ selectedRooms: newSelectedRooms })
  }

  getSum = () => {
    const { selectedRooms } = this.state

    return `${breakPriceToDigits(8900 * selectedRooms.length)} ₽`
  }

  skipStep = async () => {
    const { activeProject, goNext } = this.props

    await ProjectService.skipDevelopmentStage(activeProject, 'Plan')

    goNext(true)
  }

  getArchive = async () => {
    const { activeProject } = this.props
    const { rooms, activeRoom } = this.state

    try {
      const { result } = await ProjectService.getPlansArchive(activeProject, rooms[activeRoom].roomId)

      downloadFile(result)
    } catch (e) {
      console.log(e)
    }
  }

  renderFirstStep = () => {
    const { rooms, selectedRooms, payModalOpened } = this.state
    const { goPrev, stage } = this.props

    if (!rooms.length) {
      return null
    }

    return (
      <React.Fragment>
        <div className="page_content">
          <div className="page-body">
            <div className="page-title">
              Рабочие чертежи
            </div>
            <div className="g_big-text mb32">
              Пожалуй, самая важная стадия дизайн-проекта, которая сводит всё придуманное
              дизайнером в чертежи и рекомендации для рабочей бригады.
            </div>
            <div className="g_text dark">
              Пожалуйста выберите комнаты для которых вы хотите заказать чертежи.
              Напоминаем вам, что данный этап оплачивается отдельно — 8900 ₽/1 комната.
              <br />
              <br />
              Если вам не нужно делать рабочие чертежи, то вы можете пропустить этот шаг.
              По всем вопросам вас проконсультирует дизайнер в чате.
            </div>

            {
              !stage.isPaid && stage.needPayment && !stage.paymentFailed && (
              <React.Fragment>
                <div className="g_small-title mt48">
                          Выберите тип объекта
                </div>
                <InfoBox style={{ marginTop: 0 }}>
                  <div className="cards-wrapper">
                    {rooms.map(room => (
                      <CardSelect
                        key={room.model}
                        card={{ ...room, ...constRooms.find(cr => cr.model === room.model) }}
                        onChange={this.selectRoom}
                        active={selectedRooms.includes(room.roomId)}
                      />
                    ))}
                  </div>
                </InfoBox>
                <div className="g_small-title mt48">
                          Сумма для оплаты
                </div>
                <div className="price">
                  {this.getSum()}
                </div>
              </React.Fragment>
              )
              }


            {
              !stage.isPaid && !stage.needPayment && !stage.paymentFailed && (
              <div className="wait-box">
                <Icon icon="watch" width={48} height={48} />
                <div className="page-info">
                Ждем пока деньги дойдут.
                  {' '}
                  <br />
                  {' '}
            Это может занять какое-то время.
                </div>
              </div>
              )
            }

            {
              stage.paymentFailed && (
              <div className="wait-box">
                <i className="material-icons cross">
            highlight_off
                </i>
                <div className="page-info">
                Платеж не прошел.
                  {' '}
                  <br />
                  {' '}
            Пожалуйста, попробуйте еще раз.
                </div>
              </div>
              )
            }

            {
              stage.needPayment && !stage.paymentFailed && (
                <Button
                  text="Оплатить"
                  disabled={!selectedRooms.length}
                  className="pay-btn"
                  onClick={() => this.setState({ payModalOpened: true })}
                />
              )
            }

            {
              stage.needPayment && stage.paymentFailed && (
                <Button
                  text="Повторить"
                  disabled={!selectedRooms.length}
                  className="pay-btn"
                  onClick={() => this.setState({ payModalOpened: true })}
                />
              )
            }

            <div className="buttons-row">
              <div>
                <Button
                  type="text"
                  text="Назад"
                  onClick={goPrev}
                />
              </div>
              {
                !stage.isPaid && stage.needPayment && !stage.paymentFailed
                  && (
                  <Button
                    type="bordered"
                    text="Пропустить шаг"
                    onClick={this.skipStep}
                  />
                  )
              }
            </div>
          </div>
          <ProjectProducts
            rooms={rooms}
          />
        </div>
        <Modal
          isOpen={payModalOpened}
          onCancel={() => this.setState({ payModalOpened: false })}
        >
          <PayModalBox>
            <div className="page-title no-before">
              Готовы оплатить?
            </div>
            <div className="g_big-text">
              Оплата будет продолжена на сайте платёжной системы Яндекс.Деньги
            </div>
            <div className="modal-btns">
              <Button
                type="bordered"
                text="Отменить"
                onClick={() => this.setState({ payModalOpened: false })}
                className="btn"
              />
              <Button
                text="Подтвердить и продолжить"
                className="btn"
                onSave={this.orderPlans}
              />
            </div>
          </PayModalBox>
        </Modal>
      </React.Fragment>
    )
  }

  renderSecondStep = () => {
    const { rooms } = this.state
    const { updateStage, goPrev } = this.props

    if (!rooms.length) {
      return null
    }

    return (
      <React.Fragment>
        <div className="page_content">
          <div className="page-body">
            <div className="page-title">
              Рабочие чертежи
            </div>
            <div className="g_big-text mb32">
              Дизайнер приступил к созданию рабочих чертежей.
            </div>
            <div className="g_text dark">
              В среднем этот этап займет до 5 дней, но как правило меньше.
              Вы можете общаться с вашим дизайнером в чате.
              <br />
              <br />
              После того, как Дизайнер загрузит файлы у вас будет 4 дня на согласование и обсуждение Мудборда.
            </div>
            <div className="wait-box">
              <Icon icon="watch" width={48} height={48} />
              <div className="page-info">
                Дизайнер готовит материалы. Пожалуйста, дождитесь пока он их
                загрузит на платформу, вам придёт оповещение.
              </div>
            </div>
            <div className="buttons-row">
              <div>
                <Button
                  text="Назад"
                  type="text"
                  onClick={goPrev}
                />
              </div>
              <Button
                type="bordered"
                text="Проверить"
                onClick={() => updateStage()}
              />
            </div>
          </div>
          <ProjectProducts
            rooms={rooms}
          />
        </div>
      </React.Fragment>
    )
  }

  renderThirdStep = () => {
    const {
      showFullImage, rooms, activeRoom, currentImageIndex
    } = this.state
    const {
      goPrev, step, stage, goNext
    } = this.props

    return (
      <React.Fragment>
        <div className="page_content top-content">
          <div className="page-title">
            Рабочие чертежи
          </div>
          <div className="page-description">
            Все готово! Пожалуйста, проверьте все чертежи!
          </div>
          <div className="g_small-title mb16">
            Выберите комнату
          </div>
          <RoomTabs
            items={rooms}
            active={activeRoom}
            onChange={index => this.setState({ activeRoom: index })}
          />
        </div>
        {!!rooms.length && (
          <React.Fragment>
            <PhotoCarousel
              photos={rooms[activeRoom].roomPhotos}
              imgOnClick={index => this.setState({
                currentImageIndex: index,
                showFullImage: true
              })
              }
            />
            <div className="btn_content">
              <div>
                <Button
                  text="Назад"
                  onClick={goPrev}
                  type="text"
                />
              </div>
              <div>
                <Button
                  className="all-download-btn"
                  color="additional"
                  type="filled"
                  text="Скачать одним архивом"
                  icon="save_alt"
                  onClick={this.getArchive}
                />
                {stage.stage === step ? (
                  <Button
                    className="all-approve-btn"
                    type="filled"
                    text="Согласовать и продолжить"
                    onClick={this.confirmStage}
                  />
                ) : (
                  <Button
                    text="Вперед"
                    onClick={() => goNext()}
                  />
                )}
              </div>
            </div>
            <div className="page_content bottom-content">
              <ProjectProducts
                rooms={rooms}
              />
            </div>
              {!!rooms[activeRoom].roomPhotos && (
              <Lightbox
                isOpen={showFullImage}
                currentImageIndex={currentImageIndex}
                images={rooms[activeRoom].roomPhotos.map(f => f.src)}
                getPrev={() => this.setState({ currentImageIndex: currentImageIndex - 1 })}
                getNext={() => this.setState({ currentImageIndex: currentImageIndex + 1 })}
                onCancel={() => this.setState({ showFullImage: false })}
              />
              )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { step, emailModalOpen } = this.state

    return (
      <MudbordBox>
        {step === 1 && this.renderFirstStep()}
        {step === 2 && this.renderSecondStep()}
        {step === 3 && this.renderThirdStep()}

        <EmailModal
          isOpen={emailModalOpen}
          confirm={this.orderPlans}
          onCancel={() => this.setState({ emailModalOpen: false })}
        />
      </MudbordBox>
    )
  }
}

export { ClientPlans }
