export const roomAssignment = [
  {
    img: 'hallway',
    name: 'Прихожая',
    id: 1,
    model: 'Hallway'
  },
  {
    img: 'bathroom',
    name: 'Туалет и Ванная',
    id: 2,
    model: 'Bathroom',
  },
  {
    img: 'kitchen',
    name: 'Кухня',
    id: 3,
    model: 'Kitchen',
  },
  {
    img: 'livingRoom',
    name: 'Гостиная',
    id: 4,
    model: 'LivingRoom',
  },
  {
    img: 'bedroom',
    name: 'Спальня',
    id: 5,
    model: 'BedRoom',
  },
  {
    img: 'nursery',
    name: 'Детская',
    id: 6,
    model: 'ChildrenRoom',
  },
  {
    img: 'wardrobe',
    name: 'Гардероб',
    id: 7,
    model: 'Wardrobe',
  },
  {
    img: 'cabinet',
    name: 'Кабинет',
    id: 8,
    model: 'Parlor',
  },
  {
    img: 'loggia',
    name: 'Лоджия и балкон',
    id: 9,
    model: 'LoggiaOrBalcony',
  },
  {
    img: 'other',
    name: 'Другое',
    id: 10,
    model: 'Custom',
  }
]
