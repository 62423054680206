export const HOST = `${process.env.HOST}`

export const API = {
  AUTHENTICATION: {
    createClient: `${HOST}/api/authentication/createClient`,
    createDesigner: `${HOST}/api/authentication/createDesigner`,
    requestCode: `${HOST}/api/authentication/requestCode`,
    bind: `${HOST}/api/authentication/bind`,
    login: `${HOST}/api/authentication/login`,
    checkPhone: `${HOST}/api/authentication/check-phone`,
    validateToken: `${HOST}/api/authentication/validateToken`
  },
  CLIENT: {
    setBasicInfo: `${HOST}/api/client/project/setBasicInfo`,
    createProject: `${HOST}/api/client/project/create`,
    setAdditionalInfo: `${HOST}/api/client/project/setAdditionalInfo`,
    setStyles: `${HOST}/api/client/project/setStyles`,
    setBudget: `${HOST}/api/client/project/setBudget`,
    setDesignerRequirements: `${HOST}/api/client/project/setDesignerRequirements`,
    getQuestions: `${HOST}/api/client/project/getQuestions`,
    getProjectsList: `${HOST}/api/client/project/list`,
    getProject: `${HOST}/api/client/project/get`,
    getDesigners: `${HOST}/api/client/project/get-designers`,
    selectDesigner: `${HOST}/api/client/project/select-designer`,
    getDesignerPortfolio: `${HOST}/api/designer/portfolio-for-specific-designer`,
    getProjectShortDescription: `${HOST}/api/client/project/get-short-description`
  },
  PROJECT: {
    finishFilling: `${HOST}/api/client/project/finish-filling`,
    addDesignerToProject: `${HOST}/api/project/temp/add-designer-to-pool`,
    removeDesigners: `${HOST}/api/client/project/remove-designers`,
    getMembers: `${HOST}/api/client/project/get-members`,
    DEVELOPMENT: {
      abandonDesigner: `${HOST}/api/project/development/abandon-designer`,
      getRooms: `${HOST}/api/project/development/get-rooms`,
      uploadCollagePhoto: `${HOST}/api/project/development/upload-collage-photo`,
      removeCollagePhoto: `${HOST}/api/project/development/delete-collage-photo`,
      uploadMoodboardPhoto: `${HOST}/api/project/development/upload-moodboard-photo`,
      removeMoodboardPhoto: `${HOST}/api/project/development/delete-moodboard-photo`,
      getCurrentStage: `${HOST}/api/project/development/get-current-stage`,
      confirmStage: `${HOST}/api/project/development/confirm-stage`,
      skipStage: `${HOST}/api/project/development/skip-stage`,
      getArchiveMoodboards: `${HOST}/api/project/development/get-archive-moodboards`,
      getMoodboards: `${HOST}/api/project/development/get-moodboards`,
      getCollages: `${HOST}/api/project/development/get-collages`,
      getArchiveCollages: `${HOST}/api/project/development/get-archive-collages`,
      getZones: `${HOST}/api/project/development/get-zones`,
      uploadZonePhoto: `${HOST}/api/project/development/upload-zone-photo`,
      removeZonePhoto: `${HOST}/api/project/development/delete-zone-photo`,
      getArchiveZones: `${HOST}/api/project/development/get-archive-zones`,
      getPlans: `${HOST}/api/project/development/get-plans`,
      uploadPlanFile: `${HOST}/api/project/development/upload-plan-file`,
      removePlanFile: `${HOST}/api/project/development/delete-plan-file`,
      getArchivePlans: `${HOST}/api/project/development/get-archive-plans`,
      getVisualization: `${HOST}/api/project/development/get-visulization`,
      uploadVisualizationFile: `${HOST}/api/project/development/upload-visualization-file`,
      removeVisualizationFile: `${HOST}/api/project/development/delete-visualization-file`,
      getArchiveVisualizations: `${HOST}/api/project/development/get-archive-visualizations`,
      orderRoomVisualization: `${HOST}/api/project/development/order-room-visalization`,
      getOrderedVisualizationRooms: `${HOST}/api/project/development/get-ordered-visulization-rooms`,
      orderRoomPlans: `${HOST}/api/project/development/order-room-plans`,
      getOrderedPlanRooms: `${HOST}/api/project/development/get-ordered-workplans-rooms`,
      finishRoomPlans: `${HOST}/api/project/development/finish-room-plan`,
      orderBrigade: `${HOST}/api/project/development/order-brigade`
    },
    PREPARATIONS: {
      abandonDesigner: `${HOST}/api/project/preparations/abandon-designer`,
      confirmDesigner: `${HOST}/api/project/preparations/confirm-designer`,
      uploadRoomPhoto: `${HOST}/api/project/preparations/upload-room-photo`,
      removeRoomPhoto: `${HOST}/api/project/preparations/delete-room-photo`,
      uploadRoomPlan: `${HOST}/api/project/preparations/upload-room-plan`,
      removeRoomPlan: `${HOST}/api/project/preparations/delete-room-plan`,
      getRoomInformation: `${HOST}/api/project/preparations/get-room-information`,
      confirmStage: `${HOST}/api/project/preparations/confirm-stage`,
      setRoomInformation: `${HOST}/api/project/preparations/set-room-information`,
      getCurrentStage: `${HOST}/api/project/preparations/get-current-stage`,
      setFavoriteStyles: `${HOST}/api/project/preparations/set-favorite-styles`,
      setBudget: `${HOST}/api/project/preparations/set-budget`,
      getBudget: `${HOST}/api/project/preparations/get-budget`,
      setAdditionalRoomInfo: `${HOST}/api/project/preparations/set-information`,
      getAdditionalRoomInfo: `${HOST}/api/project/preparations/get-additional-info`,
      uploadOldFurniturePhoto: `${HOST}/api/project/preparations/upload-old-furniture-photo`,
      uploadOldFurnishPhoto: `${HOST}/api/project/preparations/upload-old-furnish-photo`,
      removeOldFurniturePhoto: `${HOST}/api/project/preparations/delete-old-furniture-photo`,
      removeOldFurnishPhoto: `${HOST}/api/project/preparations/delete-old-furnish-photo`,
      getOldFurniture: `${HOST}/api/project/preparations/get-old-furtinture`,
      setOldStuffReady: `${HOST}/api/project/preparations/set-old-stuff-ready`,
      getStyles: `${HOST}/api/project/preparations/get-styles`,
      skipStage: `${HOST}/api/project/preparations/skip-stage`
    }
  },
  USER: {
    authorize: `${HOST}/api/user/authorize`,
    setEmail: `${HOST}/api/user/setEmail`
  },
  DESIGNER: {
    createPortfolio: `${HOST}/api/designer/createPortfolio`,
    setBasicInfo: `${HOST}/api/designer/setBasicInfo`,
    basicInfo: `${HOST}/api/designer/basicInfo`,
    addAvatar: `${HOST}/api/designer/addAvatar`,
    setTestLink: `${HOST}/api/designer/setTestLink`,
    removePortfolio: `${HOST}/api/designer/removePortfolio`,
    setPortfolioDescription: `${HOST}/api/designer/setPortfolioDescription`,
    addPortfolioPhoto: `${HOST}/api/designer/addPortfolioPhoto`,
    removePortfolioPhoto: `${HOST}/api/designer/removePortfolioPhoto`,
    getQuestions: `${HOST}/api/designer/getQuestions`,
    setPossibilities: `${HOST}/api/designer/setPossibilities`,
    setTestResults: `${HOST}/api/designer/setTestResults`,
    setLegal: `${HOST}/api/designer/setLegal`,
    getFullInfo: `${HOST}/api/designer/fullInfo`
  },
  MARKETPLACE: {
    getSugestions: `${HOST}/api/marketplace/get-sugestions`,
    getPossibleProjects: `${HOST}/api/marketplace/get-possible-projects`,
    getCategories: `${HOST}/api/marketplace/get-categories`,
    getProduct: `${HOST}/api/marketplace/get-product`,
    getProducts: `${HOST}/api/marketplace/get-products-filtered`,
    getRooms: `${HOST}/api/marketplace/get-rooms`,
    getRoomProduct: `${HOST}/api/marketplace/get-room-products`,
    addProductToProject: `${HOST}/api/marketplace/add-product-to-project`,
    removeProductFromProject: `${HOST}/api/marketplace/remove-product-from-project`,
    getOrderedProductsForProjectRoom: `${HOST}/api/marketplace/get-products-for-project-room`,
    issueOrderForProject: `${HOST}/api/marketplace/issue-order-for-project`,
    getSubcategories: `${HOST}/api/marketplace/get-subcategories`,
    getFilters: `${HOST}/api/marketplace/get-filters`,
    getCategoryPath: `${HOST}/api/marketplace/get-category-path`,
    getCategoryDescription: `${HOST}/api/marketplace/get-category-description`
  },
  CHAT: {
    getNotificationState: `${HOST}/api/messenger/get-chat-notification-state`,
    disableNotification: `${HOST}/api/messenger/disable-chat-notification`,
    activateNotification: `${HOST}/api/messenger/activate-chat-notification`,
    addAdminPreparations: `${HOST}/api/project/preparations/add-admin`,
    addAdminDevelopment: `${HOST}/api/project/development/add-admin`,
    server: `${HOST}/chat/chat`
  },
  FREELANCE: {
    VISUALIZATION: () => {
      const path = `${HOST}/api/freelance/visulization`

      return {
        getTechspec: `${path}/get-techspec`,
        addFile: `${path}/add-file`,
        removeFile: `${path}/remove-file`,
        setTechspec: `${path}/set-techspec`,
        publishTechSpec: `${path}/publish-tech-spec`
      }
    },
    BRIGADE: () => {
      const path = `${HOST}/api/freelance/brigade`

      return {
        getTechspec: `${path}/get-techspec`,
        addFile: `${path}/add-file`,
        removeFile: `${path}/remove-file`,
        setTechspec: `${path}/set-techspec`,
        publishTechSpec: `${path}/publish-tech-spec`
      }
    }
  }
}
