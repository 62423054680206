import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const PortfolioBox = styled.div`
  .title {
    margin-bottom: 30px;
    font-family: FuturaNewMedium-Reg;
    font-size: 28px;
    font-weight: 500;
    color: ${COLORS.darkBlue};
  }

  .cards-wrapper {
    max-width: 750px;
    display: flex;
    flex-wrap: wrap;

    .card {
      width: 235px;
      padding: 18px;
      cursor: pointer;

      &:hover {
        border-radius: 8px;
        background-color: rgba(37, 60, 94, 0.05);

        .img-wrapper .img-overlay {
          display: flex;
        }
      }

      .img-wrapper {
        position: relative;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .empty {
          text-align: center;
        }

        .img-overlay {
          display: none;
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 4px;
          background-color: rgba(37, 60, 94, 0.5);
          color: white;
          font-family: Roboto-Medium;
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          justify-content: center;
          flex-direction: column;
        }

        .img {
          position: absolute;
          width: 200px;
          height: 200px;
          border-radius: 4px;
          object-fit: contain;
        }
      }

      .name {
        margin-top: 16px;
        font-family: Roboto-Regular;
        font-size: 16px;
        color: ${COLORS.darkBlue};
      }
    }
  }
`

const ProjectBox = styled.div`
  position: relative;

  .big-arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    font-size: 60px;
    color: ${COLORS.lineLight};

    &:hover {
      color: white;
    }

    &.left {
      left: -90px;
    }

    &.right {
      right: -90px;
    }

    &.disabled {
      display: none;
    }
  }

  .title {
    margin-bottom: 30px;
    font-family: FuturaNewMedium-Reg;
    font-size: 28px;
    font-weight: 500;
    color: ${COLORS.darkBlue};
  }

  .icon-back {
    font-size: 29px;
    cursor: pointer;
    vertical-align: sub;
  }

  .main-info {
    display: flex;
    max-width: 730px;

    .img-wrapper {
      position: relative;
      width: 350px;
      height: 466px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid ${COLORS.lineLight};

      .img {
        max-height: 466px;
        max-width: 350px;
        object-fit: contain;
      }

      .fullscreen-icon-wrapper {
        position: absolute;
        bottom: 12px;
        right: 12px;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        opacity: 0.7;
        background-color: ${COLORS.darkBlue};

        i {
          color: white;
          font-size: 33px;
          cursor: pointer;
        }
      }
    }

    .description {
      width: 350px;
      margin-left: 30px;
      font-family: Roboto-Regular;
      opacity: 0.7;
      font-size: 16px;
      font-weight: normal;
      color: ${COLORS.darkBlue};

      .about-project {
        font-family: Roboto-Medium;
        font-size: 16px;
        font-weight: 500;
        color: ${COLORS.darkBlue};
      }
    }
  }

  .images-slider {
    margin-top: 12px;
    position: relative;
    width: 351px;

    .arrow {
      position: absolute;
      top: 27px;
      cursor: pointer;

      &.left {
        left: -25px;
      }

      &.right {
        right: -25px;
      }

      &.disabled {
        cursor: default;
        color: ${COLORS.lineLight};
        pointer-events: none;
      }
    }

    .slides-wrapper {
      overflow: hidden;

      .slides {
        width: max-content;
        display: flex;

        .img-preview-wrapper {
          position: relative;
          margin-right: 13px;
          width: 78px;
          height: 78px;
          cursor: pointer;

          &:hover {
            .img-preview-overlay {
              display: none;
            }
          }

          &.active {
            padding: 7px;
            border: 1px solid ${COLORS.darkBlue};
            border-radius: 6px;
            background-color: rgba(37, 60, 94, 0.05);

            .img-preview-overlay {
              display: none;
            }
          }

          .img-preview {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }

          .img-preview-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            background-color: white;
            top: 0;
          }
        }
      }
    }
  }
`

export { PortfolioBox, ProjectBox }
