import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { CheckBox } from '@ui/atoms'
import { MarketplaceService } from '@services/MarketplaceService'
import { rooms } from '@constants/rooms'
import { ProjectsSelectBox } from './projectsSelect.style'

const parseSize = size => size.split('x').map(size => Math.round(Number(size) / 10) / 10).join('x')

class ProjectsSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      projects: [],
      selectedRooms: []
    }
  }

  async componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideProjectsSelect)
  }

  handleDropdown = async (event) => {
    event.stopPropagation()

    try {
      const { productId } = this.props

      let { projects } = this.state

      this.setState(state => ({ isOpen: !state.isOpen }))

      if (projects.length) {
        return
      }

      const response = await MarketplaceService.getPossibleProjects(productId)

      projects = response.result
      const selectedRooms = []

      projects.forEach((project) => {
        project.rooms.forEach((room) => {
          if (room.hasProduct) {
            selectedRooms.push(room.roomId)
          }
        })
      })

      this.setState({ projects, selectedRooms })
    } catch (error) {
      console.error(error)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutsideProjectsSelect)
  }

  handleClickOutsideProjectsSelect = (event) => {
    if (this.projectsDropdown && !this.projectsDropdown.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  toggleRoom = async (roomId, projectId) => {
    const { selectedRooms } = this.state
    const { productId } = this.props

    try {
      if (selectedRooms.includes(roomId)) {
        const index = selectedRooms.indexOf(roomId)

        await MarketplaceService.removeProductFromProject({
          projectId, productId, roomId, count: 1
        })

        this.setState(state => ({ selectedRooms: [...state.selectedRooms.slice(0, index), ...state.selectedRooms.slice(index + 1)] }))
      } else {
        await MarketplaceService.addProductToProject({
          projectId, productId, roomId, count: 1
        })

        this.setState(state => ({ selectedRooms: [...state.selectedRooms, roomId] }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { className } = this.props

    const { isOpen, projects, selectedRooms } = this.state

    return (
      <ProjectsSelectBox className={className} innerRef={elem => this.projectsDropdown = elem}>
        <div className="toggle" onClick={event => this.handleDropdown(event)}>
          Добавить в проект
          {
            isOpen ? (
              <i className="material-icons">
            arrow_drop_up
              </i>
            ) : (
              <i className="material-icons">
arrow_drop_down
              </i>
            )
          }
        </div>

        {isOpen && (
          <div className="dropdown" onClick={event => event.stopPropagation()}>
            <Scrollbars
              autoHeight
              autoHeightMax={350}
            >
              {
              projects.length
                ? projects.map(project => (
                  <React.Fragment key={project.projectId}>
                    <div className="project-title">
                      {project.projectName}
                    </div>

                    {
                project.rooms.map(room => (
                  <div className="checkboxes-wrapper" key={room.roomId}>
                    <div className="room-info">
                      <CheckBox
                        onFocus={() => undefined}
                        label={rooms.find(elem => elem.model === room.purpose).name || 'Кухня'}
                        onChange={() => this.toggleRoom(room.roomId, project.projectId)}
                        checked={selectedRooms.includes(room.roomId)}
                        suffix={parseSize(room.size)}
                      />
                    </div>
                  </div>
                ))
              }
                  </React.Fragment>
                )) : (
                  <div>
Список доступных проектов пуст
                  </div>
                )
          }
            </Scrollbars>

          </div>
        )}
      </ProjectsSelectBox>
    )
  }
}

export { ProjectsSelect }
