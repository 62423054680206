import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'

import { Steps } from '@ui/organisms/steps'
import {
  FirstUserStepsBox,
  ImageBox,
  HeaderBox
} from '@pages/client/FirstUserSteps/firstUserSteps.style'
import { Info } from '@pages/client/FirstUserSteps/Info'
import { Style } from '@pages/client/FirstUserSteps/Style'
import { Designer } from '@pages/client/FirstUserSteps/Designer'
import { DesignerSearch } from '@pages/client/FirstUserSteps/DesignerSearch'
import { Hello } from '@pages/client/FirstUserSteps/Hello'
import classnames from 'classnames'
import { DesignerChoice } from '@pages/client/FirstUserSteps/DesignerChoice'
import { Logo, Button, Preloader } from '@ui/atoms'
import { MainFooter } from '@ui/organisms/mainFooter'
import { MainFooterMobile } from '@ui/organisms/mainFooter.mobile'
import { ROUTES } from '@constants'
import { ClientService } from '@services/ClientService'
import { pages } from '@pages/client/FirstUserSteps/configs/analytics'

import { AnalyticsService } from '@services/AnalyticsService'
import { UserService } from '@services/UserService';
import { RESPONSIVE } from '@constants/responsive'
import { HelloMobile } from '@pages/client/FirstUserSteps/Hello/index.mobile'
import { InfoMobile } from '@pages/client/FirstUserSteps/Info/index.mobile'
import { StyleMobile } from '@pages/client/FirstUserSteps/Style/index.mobile'
import { DesignerMobile } from '@pages/client/FirstUserSteps/Designer/index.mobile'
import { Stub } from '@ui/organisms/stub'
import logo from '@assets/images/logo.png'
import {
  hello,
  aboutProject,
  style,
  designer,
  waitingForDesigner,
  designerChoice
} from './configs/images'

const steps = [
  {
    name: 'Знакомство',
    stepForStepsNumber: 1,
    renderTitle: () => (
      null
    ),
    renderComponent: ({ goNext, state }) => (
      <Hello goNext={goNext} stepState={state} />
    ),
    imgSrc: hello,
    renderMobileComponent: ({ goNext, state }) => (
      <HelloMobile goNext={goNext} stepState={state} />
    )
  },
  {
    name: 'О проекте',
    stepForStepsNumber: 2,
    renderTitle: () => (
      <div className="step-title">
        Расскажите нам
        <br />
        о вашем проекте
        {' '}
        <span role="img" aria-label="hi">
          💬
        </span>
      </div>
    ),
    renderComponent: ({ goNext, goPrev, state }) => (
      <Info goNext={goNext} stepState={state} goPrev={goPrev} />
    ),
    imgSrc: aboutProject,
    renderMobileComponent: ({ goNext, goPrev, state }) => (
      <InfoMobile goNext={goNext} stepState={state} goPrev={goPrev} />
    )
  },
  {
    name: 'Выбор стиля',
    stepForStepsNumber: 3,
    renderTitle: () => {
    },
    renderComponent: ({ goNext, goPrev, state }) => (
      <Style goNext={goNext} stepState={state} goPrev={goPrev} />
    ),
    imgSrc: style,
    renderMobileComponent: ({ goNext, goPrev, state }) => (
      <StyleMobile goNext={goNext} stepState={state} goPrev={goPrev} />
    )
  },
  {
    name: 'Подбор дизайнера',
    visible: false,
    stepForStepsNumber: 4,
    progress: 100,
    renderTitle: () => (
      <div className="step-title">
        Всё готово!
        {' '}
        <span role="img" aria-label="hi">
        🎉
        </span>
        <br />
        Осталось немного подождать
        {' '}
      </div>
    ),
    renderComponent: ({ goNext, state }) => (
      <DesignerSearch goNext={goNext} stepState={state} step={1} />
    ),
    imgSrc: waitingForDesigner,
    renderMobileComponent: ({ goNext, state }) => (
      <DesignerSearch goNext={goNext} stepState={state} step={1} />
    )
  },
  {
    name: 'Подбор дизайнера',
    visible: false,
    stepForStepsNumber: 5,
    progress: 100,
    renderTitle: () => (
      <div className="step-title">
        Всё готово!
        {' '}
        <span role="img" aria-label="hi">
        🎉
        </span>
        <br />
        Осталось немного подождать
        {' '}
      </div>
    ),
    renderComponent: ({ goNext, state }) => (
      <DesignerSearch goNext={goNext} stepState={state} step={2} />
    ),
    imgSrc: waitingForDesigner,
    renderMobileComponent: ({ goNext, state }) => (
      <DesignerSearch goNext={goNext} stepState={state} step={2} />
    )
  },
  {
    name: 'Подбор дизайнера',
    stepForStepsNumber: 6,
    renderTitle: () => (
      <div className="step-title">
        А теперь давайте подберем вам лучшего дизайнера
        {' '}
        <span role="img" aria-label="hi">
          👌
        </span>
        <div className="step-title-description-wrapper">
          <div className="step-title-description">
          Мы ищем для вас лучшего дизайнера. Это может занять до 24 часов,
          но как правило меньше.
          Мы оповестим вас о результатах по SMS и напишем вам письмо на Email.
          </div>
        </div>
      </div>
    ),
    renderComponent: ({ goNext, state, goPrev }) => (
      <Designer goNext={goNext} stepState={state} goPrev={goPrev} />
    ),
    imgSrc: designer,
    renderMobileComponent: ({ goNext, goPrev, state }) => (
      <DesignerMobile goNext={goNext} stepState={state} goPrev={goPrev} />
    )
  },
  {
    name: 'Подбор дизайнера',
    visible: false,
    stepForStepsNumber: 7,
    progress: 100,
    renderTitle: () => (
      <div className="step-title">
        Всё готово!
        {' '}
        <span role="img" aria-label="hi">
        🎉
        </span>
        <br />
        Осталось немного подождать
        {' '}
      </div>
    ),
    renderComponent: ({ goNext, state }) => (
      <DesignerSearch goNext={goNext} stepState={state} step={3} />
    ),
    imgSrc: waitingForDesigner,
    renderMobileComponent: ({ goNext, state }) => (
      <DesignerSearch goNext={goNext} stepState={state} step={3} />
    )
  },
  {
    name: 'Подбор дизайнера',
    visible: false,
    stepForStepsNumber: 8,
    progress: 100,
    renderTitle: () => (
      <div className="step-title">
        Мы подобрали вам дизайнеров!
        {' '}
        <span role="img" aria-label="ready">
          🎉
        </span>
      </div>
    ),
    renderComponent: ({ goNext, state, goToStep }) => (
      <DesignerChoice goNext={goNext} stepState={state} goToStep={goToStep} />
    ),
    imgSrc: designerChoice,
    renderMobileComponent: ({ goNext, state, goToStep }) => (
      <DesignerChoice goNext={goNext} stepState={state} goToStep={goToStep} />
    )
  }
]

const stepsForSteps = steps.filter(step => step.visible !== false)
const defaultStepsData = steps.reduce(
  (res, el, index) => ({
    ...res,
    [index]: {}
  }),
  {}
)

const mapStateToProps = state => ({
  userData: state.user.userData,
  project: state.project
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: 'LOGOUT_REQUEST' })
})

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class FirstUserSteps extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stepsData: defaultStepsData,
      currentStep: 1,
      isLoading: true
    }
  }

  async componentDidMount() {
    try {
      const { project, userData } = this.props

      if (userData.phone) {
        const response = await UserService.checkPhone(userData.phone)

        if (!response.result) {
          this.goToStep(1)

          this.setState({ isLoading: false })

          return
        }
      }

      if (project.activeProjectId) {
        const { result } = await ClientService.getProject(
          project.activeProjectId
        )

        const designersListResponse = await ClientService.getDesigners(
          project.activeProjectId
        )

        if (designersListResponse.result.length > 0) {
          this.goToStep(8)

          this.setState({ isLoading: false })

          return
        }

        if (result.designerRequirements) {
          this.goToStep(7)

          this.setState({ isLoading: false })

          return
        }

        if (result.styles && result.styles.length) {
          this.goToStep(4)

          this.setState({ isLoading: false })

          return
        }

        if (result.rooms && result.rooms.length) {
          this.goToStep(3)

          this.setState({ isLoading: false })

          return
        }

        this.goToStep(2)

        this.setState({ isLoading: false })

        return
      }

      AnalyticsService.pageVisited(pages[1])
    } catch (error) {
      console.error(error)
    }

    this.setState({ isLoading: false })
  }

  goToStep = (step) => {
    this.setState({ currentStep: step })

    AnalyticsService.pageVisited(pages[step])

    window.scroll(window, 0)
  }

  goNext = () => {
    const { currentStep } = this.state

    this.setState(state => ({ currentStep: state.currentStep + 1 }))

    AnalyticsService.pageVisited(pages[currentStep + 1])

    window.scroll(window, 0)
  }

  goPrev = () => {
    const { currentStep } = this.state

    this.setState(state => ({ currentStep: state.currentStep - 1 }))

    AnalyticsService.pageVisited(pages[currentStep - 1])

    window.scroll(window, 0)
  }

  render() {
    const { userData, logout, history } = this.props

    const { currentStep, isLoading } = this.state

    const { stepsData } = this.state

    if (isLoading) {
      return (
        <FirstUserStepsBox>
          <ImageBox src={hello}>
            <Logo className="logo" />
          </ImageBox>
          <Preloader />
        </FirstUserStepsBox>
      )
    }

    return (
      <FirstUserStepsBox>
        <ImageBox src={steps[currentStep - 1].imgSrc} />
        <div className="page">
          <HeaderBox>
            <div className="header-container">
              <MediaQuery minWidth={RESPONSIVE.MOBILE}>
                <a className="logo" href="/">
                  <img src={logo} alt="logo" />
                </a>

                <Steps
                  steps={stepsForSteps}
                  currentStep={steps[currentStep - 1].stepForStepsNumber}
                  maxStep={4}
                />
              </MediaQuery>
              <MediaQuery maxWidth={RESPONSIVE.MOBILE}>
                <div className="mobile-header">
FARADISE
                </div>
              </MediaQuery>
              <div className="controls">
                <div className="auth-user">
                  {!userData.phone ? (
                    <Button
                      text="Войти"
                      type="bordered"
                      size="small"
                      color="primary"
                      className="login-btn"
                      onClick={() => history.push(ROUTES.login)}
                    />
                  ) : (
                    <React.Fragment>
                      <div className="greeting">
                        Привет,
                        {' '}
                        {userData.name}
                        !
                      </div>
                      <div
                        className="logout"
                        onClick={() => {
                          this.setState({ stepsData: defaultStepsData })
                          logout()
                          history.push(ROUTES.main)
                          window.scroll(window, 0)
                        }}
                      >
                        Выйти
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </HeaderBox>
          <div className={classnames({ 'responsive-box': currentStep !== 3 })}>
            <div className={`step-page step${currentStep}`}>
              {!isLoading ? (
                <React.Fragment>
                  <MediaQuery minWidth={RESPONSIVE.MOBILE}>
                    {steps[currentStep - 1].renderTitle()}
                    {steps[currentStep - 1].renderComponent({
                      goNext: this.goNext,
                      state: stepsData[currentStep],
                      goPrev: this.goPrev,
                      goToStep: this.goToStep
                    })}
                  </MediaQuery>
                  <MediaQuery maxWidth={RESPONSIVE.MOBILE}>
                    {currentStep <= 7 && (steps[currentStep - 1].renderTitle())}
                    {currentStep <= 7 ? (
                      steps[currentStep - 1].renderMobileComponent({
                        goNext: this.goNext,
                        state: stepsData[currentStep],
                        goPrev: this.goPrev,
                        goToStep: this.goToStep
                      })
                    ) : (
                      <Stub isInnerPage />
                    )}
                  </MediaQuery>
                </React.Fragment>
              ) : (
                <Preloader />
              )}
            </div>
          </div>
          <MediaQuery maxWidth={RESPONSIVE.MOBILE}>
            <MainFooterMobile className="first-step-footer" />
          </MediaQuery>
          <MediaQuery minWidth={RESPONSIVE.MOBILE}>
            <MainFooter className="first-step-footer" />
          </MediaQuery>
        </div>
      </FirstUserStepsBox>
    )
  }
}

export { FirstUserSteps }
