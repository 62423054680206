import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Header, OtherBox } from '@pages/other/style'
import { MainFooter } from '@ui/organisms/mainFooter'
import { COLORS } from '@constants'

import search from '@assets/images/icons/search.png'
import call from '@assets/images/icons/call.png'
import sale from '@assets/images/icons/sale.png'
import mobile from '@assets/images/icons/mobile_cash.png'
import tech from '@assets/images/icons/tech.png'
import key from '@assets/images/icons/key.png'
import partners from '@assets/images/partners.png'

const SuppliersBox = styled(OtherBox)`
  .top-text {
    max-width: 1113px;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${COLORS.darkBlue};
    font-family: FuturaNewBook-Reg;
  }

  .sub-title {
    margin-top: 28px;
    font-family: FuturaNewBold-Reg;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    color: ${COLORS.darkBlue};
    &.center {
      text-align: center;
    }
  }

  .rows {
    display: flex;
    flex-flow: row;
    .row:first-child {
      margin-right: 60px;
    }
  }

  .how-to-pay {
    display: flex;
    flex-direction: column;
  }

  .benefits {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .benefits-row {
      display: flex;
      margin-bottom: 40px;

      .text-container {
        max-width: 350px;

        .title {
          margin-bottom: 16px;
          font-family: FuturaNewBold-Reg;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.2;
          color: ${COLORS.darkBlue};
        }
      }
    }

    .img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      margin-right: 46px;
    }
  }

  .partners-img {
    margin-top: 32px;
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
  }

  .links-us {
    .title {
      font-family: FuturaNewBold-Reg;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    
    p{
    margin: 0;
    }
    
    a {
    margin-bottom: 5px;
      font-size: 16px;
      line-height: 1.75;
      color: ${COLORS.getBlueColor(0.7)};
      &:hover{
        color: ${COLORS.orange};
      }
    }
  }
`

@withRouter
class Suppliers extends Component {
  render() {
    const { isInnerPage } = this.props

    return (
      <React.Fragment>
        {!isInnerPage && (
          <Header>
            <a className="logo" href="/">
              FARADISE
            </a>
          </Header>
        )}

        <SuppliersBox isInnerPage={isInnerPage}>
          <h1>
Для поставщиков
          </h1>
          <div className="rows">
            <div className="row">
              <div className="sub-title">
Мы решаем три основных задачи
              </div>
              <div className="benefits">
                <div className="benefits-row">
                  <img src={search} alt="" className="img" />
                  <div className="text-container">
                    <div className="title">
Поиск клиентов
                    </div>
                    <span>
                      Продвигая приложение мы постоянно находим для вас новых
                      потенциальных покупателей
                    </span>
                  </div>
                </div>
                <div className="benefits-row">
                  <img src={call} alt="" className="img" />
                  <div className="text-container">
                    <div className="title">
Консультация
                    </div>
                    <span>
                      Избавляем пользователя от необходимости ехать в магазин
                    </span>
                  </div>
                </div>
                <div className="benefits-row">
                  <img src={sale} alt="" className="img" />
                  <div className="text-container">
                    <div className="title">
Продажа
                    </div>
                    <span>
Избавляем клиента от сомнений и раздумий
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="sub-title">
Преимущества работы с нами
              </div>
              <div className="benefits">
                <div className="benefits-row">
                  <img src={mobile} alt="" className="img" />
                  <div className="text-container">
                    <div className="title">
Продажи нового уровня
                    </div>
                    <span>
                      Размещая свои товары в нашем приложении, вы получаете
                      уникальный инструмент продаж, способный не только удивить
                      покупателя, но и помочь ему понять, как мебель будет
                      смотреться у него дома. Покупатель сможет сразу
                      определить, подходит ли ему ваш товар.
                    </span>
                  </div>
                </div>
                <div className="benefits-row">
                  <img src={tech} alt="" className="img" />
                  <div className="text-container">
                    <div className="title">
                      Наши технологии — ваши возможности
                    </div>
                    <span>
                      У вас нет необходимости тратить огромные деньги на
                      разработку мобильного приложения с дополненной
                      реальностью, мы уже все сделали за вас.
                    </span>
                  </div>
                </div>
                <div className="benefits-row">
                  <img src={key} alt="" className="img" />
                  <div className="text-container">
                    <div className="title">
Легкий вход
                    </div>
                    <span>
                      Заключите договор, подтвердите оферту и загрузите свой
                      прайс. Дальше работать будем мы
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-title center">
Уже с нами
          </div>
          <img className="partners-img" src={partners} alt="" />
          <div className="links-us">
            <p className="title">
Свяжитесь с нами
            </p>
            <p>
              <a href="tel:+74951327462">
+7(495) 132-74-62
              </a>
            </p>
            <p>
              <a href="mail:privet@faradise.ru">
privet@faradise.ru
              </a>
            </p>
          </div>
        </SuppliersBox>

        {!isInnerPage && <MainFooter />}
      </React.Fragment>
    )
  }
}

export { Suppliers }
