export const whyCheckBox = [
  {
    label: 'Хочу сделать ремонт',
    placeholder: '',
    withInput: false,
    id: 1,
    model: 'Renovation'
  },
  {
    label: 'Хочу обновить интерьер (без ремонта)',
    placeholder: '',
    withInput: false,
    id: 2,
    model: 'Renew'
  },
  {
    label: 'Переехал(а) в новую квартиру/комнату',
    placeholder: '',
    withInput: false,
    id: 3,
    model: 'MoveToNewPlace',
  },
  {
    label: 'Ищу новую мебель',
    placeholder: '',
    withInput: false,
    id: 4,
    model: 'SearchForNewFurniture',
  },
  {
    label: 'Съехался(ась) с человеком и пора обновить интерьер',
    placeholder: '',
    withInput: false,
    id: 5,
    model: 'StartLivingTogether',
  },
  {
    label: 'Купил(а) новую квартиру/дом',
    placeholder: '',
    withInput: false,
    id: 6,
    model: 'NewHouse',
  },
  {
    label: 'Переехали в новый офис',
    placeholder: '',
    withInput: false,
    id: 7,
    model: 'NewOffice',
  },
  {
    label: 'Другое',
    placeholder: 'Напишите свою причину',
    withInput: true,
    id: 8,
    model: 'Custom'
  }
]
