import artDeco from '@assets/images/styles/artDeco.png'
import cantry from '@assets/images/styles/cantry.png'
import ecl from '@assets/images/styles/ecl.png'
import fusion from '@assets/images/styles/fusion.png'
import industrial from '@assets/images/styles/industrial.png'
import min from '@assets/images/styles/min.png'
import modern from '@assets/images/styles/modern.png'
import modernClassic from '@assets/images/styles/modernClassic.png'
import provance from '@assets/images/styles/provance.png'
import scandinavian from '@assets/images/styles/scandinavian.png'

const stylesList = [
  {
    img: ecl,
    name: 'Эклектика',
    id: 1,
    model: 'Eclecticism'
  },
  {
    img: provance,
    name: 'Прованс',
    id: 2,
    model: 'Provence'
  },
  {
    img: fusion,
    name: 'Фьюжн',
    id: 3,
    model: 'Fusion'
  },
  {
    img: artDeco,
    name: 'Арт-Деко',
    id: 4,
    model: 'ArtDeco'
  },
  {
    img: cantry,
    name: 'Кантри',
    id: 5,
    model: 'Country'
  },
  {
    img: modernClassic,
    name: 'Современная классика',
    id: 6,
    model: 'ModernClassic'
  },
  {
    img: scandinavian,
    name: 'Скандинавский',
    id: 7,
    model: 'Scandinavian'
  },
  {
    img: industrial,
    name: 'Лофт',
    id: 8,
    model: 'Industrial'
  },
  {
    img: modern,
    name: 'Современный',
    id: 9,
    model: 'Modern'
  },
  {
    img: min,
    name: 'Минимализм',
    model: 'Minimalism',
    id: 10
  }
]

export { stylesList }
