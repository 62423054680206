import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Icon } from '@ui/atoms';
import { COLORS } from '@constants/colors';
import ROOMS from '../../constants/rooms';

const RoomTabsBox = styled.div`
  border-bottom: 1px solid ${COLORS.getBlueColor(0.12)};
  display: flex;
  margin-right: -10000px;
  margin-left: -165px;
  padding-left: 165px;
  position: relative;
  align-items: center;
  
  &:after {
    content: '';
    width: 100%;
    height: 3px;
    transform: translateY(100%);
    position: absolute;
    bottom: -1px;
    z-index: 3;
    background-color: white;
  }
  
  .rooms-wrapper {
    width: 750px;
    overflow: hidden;
  }
  
  .rooms-track {
    display: flex;
    position: relative;
  }
  
  .room {
    height: 90px;
    width: calc(750px / 5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: ${COLORS.getBlueColor(0.03)};
    border: 0.5px solid ${COLORS.getBlueColor(0.12)};
    flex-shrink: 0;
    user-select: none;
    
    &:not(.active):hover {
      background-color: ${COLORS.getBlueColor(0.05)};
    }
    
    svg {
      fill: ${COLORS.getBlueColor(0.7)};
      height: 32px;
      width: 32px;
    }
    
    &.active {
      box-shadow: 0 -2px 4px 0 ${COLORS.getBlueColor(0.12)};
      background-color: white;
      border-top: 2px solid ${COLORS.orange};
      border-bottom: none;
      
      svg {
        fill: ${COLORS.getBlueColor()};
      }
      
      .title {
        font-family: Roboto-Medium;
        font-weight: 500;
      }
    }
    
    .title {
      font-family: Roboto-Regular;
      font-size: 12px;
      line-height: 18px;
      color: ${COLORS.getBlueColor()};
      margin-top: 8px;
    }
  }
  
  .navigation-arrow {
    cursor: pointer;
    
    svg {
      width: 20px;
      fill: ${COLORS.getBlueColor(0.12)};
    }
    
    &:not(.disabled):hover {
      svg {
        fill: ${COLORS.orange};
      }
    }
    
    &.next svg {
      position: relative;
      left: 5px;
    }
  }
`;

class RoomTabs extends React.Component {
  next = () => {
    const { active, onChange, items } = this.props;

    if (active !== items.length - 1) {
      onChange(active + 1)
    }
  }

  handleTranslate = (active, items) => {
    if (items.length < 5) {
      return 0;
    }

    if (active < 2) {
      return 0;
    }

    if (active > items.length - 3) {
      return (items.length - 3) * -150 + 300;
    }

    return active * -150 + 300;
  }

  prev = () => {
    const { active, onChange } = this.props;

    if (active !== 0) {
      onChange(active - 1)
    }
  }

  render() {
    const { active, items, onChange } = this.props;
    const translate = this.handleTranslate(active, items);

    return (
      <RoomTabsBox>
        {items.length > 5 && (
          <div
            className={classNames('navigation-arrow', {
              disabled: active === 0
            })}
            onClick={this.prev}
          >
            <Icon
              icon="chevronLeft"
            />
          </div>
        )}
        <div className="rooms-wrapper">
          <div
            className="rooms-track"
            style={{
              transform: `translateX(${translate}px)`
            }}
          >
            {items.map((item, index) => {
              const room = ROOMS.find(ROOM => ROOM.model === item.model);

              return (
                <div
                  className={classNames('room', {
                    active: index === active,
                    g_disabled: item.disabled
                  })}
                  onClick={() => onChange(index)}
                  key={`room-tab-${Math.random()}`}
                >
                  <Icon icon={room.img} />
                  <div className="title">
                    {room.name}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {items.length > 5 && (
          <div
            className={classNames('navigation-arrow next', {
              disabled: active === items.length - 1
            })}
            onClick={this.next}
          >
            <Icon
              icon="chevronRight"
            />
          </div>
        )}
      </RoomTabsBox>
    )
  }
}

export { RoomTabs }
