import styled from 'styled-components'
import { COLORS } from '@constants/colors'

const HeaderBox = styled.div`
  position: fixed;
  background-color: ${COLORS.white};
  z-index: 3333;
  border-bottom: 1px solid ${COLORS.getBlueColor(0.06)};
  width: 100%;
  top: 0;
  left: 0;

  .logo {
    height: 26px;
    margin-right: 32px;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  .top-row {
    box-shadow: 0 2px 4px 0 ${COLORS.getBlueColor(0.08)};
    padding: 0 165px;

    .top-row-content {
      max-width: 1110px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .left-side {
      display: flex;
      align-items: center;
    }
  }

  .bottom-row {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 165px;
  }

  .tabs {
    display: flex;
  }

  .tab {
    padding: 26px 16px 22px 16px;
    font-family: Roboto-Medium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.8px;
    font-weight: 500;
    color: ${COLORS.getBlueColor(0.87)};
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      width: 24px;
      height: 24px;
      fill: ${COLORS.getBlueColor(0.7)};
    }

    &.active {
      color: ${COLORS.orange};
      border-bottom: 2px solid ${COLORS.orange};
    }

    &.disabled {
      opacity: 0.54;
      pointer-events: none;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
  }

  .menu-control {
    padding: 8px;
    border: 1px solid ${COLORS.getBlueColor(0.12)};
    border-radius: 4px;
    cursor: pointer;
    margin-left: 8px;
    height: 40px;
    position: relative;

    &.opened {
      background-color: ${COLORS.getBlueColor(0.05)};
      border: 1px solid ${COLORS.getBlueColor()};
    }
  }

  .trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .notifications {
    svg {
      width: 22px;
      height: 22px;
      fill: ${COLORS.getBlueColor(0.7)};
    }
  }

  .profile {
    position: relative;

    svg {
      fill: ${COLORS.getBlueColor(0.7)};
      width: 24px;
      height: 24px;
      pointer-event: none;
    }

    .pop-over {
      width: 200px;
      right: 0;
      bottom: -16px;

      .name {
        color: ${COLORS.getBlueColor()};
      }

      .phone {
        color: ${COLORS.getBlueColor(0.54)};
      }

      .logout {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        padding: 12px 0;
        cursor: pointer;
        color: ${COLORS.orange};
        background-color: ${COLORS.getBlueColor(0.05)};
        font-family: Roboto-Medium;
      }
    }
  }

  .projects {
    position: relative;

    &.opened {
      background-color: ${COLORS.getBlueColor(0.05)};

      & > svg {
        transform: rotate(180deg);
      }
    }

    .pop-over {
      width: 335px;
      left: 0;
      letter-spacing: 0;
    }
  }

  .pop-over {
    position: absolute;
    bottom: 0;
    background-color: ${COLORS.white};
    transform: translateY(100%);
    border-radius: 4px;
    box-shadow: 0 20px 32px 0 rgba(71, 75, 86, 0.15),
      0 0 1px 0 rgba(71, 75, 86, 0.31);
    cursor: default;

    .add-project {
      padding: 12px 16px;
      background-color: ${COLORS.getBlueColor(0.05)};
      color: ${COLORS.orange};
      font-size: 16px;
      line-height: 24px;
      font-family: Roboto-Medium;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        fill: ${COLORS.orange};
        margin-right: 8px;
      }
    }

    .body {
      padding: 16px;
      font-family: Roboto-Regular;
      line-height: 24px;
      font-size: 16px;
    }

    .project {
      padding: 12px 16px;
      font-size: 14px;
      color: ${COLORS.getBlueColor(0.87)};
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: ${COLORS.getBlueColor(0.05)};
      }

      &.active {
        border: 1px solid ${COLORS.getBlueColor()};
        background-color: ${COLORS.getBlueColor(0.05)};
      }
    }
  }

  .active-project-block {
    border-left: 1px solid ${COLORS.getBlueColor(0.12)};
    padding-left: 27px;

    .project {
      font-family: Roboto-Medium;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
      color: ${COLORS.getBlueColor(0.38)};
    }

    .name {
      font-family: Roboto-Regular;
      font-size: 16px;
      line-height: 20px;
      color: ${COLORS.getBlueColor()};
    }
  }

  &.short {
    left: 270px;

    .top-row,
    .bottom-row {
      padding: 0 0 0 95px;
      margin: 0;
    }

    .top-row-content {
      width: 750px;
      margin: 0;
    }

    .bottom-row {
      width: 845px;
    }
  }

  .new-project {
    font-size: Roboto-Regular;
    font-size: 16px;
    line-height: 20px;
    color: ${COLORS.getBlueColor()};
  }

  .main-steps {
    padding-top: 16px;
    justify-content: flex-start;

    .step {
      padding-bottom: 14px;
    }
  }

  .search-box {
    margin-left: 165px;
    margin-right: 165px;
  }
`

export { HeaderBox }
